function collapsibleShow(button: string, container: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)

  element1?.classList.remove('collapsed')
  element2?.classList.add('show')
}

function collapsibleHide(button: string, container: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)

  element1?.classList.add('collapsed')
  element2?.classList.remove('show')
}

function collapsible(button: string, container: string) {
  let element = document.getElementById(button)

  if (element?.classList.contains('collapsed')) {
    collapsibleShow(button, container)
  } else {
    collapsibleHide(button, container)
  }
}

export { collapsibleShow, collapsibleHide, collapsible }
