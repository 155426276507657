import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { Company } from '../../../models/company.model';

export interface TreasuryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  companyForUser: Company | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  setTreasuryAccountsForCompany: Dispatch<SetStateAction<TreasuryAccount[] | undefined | null>>
};

const TreasuryPage: React.FunctionComponent<TreasuryPageProps> = props => {
  const {setRoute, companyForUser, treasuryAccountsForCompany, setTreasuryAccountsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, companyForUser, treasuryAccountsForCompany, setTreasuryAccountsForCompany}}></Outlet>
  );
}

export function UseOutletContextTreasury() {
  return useOutletContext<ContexType>()
};

export default TreasuryPage;
