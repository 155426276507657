import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getMessage } from '../../languages/translations/response';
import { legendInvalid, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';
import { evaluateLegendValidateEmptyIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../../components/element/Legend';

import logo from '../../assets/images/svg/logo-official.svg';
import background from '../../assets/images/themes/image-new.png';

export interface ComingSoonPageProps {};

const ComingSoonPage: React.FunctionComponent<ComingSoonPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [email, setEmail] = useState({value: '', valid: false})
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const submitNotifyMe = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (email.valid && email.value.length > 0) {
      Swal.fire({
        title: getMessage('Successfully subscribed', lang.code),
        text: lang.labels.thankYouVeryMuchForSubscribingRedirectingToPreviousPage,
        icon: 'success',
        buttonsStyling: !1,
        confirmButtonText: lang.labels.okGotIt,
        customClass: {confirmButton: 'btn btn-primary'}
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
        navigate(-1)
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (email.value.length === 0) {
          setEmail({...email , valid: false})
          legendInvalid('container-validate-email-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const validateEmail = () => {
    evaluateLegendValidateEmptyIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid')
    legendValidInvalidRestart('container-validate-email-required')
  }

  useEffect(() => {
    const targetDate = new Date('2025-08-31T23:59:59Z')
    const currentDate = new Date()

    const interval = setInterval(() => {
      let difference = targetDate.getTime() - currentDate.getTime()
      let remainingDays = Math.floor(difference / (1000 * 60 * 60 * 24))
      let remainingHours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let remainingMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      let remainingSeconds = Math.floor((difference % (1000 * 60)) / 1000)

      setDays(remainingDays)
      setHours(remainingHours)
      setMinutes(remainingMinutes)
      setSeconds(remainingSeconds)

      if (difference <= 0) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid">
        <div className="d-flex flex-column flex-column-fluid flex-center p-10">
          <div className="cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-50px h-lg-75px mb-5" />
          </div>
          <div className="pt-5 mb-10 text-center">
            <h1 className="fs-4x text-gray-900 text-capitalize mb-5">{lang.labels.comingSoon}</h1>
            <div className="fw-bold fs-3 text-gray-400">
              {lang.labels.thePageYouLookedNotFound}
              <br />
              {lang.labels.pageInProduction}
            </div>
          </div>
          <div className="d-flex text-center mb-10">
            <div className="w-75px rounded-3 bg-body shadow p-5 mx-3">
              <div className="fs-2 fw-bolder text-gray-800">{days}</div>
              <div className="fs-7 fw-bold text-muted text-lowercase">{lang.labels.days}</div>
            </div>
            <div className="w-75px rounded-3 bg-body shadow p-5 mx-3">
              <div className="fs-2 fw-bolder text-gray-800">{hours}</div>
              <div className="fs-7 text-muted text-lowercase">{lang.labels.hrs}</div>
            </div>
            <div className="w-75px rounded-3 bg-body shadow p-5 mx-3">
              <div className="fs-2 fw-bolder text-gray-800">{minutes}</div>
              <div className="fs-7 text-muted text-lowercase">{lang.labels.min}</div>
            </div>
            <div className="w-75px rounded-3 bg-body shadow p-5 mx-3">
              <div className="fs-2 fw-bolder text-gray-800">{seconds}</div>
              <div className="fs-7 text-muted text-lowercase">{lang.labels.sec}</div>
            </div>
          </div>
          <div className="fw-bolder fs-2 text-gray-400 mb-5">{lang.labels.getNotifiedWhenWeLaunch}</div>
          <form className="form fv-row rounded m-0 p-3 bg-body fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container" noValidate onSubmit={submitNotifyMe}>
            <div className="d-flex flex-center">
              <input id="input-email" className="form-control form-control-lg form-control-solid w-md-275px me-2" type="email" name="email" autoComplete="off" placeholder={lang.labels.emailAddress} value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail}/>
              <button className="btn btn-lg btn-primary text-nowrap ms-2" type="submit" data-kt-indicator={loadIndicator}>
                <span className="indicator-label fw-bolder">{lang.labels.notifyMe}</span>
                <span className="indicator-progress">
                  {lang.labels.pleaseWait}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
            <AppLegend component={null} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
          </form>
          <img src={background} alt={lang.labels.background} className="mt-5 h-200px h-md-100px" />
        </div>
      </div>
    </div>
  )
};

export default ComingSoonPage;
