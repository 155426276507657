import { Language } from "../languages/labels/language.labels"

export function qrTemplateList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'qr-iban', description: lang.labels.qrIbanAndQrReference },
    { code: 'scor', description: lang.labels.ibanAndStructuredCreditorReference },
    { code: 'no-reference', description: lang.labels.ibanWithoutReference },
    { code: 'none', description: lang.labels.none }
  ]
}

export function qrTemplateValue(lang: Language, code: string): string {
  const qrTemplate = qrTemplateList(lang).find(item => item.code === code)
  return qrTemplate ? qrTemplate.description : lang.labels.undefined
}
