import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextService } from '../service/Service';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ServiceDA } from '../../../../services/service.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { legendInvalid, legendInvalidIcon } from '../../../../tools/legend.data.entry.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { validateAttributes } from '../../../../scripts/validate.attributes.script';

import AppServiceForm from './card/ServiceForm';

export interface ServiceCreatePageProps {};

let errorResponse: Error, messageResponse: Message;

const ServiceCreatePage: React.FunctionComponent<ServiceCreatePageProps> = props => {
  const {setRoute, settingsForCompany, companyForUser, categoriesForCompany, loadServicesForCompany} = UseOutletContextService()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [description, setDescription] = useState({value: '', valid: false})
  const [category, setCategory] = useState({value: '', valid: false})
  const [code, setCode] = useState({value: '', valid: true})
  const [sku, setSku] = useState({value: '', valid: true})
  const [codebar, setCodebar] = useState({value: '', valid: true})
  const [price, setPrice] = useState({value: '', valid: false})
  const [taxRate, setTaxRate] = useState({value: '', valid: false})
  const [unit, setUnit] = useState({value: '', valid: false})
  const [discount, setDicount] = useState({value: {type: 'none', value: '', quantity: ''}, valid: {type: true, value: true, quantity: true}})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  const [photos, setPhotos] = useState({value: [] as {view: string, file: any}[], valid: {gallery: true, list: [] as {file: boolean, message: string}[]}})
  const [tags, setTags] = useState({value: [] as string[], valid: true})

  const submitServiceCreate = async () => {
    setLoadIndicator('on')

    const namesIsValid: boolean = !Object.values(names.valid).includes(false)
    const discountIsValid: boolean = !Object.values(discount.valid).includes(false)
    const photosIsValid: boolean = validateAttributes(photos.valid.list)

    if (companyForUser && name.valid && description.valid && code.valid && sku.valid && codebar.valid && price.valid && taxRate.valid && unit.valid && category.valid && tags.valid && discountIsValid && namesIsValid &&photosIsValid) {
      let listPhotos: any[] = photos.value.map((sub_item: any) => {return sub_item.file})

        await ServiceDA.postService(companyForUser.id, name.value, description.value, code.value, sku.value, codebar.value, price.value, taxRate.value, unit.value, category.value, discount.value, names.value, tags.value, listPhotos).then( (response) => {
        if (response.status === 201) { 
          messageResponse = response.data

          loadServicesForCompany(companyForUser.id)

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/inventory/service/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'container-validate-name-required')
          legendInvalidIcon('input-name-other', 'container-validate-name-other-required')
        }
        if (category.value.length === 0) {
          legendInvalid('container-validate-category-required')
        }
        if (description.value.length === 0) {
          legendInvalidIcon('input-description', 'container-validate-description-required')
        }
        if (unit.value.length === 0) {
          legendInvalid('container-validate-unit-required')
        }
        if (taxRate.value.length === 0) {
          legendInvalid('container-validate-tax-required')
        }
        if (price.value.length === 0) {
          legendInvalidIcon('input-price', 'container-validate-price-required')
        }
        if (discount.value.type !== 'none') {
          if (discount.value.value.length === 0) {
            legendInvalidIcon('input-discount-value', 'container-validate-discount-value-required')
          }
          if (discount.value.quantity.length === 0) {
            legendInvalidIcon('input-discount-quantity', 'container-validate-discount-quantity-required')
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.services, branch: lang.labels.createService}, company: false})
    uploadTooltip()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { settingsForCompany && categoriesForCompany
        ?
        <AppServiceForm action={"create"} loadIndicator={loadIndicator} name={name} description={description} category={category} code={code} sku={sku} codebar={codebar} price={price} taxRate={taxRate} unit={unit} discount={discount} names={names} photos={photos} tags={tags} setName={setName} setDescription={setDescription} setCategory={setCategory} setCode={setCode} setSku={setSku} setCodebar={setCodebar} setPrice={setPrice} setTaxRate={setTaxRate} setUnit={setUnit} setDicount={setDicount} setNames={setNames} setPhotos={setPhotos} setTags={setTags} submitService={submitServiceCreate}></AppServiceForm>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default ServiceCreatePage;
