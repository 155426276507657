import { Language } from "../languages/labels/language.labels"

export function languageDownloadList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'EN', description: lang.labels.english },
    { code: 'FR', description: lang.labels.french },
    { code: 'IT', description: lang.labels.italian },
    { code: 'DE', description: lang.labels.german }
  ]
}

export function languageDownloadValue(lang: Language, code: string): string {
  const language = languageDownloadList(lang).find(item => item.code === code)
  return language ? language.description : lang.labels.undefined
}
