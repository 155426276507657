import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { monthValue } from '../../libraries/month.library';
import { Company } from '../../models/company.model';

export interface AppChartBarSalesPurchasesProps {
  id: string,
  companyForUser: Company,
  info: {month: string, total_sales: number, total_purchases: number}[]
};

const AppChartBarSalesPurchases: React.FunctionComponent<AppChartBarSalesPurchasesProps> = ({id, companyForUser, info}) => {
  const {lang} = StateLanguage()

  const state = {
    series: [{
      name: lang.labels.amountSales,
      data: info.map((item) => {
        return item.total_sales
      })
    }, {
      name: lang.labels.amountPurchases,
      data: info.map((item) => {
        return item.total_purchases
      })
    }],
    options: {
      chart: {
        id: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "85%",
          endingShape: "rounded"
        },
      },
      colors: ["#50CD89", "#F1416C"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: info.map((item) => {
          return monthValue(lang, item.month).toLocaleUpperCase()
        }),
      },
      yaxis: {
        title: {
          text: `${companyForUser.currency.code}: ${companyForUser.currency.name} (${companyForUser.currency.symbol})`
        }
      },
      fill: {
        opacity: 1
      }
    }
  }

  return (
    <div id={id}>
      <ReactApexChart options={state.options} series={state.series} type="bar" height={400} />
    </div>
  )
};

export default AppChartBarSalesPurchases;
