import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import logo from '../../assets/images/svg/logo-official.svg';
import background from '../../assets/images/themes/image-error.png';

export interface NotFoundPageProps {};

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate('/', {replace: true})
  }

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid">
        <div className="d-flex flex-column flex-column-fluid text-center p-10">
          <div className="cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-50px h-lg-75px mb-5" />
          </div>
          <div className="pt-5 mb-10">
            <h1 className="fs-4x text-gray-900 text-capitalize mb-5">{lang.labels.pageNotFound}</h1>
            <div className="fw-bold fs-3 text-gray-400 mb-10">
              {lang.labels.thePageYouLookedNotFound}
              <br />
              {lang.labels.pleaseCorrectThePathOfThePageInOrderToAccess}
            </div>
            <div className="text-center">
              <button className="btn btn-lg btn-primary fw-bolder" onClick={handleOnClick}>{lang.labels.goToPreviousPage}</button>
            </div>
          </div>
          <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-150px" style={{backgroundImage: `url(${background})`}}></div>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.purchase}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default NotFoundPage;
