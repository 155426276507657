import React from 'react';
import { Outlet } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import logo from '../../assets/images/svg/logo-official.svg';
import background from '../../assets/images/themes/image-welcome.png';

export interface AuthPageProps {};

const AuthPage: React.FunctionComponent<AuthPageProps> = props => {
  const {lang} = StateLanguage()

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{backgroundImage: `url(${background})`}}>
        <div className="d-flex flex-center flex-column flex-column-fluid p-10">
          <div className="mb-10 cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-60px" />
          </div>
          <Outlet></Outlet>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.contact}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
