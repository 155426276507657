import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { HexColorPicker } from 'react-colorful';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCompany } from '../Company';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { SettingsDA } from '../../../../services/settings.services';
import { Error } from '../../../../models/error.model';
import { Settings } from '../../../../models/settings.model';
import { Template } from '../../../../models/template.model';
import { legendInvalid, legendValidInvalidRestart } from '../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidateRequiredList } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { colorTemplateDescription } from '../../../../libraries/template.color.library';

import AppLegend from '../../../../components/element/Legend';

import imgEmpty from '../../../../assets/images/empty.png';

export interface AppCompanyUpdateDownloadProps {};

let errorResponse: Error, settingsResponse: Settings;

const AppCompanyUpdateDownload: React.FunctionComponent<AppCompanyUpdateDownloadProps> = props => {
  const {appServiceForUser, companyForUser, settingsForCompany, templates, loadCompanyForUser} = UseOutletContextCompany()
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [template, setTemplate] = useState({value: '', object: null as any, valid: false})
  const [colors, setColors] = useState({value: [] as string[], valid: [] as boolean[]})

  const submitCompanyUpdate = async () => {
    setLoadIndicator('on')

    let colorsIsValid: boolean = colors.valid.every((item) => (item === true))

    if (appServiceForUser && companyForUser && colorsIsValid && settingsForCompany && templates && colors.valid && template.valid) {
      let templateList: Template[] = settingsForCompany.templates.concat(templates) as Template[]
      let templateSelected: Template = templateList.find((item) => (item.id === template.value)) as Template
      let deadlineList: {id: string, type: string, quantity: string}[] = settingsForCompany.payment_deadlines.map((item) => { return {id: item.id, type: item.type, quantity: item.quantity.toString()} })

      await SettingsDA.putSettings(companyForUser.id, templateSelected, colors.value, settingsForCompany.tax_fees, deadlineList, settingsForCompany.measure_units).then( (response) => {
        if (response.status === 200) {
          settingsResponse = response.data

          if (settingsResponse) {
            loadCompanyForUser(appServiceForUser.api_key, companyForUser.id, () => {
              Swal.fire({
                title: lang.labels.successfullyUpdatedCompanySettings,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
                restartForm()
              })
            })
          } else {
            Swal.fire({
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        for (let i = 0; i < colors.valid.length; i++) {
          if (colors.value[i].length === 0) {
            legendInvalid('container-validate-color-required-' + i)
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeColor = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempColorsValue = colors.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return event.target.value
      } else {
        return temp_item
      }
    })

    setColors({...colors, value: tempColorsValue})
  }

  const handleChangeColorPicker = (index: number, item: string) => {
    let tempColorsValue = colors.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item = item
      }
      return temp_item
    })
    let tempColorsValid = colors.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item = true
      }
      return temp_item
    })

    setColors({...colors, value: tempColorsValue, valid: tempColorsValid})
  }

  const handleChangeTemplate = (item: Template) => {
    setTemplate({...template, value: item.id, object: item, valid: true})
  }

  const validateColor = (index: number) => {
    evaluateLegendValidateRequiredList(expressions.hexadecimal, index, colors, setColors, 'container-validate-color-valid-' + index, 'container-validate-color-required-' + index)
  }

  const executeFormRestart = () => {
    if (settingsForCompany) {
      uploadData()
      restartForm()
    }
  }

  function colorHex(index: number): string {
    if (colors.valid[index]) {
      return colors.value[index]
    } else {
      return '#00000000'
    }
  }

  function uploadData() {
    if (settingsForCompany) {
      let tempColorsValue: string[] = []
      let tempColorsValid: boolean[] = []

      for(let color of settingsForCompany.colors) {
        tempColorsValue.push(color)
        tempColorsValid.push(true)
      }

      setTemplate({value: settingsForCompany.template_current.id, object: settingsForCompany.template_current, valid: true})
      setColors({value: tempColorsValue, valid: tempColorsValid})
    }
  }

  function restartForm() {
    for (let i = 0; i < colors.valid.length; i++) {
      legendValidInvalidRestart('container-validate-color-valid-' + i)
      legendValidInvalidRestart('container-validate-color-required-' + i)
    }
  }

  useEffect( () => {
    uploadData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsForCompany])

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title fs-4 fw-bolder">{lang.labels.companyDownloadSettings}</div>
      </div>
      { settingsForCompany && templates
        ?
        <div className="form">
          <div className="card-body">
            <div className="mb-5">
              <div className="fs-6 fw-bold">{lang.labels.selectAndSaveOneToFourDesiredColors}</div>
            </div>
            <div className="row g-5 mb-10">
              { colors.value.map (( (item, index) => { return (
                <div key={index} className="col">
                  <div className="card bg-light">
                    <div className="card-body text-center p-0">
                      <div className="text-gray-800 text-hover-dark fw-bold">
                        <Dropdown>
                          <Dropdown.Toggle variant="w-100 my-5 p-0">
                            <div className="symbol w-200px h-100px" style={{backgroundImage: `url(${imgEmpty})`}}>
                              <div className="symbol w-100 h-100" style={{backgroundColor: `${colorHex(index)}`}}>
                                <input className="border-top-0 border-start-0 border-end-0 w-80px text-center fw-bold p-0 mt-10" type="text" name="color" maxLength={7} value={item} onChange={(event) => handleChangeColor(index, event)}  onKeyUp={() => validateColor(index)} onBlur={() => validateColor(index)} />
                                <AppLegend component={null} attribute={{validity: colors.valid[index], name: "color", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="w-100 p-0">
                            <HexColorPicker color={colorHex(index)} onChange={(event) => handleChangeColorPicker(index, event)} />
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="text-uppercase mb-5">{colorTemplateDescription(lang, index)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}))}
            </div>
            <div className="mb-5">
              <div className="fs-6 fw-bold">{lang.labels.selectAndSaveDesiredLayoutToDownload}</div>
            </div>
            <div className="d-flex align-items-center justify-content-end mb-5">
              <div className='d-print-none border border-dashed border-gray-300 p-2'>
                <span className="badge badge-light-info mx-1">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 svg-icon-info me-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"/>
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
                      </svg>
                    </span>
                    {lang.labels.current}
                  </div>
                </span>
                <span className="badge badge-light-warning mx-1">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 svg-icon-warning me-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                        <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="black"/>
                      </svg>
                    </span>
                    {lang.labels.private}
                  </div>
                </span>
                <span className="badge badge-light-success mx-1">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 svg-icon-success me-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="black"/>
                      </svg>
                    </span>
                    {lang.labels.free}
                  </div>
                </span>
                <span className="badge badge-light-danger mx-1">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 svg-icon-danger me-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M18 22H6C5.4 22 5 21.6 5 21V8C6.6 6.4 7.4 5.6 9 4H15C16.6 5.6 17.4 6.4 19 8V21C19 21.6 18.6 22 18 22ZM12 5.5C11.2 5.5 10.5 6.2 10.5 7C10.5 7.8 11.2 8.5 12 8.5C12.8 8.5 13.5 7.8 13.5 7C13.5 6.2 12.8 5.5 12 5.5Z" fill="black"/>
                        <path d="M12 7C11.4 7 11 6.6 11 6V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V6C13 6.6 12.6 7 12 7ZM15.1 10.6C15.1 10.5 15.1 10.4 15 10.3C14.9 10.2 14.8 10.2 14.7 10.2C14.6 10.2 14.5 10.2 14.4 10.3C14.3 10.4 14.3 10.5 14.2 10.6L9 19.1C8.9 19.2 8.89999 19.3 8.89999 19.4C8.89999 19.5 8.9 19.6 9 19.7C9.1 19.8 9.2 19.8 9.3 19.8C9.5 19.8 9.6 19.7 9.8 19.5L15 11.1C15 10.8 15.1 10.7 15.1 10.6ZM11 11.6C10.9 11.3 10.8 11.1 10.6 10.8C10.4 10.6 10.2 10.4 10 10.3C9.8 10.2 9.50001 10.1 9.10001 10.1C8.60001 10.1 8.3 10.2 8 10.4C7.7 10.6 7.49999 10.9 7.39999 11.2C7.29999 11.6 7.2 12 7.2 12.6C7.2 13.1 7.3 13.5 7.5 13.9C7.7 14.3 7.9 14.5 8.2 14.7C8.5 14.9 8.8 14.9 9.2 14.9C9.8 14.9 10.3 14.7 10.6 14.3C11 13.9 11.1 13.3 11.1 12.5C11.1 12.3 11.1 11.9 11 11.6ZM9.8 13.8C9.7 14.1 9.5 14.2 9.2 14.2C9 14.2 8.8 14.1 8.7 14C8.6 13.9 8.5 13.7 8.5 13.5C8.5 13.3 8.39999 13 8.39999 12.6C8.39999 12.2 8.4 11.9 8.5 11.7C8.5 11.5 8.6 11.3 8.7 11.2C8.8 11.1 9 11 9.2 11C9.5 11 9.7 11.1 9.8 11.4C9.9 11.7 10 12 10 12.6C10 13.2 9.9 13.6 9.8 13.8ZM16.5 16.1C16.4 15.8 16.3 15.6 16.1 15.4C15.9 15.2 15.7 15 15.5 14.9C15.3 14.8 15 14.7 14.6 14.7C13.9 14.7 13.4 14.9 13.1 15.3C12.8 15.7 12.6 16.3 12.6 17.1C12.6 17.6 12.7 18 12.9 18.4C13.1 18.7 13.3 19 13.6 19.2C13.9 19.4 14.2 19.5 14.6 19.5C15.2 19.5 15.7 19.3 16 18.9C16.4 18.5 16.5 17.9 16.5 17.1C16.7 16.8 16.6 16.4 16.5 16.1ZM15.3 18.4C15.2 18.7 15 18.8 14.7 18.8C14.4 18.8 14.2 18.7 14.1 18.4C14 18.1 13.9 17.7 13.9 17.2C13.9 16.8 13.9 16.5 14 16.3C14.1 16.1 14.1 15.9 14.2 15.8C14.3 15.7 14.5 15.6 14.7 15.6C15 15.6 15.2 15.7 15.3 16C15.4 16.2 15.5 16.6 15.5 17.2C15.5 17.7 15.4 18.1 15.3 18.4Z" fill="black"/>
                      </svg>
                    </span>
                    {lang.labels.payment}
                  </div>
                </span>
              </div>
            </div>
            <div className="row g-5">
              { settingsForCompany.templates.map (( (item, index) => { return (
                <div key={index} className="col-md-6 col-lg-4 col-xl-3">
                  <div className={`card h-100 flex-center p-5 ${template.valid && template.value === item.id ? "bg-light-primary border-primary border border-dashed" : "bg-light"}`}>
                    <div className="position-relative d-flex flex-center align-items-end h-125px w-125px">
                      <span className="svg-icon svg-icon-2x svg-icon-warning position-absolute top-0 start-0 translate-middle">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                          <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="black"/>
                        </svg>
                      </span>
                      { item.id === settingsForCompany.template_current.id &&
                        <span className="svg-icon svg-icon-2x svg-icon-info position-absolute top-0 start-100 translate-middle">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"/>
                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
                          </svg>
                        </span>
                      }
                      <div className="template-model symbol symbol-100px mb-3 position-relative" data-bs-toggle="modal" data-bs-target={`#template-model-${item.name.replaceAll(" ", "-")}`}>
                        <img src={item.thumbnail} alt={lang.labels.template} className="theme-light-show template-model-image" />
                        <label className="template-model-label text-capitalize fw-bold">{item.name}</label>
                      </div>
                    </div>
                    <button className="btn btn-bg-secondary btn-active-color-primary btn-sm fw-bold w-100" type="button" onClick={() => handleChangeTemplate(item)}>{lang.labels.selectModel}</button>
                  </div>
                  <div id={`template-model-${item.name.replaceAll(" ", "-")}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img src={item.thumbnail} alt={lang.labels.template} className="theme-light-show w-100 h-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}))}
              { templates.map (( (item, index) => { return (
                <div key={index} className="col-md-6 col-lg-4 col-xl-3">
                  <div className={`card h-100 flex-center p-5 ${template.valid && template.value === item.id ? "bg-light-primary border-primary border border-dashed" : "bg-light"}`}>
                    <div className="position-relative d-flex flex-center align-items-end h-125px w-125px">
                      <span className={`svg-icon svg-icon-2x svg-icon-${ item.price > 0 ? "danger" : "success"} position-absolute top-0 start-0 translate-middle`}>
                        { item.price > 0
                          ?
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M18 22H6C5.4 22 5 21.6 5 21V8C6.6 6.4 7.4 5.6 9 4H15C16.6 5.6 17.4 6.4 19 8V21C19 21.6 18.6 22 18 22ZM12 5.5C11.2 5.5 10.5 6.2 10.5 7C10.5 7.8 11.2 8.5 12 8.5C12.8 8.5 13.5 7.8 13.5 7C13.5 6.2 12.8 5.5 12 5.5Z" fill="black"/>
                            <path d="M12 7C11.4 7 11 6.6 11 6V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V6C13 6.6 12.6 7 12 7ZM15.1 10.6C15.1 10.5 15.1 10.4 15 10.3C14.9 10.2 14.8 10.2 14.7 10.2C14.6 10.2 14.5 10.2 14.4 10.3C14.3 10.4 14.3 10.5 14.2 10.6L9 19.1C8.9 19.2 8.89999 19.3 8.89999 19.4C8.89999 19.5 8.9 19.6 9 19.7C9.1 19.8 9.2 19.8 9.3 19.8C9.5 19.8 9.6 19.7 9.8 19.5L15 11.1C15 10.8 15.1 10.7 15.1 10.6ZM11 11.6C10.9 11.3 10.8 11.1 10.6 10.8C10.4 10.6 10.2 10.4 10 10.3C9.8 10.2 9.50001 10.1 9.10001 10.1C8.60001 10.1 8.3 10.2 8 10.4C7.7 10.6 7.49999 10.9 7.39999 11.2C7.29999 11.6 7.2 12 7.2 12.6C7.2 13.1 7.3 13.5 7.5 13.9C7.7 14.3 7.9 14.5 8.2 14.7C8.5 14.9 8.8 14.9 9.2 14.9C9.8 14.9 10.3 14.7 10.6 14.3C11 13.9 11.1 13.3 11.1 12.5C11.1 12.3 11.1 11.9 11 11.6ZM9.8 13.8C9.7 14.1 9.5 14.2 9.2 14.2C9 14.2 8.8 14.1 8.7 14C8.6 13.9 8.5 13.7 8.5 13.5C8.5 13.3 8.39999 13 8.39999 12.6C8.39999 12.2 8.4 11.9 8.5 11.7C8.5 11.5 8.6 11.3 8.7 11.2C8.8 11.1 9 11 9.2 11C9.5 11 9.7 11.1 9.8 11.4C9.9 11.7 10 12 10 12.6C10 13.2 9.9 13.6 9.8 13.8ZM16.5 16.1C16.4 15.8 16.3 15.6 16.1 15.4C15.9 15.2 15.7 15 15.5 14.9C15.3 14.8 15 14.7 14.6 14.7C13.9 14.7 13.4 14.9 13.1 15.3C12.8 15.7 12.6 16.3 12.6 17.1C12.6 17.6 12.7 18 12.9 18.4C13.1 18.7 13.3 19 13.6 19.2C13.9 19.4 14.2 19.5 14.6 19.5C15.2 19.5 15.7 19.3 16 18.9C16.4 18.5 16.5 17.9 16.5 17.1C16.7 16.8 16.6 16.4 16.5 16.1ZM15.3 18.4C15.2 18.7 15 18.8 14.7 18.8C14.4 18.8 14.2 18.7 14.1 18.4C14 18.1 13.9 17.7 13.9 17.2C13.9 16.8 13.9 16.5 14 16.3C14.1 16.1 14.1 15.9 14.2 15.8C14.3 15.7 14.5 15.6 14.7 15.6C15 15.6 15.2 15.7 15.3 16C15.4 16.2 15.5 16.6 15.5 17.2C15.5 17.7 15.4 18.1 15.3 18.4Z" fill="black"/>
                          </svg>
                          :
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="black"/>
                          </svg>
                        }
                      </span>
                      { item.id === settingsForCompany.template_current.id &&
                        <span className="svg-icon svg-icon-2x svg-icon-info position-absolute top-0 start-100 translate-middle">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"/>
                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
                          </svg>
                        </span>
                      }
                      <div className="template-model symbol symbol-100px mb-3 position-relative" data-bs-toggle="modal" data-bs-target={`#template-model-${item.name.replaceAll(" ", "-")}`}>
                        <img src={item.thumbnail} alt={lang.labels.template} className="theme-light-show template-model-image" />
                        <label className="template-model-label text-capitalize fw-bold">{item.name}</label>
                      </div>
                    </div>
                    <button className="btn btn-bg-secondary btn-active-color-primary btn-sm fw-bold w-100" type="button" onClick={() => handleChangeTemplate(item)}>{lang.labels.selectModel}</button>
                  </div>
                  <div id={`template-model-${item.name.replaceAll(" ", "-")}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img src={item.thumbnail} alt={lang.labels.template} className="theme-light-show w-100 h-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}))}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-light btn-active-light-primary" type="reset" onClick={executeFormRestart}>{lang.labels.discard}</button>
            <button className="btn btn-primary ms-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCompanyUpdate}>
              <span className="indicator-label">
                {lang.labels.saveChanges}
              </span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <div className="d-flex justify-content-center align-items-center w-100 h-200px">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{lang.labels.loading}</span>
          </div>
        </div>
      }
    </div>
  )
};

export default AppCompanyUpdateDownload;
