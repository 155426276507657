import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { CompanyCategories } from '../models/company.categories.model';
import { Company } from '../models/company.model';
import { Category } from '../models/category.model';
import { Product } from '../models/product.model';
import { Service } from '../models/service.model';
import { appToken } from '../scripts/app.token.script';

export class CategoryDA {
  static apiResponse: ApiResponse

  public static async getCategories(id_company: string, category_name: string, category_type: string, sort_field: string, sort_mode: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categoriesByCompany: CompanyCategories | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/categories.data.json').then(response => { categoriesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categoriesByCompany!.id_company === id_company) {
        let listCategories: Category[] = []

        switch (true) {
          case category_name.length === 0 && category_type.length === 0 && sort_mode.length === 0 && sort_field.length === 0:
            listCategories = categoriesByCompany!.categories
            break;
          case category_name.length === 0:
            listCategories = (category_type.length > 0) ? categoriesByCompany!.categories.filter((item: Category) => item.type === category_type) : categoriesByCompany!.categories
            break;
          case category_name.length !== 0:
            for (let category of categoriesByCompany!.categories) {
              if (category.display_name.toLowerCase().indexOf(category_name.toLocaleLowerCase()) !== -1) {
                listCategories.push(category)
              }
            }
            break;
        }

        categoriesByCompany!.filter.category_name = category_name
        categoriesByCompany!.filter.category_type = category_type
        categoriesByCompany!.filter.sort_field = sort_field
        categoriesByCompany!.filter.sort_mode = sort_mode
        categoriesByCompany!.categories = listCategories

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: categoriesByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories?name=${category_name}&type=${category_type}&order_by=${sort_field}&order_direction=${sort_mode}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getCategory(id_company: string, id_category: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categoryProducts: Category | null = null
      let categoryServices: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.products.data.json').then(response => { categoryProducts = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/category.services.data.json').then(response => { categoryServices = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categoryProducts!.id === id_category || categoryServices!.id === id_category) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (id_category === categoryProducts!.id) ? categoryProducts : categoryServices
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postCategory(id_company: string, type_category: string, display_name: string, description: string, names: { en: string, fr: string, it: string, de: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let category: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        category = {
          id: `${(Math.floor(Math.random() * 1000)).toString()}category${(Math.floor(Math.random() * 1000)).toString()}`,
          type: type_category,
          display_name: display_name,
          description: description,
          names: names,
          list: []
        }

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: category
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        type: type_category,
        display_name,
        description,
        names
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCategory(id_company: string, id_category: string, display_name: string, description: string, names: { en: string, fr: string, it: string, de: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categoryProduct: Category | null = null
      let categoryService: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.products.data.json').then(response => { categoryProduct = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/category.services.data.json').then(response => { categoryService = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categoryProduct!.id === id_category || categoryService!.id === id_category) {
        if (categoryProduct!.id === id_category) {
          categoryProduct!.display_name = display_name
          categoryProduct!.description = description
          categoryProduct!.names = names
        }
        if (categoryService!.id === id_category) {
          categoryService!.display_name = display_name
          categoryService!.description = description
          categoryService!.names = names
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (categoryProduct!.id === id_category) ? categoryProduct : categoryService
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        display_name: display_name,
        description: description,
        names: names
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCategoryProducts(id_company: string, id_category: string, id_products: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categoryProduct: Category | null = null
      let categoryService: Category | null = null
      let products: Product[] | null = null
      let services: Service[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.products.data.json').then(response => { categoryProduct = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/category.services.data.json').then(response => { categoryService = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/products.data.json').then(response => { products = response.data.products }).catch(error => { alert(error) })
      await axios.get('../../../../../database/services.data.json').then(response => { services = response.data.services }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categoryProduct!.id === id_category || categoryService!.id === id_category) {
        if (categoryProduct!.id === id_category) {
          categoryProduct!.list = products!.filter((item: Product) => id_products.includes(item.id))
        }
        if (categoryService!.id === id_category) {
          categoryService!.list = services!.filter((item: Service) => id_products.includes(item.id))
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (categoryProduct!.id === id_category) ? categoryProduct : categoryService
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}/listing`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        ids: id_products
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deleteCategories(id_company: string, id_categories: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categories: Category[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/categories.data.json').then(response => { categories = response.data.categories }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categories!.some((item: Category) => id_categories.includes(item.id))) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { categories: id_categories }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
