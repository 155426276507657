function controllerZeroApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.remove('active')
  element2?.classList.remove('active')
  element3?.classList.remove('active')
  element4?.classList.remove('active')
  element5?.classList.remove('active')
}

function controllerOneApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.add('active')
  element2?.classList.remove('active')
  element3?.classList.remove('active')
  element4?.classList.remove('active')
  element5?.classList.remove('active')
}

function controllerTwoApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.add('active')
  element2?.classList.add('active')
  element3?.classList.remove('active')
  element4?.classList.remove('active')
  element5?.classList.remove('active')
}

function controllerThreeApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.add('active')
  element2?.classList.add('active')
  element3?.classList.add('active')
  element4?.classList.remove('active')
  element5?.classList.remove('active')
}

function controllerFourApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.add('active')
  element2?.classList.add('active')
  element3?.classList.add('active')
  element4?.classList.add('active')
  element5?.classList.remove('active')
}

function controllerFiveApproved(container_1: string, container_2: string, container_3: string, container_4: string, container_5: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)
  let element3 = document.getElementById(container_3)
  let element4 = document.getElementById(container_4)
  let element5 = document.getElementById(container_5)

  element1?.classList.add('active')
  element2?.classList.add('active')
  element3?.classList.add('active')
  element4?.classList.add('active')
  element5?.classList.add('active')
}

export { controllerZeroApproved, controllerOneApproved, controllerTwoApproved, controllerThreeApproved, controllerFourApproved, controllerFiveApproved }
