import { Language } from "../languages/labels/language.labels";

export function treasuryTypeList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'cash', description: lang.labels.cashAccount },
    { code: 'postal', description: lang.labels.postalAccount },
    { code: 'bank', description: lang.labels.bankAccount }
  ]
}

export function treasuryAccountTypeValue(lang: Language, code: string): string {
  const treasuryAccountType = treasuryTypeList(lang).find(item => item.code === code)
  return treasuryAccountType ? treasuryAccountType.description : lang.labels.undefined
}
