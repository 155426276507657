function navTabActive(button: string, container: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)

  element1?.classList.add('active')
  element2?.classList.add('active')
  element2?.classList.add('show')
}

function navTabDesactive(button: string, container: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)

  element1?.classList.remove('active')
  element2?.classList.remove('active')
  element2?.classList.remove('show')
}

function navTabReload(list_button: string[], list_container: string[], button: string, container: string) {
  if (list_button.length === list_container.length) {
    for (let i = 0; i < list_button.length; i++) {
      if (list_button[i] === button && list_container[i] === container) {
        navTabActive(list_button[i], list_container[i])
      } else {
        navTabDesactive(list_button[i], list_container[i])
      }
    }
  }
}

export { navTabActive, navTabDesactive, navTabReload }
