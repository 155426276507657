import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';

import { StateLanguage } from '../../languages/config/StateLanguage';

export interface AppChartGaugeOrderProps {
  id: string,
  info: {status: string, percentage: number}[]
};

const AppChartGaugeOrder: React.FunctionComponent<AppChartGaugeOrderProps> = ({id, info}) => {
  const {lang} = StateLanguage()

  useEffect( () => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "none",
      innerRadius: am5.percent(20),
      startAngle: -90,
      endAngle: 180
    }));

    let data = [
      {
        category: lang.labels.completed,
        value: info.find((item) => (item.status === "completed"))!!.percentage,
        full: 100,
        columnSettings: {
          fill: am5.color("#7E8299")
        }
      }, {
        category: lang.labels.approved,
        value: info.find((item) => (item.status === "approved"))!!.percentage,
        full: 100,
        columnSettings: {
          fill: am5.color("#50CD89")
        }
      }, {
        category: lang.labels.canceled,
        value: info.find((item) => (item.status === "canceled"))!!.percentage,
        full: 100,
        columnSettings: {
          fill: am5.color("#F1416C")
        }
      }, {
        category: lang.labels.pending,
        value: info.find((item) => (item.status === "pending"))!!.percentage,
        full: 100,
        columnSettings: {
          fill: am5.color("#FFC700")
        }
      }, {
      category: lang.labels.inProgress,
      value: info.find((item) => (item.status === "in progress"))!!.percentage,
      full: 100,
        columnSettings: {
          fill: am5.color("#009EF7")
        }
      }
    ];

    let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10
    });

    xRenderer.grid.template.setAll({
      forceHidden: true
    });

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      min: 0,
      max: 100,
      strictMinMax: true,
      numberFormat: "#'%'",
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 10
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "500",
      fontSize: 12,
      templateField: "columnSettings"
    });

    yRenderer.grid.template.setAll({
      forceHidden: true
    });

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer
    }));

    yAxis.data.setAll(data);

    let series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "full",
      categoryYField: "category",
      fill: root.interfaceColors.get("alternativeBackground")
    }));

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20
    });

    series1.data.setAll(data);

    let series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "value",
      categoryYField: "category"
    }));

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}: {valueX}%",
      cornerRadius: 20,
      templateField: "columnSettings"
    });

    series2.data.setAll(data);

    series1.appear();
    series2.appear();

    chart.appear(100, 10);

    return () => { root.dispose() }
  }, [id, info, lang])

  return (
    <div id={id} className="w-100 h-300px"></div> 
  )
};

export default AppChartGaugeOrder;
