export function accumulatedAmountLite(documents: any[]): {subtotal: number, rebate: number, tax: number, total: number} {
  let amountSubtotal: number = 0
  let amountRebate: number = 0
  let amountTax: number = 0
  let amountTotal: number = 0

  for (let document of documents) {
    amountSubtotal += document.amount.sub_total
    amountRebate += document.amount.rebate_total
    amountTax += document.amount.tax_total
    amountTotal += document.amount.total
  }

  return {subtotal: amountSubtotal, rebate: amountRebate, tax: amountTax, total: amountTotal}
}

export function accumulatedAmount(documents: any[]): {subtotal: number, rebate: number, tax: number, paid: number, debt: number, total: number} {
  let amountSubtotal: number = 0
  let amountRebate: number = 0
  let amountTax: number = 0
  let amountPaid: number = 0
  let amountDebt: number = 0
  let amountTotal: number = 0

  for (let document of documents) {
    amountSubtotal += document.amount.sub_total
    amountRebate += document.amount.rebate_total
    amountTax += document.amount.tax_total
    amountPaid += document.amount.paid
    amountDebt += document.amount.debt
    amountTotal += document.amount.total
  }

  return {subtotal: amountSubtotal, rebate: amountRebate, tax: amountTax, paid: amountPaid, debt: amountDebt, total: amountTotal}
}