import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle, FaCheck } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextContact } from '../Contact';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { ApiGeolocation } from '../../../../services/api.geolocation';
import { UuidDL } from '../../../../services/uuid.service';
import { Error } from '../../../../models/error.model';
import { ResourceUUID } from '../../../../models/resource.uuid.model';
import { Currency } from '../../../../models/currency.model';
import { legendIcon, legendInvalidIcon, legendValid, legendValidIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendInvalid, legendValidInvalidRequiredRestart } from '../../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { inputDisabled, inputEnabled, inputEnabledList } from '../../../../tools/input.enabled.disabled.tool';
import { firstForm, lastForm, nextForm, previousForm, removeFirstForm, removeLastForm } from '../../../../tools/stepper.tool';
import { imageCompany } from '../../../../scripts/image.value.script';
import { removeUploadedImage, uploadImage } from '../../../../scripts/upload.image.script';
import { evaluateLegendValidateEmpty, evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequiredIcon } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { countryList, countryCode, countryName } from '../../../../libraries/countries.library';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { languageDownloadList, languageDownloadValue } from '../../../../libraries/language.download.library';
import { qrTemplateList, qrTemplateValue } from '../../../../libraries/qr.template.library';

import AppLegend from '../../../../components/element/Legend';

import logoBlank from '../../../../assets/images/default.png';
import iconCard from '../../../../assets/images/icons/card-secret-icon.svg';
import iconBank from '../../../../assets/images/icons/bank-ico.svg';

export interface AppContactCreateStepperProps {
  component: string,
  loadIndicator: string,
  uuid: {value: string, valid: boolean},
  type: {value: string, valid: boolean},
  name: {value: string, valid: boolean},
  firstName: {value: string, valid: boolean},
  lastName: {value: string, valid: boolean},
  logo: {value: string, file: any, valid: boolean},
  email: {value: string, valid: boolean},
  phone: {value: string, valid: boolean},
  website: {value: string, valid: boolean},
  vatNumber: {value: string, valid: boolean},
  registerNumber: {value: string, valid: boolean},
  street: {value: string, valid: boolean},
  streetNumber: {value: string, valid: boolean},
  additional: {value: string, valid: boolean},
  administrativeArea: {value: string, valid: boolean},
  city: {value: string, valid: boolean},
  zipCode: {value: string, valid: boolean},
  country: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  financialEntity: {value: string, valid: boolean},
  iban: {value: string, valid: boolean},
  bban: {value: string, valid: boolean},
  bic: {value: string, valid: boolean},
  language: {value: string, valid: boolean},
  qrType: {value: string, valid: boolean},
  companies: string[] | null,
  setUuid: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setType: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setFirstName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setLastName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setLogo: Dispatch<SetStateAction<{value: string, file: any, valid: boolean}>>,
  setEmail: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPhone: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setWebsite: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setVatNumber: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setRegisterNumber: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setStreet: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setStreetNumber: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdditional: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdministrativeArea: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCity: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setZipCode: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCountry: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setFinancialEntity: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setIban: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setBban: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setBic: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setLanguage: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setQrTtype: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCompanies: Dispatch<SetStateAction<string[]>> | null,
  submitContactCreate: Function
};

let errorResponse: Error, uuidResponse: ResourceUUID;

const AppContactCreateStepper: React.FunctionComponent<AppContactCreateStepperProps> = ({component, loadIndicator, uuid, type, name, firstName, lastName, logo, email, phone, website, vatNumber, registerNumber, street, streetNumber, additional, administrativeArea, city, zipCode, country, currency, financialEntity, iban, bban, bic, language, qrType, companies, setUuid, setType, setName, setFirstName, setLastName, setLogo, setEmail, setPhone, setWebsite, setVatNumber, setRegisterNumber, setStreet, setStreetNumber, setAdditional, setAdministrativeArea, setCity, setZipCode, setCountry, setCurrency, setFinancialEntity, setIban, setBban, setBic, setLanguage, setQrTtype, setCompanies, submitContactCreate}) => {
  const {userLoggedIn, appServiceForUser, companyForUser} = UseOutletContextContact()
  const {lang} = StateLanguage()

  const [spinnerAnimation, setSpinnerAnimation] = useState(false)
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const loadUuid = async (api_key: string, uuid: string) => {
    await UuidDL.getUuid(api_key, uuid).then( (response) => {
      if (response.status === 200) {
        uuidResponse = response.data
        setSpinnerAnimation(false)
        setType({value: uuidResponse.type, valid: true})

        if (uuidResponse.type === 'person') {
          inputDisabled(component + '-input-first-name')
          inputDisabled(component + '-input-last-name')
          inputDisabled(component + '-input-email')
          legendIcon(component + '-input-first-name')
          legendIcon(component + '-input-last-name')
          legendIcon(component + '-input-email')

          setFirstName({value: uuidResponse.data.first_name, valid: (uuidResponse.data.first_name.length === 0) ? false : true})
          setLastName({value: uuidResponse.data.last_name, valid: (uuidResponse.data.last_name.length === 0) ? false : true})
        }

        if (uuidResponse.type === 'company') {
          inputDisabled(component + '-input-name')
          legendIcon(component + '-input-name')

          setName({value: uuidResponse.data.name, valid: (uuidResponse.data.name.length === 0) ? false : true})
          setLogo({value: uuidResponse.data.logo, file: uuidResponse.data.logo, valid: true})
          setVatNumber({value: uuidResponse.data.vat_number, valid: true})
          setRegisterNumber({value: uuidResponse.data.register_number, valid: true})
          setCurrency({value: uuidResponse.data.currency.code, object: uuidResponse.data.currency, valid: (uuidResponse.data.currency.code.length === 0) ? false : true})
          setLanguage({value: uuidResponse.data.language, valid: (uuidResponse.data.language.length === 0) ? false : true})
          setQrTtype({value: uuidResponse.data.qr_type, valid: (uuidResponse.data.qr_type.length === 0) ? false : true})
        }

        setEmail({value: uuidResponse.data.email, valid: (uuidResponse.data.email.length === 0) ? false : true})
        setPhone({value: uuidResponse.data.phone, valid: (uuidResponse.data.phone.length === 0) ? false : true})
        setWebsite({value: uuidResponse.data.website, valid: true})
        setStreet({value: uuidResponse.data.address.street, valid: (uuidResponse.data.address.street.length === 0) ? false : true})
        setStreetNumber({value: uuidResponse.data.address.number, valid: (uuidResponse.data.address.number.length === 0) ? false : true})
        setAdditional({value: uuidResponse.data.address.additional, valid: true})
        setAdministrativeArea({value: uuidResponse.data.address.administrative_area, valid: (uuidResponse.data.address.administrative_area.length === 0) ? false : true})
        setCity({value: uuidResponse.data.address.city, valid: (uuidResponse.data.address.city.length === 0) ? false : true})
        setZipCode({value: uuidResponse.data.address.zip_code, valid: (uuidResponse.data.address.zip_code.length === 0) ? false : true})
        setCountry({value: uuidResponse.data.address.country, valid: (uuidResponse.data.address.country.length === 0) ? false : true})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions).then( () => {
          setSpinnerAnimation(false)
        })
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const handleChangeUuid = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setUuid({...uuid, value: event.target.value})
  }

  const handleChangeType = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setType({...type, value: event.target.value, valid: true})
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], logo, setLogo, component + '-container-validate-logo-type', component + '-container-validate-logo-size')
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeVatNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVatNumber({...vatNumber, value: event.target.value})
  }

  const handleChangeRegisterNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setRegisterNumber({...registerNumber, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid(component + '-container-validate-country-required')
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid(component + '-container-validate-currency-required')
  }

  const handleChangeFinancialEntity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFinancialEntity({...financialEntity, value: event.target.value})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBban({...bban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBic({...bic, value: event.target.value})
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
    legendValid(component + '-container-validate-language-required')
  }

  const handleChangeQrType = (item: string) => {
    setQrTtype({...qrType, value: item, valid: true})
    legendValid(component + '-container-validate-qr-type-required')
  }

  const handleChangeCompanies = (event: React.ChangeEvent <HTMLInputElement>) => {
    if (companies && setCompanies) {
      if (companies.includes(event.target.value)) {
        setCompanies(companies.filter((temp_item) => (temp_item !== event.target.value)))
      } else {
        setCompanies([...companies, event.target.value])
      }
    }
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({text: text, list: list})
  }

  const handleRemoveLogo = () => {
    removeUploadedImage(logo, setLogo, component + '-container-validate-logo-type', component + '-container-validate-logo-size')
    removeTooltip()
  }

  const validateUuid = () => {
    if (appServiceForUser && expressions && uuid.value.length > 0) {
      inputDisabled(component + '-input-type-person')
      inputDisabled(component + '-input-type-company')

      if (expressions.uuid.test(uuid.value)) {
        legendValidIcon(component + '-input-uuid', component + '-container-validate-uuid-valid')
        setUuid({...uuid, valid: true})
        setSpinnerAnimation(true)
        loadUuid(appServiceForUser.api_key, uuid.value)
      } else {
        legendInvalidIcon(component + '-input-uuid', component + '-container-validate-uuid-valid')
        setUuid({...uuid, valid: false})
        setSpinnerAnimation(false)
        uploadData()
        restartForm()
      }
    } else if (uuid.value.length === 0) {
      inputEnabled(component + '-input-type-person')
      inputEnabled(component + '-input-type-company')
      legendValidInvalidIconRestart(component + '-input-uuid', component + '-container-validate-uuid-valid')
      setUuid({...uuid, valid: true})
      setSpinnerAnimation(false)
      uploadData()
      restartForm()
    }
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, component + '-input-name', component + '-container-validate-name-valid', component + '-container-validate-name-required')
  }

  const validateFirstName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, firstName, setFirstName, component + '-input-first-name', component + '-container-validate-first-name-valid', component + '-container-validate-first-name-required')
  }

  const validateLastName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, lastName, setLastName, component + '-input-last-name', component + '-container-validate-last-name-valid', component + '-container-validate-last-name-required')
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, component + '-input-email', component + '-container-validate-email-valid', component + '-container-validate-email-required')
  }

  const validatePhone = () => {
    evaluateLegendValidateRequiredIcon(expressions.phone, phone, setPhone, component + '-input-phone', component + '-container-validate-phone-valid', component + '-container-validate-phone-required')
  }

  const validateWebsite = () => {
    evaluateLegendValidateEmptyIcon(expressions.website, website, setWebsite, component + '-input-website', component + '-container-validate-website-valid')
  }

  const validateVatNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.vat, vatNumber, setVatNumber, component + '-input-vat-number', component + '-container-validate-vat-number-valid')
  }

  const validateRegisterNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.registernumber, registerNumber, setRegisterNumber, component + '-input-register-number', component + '-container-validate-register-number-valid')
  }

  const validateStreet = () => {
    evaluateLegendValidateRequiredIcon(expressions.street, street, setStreet, component + '-input-street', component + '-container-validate-street-valid', component + '-container-validate-street-required')
  }

  const validateNumber = () => {
    evaluateLegendValidateRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, component + '-input-street-number', component + '-container-validate-street-number-valid', component + '-container-validate-street-number-required')
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, component + '-input-additional', component + '-container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, component + '-input-administrative-area', component + '-container-validate-administrative-area-valid', component + '-container-validate-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, city, setCity, component + '-input-city', component + '-container-validate-city-valid', component + '-container-validate-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidateRequiredIcon(expressions.zipcode, zipCode, setZipCode, component + '-input-zip-code', component + '-container-validate-zip-code-valid', component + '-container-validate-zip-code-required')
  }

  const validateFinancialEntity = () => {
    evaluateLegendValidateEmpty(expressions.name, financialEntity, setFinancialEntity, component + '-container-validate-financial-entity-valid')
  }

  const validateIban = () => {
    evaluateLegendValidateEmpty(expressions.iban, iban, setIban, component + '-container-validate-iban-valid')
  }

  const validateBban = () => {
    evaluateLegendValidateEmpty(expressions.bban, bban, setBban, component + '-container-validate-bban-valid')
  }

  const validateBic = () => {
    evaluateLegendValidateEmpty(expressions.bic, bic, setBic, component + '-container-validate-bic-valid')
  }

  const executeFormPrevious = () => {
    switch (true) {
      case document.getElementById('form-title-6')?.classList.contains('current'):
        removeLastForm('container-stepper-form')
        previousForm('form-title-5', 'form-title-6', 'form-container-5', 'form-container-6')
        break;
      case document.getElementById('form-title-5')?.classList.contains('current'):
        if (component === 'modal') {
          removeLastForm('container-stepper-form')
        }
        previousForm('form-title-4', 'form-title-5', 'form-container-4', 'form-container-5')
        break;
      case document.getElementById('form-title-4')?.classList.contains('current'):
        previousForm('form-title-3', 'form-title-4', 'form-container-3', 'form-container-4')
        break;
      case document.getElementById('form-title-3')?.classList.contains('current'):
        previousForm('form-title-2', 'form-title-3', 'form-container-2', 'form-container-3')
        break;
      case document.getElementById('form-title-2')?.classList.contains('current'):
        firstForm('container-stepper-form')
        previousForm('form-title-1', 'form-title-2', 'form-container-1', 'form-container-2')
        break;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const executeFormNext = () => {
    switch (true) {
      case document.getElementById('form-title-1')?.classList.contains('current'):
        if (uuid.valid && type.valid) {
          removeFirstForm('container-stepper-form')
          nextForm('form-title-1', 'form-title-2', 'form-container-1', 'form-container-2')
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (!uuid.valid) {
              legendInvalidIcon(component + '-input-uuid', component + '-container-validate-uuid-valid')
            }
            if (!type.valid) {
              legendInvalid(component + '-container-validate-type-required')
            }
          })
        }
        break;
      case document.getElementById('form-title-2')?.classList.contains('current'):
        if (type.value === 'person') {
          if (firstName.valid && lastName.valid && email.valid && phone.valid) {
            nextForm('form-title-2', 'form-title-3', 'form-container-2', 'form-container-3')
            if (!website.valid) {
              setWebsite({value: '', valid: true})
              legendValidInvalidIconRestart(component + '-input-website', component + '-container-validate-website-valid')
            }
          } else {
            Swal.fire({
              text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
              icon: 'error',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              if (firstName.value.length === 0) {
                legendInvalidIcon(component + '-input-first-name', component + '-container-validate-first-name-required')
              }
              if (lastName.value.length === 0) {
                legendInvalidIcon(component + '-input-last-name', component + '-container-validate-last-name-required')
              }
              if (email.value.length === 0) {
                legendInvalidIcon(component + '-input-email', component + '-container-validate-email-required')
              }
              if (phone.value.length === 0) {
                legendInvalidIcon(component + '-input-phone', component + '-container-validate-phone-required')
              }
            })
          }
        }
        if (type.value === 'company') {
          if (name.valid && email.valid && phone.valid) {
            nextForm('form-title-2', 'form-title-3', 'form-container-2', 'form-container-3')
            if (!logo.valid) {
              setLogo({value: '', file: null, valid: true})
              legendValidInvalidRequiredRestart(component + '-container-validate-logo-type', component + '-container-validate-logo-size')
            }
            if (!website.valid) {
              setWebsite({value: '', valid: true})
              legendValidInvalidIconRestart(component + '-input-website', component + '-container-validate-website-valid')
            }
            if (!vatNumber.valid) {
              setVatNumber({value: '', valid: true})
              legendValidInvalidIconRestart(component + '-input-vat-number', component + '-container-validate-vat-number-valid')
            }
            if (!registerNumber.valid) {
              setRegisterNumber({value: '', valid: true})
              legendValidInvalidIconRestart(component + '-input-register-number', component + '-container-validate-register-number-valid')
            }
          } else {
            Swal.fire({
              text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
              icon: 'error',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              if (name.value.length === 0) {
                legendInvalidIcon(component + '-input-name', component + '-container-validate-name-required')
              }
              if (email.value.length === 0) {
                legendInvalidIcon(component + '-input-email', component + '-container-validate-email-required')
              }
              if (phone.value.length === 0) {
                legendInvalidIcon(component + '-input-phone', component + '-container-validate-phone-required')
              }
            })
          }
        }
        break;
      case document.getElementById('form-title-3')?.classList.contains('current'):
        if (street.valid && streetNumber.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid) {
          nextForm('form-title-3', 'form-title-4', 'form-container-3', 'form-container-4')
          if (!additional.valid) {
            setAdditional({value: '', valid: true})
            legendValidInvalidIconRestart(component + '-input-additional', component + '-container-validate-additional-valid')
          }
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (street.value.length === 0) {
              legendInvalidIcon(component + '-input-street', component + '-container-validate-street-required')
            }
            if (streetNumber.value.length === 0) {
              legendInvalidIcon(component + '-input-street-number', component + '-container-validate-street-number-required')
            }
            if (administrativeArea.value.length === 0) {
              legendInvalidIcon(component + '-input-administrative-area', component + '-container-validate-administrative-area-required')
            }
            if (city.value.length === 0) {
              legendInvalidIcon(component + '-input-city', component + '-container-validate-city-required')
            }
            if (zipCode.value.length === 0) {
              legendInvalidIcon(component + '-input-zip-code', component + '-container-validate-zip-code-required')
            }
            if (country.value.length === 0) {
              legendInvalidIcon(component + '-input-country', component + '-container-validate-country-required')
            }
          })
        }
        break;
      case document.getElementById('form-title-4')?.classList.contains('current'):
        if (type.value === 'person') {
          nextForm('form-title-4', 'form-title-5', 'form-container-4', 'form-container-5')
          if (component === 'modal') {
            lastForm('container-stepper-form')
          }
          if (!financialEntity.valid) {
            setFinancialEntity({...financialEntity, value: '', valid: true})
            legendValidInvalidRestart(component + '-container-validate-financial-entity-valid')
          }
          if (!iban.valid) {
            setIban({value: '', valid: true})
            legendValidInvalidRestart(component + '-container-validate-iban-valid')
          }
          if (!bban.valid) {
            setBban({value: '', valid: true})
            legendValidInvalidRestart(component + '-container-validate-bban-valid')
          }
          if (!bic.valid) {
            setBic({value: '', valid: true})
            legendValidInvalidRestart(component + '-container-validate-bic-valid')
          }
        }
        if (type.value === 'company') {
          if (currency.valid) {
            nextForm('form-title-4', 'form-title-5', 'form-container-4', 'form-container-5')
            if (component === 'modal') {
              lastForm('container-stepper-form')
            }
            if (!financialEntity.valid) {
              setFinancialEntity({value: '', valid: true})
              legendValidInvalidRestart(component + '-container-validate-financial-entity-valid')
            }
            if (!iban.valid) {
              setIban({value: '', valid: true})
              legendValidInvalidRestart(component + '-container-validate-iban-valid')
            }
            if (!bban.valid) {
              setBban({value: '', valid: true})
              legendValidInvalidRestart(component + '-container-validate-bban-valid')
            }
            if (!bic.valid) {
              setBic({value: '', valid: true})
              legendValidInvalidRestart(component + '-container-validate-bic-valid')
            }
          } else {
            Swal.fire({
              text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
              icon: 'error',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              if (currency.value.length === 0) {
                legendInvalid(component + '-container-validate-currency-required')
              }
            })
          }
        }
        break;
      case document.getElementById('form-title-5')?.classList.contains('current'):
        if (language.valid && qrType.valid) {
          lastForm('container-stepper-form')
          nextForm('form-title-5', 'form-title-6', 'form-container-5', 'form-container-6')
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (language.value.length === 0) {
              legendInvalid(component + '-container-validate-language-required')
            }
            if (qrType.value.length === 0) {
              legendInvalid(component + '-container-validate-qr-type-required')
            }
          })
        }
        break;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  function restartForm() {
    legendValidInvalidRestart(component + '-container-validate-type-required')
    legendValidInvalidIconRestart(component + '-input-name', component + '-container-validate-name-valid')
    legendValidInvalidIconRestart(component + '-input-name', component + '-container-validate-name-required')
    legendValidInvalidIconRestart(component + '-input-first-name', component + '-container-validate-first-name-valid')
    legendValidInvalidIconRestart(component + '-input-first-name', component + '-container-validate-first-name-required')
    legendValidInvalidIconRestart(component + '-input-last-name', component + '-container-validate-last-name-valid')
    legendValidInvalidIconRestart(component + '-input-last-name', component + '-container-validate-last-name-required')
    legendValidInvalidRequiredRestart(component + '-container-validate-logo-type', component + '-container-validate-logo-size')
    legendValidInvalidIconRestart(component + '-input-email', component + '-container-validate-email-valid')
    legendValidInvalidIconRestart(component + '-input-email', component + '-container-validate-email-required')
    legendValidInvalidIconRestart(component + '-input-phone', component + '-container-validate-phone-valid')
    legendValidInvalidIconRestart(component + '-input-phone', component + '-container-validate-phone-required')
    legendValidInvalidIconRestart(component + '-input-website', component + '-container-validate-website-valid')
    legendValidInvalidIconRestart(component + '-input-vat-number', component + '-container-validate-vat-number-valid')
    legendValidInvalidIconRestart(component + '-input-register-number', component + '-container-validate-register-number-valid')
    legendValidInvalidIconRestart(component + '-input-street', component + '-container-validate-street-valid')
    legendValidInvalidIconRestart(component + '-input-street', component + '-container-validate-street-required')
    legendValidInvalidIconRestart(component + '-input-street-number', component + '-container-validate-street-number-valid')
    legendValidInvalidIconRestart(component + '-input-street-number', component + '-container-validate-street-number-required')
    legendValidInvalidIconRestart(component + '-input-additional', component + '-container-validate-additional-valid')
    legendValidInvalidIconRestart(component + '-input-administrative-area', component + '-container-validate-administrative-area-valid')
    legendValidInvalidIconRestart(component + '-input-administrative-area', component + '-container-validate-administrative-area-required')
    legendValidInvalidIconRestart(component + '-input-city', component + '-container-validate-city-valid')
    legendValidInvalidIconRestart(component + '-input-city', component + '-container-validate-city-required')
    legendValidInvalidIconRestart(component + '-input-zip-code', component + '-container-validate-zip-code-valid')
    legendValidInvalidIconRestart(component + 'input-zip-code', component + '-container-validate-zip-code-required')
    legendValidInvalidRestart(component + '-container-validate-country-required')
    legendValidInvalidRestart(component + '-container-validate-currency-required')
    legendValidInvalidRestart(component + '-container-validate-financial-entity-valid')
    legendValidInvalidRestart(component + '-container-validate-iban-valid')
    legendValidInvalidRestart(component + '-container-validate-bban-valid')
    legendValidInvalidRestart(component + '-container-validate-bic-valid')
    legendValidInvalidRestart(component + '-container-validate-language-required')
    legendValidInvalidRestart(component + '-container-validate-qr-type-required')
    inputEnabledList('input[type="text"]')
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  function uploadData() {
    if (companyForUser) {
      setType({value: '', valid: false})
      setName({value: '', valid: false})
      setFirstName({value: '', valid: false})
      setLastName({value: '', valid: false})
      setLogo({value: '', file: null, valid: true})
      setEmail({value: '', valid: false})
      setPhone({value: '', valid: false})
      setWebsite({value: '', valid: true})
      setVatNumber({value: '', valid: true})
      setRegisterNumber({value: '', valid: true})
      setStreet({value: '', valid: false})
      setStreetNumber({value: '', valid: false})
      setAdditional({value: '', valid: true})
      setAdministrativeArea({value: '', valid: false})
      setCity({value: '', valid: false})
      setZipCode({value: '', valid: false})
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})
      setFinancialEntity({value: '', valid: true})
      setIban({value: '', valid: true})
      setBban({value: '', valid: true})
      setBic({value: '', valid: true})
      setLanguage({value: companyForUser.language, valid: true})
      setQrTtype({value: companyForUser.qr_type, valid: true})
    }

    loadLocationBrowser()
  }

  async function loadLocationBrowser() {
    ApiGeolocation.getLocateIPAddress().then( (response) => {
      if (response) {
        setCountry({value: response.country_code, valid: true})
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    uploadData()
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <>
      { userLoggedIn && companyForUser &&
        <div id="container-stepper-form" className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            <div className="stepper-nav">
              <div id="form-title-1" className="stepper-item current">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas"><FaCheck /></i>
                  <span className="stepper-number">1</span>
                </div>
                <div className="stepper-label w-200px">
                  <h4 className="stepper-title">{lang.labels.uuidAndType}</h4>
                  <div className="stepper-desc">{lang.labels.contactUUIDandType}</div>
                </div>
              </div>
              <div id="form-title-2" className="stepper-item">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check"><FaCheck /></i>
                  <span className="stepper-number">2</span>
                </div>
                <div className="stepper-label w-200px">
                  <h4 className="stepper-title">{lang.labels.contactIdentity}</h4>
                  <div className="stepper-desc">{lang.labels.definingTheIdentityOfContact}</div>
                </div>
              </div>
              <div id="form-title-3" className="stepper-item">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check"><FaCheck /></i>
                  <span className="stepper-number">3</span>
                </div>
                <div className="stepper-label w-200px">
                  <h4 className="stepper-title">{lang.labels.contactInformation}</h4>
                  <div className="stepper-desc">{lang.labels.definingTheInformationOfContact}</div>
                </div>
              </div>
              <div id="form-title-4" className="stepper-item">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check"><FaCheck /></i>
                  <span className="stepper-number">4</span>
                </div>
                <div className="stepper-label w-200px">
                  <h4 className="stepper-title">{lang.labels.legalInformation}</h4>
                  <div className="stepper-desc">{lang.labels.definingTheLegalInformationOfContact}</div>
                </div>
              </div>
              <div id="form-title-5" className="stepper-item">
                <div className="stepper-line w-40px"></div>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check"><FaCheck /></i>
                  <span className="stepper-number">5</span>
                </div>
                <div className="stepper-label w-200px">
                  <h4 className="stepper-title">{lang.labels.fileDownloadSettings}</h4>
                  <div className="stepper-desc">{lang.labels.definingTheSettingsToDownloadQuotesAndInvoices}</div>
                </div>
              </div>
              { component === "page" &&
                <div id="form-title-6" className="stepper-item">
                  <div className="stepper-line w-40px"></div>
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check"><FaCheck /></i>
                    <span className="stepper-number">6</span>
                  </div>
                  <div className="stepper-label w-200px">
                    <h4 className="stepper-title">{lang.labels.company}</h4>
                    <div className="stepper-desc">{lang.labels.selectCompanyAndSave}</div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="flex-row-fluid px-lg-5">
            <div className="form">
              <div id="form-container-1" className="current" data-kt-stepper-element="content">
                <div className="w-100">
                  { component === "page" &&
                    <label className="d-flex fs-6 fw-bold text-justify mb-10">
                      {lang.labels.youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact}
                    </label>
                  }
                  <div className="fv-row mb-10">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.contactUUID}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.specifyTheUniqueUUID}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="w-100 position-relative">
                      <input id="input-uuid" className="form-control form-control-solid pe-14" type="text" name="uuid" value={uuid.value} onChange={handleChangeUuid} onKeyUp={validateUuid} />
                      <span id="icon-spinner" className={`position-absolute top-50 end-0 translate-middle-y me-4 ${!spinnerAnimation && "d-none"}`}>
                        <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                      </span>
                    </div>
                    <div className="form-text text-justify">{lang.labels.whenCreatingAContactByUUID}</div>
                    <AppLegend component={component} attribute={{validity: uuid.valid, name: "uuid", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        <span className="required">{lang.labels.contactType}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.selectTheTypeOfContact}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="fv-row">
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-50px me-5">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black"/>
                                  <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black"/>
                                </svg>
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder">{lang.labels.person}</span>
                            <span className="fs-7 text-muted">{lang.labels.createContactPersonStructure}</span>
                          </span>
                        </span>
                        <span className="form-check form-check-custom form-check-solid">
                          <input id="input-type-person" className="form-check-input" type="radio" name="type-person" value="person" checked={type.value === "person"} onChange={handleChangeType} />
                        </span>
                      </label>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-50px me-5">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black"/>
                                  <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black"/>
                                  <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black"/>
                                </svg>
                                </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder">{lang.labels.company}</span>
                            <span className="fs-7 text-muted">{lang.labels.createContactCompanyStructure}</span>
                          </span>
                        </span>
                        <span className="form-check form-check-custom form-check-solid">
                          <input id="input-type-company" className="form-check-input" type="radio" name="type-person" value="company" checked={type.value === "company"} onChange={handleChangeType} />
                        </span>
                      </label>
                    </div>
                    <AppLegend component={component} attribute={{validity: type.valid, name: "type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </div>
              <div id="form-container-2" className="pending" data-kt-stepper-element="content">
                <div className="w-100">
                  { type.value === "person" &&
                    <>
                      <div className="fv-row mb-5">
                        <label className="form-label required">{lang.labels.firstName}</label>
                        <input id="input-first-name" className="form-control form-control-solid" type="text" name="first-name" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                        <AppLegend component={component} attribute={{validity: firstName.valid, name: "first-name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label required">{lang.labels.lastName}</label>
                        <input id="input-last-name" className="form-control form-control-solid" type="text" name="last-name" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                        <AppLegend component={component} attribute={{validity: lastName.valid, name: "last-name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </>
                  }
                  { type.value === "company" &&
                    <>
                      <div className="fv-row mb-5">
                        <label className="form-label required">{lang.labels.companyName}</label>
                        <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                        <AppLegend component={component} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.companyLogo}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theFileMustBeFormat}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <div className="mt-5">
                          <div className="image-input image-input-outline" data-kt-image-input="true">
                          <img src={logo.valid && logo.value.length > 0 ? logo.value : logoBlank} className="image-input-wrapper w-auto h-125px" alt={lang.labels.logo} />
                            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.changeLogo} onClick={removeTooltip}>
                              <i className="bi bi-pencil-fill fs-7"></i>
                              <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                            </label>
                            <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow ${(!logo.valid || logo.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                              <i className="bi bi-x fs-2" ></i>
                            </span>
                          </div>
                        </div>
                        <AppLegend component={component} attribute={{validity: logo.valid, name: "logo", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </>
                  }
                  <div className="fv-row mb-5">
                    <label className="form-label required">{lang.labels.email}</label>
                    <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                    <AppLegend component={component} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row mb-5">
                    <label className="form-label required">{lang.labels.phoneNumber}</label>
                    <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                    <AppLegend component={component} attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className={`${type.value === "company" && "fv-row mb-5"}`}>
                    <label className="form-label">{lang.labels.webSite}</label>
                    <input id="input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                    <AppLegend component={component} attribute={{validity: website.valid, name: "website", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  { type.value === "company" &&
                    <>
                      <div className="fv-row">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.vatNumber}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.identificationNumberForACompany}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <input id="input-vat-number" className="form-control form-control-solid"  type="text" name="vat-number" value={vatNumber.value} onChange={handleChangeVatNumber} onKeyUp={validateVatNumber} onBlur={validateVatNumber} />
                        <AppLegend component={component} attribute={{validity: vatNumber.valid, name: "vat-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row d-none">
                        <label className="form-label">{lang.labels.registerNumber}</label>
                        <input id="input-register-number" className="form-control form-control-solid" type="text" name="register-number" value={registerNumber.value} onChange={handleChangeRegisterNumber} onKeyUp={validateRegisterNumber} onBlur={validateRegisterNumber} />
                        <AppLegend component={component} attribute={{validity: registerNumber.valid, name: "register-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </>
                  }
                </div>
              </div>
              <div id="form-container-3" className="pending" data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="row fv-row mb-5">
                    <div className="col-xl-8 mb-5 mb-xl-0">
                      <label className="form-label required">{lang.labels.street}</label>
                      <input id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                      <AppLegend component={component} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                    <div className="col-xl-4">
                      <label className="form-label">
                        <div className="d-flex align-items-center">
                          <span className="required">{lang.labels.number}</span>
                          <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                        </div>
                      </label>
                      <input id="input-street-number" className="form-control form-control-solid" type="text" name="street-number" placeholder={lang.labels.noNumber} value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
                      <AppLegend component={component} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  </div>
                  <div className="fv-row mb-5">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.additional}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <input id="input-additional" className="form-control form-control-solid" type="text" name="additional" value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                    <AppLegend component={component} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row mb-5">
                    <label className="form-label required">{lang.labels.administrativeArea}</label>
                    <input id="input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                    <AppLegend component={component} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="row fv-row mb-5">
                    <div className="col-xl-4 mb-5 mb-xl-0">
                      <label className="form-label">
                        <div className="d-flex align-items-center">
                          <span className="required">{lang.labels.zipCode}</span>
                          <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                        </div>
                      </label>
                      <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                      <AppLegend component={component} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                    <div className="col-xl-8">
                      <label className="form-label required">{lang.labels.city}</label>
                      <input id="input-city" className="form-control form-control-solid" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                      <AppLegend component={component} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  </div>
                  <div className="fv-row">
                    <label className="form-label required">{lang.labels.country}</label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {country.valid ? countryName(country.value, lang.code) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-search select2-search--dropdown">
                            <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                          </span>
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { searchCountry.list.length > 0
                                ?
                                <>
                                  { searchCountry.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </>
                                :
                                <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                              }
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={component} attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </div>
              <div id="form-container-4" className="pending" data-kt-stepper-element="content">
                <div className="w-100">
                  { type.value === "company" &&
                    <div className="fv-row mb-5">
                      <label className="form-label required">{lang.labels.currency}</label>
                      <Dropdown>
                        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                          <span className="selection">
                            <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                              <span className="select2-selection__rendered" role="textbox">
                                <span className="select2-selection__placeholder">
                                  {currency.valid ? currencyValue(currency.value) : lang.labels.selectOption}
                                </span>
                              </span>
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                          <span className="select2-dropdown select2-dropdown--below">
                            <span className="select2-results">
                              <ul className="select2-results__options" role="listbox">
                                { currencyList().map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                                  </li>
                                )}))}
                              </ul>
                            </span>
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                      <AppLegend component={component} attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  }
                  <div className="fv-row mb-5">
                    <label className="form-label">{lang.labels.financialEntity}</label>
                    <div className="position-relative">
                      <input className="form-control form-control-solid pe-13" type="text" name="financial-entity" value={financialEntity.value} onChange={handleChangeFinancialEntity} onKeyUp={validateFinancialEntity} onBlur={validateFinancialEntity} />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <img src={iconBank} alt={lang.labels.bank} className="h-20px" />
                      </div>  
                    </div>
                    <AppLegend component={component} attribute={{validity: financialEntity.valid, name: "financial-entity", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row mb-5">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.iban}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumber}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="position-relative">
                      <input id="input-iban" className="form-control form-control-solid pe-13" type="text" name="iban" value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                      </div>  
                    </div>
                    <AppLegend component={component} attribute={{validity: iban.valid, name: "iban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row mb-5">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.bban}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.basicBankAccountNumber}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="position-relative">
                      <input id="input-bban" className="form-control form-control-solid pe-13" type="text" name="bban" value={bban.value} onChange={handleChangeBban} onKeyUp={validateBban} onBlur={validateBban} />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                      </div>
                    </div>
                    <AppLegend component={component} attribute={{validity: bban.valid, name: "bban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.bicSwift}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.bankIdentifierCode}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="position-relative">
                      <input id="input-bic" className="form-control form-control-solid pe-13" type="text" name="bic" value={bic.value} onChange={handleChangeBic} onKeyUp={validateBic} onBlur={validateBic} />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                      </div>
                    </div>
                    <AppLegend component={component} attribute={{validity: bic.valid, name: "bic", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </div>
              <div id="form-container-5" className="pending" data-kt-stepper-element="content">
                <div className="w-100">
                  { component === "page" &&
                    <label className="fw-bold text-justify mb-10">
                      <span className="fs-6 p-0 me-2">{lang.labels.selectTheOptionsThatBestConsiderWhenDownloading}</span>
                      <span className="text-muted p-0">{lang.labels.byDefaultValuesOfTheCompanyAreTaken}</span>
                    </label>
                  }
                  <div className="fv-row mb-5">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        <span className="required">{lang.labels.language}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {language.valid ? languageDownloadValue(lang, language.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { languageDownloadList(lang).map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.description}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={component} attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="fv-row">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        <span className="required">{lang.labels.qrType}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {qrType.valid ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { qrTemplateList(lang).map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.description}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={component} attribute={{validity: qrType.valid, name: "qr-type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  { component === "modal" &&
                    <div className="d-flex flex-stack flex-wrap flex-md-nowrap bg-light-warning border border-warning border-dashed rounded p-5 mt-20">
                      <div className="notice d-flex align-items-center mb-5 mb-md-0 me-0 me-md-5">
                        <span className="svg-icon svg-icon-2tx svg-icon-warning me-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                          </svg>
                        </span>
                        <div className="d-block">
                          <span className="d-block text-start text-dark fw-bolder fs-6">{lang.labels.weNeedYourAttention}</span>
                          <span className="fs-7 text-gray-700">
                            {lang.labels.thisContactWillOnlyBeRegisteredInCompany}
                            <span className="fw-bolder text-primary ms-1">{companyForUser.name}</span>
                            .
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              { component === "page" && companies && setCompanies &&
                <div id="form-container-6" className="pending" data-kt-stepper-element="content">
                  <div className="w-100">
                    <div className="fv-row">
                      <label className="d-flex align-items-center fs-6 fw-bold text-justify mb-10">
                        <span className="required">{lang.labels.selectCompaniesToCreateContact}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.listOfCompaniesToWhichIHaveAccess}><FaExclamationCircle /></i>
                      </label>
                      <div className="hover-scroll-y">
                        <label className="d-flex flex-stack cursor-pointer my-3">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-5">
                              <span className="symbol-label bg-light">
                                <img src={imageCompany(companyForUser.logo)} alt={lang.labels.logo} className="w-50 h-50" />
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bolder">{companyForUser.name}</span>
                              <span className="fs-7 text-muted">{companyForUser.address.city}, {countryName(companyForUser.address.country, lang.code)}</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid me-2">
                            <input className="form-check-input" type="checkbox" value={companyForUser.id} checked disabled />
                          </span>
                        </label>
                        { userLoggedIn.companies.map (( (item, index) => { return (
                          <div key={index}>
                            { companyForUser.id !== item.id &&
                              <label className="d-flex flex-stack cursor-pointer my-3">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-5">
                                    <span className="symbol-label bg-light">
                                      <img src={imageCompany(item.logo)} alt={lang.labels.logo} className="w-50 h-50" />
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bolder">{item.name}</span>
                                    <span className="fs-7 text-muted">{item.address.city}, {countryName(item.address.country, lang.code)}</span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid me-2">
                                  <input className="form-check-input" type="checkbox" value={item.id} checked={companies.includes(item.id)} onChange={handleChangeCompanies} />
                                </span>
                              </label>
                            }
                          </div>
                        )}))}
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="d-flex flex-stack mt-10">
                <div>
                  <button className="btn btn-light-primary me-3" type="button" data-kt-stepper-action="previous" onClick={executeFormPrevious}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-3 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                          <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.back}
                    </div>
                  </button>
                </div>
                <div>
                  <button className="btn btn-primary" type="button" data-kt-stepper-action="next" onClick={executeFormNext}>
                    <div className="d-flex align-items-center">
                      {lang.labels.continue}
                      <span className="svg-icon svg-icon-3 me-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
                        </svg>
                      </span>
                    </div>
                  </button>
                  <button className="btn btn-primary" data-kt-stepper-action="submit" type="button" data-kt-indicator={loadIndicator} onClick={() => submitContactCreate()}>
                    <span className="indicator-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.create}
                        <span className="svg-icon svg-icon-3 me-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="black"/>
                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="black"/>
                          </svg>
                        </span>
                      </div>
                    </span>
                    <span className="indicator-progress">
                      {lang.labels.pleaseWait}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default AppContactCreateStepper;
