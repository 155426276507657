import { Dispatch, SetStateAction } from "react"
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from "../tools/pagination.tool"

function paginationSelect(item: number, subject: any, setSubject: Dispatch<SetStateAction<any>>, buttons: string, button_previous: string, button_next: string) {
  let minLimitNew: number = subject.total * (item - 1)
  let maxLimitNew: number = subject.total * item
  let elements: string[] = []

  if (item === 1) {
    pageLimitDisabled(button_previous)
  } else {
    pageLimitEnabled(button_previous)
  }

  if (item === subject.pages.length) {
    pageLimitDisabled(button_next)
  }else {
    pageLimitEnabled(button_next)
  }

  for (let item of subject.pages) {
    elements.push(buttons + item)
  }

  pageCurrent(item, elements)
  setSubject({...subject, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
}

function paginationPrevious(subject: any, setSubject: Dispatch<SetStateAction<any>>, buttons: string, button_previous: string, button_next: string) {
  let currentNew: number = subject.current - 1
  let minLimitNew: number = subject.total * (currentNew - 1)
  let maxLimitNew: number = subject.total * currentNew
  let elements: string[] = []

  if (currentNew === 1) {
    pageLimitDisabled(button_previous)
  }

  if (currentNew < subject.pages.length) {
    pageLimitEnabled(button_next)
  }

  for (let item of subject.pages) {
    elements.push(buttons + item)
  }

  pageCurrent(currentNew, elements)
  setSubject({...subject, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
}

function paginationNext(subject: any, setSubject: Dispatch<SetStateAction<any>>, buttons: string, button_previous: string, button_next: string) {
  let currentNew: number = subject.current + 1
  let minLimitNew: number = subject.total * (currentNew - 1)
  let maxLimitNew: number = subject.total * currentNew
  let elements: string[] = []

  if (currentNew > 1) {
    pageLimitEnabled(button_previous)
  }

  if (currentNew === subject.pages.length) {
    pageLimitDisabled(button_next)
  }

  for (let item of subject.pages) {
    elements.push(buttons + item)
  }

  pageCurrent(currentNew, elements)
  setSubject({...subject, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
}

function rowClass(index: number, subject: any): string {
  if (subject.limits.min <= index && index < subject.limits.max) {
    if ((index + 1) % 2 !== 0) {
      return 'odd'
    } else {
      return 'even'
    }
  } else {
    return 'd-none'
  }
}

export { paginationSelect, paginationPrevious, paginationNext, rowClass }
