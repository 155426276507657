export function timeFormat24h(date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let hours: string = dateValue.getHours().toString().padStart(2, '0')
  let minutes: string = dateValue.getMinutes().toString().padStart(2, '0')

  return `${hours}:${minutes} h`
}

export function timeFormat12h(date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let hours: string = dateValue.getHours().toString().padStart(2, '0')
  let minutes: string = dateValue.getMinutes().toString().padStart(2, '0')

  switch (true) {
    case Number(hours) === 12:
      if (Number(minutes) === 0) {
        return `${hours}:${minutes} m`
      } else {
        return `${hours}:${minutes} pm`
      }
    case Number(hours) === 0:
      return `${Number(hours) + 12}:${minutes} am`
    case Number(hours) > 12:
      return `${Number(hours) - 12}:${minutes} pm`
    case Number(hours) < 12:
      return `${Number(hours)}:${minutes} am`
    default:
      return `${hours}:${minutes}`
  }
}

export function dateFormatAbbreviated(date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let day: string = dateValue.getDate().toString().padStart(2, "0")
  let month: string = (dateValue.getMonth() + 1).toString()
  let year: string = dateValue.getFullYear().toString()

  switch (dateValue.getMonth()) {
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sep'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    case 11:
      month = 'Dec'
      break;
  }

  return `${day} ${month} ${year}`
}

export function datetimeFormat24h(date: string): string {
  return `${dateFormatAbbreviated(date)}, ${timeFormat24h(date)}`
}

export function datetimeFormat12h(date: string): string {
  return `${dateFormatAbbreviated(date)}, ${timeFormat12h(date)}`
}
