import React, { Dispatch, SetStateAction, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { modalHide } from '../../tools/modal.tool';
import { Product } from '../../models/product.model';
import { Service } from '../../models/service.model';
import { imageProduct } from '../../scripts/image.value.script';

import imgProductDefault from '../../assets/images/product.png';
import imgServiceDefault from '../../assets/images/service.png';

export interface AppModalCategoryUpdateListingProps {
  companyForUser: Company | undefined | null,
  productsForCompany: Product[] | undefined | null,
  servicesForCompany: Service[] | undefined | null,
  category: Category | undefined | null,
  categoryListing: string[] | undefined | null,
  setCategory: Dispatch<SetStateAction<Category | undefined | null>>,
  setCategoryListing: Dispatch<SetStateAction<string[] | undefined | null>>,
  loadProductsForCompany: Function,
  loadServicesForCompany: Function
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryUpdateListing: React.FunctionComponent<AppModalCategoryUpdateListingProps> = ({companyForUser, productsForCompany, servicesForCompany, category, categoryListing, setCategory, setCategoryListing, loadProductsForCompany, loadServicesForCompany}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitCategoryUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && category && categoryListing) {
      await CategoryDA.putCategoryProducts(companyForUser.id, category.id, categoryListing).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data
          setCategory(categoryResponse)

          if (categoryResponse.type === 'product') {
            loadProductsForCompany(companyForUser.id)
          }
          if (categoryResponse.type === 'service') {
            loadServicesForCompany(companyForUser.id)
          }
          Swal.fire({
            title: lang.labels.successfullyUpdatedCategory,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryUpdateListing()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeSelectItem = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (categoryListing) {
      let list: string[] = (event.target.checked) ? (!categoryListing.includes(event.target.value)) ? categoryListing.concat(event.target.value) : categoryListing : categoryListing.filter((item) => (item !== event.target.value))
      setCategoryListing(list)
    }
  }

  const executeHideModalCategoryUpdateListing = () => {
    modalHide('modal-category-update-listing')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    setCategoryListing([])
  }

  return (
    <div id="modal-category-update-listing" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.updateCategoryItemList}</h2>
          </div>
          <div className="modal-body">
            { productsForCompany && servicesForCompany && category && categoryListing
              ?
              <>
                <div className="text-justify text-gray-700 mb-5">
                  {lang.labels.checkOrUncheckElementsToAddOrRemoveCategory}
                  <strong className="mx-1">{category.display_name}</strong>
                  .
                </div>
                <div className="mh-500px hover-scroll-y">
                  { (category.type === "product" ? productsForCompany : servicesForCompany).map (( (item, index) => { return (
                    <div key={index}>
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        <span className="d-flex align-items-center me-2">
                          { item.photos.length > 0
                            ?
                            <Carousel slide={false} controls={false} indicators={false} touch={false}>
                              { item.photos.map (( (item, index) => { return (
                                <Carousel.Item key={index}>
                                  <img src={imageProduct(item)} className="d-block w-35px h-35px" alt={lang.labels.photography + index} />
                                </Carousel.Item>
                              )}))}
                            </Carousel>
                            :
                            <span className="symbol symbol-35px">
                              <span className="symbol-label">
                                <img src={category.type === "product" ? imgProductDefault : imgServiceDefault} alt={lang.labels.photography} className="w-100 h-100" />
                              </span>
                            </span>
                          }
                          <span className="d-flex flex-column mx-3">
                            <span className="fw-bolder text-gray-900">{item.display_name}</span>
                            <span className="fs-8 text-muted">
                              { category.type === "product"
                                ?
                                <>
                                  {lang.labels.variants}:
                                  <span className="ms-1">{(item as Product).variants.length}</span>
                                </>
                                :
                                <>
                                  {(item as Service).description}
                                </>
                              }
                            </span>
                          </span>
                        </span>
                        <span className="form-check form-check-custom form-check-solid me-2">
                          <input className="form-check-input" type="checkbox" value={item.id} checked={categoryListing.includes(item.id)} onChange={handleChangeSelectItem} />
                        </span>
                      </label>
                    </div>
                  )}))}
                </div>
              </>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalCategoryUpdateListing}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryUpdateListing;
