import { contentEnglish } from '../translations/content_en';
import { contentSpanish } from '../translations/content_es';
import { contentFrench } from '../translations/content_fr';

export const Texts = {
  lang: {
    english: contentEnglish,
    spanish: contentSpanish,
    french: contentFrench
  },
  default: contentEnglish
}
