import React from 'react';
import ReactDOM from 'react-dom';

import App from './interfaces/App';
import reportWebVitals from './reportWebVitals';
import { ContextLanguage } from './languages/config/ContextLanguage';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';
import 'react-multi-carousel/lib/styles.css';

import './assets/sass/style.scss';
import './assets/sass/plugins.scss';
import './assets/sass/_init.scss';

import './index.css';

ReactDOM.render(
  <ContextLanguage>
    <React.StrictMode>
      <App></App>
    </React.StrictMode>
  </ContextLanguage>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
