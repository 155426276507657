import Cookies from 'universal-cookie';

const cookies = new Cookies()

export function authUserLoggedIn(): boolean {
  if (cookies.get('token') && cookies.get('expires_at') && cookies.get('app_service')) {
    let dateExpiration: Date = new Date(cookies.get('expires_at'))
    let timeExpiration: number = dateExpiration.getTime()
    let timestamp: number = Date.now()

    return (timeExpiration > timestamp) ? true : false
  } else {
    return false
  }
}
