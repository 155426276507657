import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

export interface AppDropdownLanguagesProps {};

const AppDropdownLanguages: React.FunctionComponent<AppDropdownLanguagesProps> = () => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const submitLanguage = (item: string) => {
    if (item !== localStorage.getItem('language')) {
      localStorage.setItem('language', item)
      navigate(0)
    }
  }

  function language(): string {
    return (localStorage.getItem('language')) ? localStorage.getItem('language')!! : 'english'
  }

  return (
    <div id="dropdown-languages" className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary w-250px" data-kt-menu="true">
      <div id="background-dropdown-header" className="d-flex flex-column bgi-no-repeat rounded-top p-5 mb-2">
        <h5 className="text-white m-0">{lang.labels.appLanguage}</h5>
      </div>
      <div className="menu-item mx-2">
        <Link to="#" className={`menu-link d-flex px-3 ${language() === "english" && "active"}`} onClick={() => submitLanguage("english")}>
          <span className="symbol symbol-20px me-3">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="black"/>
              <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="black"/>
            </svg>
          </span>
          {lang.labels.english}
        </Link>
      </div>
      <div className="menu-item mx-2">
        <Link to="#" className={`menu-link d-flex px-3 ${language() === "french" && "active"}`} onClick={() => submitLanguage("french")}>
          <span className="symbol symbol-20px me-3">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="black"/>
              <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="black"/>
            </svg>
          </span>
          {lang.labels.french}
        </Link>
      </div>
      <div className="menu-item mx-2">
        <Link to="#" className={`menu-link d-flex px-3 ${language() === "spanish" && "active"}`} onClick={() => submitLanguage("spanish")}>
          <span className="symbol symbol-20px me-3">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="black"/>
              <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="black"/>
            </svg>
          </span>
          {lang.labels.spanish}
        </Link>
      </div>
      <div className="separator mt-2"></div>
      <div className="menu-item" >
        <div className="menu-content d-flex align-items-center text-justify p-3">
          <span className="fw-bold text-muted fs-8">{lang.labels.theAppWillBeTranslated}</span>
        </div>
      </div>
      <div className="separator mb-2"></div>
    </div>
  )
}

export default AppDropdownLanguages;
