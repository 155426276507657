import { Language } from "../languages/labels/language.labels";

export function paymentMethodList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'cash', description: lang.labels.cash },
    { code: 'card', description: lang.labels.card },
    { code: 'paypal', description: lang.labels.payPal },
    { code: 'transfer', description: lang.labels.transfer },
    { code: 'mobile', description: lang.labels.mobile }
  ]
}

export function paymentStatusList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'not payed', description: lang.labels.notPayed },
    { code: 'partially paid', description: lang.labels.partiallyPaid },
    { code: 'fully paid', description: lang.labels.fullyPaid }
  ]
}

export function paymentAllList(lang: Language): {code: string, description: string}[] {
  const paymentAll = [...paymentMethodList(lang), ...paymentStatusList(lang)]
  return Array.from((new Map(paymentAll.map(item => [item.code, item]))).values())
}

export function paymentMethodValue(lang: Language, code: string): string {
  const paymentMethod = paymentMethodList(lang).find(item => item.code === code)
  return paymentMethod ? paymentMethod.description : lang.labels.undefined
}

export function paymentStatusValue(lang: Language, code: string): string {
  const paymentStatus = paymentStatusList(lang).find(item => item.code === code)
  return paymentStatus ? paymentStatus.description : lang.labels.undefined
}

export function paymentAllValue(lang: Language, code: string): string {
  const paymentAll = paymentAllList(lang).find(item => item.code === code)
  return paymentAll ? paymentAll.description : lang.labels.undefined
}
