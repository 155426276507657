import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Token } from '../models/token.model';
import { User } from '../models/user.model';
import { AppService } from '../models/app.service.model';
import { appToken } from '../scripts/app.token.script';

export class AuthenticationDL {
  static apiResponse: ApiResponse

  public static async login(api_key: string, email: string, password: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let token: Token | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/token.data.json').then(response => { token = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (users!.some((item: User) => item.email === email && item.password === password)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: token
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/login`

      let data: any = {
        email: email,
        password: password
      }

      await axios.post(url, data).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async verify(api_key: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some((item: AppService) => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/reset/token/email`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.post(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async register(api_key: string, first_name: string, last_name: string, email: string, phone: string, password: string, password_confirmation: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (!users!.some((item: User) => item.first_name === first_name && item.last_name === last_name && item.email === email && item.phone === phone)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/register`

      let data: any = {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        email: email,
        password: password,
        password_confirmation: password_confirmation
      }

      await axios.post(url, data).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async recover(email: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (users!.some((item: User) => item.email === email)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/password/request`

      let data: any = {
        email: email
      }

      await axios.post(url, data).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async reset(password: string, password_confirmation: string, password_token: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (users!.some((item: User) => item.password === password)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/password/reset`

      let data: any = {
        password: password,
        password_confirmation: password_confirmation,
        password_token: password_token
      }

      await axios.post(url, data).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
