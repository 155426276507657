import axios from 'axios';

export class ApiGeolocation {
  public static async getLocateIPAddress(): Promise<any> {
    let ipapi: any = null

    try {
      const response = await axios.get('https://ipapi.co/json/')
      ipapi = (response.status === 200) ? response.data : null
    } catch (error) {
      ipapi = null
    }

    return ipapi
  }
}
