import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'universal-cookie';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { AccessDL } from '../../services/access.service';
import { Error } from '../../models/error.model';
import { TokenAccess } from '../../models/token.access.model';
import { authUserLoggedIn } from '../../scripts/auth.user.script';

export interface InvitationPageProps {};

let errorResponse: Error, tokenAccessResponse: TokenAccess;

const InvitationPage: React.FunctionComponent<InvitationPageProps> = props => {
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const [mounted, setMounted] = useState(false)

  const loadInvitationCompany = async (email: string, token: string) => {
    let apiKey: string = (cookies.get('app_service')) ? cookies.get('app_service').api_key : process.env.REACT_APP_SERVICE_API_KEY

    await AccessDL.invitation(apiKey, email, token).then( (response) => {
      if (response.status === 201) {
        tokenAccessResponse = response.data

        if (authUserLoggedIn()) {
          if (cookies.get('email') && cookies.get('email') === tokenAccessResponse.email) {
            cookies.set('token', tokenAccessResponse.authorization, {path: '/', sameSite: 'lax'})
            cookies.set('company', tokenAccessResponse.company, {path: '/', sameSite: 'lax'})

            Swal.fire({
              title: lang.labels.successfullyAcceptedInvitation,
              text: lang.labels.validTokenForTheUserThatLoggedIn,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              navigate('/app/dashboard', {replace: true})
            })
          } else {
            cookies.set('email', tokenAccessResponse.email, {path: '/', sameSite: 'lax'})
            cookies.set('company', tokenAccessResponse.company, {path: '/', sameSite: 'lax'})
            cookies.remove('token', {path: '/', sameSite: 'lax'})
            cookies.remove('expires_at', {path: '/', sameSite: 'lax'})
            cookies.remove('app_service', {path: '/', sameSite: 'lax'})

            Swal.fire({
              title: lang.labels.successfullyAcceptedInvitation,
              text: lang.labels.tokenValidForAnUserThatIsDifferentFromLoggedIn,
              icon: 'warning',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              navigate('/auth/login', {replace: true})
            })
          }
        } else {
          cookies.set('email', tokenAccessResponse.email, {path: '/', sameSite: 'lax'})
          cookies.set('company', tokenAccessResponse.company, {path: '/', sameSite: 'lax'})

          Swal.fire({
            title: lang.labels.successfullyAcceptedInvitation,
            text: lang.labels.tokenValidForAUserWhoHasNotLoggedIn,
            icon: 'warning',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            navigate('/auth/login', {replace: true})
          })
        }
      } else {
        errorResponse = response.data

        cookies.remove('token', {path: '/', sameSite: 'lax'})
        cookies.remove('expires_at', {path: '/', sameSite: 'lax'})
        cookies.remove('app_service', {path: '/', sameSite: 'lax'})
        cookies.remove('company', {path: '/', sameSite: 'lax'})
        cookies.remove('email', {path: '/', sameSite: 'lax'})

        Swal.fire({
          title: getError(errorResponse.code,lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions).then( () => {
          navigate('/', {replace: true})
        })
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const executeReloadPage = () => {
    navigate(0)
  }

  function email(parameter: string | undefined | null): string {
    return (parameter && (parameter.trim().length !== 0)) ? parameter : lang.labels.noMailFound
  }

  useEffect( () => {
    setMounted(true)

    if (param.email && param.token) {
      loadInvitationCompany(param.email, param.token)
    }

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="py-lg-10">
      <h1 className="fs-2qx text-dark text-capitalize mb-5">{lang.labels.acceptingInvitationToAccessCompany}</h1>
      <div className="fs-3 fw-bold text-gray-400 mb-10">
        {lang.labels.youAreWishingToEnterWithTheFollowingEmailAddress}
        <br />
        <span className="text-primary fw-bolder ms-1">{email(param.email)}</span>
        ,
        <br />
        {lang.labels.waitAMomentToVerifyYourAccess}
      </div>
      <div className="text-center my-10">
        <section className="global">
          <div className="global-top mask">
            <div className="plane"></div>
          </div>
          <div className="global-middle mask">
            <div className="plane"></div>
          </div>
          <div className="global-bottom mask">
            <div className="plane"></div>
          </div>
          <p className="global-text">
            <i>{lang.labels.loading}</i>
          </p>
        </section>
      </div>
      <div className="d-flex justify-content-center fs-5">
        <span className="fw-bold text-gray-700">{lang.labels.doesItTakeALongTimeToLoadTheApp}</span>
        <span className="btn btn-link link-primary p-0 fw-bolder ms-1" onClick={executeReloadPage}>{lang.labels.reload}</span>
      </div>
    </div>
  )
};

export default InvitationPage;
