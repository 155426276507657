import { Language } from "../languages/labels/language.labels";

export function statusQuoteList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'in progress', description: lang.labels.inProgress },
    { code: 'pending', description: lang.labels.pending },
    { code: 'approved', description: lang.labels.approved },
    { code: 'rejected', description: lang.labels.rejected },
    { code: 'completed', description: lang.labels.completed }
  ]
}

export function statusOrderList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'in progress', description: lang.labels.inProgress },
    { code: 'pending', description: lang.labels.pending },
    { code: 'approved', description: lang.labels.approved },
    { code: 'canceled', description: lang.labels.canceled },
    { code: 'completed', description: lang.labels.completed }
  ]
}

export function statusInvoiceList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'in progress', description: lang.labels.inProgress },
    { code: 'reminder', description: lang.labels.reminder },
    { code: 'in dispute', description: lang.labels.inDispute },
    { code: 'completed', description: lang.labels.completed }
  ]
}

export function statusRecordList(lang: Language): {code: string, description: string}[] {
  const statusRecord = [...statusQuoteList(lang), ...statusOrderList(lang), ...statusInvoiceList(lang)]
  return Array.from((new Map(statusRecord.map(item => [item.code, item]))).values())
}

export function statusRecordValue(lang: Language, code: string): string {
  const statusRecord = statusRecordList(lang).find(item => item.code === code)
  return statusRecord ? statusRecord.description : lang.labels.undefined
}
