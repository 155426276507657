import { Dispatch, SetStateAction } from "react"
import { expressions } from "../libraries/regular.expressions.library"
import { controllerFiveApproved, controllerFourApproved, controllerOneApproved, controllerThreeApproved, controllerTwoApproved, controllerZeroApproved } from "../tools/meter.security.password.tool"
import { legendInvalidRequiredConfirmationIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendInvalidEmptyConfirmationIcon, legendValidRequired, legendValidRequiredConfirmationIcon, legendValidRequiredIcon } from "../tools/legend.data.entry.tool"

function validateInputPassword(password: any, setPassword: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (expressions) {
    let options: boolean[] = [false, false, false, false, false]

    options[0] = (expressions.islowercase.test(password.value)) ? true : false
    options[1] = (expressions.isuppercase.test(password.value)) ? true : false
    options[2] = (expressions.isnumber.test(password.value)) ? true : false
    options[3] = (expressions.issymbol.test(password.value)) ? true : false
    options[4] = (expressions.limit.test(password.value)) ? true : false

    let disapproved: number = options.filter((option) => (option === false)).length

    if (disapproved === 0) {
      setPassword({...password, valid: true})
      legendValidRequiredIcon(identifier, container_1, container_2)
    } else {
      setPassword({...password , valid: false})
      legendInvalidEmptyIcon(password.value, identifier, container_1, container_2)
    }
  }
}

function validateInputPasswordController(password: any, setPassword: Dispatch<SetStateAction<any>>, input_container_1: string, input_container_2: string, controller_container_1: string, controller_container_2: string, controller_container_3: string, controller_container_4: string, controller_container_5: string) {
  if (expressions) {
    let options: boolean[] = [false, false, false, false, false]

    options[0] = (expressions.islowercase.test(password.value)) ? true : false
    options[1] = (expressions.isuppercase.test(password.value)) ? true : false
    options[2] = (expressions.isnumber.test(password.value)) ? true : false
    options[3] = (expressions.issymbol.test(password.value)) ? true : false
    options[4] = (expressions.limit.test(password.value)) ? true : false

    let approved: boolean[] = options.filter((option) => (option === true))

    switch (approved.length) {
      case 0:
        setPassword({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerZeroApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 1:
        setPassword({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerOneApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 2:
        setPassword({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerTwoApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 3:
        setPassword({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerThreeApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 4:
        setPassword({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerFourApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 5:
        setPassword({...password, valid: true})
        legendValidRequired(input_container_1, input_container_2)
        controllerFiveApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
    }
  }
}

function validateInputPasswordConfirmation(is_input_password: boolean, password: any, passwordConfirmation: any, setPasswordConfirmation: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string, container_3: string) {
  if (is_input_password === true && password.value.length === 0) {
    setPasswordConfirmation({...passwordConfirmation , valid: false})
    legendInvalidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)
  } else {
    if (expressions && passwordConfirmation.value.length > 0) {
      if (expressions.password.test(passwordConfirmation.value) && passwordConfirmation.value === password.value) {
        setPasswordConfirmation({...passwordConfirmation , valid: true})
        legendValidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)
      } else {
        setPasswordConfirmation({...passwordConfirmation , valid: false})
        legendInvalidEmptyConfirmationIcon(expressions.password, password.value, passwordConfirmation.value, identifier, container_1, container_2, container_3)
      }
    } else {
      setPasswordConfirmation({...passwordConfirmation , valid: false})
      legendInvalidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)  
    }
  }
}

export { validateInputPassword, validateInputPasswordController, validateInputPasswordConfirmation }
