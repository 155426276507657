import React, { Dispatch, SetStateAction} from 'react';

import { overlayOffcanvas, overlayMenu } from '../tools/overlay.tool';
import { drawerButtonHide, drawerHide } from '../tools/drawer.tool';
import { dropdownActiveHide } from '../tools/dropdown.tool';

export interface AppOverlayProps {
  activeDrawer: {value: string, aside: string, button: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string, button: string}>>
};

const AppOverlay: React.FunctionComponent<AppOverlayProps> = ({activeDrawer, setActiveDrawer}) => {
  const executeOverlayDrawer = () => {
    switch ('on') {
      case activeDrawer.aside:
        setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
        drawerHide('navbar-aside', 'overlay-drawer')
        break;
      case activeDrawer.button:
        setActiveDrawer({...activeDrawer, value: 'off', button: 'off'})
        drawerButtonHide('button-digital-admin-pro', 'drawer-digital-admin-pro', 'overlay-drawer')
        break;
      default:
        setActiveDrawer({...activeDrawer, aside: 'off', value: 'off', button: 'off'})
        overlayOffcanvas('overlay-drawer', 'navbar-aside', ['button-digital-admin-pro'], ['drawer-digital-admin-pro'])
        break;
    }
  }

  const executeOverlayMenu = () => {
    switch (true) {
      case document.getElementById('button-notifications')?.classList.contains('active'):
        dropdownActiveHide('button-notifications', 'dropdown-notifications', 'overlay-menu')
        break;
      case document.getElementById('button-languages')?.classList.contains('active'):
        dropdownActiveHide('button-languages', 'dropdown-languages', 'overlay-menu')
        break;
      case document.getElementById('button-user-profile')?.classList.contains('active'):
        dropdownActiveHide('button-user-profile', 'dropdown-user-profile', 'overlay-menu')
        break;
      default:
        overlayMenu('overlay-menu', ['button-notifications', 'button-languages', 'button-user-profile'], ['dropdown-notifications', 'dropdown-languages', 'dropdown-user-profile'])
        break;
    }
  }

  return (
    <>
      <div id="overlay-drawer" className="" onClick={executeOverlayDrawer}></div>
      <div id="overlay-menu" className="" onClick={executeOverlayMenu}></div>
    </>
  )
}

export default AppOverlay;
