import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { legendInvalidEmptyIcon, legendValidRequiredIcon } from '../../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { evaluateLegendValidateRequiredIconObjectList } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../components/element/Legend';
import AppModalUploadFileVoucher from '../../../../components/modal/ModalVoucherUploadFile';

export interface AppPurchaseFormVouchersProps {
  vouchers: {list: boolean, value: {name: string, view: string, file: any}[], valid: {name: boolean, view: boolean, file: boolean}[]},
  setVouchers: Dispatch<SetStateAction<{list: boolean, value: {name: string, view: string, file: any}[], valid: {name: boolean, view: boolean, file: boolean}[]}>>
};

const AppPurchaseFormVouchers: React.FunctionComponent<AppPurchaseFormVouchersProps> = ({vouchers, setVouchers}) => {
  const {lang} = StateLanguage()

  const handleChangeNameToVoucher = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempVouchersValue = vouchers.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.name = event.target.value
      }
      return temp_item
    })

    setVouchers({...vouchers, value: tempVouchersValue})
  }

  const validateNameToVoucher = (index: number) => {
    evaluateLegendValidateRequiredIconObjectList(expressions.text, index, 'name', vouchers, setVouchers, 'input-voucher-name-' + index, 'container-validate-voucher-name-valid-' + index, 'container-validate-voucher-name-required-' + index)
  }

  const executeRemoveVoucher = (index: number) => {
    let tempVouchersValue = vouchers.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempVouchersValid = vouchers.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setVouchers({...vouchers, value: tempVouchersValue, valid: tempVouchersValid})

    for (let i = 0; i < tempVouchersValue.length; i++) {
      if (expressions) {
        if (expressions.text.test(tempVouchersValue[i].name)) {
          legendValidRequiredIcon('input-voucher-name-' + i, 'container-validate-voucher-name-valid-' + i, 'container-validate-voucher-name-required-' + i)
        } else {
          legendInvalidEmptyIcon(tempVouchersValue[i].name, 'input-voucher-name-' + i, 'container-validate-voucher-name-valid-' + i, 'container-validate-voucher-name-required-' + i)
        }
      }
    }
  }

  const executeShowModalUploadFileVoucher = () => {
    let allow: boolean = true

    for (let i = 0; i < vouchers.valid.length; i++) {
      if (!vouchers.valid[i].name) {
        legendInvalidEmptyIcon(vouchers.value[i].name, 'input-voucher-name-' + i, 'container-validate-voucher-name-valid-' + i, 'container-validate-voucher-name-required-' + i)
        allow = false
      }
    }

    if (allow) {
      modalShow('modal-voucher-upload-file')
    }
  }

  useEffect( () => {
    uploadTooltip()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="card card-flush mb-5">
        <div className="card-header">
          <div className="card-title">
            <h4>{lang.labels.vouchers}</h4>
          </div>
          <div className="card-toolbar">
            <button className="btn btn-sm btn-light-primary" type="button" onClick={executeShowModalUploadFileVoucher}>
              <div className="d-flex align-items-center">
                <span className="svg-icon svg-icon-3 ms-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                  </svg>
                </span>
                {lang.labels.addVouchers}
              </div>
            </button>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="dataTables_wrapper">
            <div className="table-responsive">
              <table className="table align-middle g-1 mb-1">
                <thead className="border-bottom">
                  <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                    <th className="min-w-300px text-nowrap">{lang.labels.file}</th>
                    <th className="w-100px"></th>
                  </tr>
                </thead>
                { vouchers.value.length > 0 && vouchers.valid.length > 0
                  ?
                  <tbody>
                    { vouchers.value.map (( (item, index) => { return (
                      <tr key={index}>
                        <td valign="top">
                          <input id={"input-voucher-name-" + index} className="form-control form-control-solid" type="text" name="name" value={item.name} onChange={(event) => handleChangeNameToVoucher(index, event)} onKeyUp={() => validateNameToVoucher(index)} onBlur={() => validateNameToVoucher(index)} />
                          <div id={`voucher-pdf-${index}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <iframe id='modal-voucher-view-container' title={item.name} src={item.view} width="100%" height="100%" />
                              </div>
                            </div>
                          </div>
                          <AppLegend component={null} attribute={{validity: vouchers.valid[index].name, name: "voucher-name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top" className="text-end">
                          <button className="btn btn-icon btn-primary btn-light-primary" data-bs-toggle="modal" data-bs-target={`#voucher-pdf-${index}`}>
                            <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.viewVoucher} onClick={removeTooltip}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black"/>
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                              </svg>
                            </span>
                          </button>
                          <button className="btn btn-icon btn-primary btn-light-primary ms-2" type="button" onClick={() => executeRemoveVoucher(index)}>
                            <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeVoucher} onClick={removeTooltip}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                      )}))}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td valign="top" colSpan={3}>
                        <div className="form-control form-control-sm form-control-solid text-center text-uppercase">{lang.labels.noRecords}</div>
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      </div>
      <AppModalUploadFileVoucher vouchers={vouchers} setVouchers={setVouchers}></AppModalUploadFileVoucher>
    </>
  )
};

export default AppPurchaseFormVouchers;
