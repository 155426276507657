import { jsPDF } from 'jspdf';
import { QRTemplate } from './models/qr.template.model';

export class jsPDFWithSvg extends jsPDF {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(orientation: any, unit: any, size: string) {
    super(orientation, unit, size)
  }

  private static scalePath = 0.265
  private static ptToMM = 0.35277777777778
  private x: number = 0
  private y: number = 0
  private segmentLength: number = 0.8
  private dottedLineWidth = 0.3
  private defaultLineWidth = 0

  public addSvgString = (qrTemplate: QRTemplate, x: number, y: number, w: number, h: number): jsPDF => {
    jsPDFWithSvg.scalePath = qrTemplate.px2mm
    jsPDFWithSvg.ptToMM = qrTemplate.pt2mm

    // 'this' is jsPDF object returned when jsPDF is united (new jsPDF())
    var undef
    if (x === undef || y === undef) {
      throw new Error("addSVG needs values for 'x' and 'y'");
    }

    this.x = x
    this.y = y

    function InjectCSS(cssbody: string, document: any) {
      var styletag = document.createElement('style');
      styletag.type = 'text/css';
      if (styletag.styleSheet) {
        // ie
        styletag.styleSheet.cssText = cssbody;
      } else {
        // others
        styletag.appendChild(document.createTextNode(cssbody));
      }
      document.getElementsByTagName("head")[0].appendChild(styletag);
    };

    function createWorkerNode(document: any) {
      var frameID = 'childframe' // Date.now().toString() + '_' + (Math.random() * 100).toString()
      , frame = document.createElement('iframe')
      InjectCSS(
        '.jsPDF_sillysvg_iframe {display:none;position:absolute;}'
        , document
      )
      frame.name = frameID
      frame.setAttribute("width", 0)
      frame.setAttribute("height", 0)
      frame.setAttribute("frameborder", "0")
      frame.setAttribute("scrolling", "no")
      frame.setAttribute("seamless", "seamless")
      frame.setAttribute("class", "jsPDF_sillysvg_iframe")
      document.body.appendChild(frame)
      return frame
    };

    function attachSVGToWorkerNode(svgtext: string, frame: any){
      var framedoc = ( frame.contentWindow || frame.contentDocument ).document
      framedoc.write(svgtext)
      framedoc.close()
      return framedoc.getElementsByTagName('svg')[0]
    };

    function convertPathToPDFLinesArgs(path: string[], scale: number[]){
      // we will use 'lines' method call. it needs:
      // - starting coordinate pair
      // - array of arrays of vector shifts (2-len for line, 6 len for bezier)
      // - scale array [horizontal, vertical] ratios
      // - style (stroke, fill, both)
      var x = parseFloat(path[1])
      , y = parseFloat(path[2])
      , vectors = []
      , position = 3
      , len = path.length
      while (position < len){
        if (path[position] === 'c'){
          // vectors.push([
          //   parseFloat(path[position + 1])
          //   , parseFloat(path[position + 2])
          //   , parseFloat(path[position + 3])
          //   , parseFloat(path[position + 4])
          //   , parseFloat(path[position + 5])
          //   , parseFloat(path[position + 6])
          // ])
          position += 7
        } else if (path[position] === 'l') {
          vectors.push([
            parseFloat(path[position + 1]),
            parseFloat(path[position + 2])
          ])
          position += 3
        } else if (path[position] === 'V') {
          vectors.push([
            0,
            (parseFloat(path[position + 1]) - y)*scale[1]
          ])
          vectors.push([
            (parseFloat(path[position + 3]) - x)*scale[0],
            0
          ])
          vectors.push([
            0,
            (parseFloat(path[position + 5]) - parseFloat(path[position + 1]))*scale[1]
          ])
          vectors.push([
            (parseFloat(path[position + 7]) - x)*scale[1],
            0
          ])
          position += 8
        } else if (path[position] === 'Z'){
          break
        } else {
          position += 1
        }
      }
      return [x,y,vectors]
    };

    var workernode = createWorkerNode(document)
    , svgnode = attachSVGToWorkerNode(qrTemplate.svg, workernode)
    , scale = [1,1]
    , svgw = parseFloat(svgnode.getAttribute('width'))
    , svgh = parseFloat(svgnode.getAttribute('height'))

    if (svgw && svgh) {
      // setting both w and h makes image stretch to size.
      // this may distort the image, but fits your demanded sizefor(
      if (w && h) {
        scale = [w / svgw, h / svgh]
      }
      // if only one is set, that value is set as max and SVG
      // is scaled proportionately.
      else if (w) {
        scale = [w / svgw, w / svgw]
      } else if (h) {
        scale = [h / svgh, h / svgh]
      }
    }

    var i, l, tmp
    , linesargs:any
    , items = svgnode.childNodes

    for (i = 0, l = items.length; i < l; i++) {
      tmp = items[i]
      let xTmp = tmp.getAttribute("x") ? parseFloat(tmp.getAttribute("x")) : 0
      let yTmp = tmp.getAttribute("y") ? parseFloat(tmp.getAttribute("y")) : 0
      if (tmp.tagName && tmp.tagName.toUpperCase() === 'SVG') {
        this.setLineWidth(this.defaultLineWidth)
        this.setLineDashPattern([1, 0], 0)
        let childrenSvg = tmp.childNodes
        for (let i = 0; i < childrenSvg.length; ++i) {
          tmp = childrenSvg[i]
          if (tmp.tagName && tmp.tagName.toUpperCase() === 'PATH') {
            let linetos = tmp.getAttribute("d").split('  ')
            for (let j = 0; j < linetos.length; j++) {
              linesargs = convertPathToPDFLinesArgs(linetos[j].split(' '), [jsPDFWithSvg.scalePath, jsPDFWithSvg.scalePath])
              // path start x coordinate
              //linesargs[0] = linesargs[0] * scale[0] + x + xTmp // where x is upper left X of image
              linesargs[0] = linesargs[0] * jsPDFWithSvg.scalePath + x + xTmp // where x is upper left X of image
              // path start y coordinate
              //linesargs[1] = linesargs[1] * scale[1] + y + yTmp// where y is upper left Y of image
              linesargs[1] = linesargs[1] * jsPDFWithSvg.scalePath + y + yTmp// where y is upper left Y of image
              // the rest of lines are vectors. these will adjust with scale value auto.
              this.lines(
                linesargs[2], // lines
                linesargs[0], // starting x
                linesargs[1], // starting y
                scale,
                "FD"
              )
            }
          } else if (tmp.tagName && tmp.tagName.toUpperCase() === 'TEXT') {
            let childrenText = tmp.childNodes
            let xText = tmp.getAttribute("x") ? parseFloat(tmp.getAttribute("x")) : 0
            let yText = tmp.getAttribute("y") ? parseFloat(tmp.getAttribute("y")) : 0
            let curCoord: number[] = [x + xTmp + xText, y + yTmp + yText]
            let initialCoord: number[] = [x + xTmp + xText, y + yTmp + yText]
            let lastSvgCoord: number[] = [xTmp, yTmp]
            for (let j = 0; j < childrenText.length; ++j) {
              if (childrenText[j].constructor.name === "SVGTSpanElement") {
                curCoord = this.addTextFromElementSvg(childrenText[j], initialCoord, curCoord, lastSvgCoord)
              } else if (childrenText[j].constructor.name === "Text") {
                curCoord = this.addTextFromElementSvg(tmp, initialCoord, curCoord, lastSvgCoord)
              }
            }
          } else if (tmp.tagName && tmp.tagName.toUpperCase() === "RECT") {
            let rectX = parseFloat(tmp.getAttribute("x"))
            let rectY = parseFloat(tmp.getAttribute("y"))
            let rectW = parseFloat(tmp.getAttribute("width"))
            let rectH = parseFloat(tmp.getAttribute("height"))
            let rectC = tmp.getAttribute("fill")
            let color = rectC === "white"? "#FFFFFF" : "#000000"
            this.setFillColor(color)
            this.setDrawColor(color)
            this.lines(
              [[0, rectH], [rectW, 0], [0, -1*rectH], [-1*rectW, 0]], // lines
              x + xTmp + rectX, // starting x
              y + yTmp + rectY, // starting y
              scale,
              "F"
            )
          }
        }    
      } else if (tmp.tagName && tmp.tagName.toUpperCase() === 'LINE') { 
        let x1 = tmp.getAttribute("x1") ? parseFloat(tmp.getAttribute("x1")) : 0
        let y1 = tmp.getAttribute("y1") ? parseFloat(tmp.getAttribute("y1")) : 0
        let x2 = tmp.getAttribute("x2") ? parseFloat(tmp.getAttribute("x2")) : 0
        let y2 = tmp.getAttribute("y2") ? parseFloat(tmp.getAttribute("y2")) : 0
        this.setLineWidth(this.dottedLineWidth)
        this.setLineDashPattern([this.segmentLength, this.segmentLength], 0)
        this.line(this.x + x1, this.y + y1, this.x + x2, this.y + y2)
        this.line(0, this.y, this.x + w, this.y)
      }
    }
    // clean up
    // workernode.parentNode.removeChild(workernode)
    return this
  }

  public addTextFromElementSvg = (element: any, firstCord: number[], curCoord: number[], lastSvgCoord: number[] = [5, 5]): number[] => {
    if (element.constructor.name === "SVGTSpanElement" || element.constructor.name === "SVGTextElement") {
      let posX = element.getAttributeNode("x") ? parseFloat(element.getAttributeNode("x").value) : 0
      let posY = element.getAttributeNode("y") ? parseFloat(element.getAttributeNode("y").value) : 0
      let dX = element.getAttributeNode("dx") ? parseFloat(element.getAttributeNode("dx").value) : 0
      let dY = element.getAttributeNode("dy") ? parseFloat(element.getAttributeNode("dy").value) : 0
      let fontFamily = element.getAttributeNode("font-family") ? element.getAttributeNode("font-family").value : "Arial"
      let bold = element.getAttributeNode("font-weight") ? element.getAttributeNode("font-weight").value : "normal"
      let fontSize = element.getAttributeNode("font-size") ? parseFloat(element.getAttributeNode("font-size").value) : 8
      let anchor = element.getAttributeNode("text-anchor") ? element.getAttributeNode("text-anchor").value : "none"
      let finalX = firstCord[0] + posX + dX*jsPDFWithSvg.ptToMM;
      let finalY = firstCord[1] + posY + dY*jsPDFWithSvg.ptToMM;
      if (!element.getAttributeNode("y")) {
        finalY = curCoord[1] + dY*jsPDFWithSvg.ptToMM;
      }
      if (fontFamily === "Arial") {
        fontFamily = "Helvetica"
      }
      this.setFontSize(fontSize)
      this.setFont(fontFamily, bold)
      if (anchor === "end") {
        this.text(element.innerHTML, this.x + posX + lastSvgCoord[0], this.y + posY - fontSize*jsPDFWithSvg.ptToMM + lastSvgCoord[1], {align: "right"})
      } else {
        this.text(element.innerHTML, finalX, finalY)
      }
      return [finalX, finalY]
    }
    return [0, 0]
  }
};
