import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { CompanyDashboardInsight } from '../models/company.dashboard.insight.model';
import { CompanyDashboardComparsion } from '../models/company.dashboard.comparsion.model';
import { CompanyDashboardAnalysis } from '../models/company.dashboard.analysis.model';
import { appToken } from '../scripts/app.token.script';

export class DashboardDA {
  static apiResponse: ApiResponse

  public static async getDashboardInsight(id_company: string, time_interval_range: string, time_interval_current_start_date: string, time_interval_current_end_date: string, time_interval_compare_start_date: string, time_interval_compare_end_date: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let dashboardInsightByCompany: CompanyDashboardInsight | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/dashboard.insight.data.json').then(response => { dashboardInsightByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (dashboardInsightByCompany!.id_company === id_company) {
        dashboardInsightByCompany!.time_interval.range = time_interval_range
        dashboardInsightByCompany!.time_interval.current.start_date = time_interval_current_start_date
        dashboardInsightByCompany!.time_interval.current.end_date = time_interval_current_end_date
        dashboardInsightByCompany!.time_interval.compare.start_date = time_interval_compare_start_date
        dashboardInsightByCompany!.time_interval.compare.end_date = time_interval_compare_end_date

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: dashboardInsightByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/dashboard?time_interval_range=${time_interval_range}&time_interval_current_start_date=${time_interval_current_start_date}&time_interval_current_end_date=${time_interval_current_end_date}&time_interval_compare_start_date=${time_interval_compare_start_date}&time_interval_compare_end_date=${time_interval_compare_end_date}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getDashboardComparsion(id_company: string, range_current: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let dashboardComparsionByCompany: CompanyDashboardComparsion | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/dashboard.comparsion.data.json').then(response => { dashboardComparsionByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (dashboardComparsionByCompany!.id_company === id_company) {
        if (!dashboardComparsionByCompany!.range.list.some((item: string) => item === range_current)) {
          dashboardComparsionByCompany!.range.list.push(range_current)
        }

        dashboardComparsionByCompany!.range.current = range_current

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: dashboardComparsionByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/dashboard/comparison?range_current=${range_current}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getDashboardAnalysis(id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let dashboardAnalysisByCompany: CompanyDashboardAnalysis | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/dashboard.analysis.data.json').then(response => { dashboardAnalysisByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (dashboardAnalysisByCompany!.id_company === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: dashboardAnalysisByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/dashboard/analysis`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
