import React from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from '../../models/company.model';
import { modalHide } from '../../tools/modal.tool';
import { imageUser } from '../../scripts/image.value.script';

export interface AppModalCompanyUsersProps {
  companyForUser: Company | undefined | null
};

const AppModalCompanyUsers: React.FunctionComponent<AppModalCompanyUsersProps> = ({companyForUser}) => {
  const {lang} = StateLanguage()

  const executeHideModalCompanyView = () => {
    modalHide('modal-company-view')
  }

  return (
    <div id="modal-company-view" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalCompanyView}>
              <span className="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body pt-0">
            <div className="text-center mb-5">
              <h2 className="text-capitalize mb-3">{lang.labels.browseUsers}</h2>
              <div className="text-gray-800">
                {lang.labels.usersWhoAreSubscribed}
                <span className="text-primary fw-bold ms-1">{companyForUser && companyForUser.name}</span>
              </div>
            </div>
            <div className="mh-400px scroll-y px-5 border-top border-bottom">
              { companyForUser
                ?
                <>
                  { companyForUser.users.map (( (item, index) => { return (
                    <div key={index} className={`d-flex flex-stack py-3 ${index < companyForUser.users.length - 1 && "border-bottom border-gray-300 border-bottom-dashed"}`}>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-35px symbol-circle">
                          { item.avatar.length === 0
                            ?
                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">{item.last_name.charAt(0)}</span>
                            :
                            <img src={imageUser(item.avatar)} alt={lang.labels.avatar} />
                          }
                        </div>
                        <div className="ms-5">
                          <div className="fw-bolder text-gray-900">{item.first_name} {item.last_name} </div>
                          <div className="fw-bold text-muted">{item.email}</div>
                        </div>
                      </div>
                    </div>
                  )}))}
                </>
                :
                <div className="d-flex justify-content-center align-items-center w-100 h-200px ">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCompanyUsers;
