import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { AppService } from '../../models/app.service.model';
import { Notification } from '../../models/notification.model';
import { dropdownActiveHide } from '../../tools/dropdown.tool';
import { modalShow } from '../../tools/modal.tool';

import AppModalNotificationCompany from '../modal/ModalNotificationCompany';
import AppModalNotificationQuote from '../modal/ModalNotificationQuote';
import AppModalNotificationOrder from '../modal/ModalNotificationOrder';

import imgSearch from '../../assets/images/themes/image-search.png';
import imgSettings from '../../assets/images/themes/image-settings.png';

export interface AppDropdownNotificationsProps {
  appServiceForUser: AppService | undefined | null,
  notificationsForUser: Notification[] | undefined | null,
  reloadUserLoggedIn: Function,
  loadNotificationsForUser: Function
};

const AppDropdownNotifications: React.FunctionComponent<AppDropdownNotificationsProps> = ({appServiceForUser, notificationsForUser, reloadUserLoggedIn, loadNotificationsForUser}) => {
  const {lang} = StateLanguage()

  const [notification, setNotification] = useState<Notification | undefined | null>(null)

  const executeSelectNotification = (item: Notification) => {
    setNotification(item)

    switch (item.description) {
      case 'company access':
        modalShow('modal-notification-company')
        break;
      case 'quote evaluation':
        modalShow('modal-notification-quote')
        break;
      case 'order evaluation':
        modalShow('modal-notification-order')
        break;
    }

    dropdownActiveHide('button-notifications', 'dropdown-notifications', 'overlay-menu')
  }

  function countReports(): number {
    return (notificationsForUser) ? notificationsForUser.filter((item: Notification) => (!item.view && item.description === 'company access')).length : 0
  }

  function color(notification: Notification): string {
    let classColor = "dark"

    if (!notification.view) {
      if (notification.type === 'activity') {
        classColor = "warning"
      }
      if (notification.type === 'answer') {
        switch (notification.description) {
          case 'company access':
            if (notification.detail.response === 'rejected') {
              classColor = "danger"
            } else {
              classColor = "success"
            }
            break;
          case 'quote evaluation':
            if (notification.detail.response === 'rejected') {
              classColor = "danger"
            } else {
              classColor = "success"
            }
            break;
          case 'order evaluation':
            if (notification.detail.response === 'canceled') {
              classColor = "danger"
            } else {
              classColor = "success"
            }
            break;
          default :
            classColor = "info"
            break;
        }
      }
    }

    return classColor
  }

  function message(notification: Notification): string {
    let texMessage: string = lang.labels.newNotification

    if (notification.type === 'activity') {
      switch (notification.description) {
        case 'company access':
          texMessage = lang.labels.hasReceivedAnInvitation
          break;
        case 'quote evaluation':
          texMessage = lang.labels.hasReceivedAQuote
          break;
        case 'order evaluation':
          texMessage = lang.labels.hasReceivedAnOrder
          break;
        default :
          texMessage = lang.labels.hasReceivedANotification
          break;
      }
    }

    if (notification.type === 'answer') {
      switch (notification.description) {
        case 'company access':
          texMessage = `${lang.labels.accessToCompany} ${notification.detail.company.name}`
          break;
        case 'quote evaluation':
          texMessage = `${lang.labels.quote} ${notification.detail.quote.quote_number} ${lang.labels.evaluated}`
          break;
        case 'order evaluation':
          texMessage = `${lang.labels.order} ${notification.detail.order.order_number} ${lang.labels.evaluated}`
          break;
        default :
          texMessage = lang.labels.hasReceivedANotification
          break;
      }
    }

    return texMessage
  }

  function timeElapsed(date: string): string {
    let dateNotification: Date = new Date(date)
    let dateCurrent: Date = new Date()
    let momentNotification: Moment = moment(dateNotification)
    let momentCurrent: Moment = moment(dateCurrent)
    let minutes: number = momentCurrent.diff(momentNotification, 'minute')
    let hours: number = momentCurrent.diff(momentNotification, 'hour')
    let days: number = momentCurrent.diff(momentNotification, 'day')
    let months: number = momentCurrent.diff(momentNotification, 'month')
    let years: number = momentCurrent.diff(momentNotification, 'year')

    if (years > 0) {
      return `${years} ${(years === 1) ? lang.labels.yearl : lang.labels.years}`
    } else {
      if (months > 0) {
        return `${months} ${(months === 1) ? lang.labels.month : lang.labels.months}`
      } else {
        if (days > 0) {
          return `${days} ${(days === 1) ? lang.labels.day : lang.labels.days}`
        } else {
          if (hours > 0) {
            return `${hours} ${(hours === 1) ? lang.labels.hour : lang.labels.hours}`
          } else {
            if (minutes > 0) {
              return `${minutes} ${(minutes === 1) ? lang.labels.minute : lang.labels.minutes}`
            } else {
              return lang.labels.justNow
            }
          }
        }
      }
    }
  }

  function notificationsActivityForUser(notifications: Notification[]): Notification[] {
    return notifications.filter((item: Notification) => (item.type === 'activity' && item.description === 'company access'))
  }

  function notificationsAnswerForUser(notifications: Notification[]): Notification[] {
    return notifications.filter((item: Notification) => (item.type === 'answer' && item.description === 'company access'))
  }

  return (
    <>
      <div id="dropdown-notifications" className="menu menu-sub menu-sub-dropdown menu-column w-350px" data-kt-menu="true">
        <div id="background-dropdown-header" className="d-flex flex-column bgi-no-repeat rounded-top">
          <h3 className="text-white p-5 m-0">
            {lang.labels.notifications}
            <span className="fs-8 opacity-75 ps-3">{countReports()} {countReports() === 1 ? `${lang.labels.report}` : `${lang.labels.reports}`}</span>
          </h3>
          <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-5">
            <li className="nav-item">
              <a href="#activity_notifications" className="nav-link text-white opacity-75 opacity-state-100 active" data-bs-toggle="tab">{lang.labels.activities}</a>
            </li>
            <li className="nav-item">
              <a href="#answer_notifications" className="nav-link text-white opacity-75 opacity-state-100" data-bs-toggle="tab">{lang.labels.answers}</a>
            </li>
            <li className="nav-item">
              <a href="#other_notifications" className="nav-link text-white opacity-75 opacity-state-100" data-bs-toggle="tab">{lang.labels.other}</a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div id="activity_notifications" className="tab-pane fade show active" role="tabpanel">
            <div className="scroll-y mh-350px my-2 px-3">
              { notificationsForUser
                ?
                <>
                  { notificationsActivityForUser(notificationsForUser).length > 0
                    ?
                    <>
                      { notificationsActivityForUser(notificationsForUser).map (( (item, index) => {
                        return (
                          <div key={index} className="d-flex flex-stack py-3">
                            <div className="d-flex align-items-center me-3">
                              <div className="symbol symbol-35px me-3">
                                <span className={`symbol-label bg-light-${color(item)}`}>
                                  <span className={`svg-icon svg-icon-2 svg-icon-${color(item)}`}>
                                    { item.description === "company access"
                                      ?
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black"/>
                                        <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black"/>
                                        <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black"/>
                                      </svg>
                                      :
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black" />
                                        <path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black" />
                                      </svg>
                                    }
                                  </span>
                                </span>
                              </div>
                              <div className="row">
                                <span className="text-gray-900 text-hover-primary fw-bold cursor-pointer" onClick={() => executeSelectNotification(item)}>{message(item)}</span>
                                <span className="text-gray-600 text-uppercase fs-8">{item.description}</span>
                              </div>
                            </div>
                            <span className="badge badge-light text-lowercase fs-9">{timeElapsed(item.created_at)}</span>
                          </div>
                        )
                      }))}
                    </>
                    :
                    <div className="d-flex flex-column">
                      <div className="text-center py-3">
                        <h3 className="text-dark">{lang.labels.noNotifications}</h3>
                        <div className="text-gray-600 fw-bold">{lang.labels.thereAreNoNotifications}</div>
                      </div>
                      <div className="text-center">
                        <img src={imgSearch} alt={lang.labels.background} className="mw-100 mh-150px" />
                      </div>
                    </div>
                  }
                </>
                :
                <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div id="answer_notifications" className="tab-pane fade" role="tabpanel">
            <div className="scroll-y mh-350px my-2 px-3">
              { notificationsForUser
                ?
                <>
                  { notificationsAnswerForUser(notificationsForUser).length > 0
                    ?
                    <>
                      { notificationsAnswerForUser(notificationsForUser).map (( (item, index) => {
                        return (
                          <div key={index} className="d-flex flex-stack py-3">
                            <div className="d-flex align-items-center me-3">
                              <span className={`w-80px badge badge-light-${color(item)} me-3 fs-8 text-uppercase`}>{item.detail.response}</span>
                              <span className="text-gray-800 text-hover-primary fw-bold cursor-pointer" onClick={() => executeSelectNotification(item)}>{message(item)}</span>
                            </div>
                            <span className="badge badge-light text-lowercase fs-9">{timeElapsed(item.created_at)}</span>
                          </div>
                        )
                      }))}
                    </>
                    :
                    <div className="d-flex flex-column">
                      <div className="text-center py-3">
                        <h3 className="text-dark">{lang.labels.noNotifications}</h3>
                        <div className="text-gray-600 fw-bold">{lang.labels.noNotificationsSeenYet}</div>
                      </div>
                      <div className="text-center">
                        <img src={imgSearch} alt={lang.labels.background} className="mw-100 mh-150px" />
                      </div>
                    </div>
                  }
                </>
                :
                <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div id="other_notifications" className="tab-pane fade" role="tabpanel">
            <div className="d-flex flex-column">
              <div className="text-center py-5">
                <h3 className="text-dark">{lang.labels.contactUs}</h3>
                <div className="text-gray-600 fw-bold">{lang.labels.ifYouHaveAnyQuestionsContactUs}</div>
                <div className="m-5">
                  <a href="https://digitaladmin.ch" className="btn btn-sm btn-primary">{lang.labels.digitalAdmin}</a>
                </div>
              </div>
              <div className="text-center">
                <img src={imgSettings} alt={lang.labels.background} className="mw-100 mh-150px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModalNotificationCompany appServiceForUser={appServiceForUser} notification={notification} reloadUserLoggedIn={reloadUserLoggedIn}></AppModalNotificationCompany>
      <AppModalNotificationQuote appServiceForUser={appServiceForUser} notification={notification} loadNotificationsForUser={loadNotificationsForUser}></AppModalNotificationQuote>
      <AppModalNotificationOrder appServiceForUser={appServiceForUser} notification={notification} loadNotificationsForUser={loadNotificationsForUser}></AppModalNotificationOrder>
    </>
  )
}

export default AppDropdownNotifications;
