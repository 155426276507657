import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { UseOutletContextSale } from '../Sale';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ApiGeolocation } from '../../../../services/api.geolocation';
import { Contact } from '../../../../models/contact.model';
import { legendValidInvalidRestart, legendValid, legendValidInvalidIconRestart } from '../../../../tools/legend.data.entry.tool';
import { Container } from '../../../../styles/container.style';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequiredIcon } from '../../../../scripts/validate.legend.script';
import { countryCode, countryName, countryList } from '../../../../libraries/countries.library';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../components/element/Legend';

export interface AppSaleFormDeliveryProps {
  customer: any | Contact,
  street: {value: string, valid: boolean},
  streetNumber: {value: string, valid: boolean},
  additional: {value: string, valid: boolean},
  administrativeArea: {value: string, valid: boolean},
  city: {value: string, valid: boolean},
  zipCode: {value: string, valid: boolean},
  country: {value: string, valid: boolean},
  setStreet: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setStreetNumber: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdditional: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdministrativeArea: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCity: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setZipCode: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCountry: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const AppSaleFormDelivery: React.FunctionComponent<AppSaleFormDeliveryProps> = ({customer, street, streetNumber, additional, administrativeArea, city, zipCode, country, setStreet, setStreetNumber, setAdditional, setAdministrativeArea, setCity, setZipCode, setCountry}) => {
  const {companyForUser} = UseOutletContextSale()
  const {lang} = StateLanguage()

  const [defaultAddress, setDefaultAddress] = useState(false)
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const handleChangeDefaultAddress = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDefaultAddress(event.target.checked)

    if (customer && event.target.checked) {
      setStreet({value: customer.data.address.street, valid: true})
      setStreetNumber({value: customer.data.address.number, valid: true})
      setAdditional({value: customer.data.address.additional, valid: true})
      setAdministrativeArea({value: customer.data.address.administrative_area, valid: true})
      setCity({value: customer.data.address.city, valid: true})
      setZipCode({value: customer.data.address.zip_code, valid: true})
      setCountry({value: customer.data.address.country, valid: true})
      restartForm()
    } else {
      setStreet({value: '', valid: false})
      setStreetNumber({value: '', valid: false})
      setAdditional({value: '', valid: true})
      setAdministrativeArea({value: '', valid: false})
      setCity({value: '', valid: false})
      setZipCode({value: '', valid: false})
      loadLocationBrowser()
      restartForm()
    }
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid('container-validate-country-required')
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({text: text, list: list})
  }

  const validateStreet = () => {
    evaluateLegendValidateRequiredIcon(expressions.street, street, setStreet, 'input-street', 'container-validate-street-valid', 'container-validate-street-required')
  }

  const validateNumber = () => {
    evaluateLegendValidateRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, 'input-additional', 'container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, 'input-administrative-area', 'container-validate-administrative-area-valid', 'container-validate-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, city, setCity, 'input-city', 'container-validate-city-valid', 'container-validate-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidateRequiredIcon(expressions.zipcode, zipCode, setZipCode, 'input-zip-code', 'container-validate-zip-code-valid', 'container-validate-zip-code-required')
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  function restartForm() {
    legendValidInvalidIconRestart('input-street', 'container-validate-street-valid')
    legendValidInvalidIconRestart('input-street', 'container-validate-street-required')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-valid')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-required')
    legendValidInvalidIconRestart('input-additional', 'container-validate-additional-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-required')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-valid')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-required')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-valid')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-required')
    legendValidInvalidRestart('container-validate-country-required')
  }

  async function loadLocationBrowser() {
    ApiGeolocation.getLocateIPAddress().then( (response) => {
      if (response) {
        setCountry({...country, value: response.country_code, valid: true})
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (customer && customer.data.address.street === street.value && customer.data.address.number === streetNumber.value && customer.data.address.additional === additional.value && customer.data.address.administrative_area === administrativeArea.value && customer.data.address.city === city.value && customer.data.address.zip_code === zipCode.value && customer.data.address.country === country.value) {
      setDefaultAddress(true)
    } else {
      setDefaultAddress(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect( () => {
    setStreet({value: '', valid: false})
    setStreetNumber({value: '', valid: false})
    setAdditional({value: '', valid: true})
    setAdministrativeArea({value: '', valid: false})
    setCity({value: '', valid: false})
    setZipCode({value: '', valid: false})
    setCountry({value: '', valid: false})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    loadLocationBrowser()
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="card card-flush mb-5">
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.delivery}</h4>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="d-flex flex-stack">
          <div className="fw-bold fs-6">{lang.labels.isDeliveryAddressTheSameAsCustomersAddress}</div>
          <div className="form-check form-switch form-check-custom form-check-solid">
            <input className="form-check-input w-50px h-25px" type="checkbox" checked={defaultAddress} onChange={handleChangeDefaultAddress} />
            <label className="form-check-label text-muted text-uppercase">{lang.labels.customer}</label>
          </div>
        </div>
        <Container property={(!defaultAddress).toString()}>
          <div className="separator my-3"></div>
          <div className="row mb-0 mb-lg-3">
            <div className="col-8 fv-row">
              <input id="input-street" className="form-control form-control-solid" type="text" name="street" placeholder={lang.labels.street.toUpperCase()} value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
              <AppLegend component={null} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
            <div className="col-4 fv-row">
              <input id="input-street-number" className="form-control form-control-solid" type="text" name="number" placeholder={lang.labels.number.toUpperCase()} value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
              <AppLegend component={null} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
          <div className="row mb-0 mb-lg-3">
            <div className="col-12 fv-row">
              <input id="input-additional" className="form-control form-control-solid" type="text" name="additional" placeholder={lang.labels.additional.toUpperCase()} value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
              <AppLegend component={null} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
          <div className="row mb-0 mb-lg-3">
            <div className="col-12 fv-row">
              <input id="input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" placeholder={lang.labels.administrativeArea.toUpperCase()} value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
              <AppLegend component={null} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
          <div className="row mb-0 mb-lg-3">
            <div className="col-4 fv-row">
              <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" placeholder={lang.labels.zipCode.toUpperCase()} value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
              <AppLegend component={null} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
            <div className="col-8 fv-row">
              <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} placeholder={lang.labels.city.toUpperCase()} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
              <AppLegend component={null} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
          <div className="row">
            <div className="col-12 fv-row">
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                      <span className="select2-selection__rendered" role="textbox">
                        <span className={`select2-selection__placeholder ${(!country.valid) && "text-muted"}`}>
                          {country.valid ? `${countryName(country.value, lang.code)}` : `${lang.labels.country.toUpperCase()}`}
                        </span>
                      </span>
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-search select2-search--dropdown">
                      <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                    </span>
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        { searchCountry.list.length > 0
                          ?
                          <>
                            { searchCountry.list.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                              </li>
                            )}))}
                          </>
                          :
                          <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                        }
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
              <AppLegend component={null} attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
};

export default AppSaleFormDelivery;
