import { Language } from "../languages/labels/language.labels";

export function contactTypeList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'company', description: lang.labels.company },
    { code: 'person', description: lang.labels.person }
  ]
}

export function contactTypeValue(lang: Language, code: string): string {
  const contactType = contactTypeList(lang).find(item => item.code === code)
  return contactType ? contactType.description : lang.labels.undefined
}
