function dropdownShow(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.add('show')
  element2?.classList.add('show')
  element3?.classList.add('menu-overlay-transparent')
}

function dropdownHide(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.remove('show')
  element2?.classList.remove('show')
  element3?.classList.remove('menu-overlay-transparent')
}

function dropdownActiveShow(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.add('active')
  element2?.classList.add('show')
  element3?.classList.add('menu-overlay')
}

function dropdownActiveHide(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.remove('active')
  element2?.classList.remove('show')
  element3?.classList.remove('menu-overlay')
}

export { dropdownShow, dropdownHide, dropdownActiveShow, dropdownActiveHide }
