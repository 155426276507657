export const extractBase64 = async (event: any) => new Promise((resolve) => {
  try {
    let reader = new FileReader()

    reader.readAsDataURL(event)

    reader.onload = () => {
      resolve({
        base: reader.result
      })
    }

    reader.onerror = () => {
      resolve({
        base: null
      })
    }
  } catch (error) {
    return null
  }

  return null
})
