import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { TagsInput } from "react-tag-input-component";
import { FaExclamationCircle } from 'react-icons/fa';
import Slider from 'rc-slider';

import { UseOutletContextService } from '../Service';
import { StateLanguage } from '../../../../../languages/config/StateLanguage';
import { Container } from '../../../../../styles/container.style';
import { modalShow } from '../../../../../tools/modal.tool';
import { uploadTooltip } from '../../../../../tools/tooltip.tool';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidIcon, legendValidInvalidIconRestart, legendValidInvalidRestart } from '../../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequiredIcon, evaluateLegendValidateRequiredIconObject } from '../../../../../scripts/validate.legend.script';
import { serviceCategories, serviceUnits } from '../../../../../scripts/filter.list.script';
import { categorySelected, measureUnitSelected } from '../../../../../scripts/selected.item.list.script';
import { extractBase64 } from '../../../../../scripts/extract.base.64.script';
import { languageDownloadValue } from '../../../../../libraries/language.download.library';
import { expressions } from '../../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../../components/element/Legend';
import AppModalCategoryCreate from '../../../../../components/modal/ModalCategoryCreate';
import AppModalSettingCreateTax from '../../../../../components/modal/ModalSettingsCreateTax';
import AppModalSettingCreateUnit from '../../../../../components/modal/ModalSettingsCreateUnit';

import imgDefault from '../../../../../assets/images/default.png';

export interface AppServiceFormProps {
  action: string,
  loadIndicator: string,
  name: {value: string, valid: boolean},
  description: {value: string, valid: boolean},
  category: {value: string, valid: boolean},
  code: {value: string, valid: boolean},
  sku: {value: string, valid: boolean},
  codebar: {value: string, valid: boolean},
  price: {value: string, valid: boolean},
  taxRate: {value: string, valid: boolean},
  unit: {value: string, valid: boolean},
  discount: {value: {type: string, value: string, quantity: string}, valid: {type: boolean, value: boolean, quantity: boolean}},
  names: {value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}},
  photos: {value: {view: string, file: any}[], valid: {gallery: boolean, list: {file: boolean, message: string}[]}},
  tags: {value: string[], valid: boolean},
  setName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDescription: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCategory: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCode: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setSku: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCodebar: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPrice: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setTaxRate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setUnit: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDicount: Dispatch<SetStateAction<{value: {type: string, value: string, quantity: string}, valid: {type: boolean, value: boolean, quantity: boolean}}>>,
  setNames: Dispatch<SetStateAction<{value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}}>>
  setPhotos: Dispatch<SetStateAction<{value: {view: string, file: any}[], valid: {gallery: boolean, list: {file: boolean, message: string}[]}}>>,
  setTags: Dispatch<SetStateAction<{value: string[], valid: boolean}>>,
  submitService: Function
};

const AppServiceForm: React.FunctionComponent<AppServiceFormProps> = ({action, loadIndicator, name, description, category, code, sku, codebar, price, taxRate, unit, discount, names, photos, tags, setName, setDescription, setCategory, setCode, setSku, setCodebar, setPrice, setTaxRate, setUnit, setDicount, setNames, setPhotos, setTags, submitService}) => {
  const {companyForUser, settingsForCompany, categoriesForCompany, setSettingsForCompany, setCategoriesForCompany} = UseOutletContextService()
  const {lang} = StateLanguage()

  const [categoryType, setCategoryType] = useState({value: '', valid: false, default: false})
  const [newTax, setNewTax] = useState<{main_modal: string | null, index: number | null, sub_index: number | null}>({main_modal: null, index: null, sub_index: null})
  const [newUnit, setNewUnit] = useState<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>({type: '', main_modal: null, index: null, sub_index: null})

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNames(names => ({...names, value: {...names.value, [item]: event.target.value}}))
  }

  const handleChangeCategory = (item: string) => {
    setCategory({value: item, valid: true})
    legendValid('container-validate-category-required')
  }

  const handleChangeUnit = (item: string) => {
    setUnit({value: item, valid: true})
    legendValid('container-validate-unit-required')
  }

  const handleChangeTaxRate = (item: string) => {
    setTaxRate({value: item, valid: true})
    legendValid('container-validate-tax-required')
  }

  const handleChangeTags = (items: string[]) => {
    setTags({...tags, value: items})
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDescription({...description, value: event.target.value})
  }

  const handleChangeCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCode({...code, value: event.target.value})
  }

  const handleChangeSku = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setSku({...sku, value: event.target.value})
  }

  const handleChangeCodebar = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCodebar({...codebar, value: event.target.value})
  }

  const handleChangePrice = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPrice({...price, value: event.target.value})
  }

  const handleChangeDiscountType = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDicount({value: {type: event.target.value, value: (event.target.value === 'percentage') ? '0' : '', quantity: ''}, valid: {type: true, value: (event.target.value === 'none') ? true : false, quantity: (event.target.value === 'none') ? true : false}})
  }

  const handleChangeSlideDiscountValue = (item: number | number[]) => {
    setDicount({...discount, value: {...discount.value, value: item.toString()}, valid: {...discount.valid, value: true}})
  }

  const handleChangeInputDiscountValue = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDicount({...discount, value: {...discount.value, value: event.target.value}})
  }

  const handleChangeInputDiscountQuantity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDicount({...discount, value: {...discount.value, quantity: event.target.value}})
  }

  const handleChangeFiles = async (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
    let archiveValueList: {view: string, file: any}[] = []
    let archiveValidList: {file: boolean, message: string}[] = []
    let archivesCapture: any = event.target.files
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576
    let images: Promise<any>[] = []

    for (let archiveCapture of archivesCapture) {
      if (!photos.value.some((existingArchive) => existingArchive.file.name === archiveCapture.name)) {
        if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.invalidFileType})
        } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
          archiveValueList.push({view: '', file: archiveCapture})
          archiveValidList.push({file: false, message: lang.labels.fileSizeNotAllowed})
        } else {
          images.push(
            extractBase64(archiveCapture).then((image: any) => {
              archiveValueList.push({view: image.base, file: archiveCapture});
              archiveValidList.push({file: true, message: lang.labels.valid});
            })
          )
        }
      }
    }

    await Promise.all(images)

    let tempPhotosValue = [...photos.value, ...archiveValueList]
    let tempPhotosValid = [...photos.valid.list, ...archiveValidList]
    let tempGalleryValid = (tempPhotosValid.length > 10) ? false : true

    if (tempGalleryValid) {
      legendValid('container-validate-gallery-max')
    } else {
      legendInvalid('container-validate-gallery-max')
    }

    setPhotos({value: tempPhotosValue, valid: {gallery: tempGalleryValid, list: tempPhotosValid}})
    cleanInputFile()
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && names.value[item as keyof typeof names.value].length > 0) {
      if (expressions.name.test(names.value[item as keyof typeof names.value])) {
        setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
        legendValidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      } else {
        setNames(names => ({...names, valid: {...names.valid, [item]: false}}))
        legendInvalidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      }
    } else {
      setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
      legendValidInvalidIconRestart('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
    }
  }

  const validateTagKeyUp = (event: React.KeyboardEvent <HTMLFormElement | HTMLInputElement>) => {
    if (expressions && expressions.tag.test(event.currentTarget.value)) {
      if (!tags.value.includes(event.currentTarget.value)) {
        setTags({...tags, valid: true})
        legendValid('container-validate-tags-valid')
        legendValid('container-validate-tags-exists')
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-exists')
      }
    } else {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-valid')
    }
  }

  const validateTagBlur = (event: React.FocusEvent <HTMLFormElement | HTMLInputElement>) => {
    /* if (expressions && event.currentTarget.value.length > 0 && expressions.tag.test(event.currentTarget.value) && !tags.value.includes(event.currentTarget.value)) {
      setTags({...tags, value: [...tags.value, event.currentTarget.value], valid: true})
      legendValid('container-validate-tags-required')
    } else if (tags.value.length === 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
    } */

    if (tags.value.includes(event.currentTarget.value)) {
      legendValidInvalidRestart('container-validate-tags-exists')
      setTags({...tags, valid: true})
    }

    event.currentTarget.value = ''
    legendValidInvalidRestart('container-validate-tags-valid')
  }

  const validateTagAdd = (item: string, items: string[]) => {
    if (expressions && items) {
      if (expressions.tag.test(item)) {
        setTags({...tags, valid: true})
        legendValid('container-validate-tags-valid')
        //legendValid('container-validate-tags-required')
        return true;
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-valid')
        return false;
      }
    } else {
      setTags({...tags, valid: false})
      legendValidInvalidRestart('container-validate-tags-valid')
      //legendValidInvalidRestart('container-validate-tags-required')
      return false;
    }
  }

  const validateTagExisting = (item: string) => {
    if (item.length > 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-exists')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-exists')
    }
  }

  const validateTagRemoved = (item: string) => {
    /* if (item.length > 0 && tags.value.length === 1) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-required')
    } */

    setTags({...tags, valid: true})
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name', 'container-validate-name-valid', 'container-validate-name-required')
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name-other', 'container-validate-name-other-valid', 'container-validate-name-other-required')
  }

  const validateDescription = () => {
    evaluateLegendValidateRequiredIcon(expressions.text, description, setDescription, 'input-description', 'container-validate-description-valid', 'container-validate-description-required')
  }

  const validateCode = () => {
    evaluateLegendValidateEmptyIcon(expressions.code, code, setCode, 'input-code', 'container-validate-code-valid')
  }

  const validateSku = () => {
    evaluateLegendValidateEmptyIcon(expressions.sku, sku, setSku, 'input-sku', 'container-validate-sku-valid')
  }

  const validateCodebar = () => {
    evaluateLegendValidateEmptyIcon(expressions.codebar, codebar, setCodebar, 'input-codebar', 'container-validate-codebar-valid')
  }

  const validatePrice = () => {
    evaluateLegendValidateRequiredIcon(expressions.currency, price, setPrice, 'input-price', 'container-validate-price-valid', 'container-validate-price-required')
  }

  const validateDiscountValue = () => {
    evaluateLegendValidateRequiredIconObject(expressions.currency, 'value', discount, setDicount, 'input-discount-value', 'container-validate-discount-value-valid', 'container-validate-discount-value-required')
  }

  const validateDiscountQuantity = () => {
    evaluateLegendValidateRequiredIconObject(expressions.quantity, 'quantity', discount, setDicount, 'input-discount-quantity', 'container-validate-discount-quantity-valid', 'container-validate-discount-quantity-required')
  }

  const executeRemoveFile = (index: number) => {
    let tempPhotosValue = photos.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempPhotosValid = photos.valid.list.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempGalleryValid = (tempPhotosValid.length > 10) ? false : true

    if (tempGalleryValid) {
      legendValid('container-validate-gallery-max')
    } else {
      legendInvalid('container-validate-gallery-max')
    }

    setPhotos({value: tempPhotosValue, valid: {gallery: tempGalleryValid, list: tempPhotosValid}})
    cleanInputFile()
  }

  const executeShowModalCategoryCreate = () => {
    setCategoryType({value: 'service', valid: true, default: true})
    modalShow('modal-category-create')
  }

  const executeShowModalSettingsCreateUnit = () => {
    setNewUnit({...newUnit, type: "service"})
    modalShow('modal-settings-create-unit')
  }

  const executeShowModalSettingsCreateTax = () => {
    modalShow('modal-settings-create-tax')
  }

  function cleanInputFile() {
    Array.from(document.querySelectorAll(`input[name="${'photos'}"]`)).forEach( (node)  => {
      let element = node as HTMLInputElement
      element.value = ''
    })
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsForCompany])

  return (
    <>
      { settingsForCompany && categoriesForCompany &&
        <div className="form d-flex flex-column flex-xl-row">
          <div className="d-flex flex-column flex-xl-row-auto gap-5 w-100 w-xl-350px mb-5 mb-xl-0">
            <div className="card card-flush d-block d-xl-none">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.general}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <label className="form-label required">{lang.labels.serviceName}</label>
                <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.serviceNameRequiredAndRecommendedUnique}</div>
                <AppLegend component={null} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.NameInOtherLanguages}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <label className="form-label">{lang.labels.enterInLanguage}</label>
                { Object.keys(names.value).map (( (item, index) => { return (
                  <div key={index} className="my-2">
                    <input id={"input-language-name-" + index} className="form-control" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item.toUpperCase())} value={names.value[item as keyof typeof names.value]} onChange={(event) => handleChangeNames(item, event)} onKeyUp={() => validateNames(index, item)} onBlur={() => validateNames(index, item)} />
                    <AppLegend component={null} attribute={{validity: names.valid[item as keyof typeof names.valid], name: "language-name", index: index, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                )}))}
                <div className={`form-text text-justify mt-5 ${action === "update" && "d-none"}`}>{lang.labels.ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary}</div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.serviceDetails}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="d-flex flex-wrap justify-content-between align-items-end mb-2">
                  <label className="form-label required">{lang.labels.category}</label>
                  <button className="btn btn-light-primary btn-sm" type="button" onClick={executeShowModalCategoryCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-3 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.addCategory}
                    </div>
                  </button>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {category.valid ? categorySelected(lang, serviceCategories(categoriesForCompany), category.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${serviceCategories(categoriesForCompany).length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { serviceCategories(categoriesForCompany).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === category.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === category.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCategory(item.id)}>{item.display_name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.addServiceToCategory}</div>
                <AppLegend component={null} attribute={{validity: category.valid, name: "category", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                <div className="separator my-5"></div>
                <label className="form-label">{lang.labels.tags}</label>
                <TagsInput classNames={{tag: "tag-cls bg-dark bg-opacity-10 fs-7", input: "input-cls form-control form-control-solid w-100"}} name="tags" value={tags.value} onChange={handleChangeTags} onKeyUp={validateTagKeyUp} onBlur={validateTagBlur} beforeAddValidate={validateTagAdd} onExisting={validateTagExisting} onRemoved={validateTagRemoved} />
                <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.addTagsToService}</div>
                <AppLegend component={null} attribute={{validity: tags.valid, name: "tags", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: true, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-5 ms-xl-5">
            <div className="d-flex flex-column gap-5">
              <div className="card card-flush d-none d-xl-block">
                <div className="card-header">
                  <div className="card-title">
                    <h3>{lang.labels.general}</h3>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <label className="form-label required">{lang.labels.serviceName}</label>
                  <input id="input-name-other" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                  <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.serviceNameRequiredAndRecommendedUnique}</div>
                  <AppLegend component={null} attribute={{validity: name.valid, name: "name-other", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="card card-flush">
                <div className="card-header">
                  <div className="card-title">
                    <h3>{lang.labels.advanced}</h3>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="mb-3">
                    <label className="form-label required">{lang.labels.description}</label>
                    <input id={"input-description"} className="form-control form-control-solid" type="text" name="description" value={description.value} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
                    <AppLegend component={null} attribute={{validity: description.valid, name: "description", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.code}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.uniqueServiceCodeAssignedByCompany}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <input id={"input-code"} className="form-control form-control-solid" type="text" name="code" value={code.value} onChange={handleChangeCode} onKeyUp={validateCode} onBlur={validateCode} />
                    <AppLegend component={null} attribute={{validity: code.valid, name: "code", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="d-flex flex-wrap gap-3 mb-3">
                    <div className="fv-row w-100 flex-md-root">
                      <label className="form-label">{lang.labels.sku}</label>
                      <input id={"input-sku"} className="form-control form-control-solid" type="text" name="sku" value={sku.value} onChange={handleChangeSku} onKeyUp={validateSku} onBlur={validateSku} />
                      <AppLegend component={null} attribute={{validity: sku.valid, name: "sku", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                    <div className="fv-row w-100 flex-md-root">
                      <label className="form-label">{lang.labels.codebar}</label>
                      <input id={"input-codebar"} className="form-control form-control-solid" type="text" name="codebar" value={codebar.value} onChange={handleChangeCodebar} onKeyUp={validateCodebar} onBlur={validateCodebar} />
                      <AppLegend component={null} attribute={{validity: codebar.valid, name: "codebar", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-3 mb-3">
                    <div className="fv-row w-100 flex-md-root">
                      <label className="form-label required">{lang.labels.unitMeasure}</label>
                      <Dropdown>
                        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                          <span className="selection">
                            <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                              <span className="select2-selection__rendered" role="textbox">
                                <span className="select2-selection__placeholder">
                                  {unit.value.length > 0 ? measureUnitSelected(lang, settingsForCompany.measure_units, "service", unit.value) : lang.labels.selectOption}
                                </span>
                              </span>
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                          <span className="select2-dropdown select2-dropdown--below">
                            <span className="select2-results">
                              <ul className="select2-results__options" role="listbox">
                                <li className="select2-results__option select2-results__option--selectable">
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={executeShowModalSettingsCreateUnit}>
                                    <div className="d-flex align-items-center">
                                      <span className="svg-icon svg-icon-3 me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                        </svg>
                                      </span>
                                      {lang.labels.addUnit}
                                    </div>
                                  </Dropdown.Item>
                                </li>
                                <li className={`select2-results__option select2-results__option--selectable ${serviceUnits(settingsForCompany.measure_units).length > 0 && "d-none"}`}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                </li>
                                { serviceUnits(settingsForCompany.measure_units).map (( (other_item, other_index) => { return (
                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === unit.value && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === unit.value}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnit(other_item.id)}>{other_item.name}</Dropdown.Item>
                                  </li>
                                )}))}
                              </ul>
                            </span>
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                      <AppLegend component={null} attribute={{validity: unit.valid, name: "unit", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                    <div className="fv-row w-100 flex-md-root">
                      <label className="form-label required">{lang.labels.taxRate}</label>
                      <Dropdown>
                        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                          <span className="selection">
                            <span className="select2-selection select2-selection--single form-select form-select-solid bg-light" aria-disabled="false">
                              <span className="select2-selection__rendered" role="textbox">
                                <span className="select2-selection__placeholder text-capitalize">
                                  {taxRate.value.length > 0 ? taxRate.value : lang.labels.selectOption}
                                </span>
                              </span>
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                          <span className="select2-dropdown select2-dropdown--below">
                            <span className="select2-results">
                              <ul className="select2-results__options" role="listbox">
                                <li className="select2-results__option select2-results__option--selectable d-none">
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={executeShowModalSettingsCreateTax}>
                                    <div className="d-flex align-items-center">
                                      <span className="svg-icon svg-icon-2 me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                        </svg>
                                      </span>
                                      {lang.labels.addTax}
                                    </div>
                                  </Dropdown.Item>
                                </li>
                                <li className={`select2-results__option select2-results__option--selectable ${settingsForCompany.tax_fees.length > 0 && "d-none"}`}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                </li>
                                { settingsForCompany.tax_fees.map (( (other_item, other_index) => { return (
                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item === taxRate.value && "select2-results__option--selected"}`} role="option" aria-selected={other_item === taxRate.value}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text py-2 text-capitalize" onClick={() => handleChangeTaxRate(other_item)}>{other_item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </ul>
                            </span>
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                      <AppLegend component={null} attribute={{validity: taxRate.valid, name: "tax", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">{lang.labels.price}</label>
                    <input id={"input-price"} className="form-control form-control-solid" type="text"  name="price" value={price.value} onChange={handleChangePrice} onKeyUp={validatePrice} onBlur={validatePrice} />
                    <AppLegend component={null} attribute={{validity: price.valid, name: "price", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="mb-5">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        {lang.labels.discount}
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.uniqueServiceCodeAssignedByCompany}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <div className="fv-row mb-5">
                      <div className="row row-cols-1 row-cols-md-3 g-2">
                        <div className="col">
                          <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-2 py-3 ${discount.value.type === "none" && "active"}`}>
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-center justify-content-center">
                              <input className="form-check-input" type="radio" value="none" checked={discount.value.type === "none"} onChange={handleChangeDiscountType} />
                            </span>
                            <span className="ms-2 fs-6 fw-bold text-gray-800 d-block text-nowrap">{lang.labels.noDiscount}</span>
                          </label>
                        </div>
                        <div className="col">
                          <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-2 py-3 ${discount.value.type === "percentage" && "active"}`}>
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-center justify-content-center">
                              <input className="form-check-input" type="radio" value="percentage" checked={discount.value.type === "percentage"} onChange={handleChangeDiscountType} />
                            </span>
                            <span className="ms-2 fs-6 fw-bold text-gray-800 d-block text-nowrap">{lang.labels.percentage}</span>
                          </label>
                        </div>
                        <div className="col">
                          <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-2 py-3 ${discount.value.type === "fixed" && "active"}`}>
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-center justify-content-center">
                              <input className="form-check-input" type="radio" value="fixed" checked={discount.value.type === "fixed"}  onChange={handleChangeDiscountType} />
                            </span>
                            <span className="ms-2 fs-6 fw-bold text-gray-800 d-block text-nowrap">{lang.labels.fixedAmount}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <Container property={(discount.value.type === "percentage").toString()}>
                      <div className="mb-5">
                        <label className="form-label required">{lang.labels.setDiscountPercentage}</label>
                        <div className="d-flex flex-column text-center">
                          <div className="d-flex align-items-start justify-content-center text-nowrap">
                            <span className="fw-bolder fs-1">{discount.value.value}</span>
                            <span className="fw-bold fs-7 ms-1 mt-1">%</span>
                          </div>
                          <Slider value={Number(discount.value.value)} defaultValue={Number(discount.value.value)} onChange={handleChangeSlideDiscountValue} onChangeComplete={handleChangeSlideDiscountValue} step={1} min={0} max={100} styles={{track: {height: 8, backgroundColor: "gray"}, rail: {height: 8, backgroundColor: "silver"}, handle: {height: 18, width: 18, opacity: 100, borderColor: "blue", backgroundColor: "black"}}} />
                        </div>
                      </div>
                    </Container>
                    <Container property={(discount.value.type === "fixed").toString()}>
                      <div className="mb-3">
                        <label className="form-label required">{lang.labels.fixedDiscountedAmount}</label>
                        <input id={"input-discount-value"} className="form-control form-control-solid" type="text" name="discount-value" value={discount.value.value} onChange={handleChangeInputDiscountValue} onKeyUp={validateDiscountValue} onBlur={validateDiscountValue} />
                        <AppLegend component={null} attribute={{validity: discount.valid.value, name: "discount-value", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </Container>
                    <Container property={(discount.value.type === "percentage" || discount.value.type === "fixed").toString()}>
                      <div className="mb-0">
                        <label className="form-label required">{lang.labels.quantityMinimumDiscount}</label>
                        <input id={"input-discount-quantity"} className="form-control form-control-solid" type="text" name="discount-quantity" value={discount.value.quantity} onChange={handleChangeInputDiscountQuantity} onKeyUp={validateDiscountQuantity} onBlur={validateDiscountQuantity} />
                        <AppLegend component={null} attribute={{validity: discount.valid.quantity, name: "discount-quantity", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </Container>
                  </div>
                  <div className="mb-0">
                    <div className="dropzone dz-clickable position-relative">
                      <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name={"photos"} accept=".png, .jpg, .jpeg" multiple onChange={handleChangeFiles} />
                      { photos.value.length > 0 && photos.valid.list.length > 0 && photos.value.length === photos.valid.list.length
                        ?
                        <>
                          { photos.value.map (( (item, index) => { return (
                            <div key={index} className="dz-preview dz-file-preview dz-error dz-complete">
                              <div className="dz-image">
                                <img src={photos.value[index].file ? item.view : imgDefault} className="w-100 h-100" alt={lang.labels.photography + index} />
                              </div>
                              <div className="dz-details">
                                <div className="dz-size">
                                  <span>{item.file.size ? (item.file.size / (1024 * 1024)).toFixed(2) : item.file.length} {lang.labels.mb}</span>
                                </div>
                                <div className="dz-filename">
                                  <span className="text-uppercase">{item.file.name ? item.file.name.split('.').pop() : item.file.split(".").pop()}</span>
                                </div>
                              </div>
                              { photos.value[index].file
                                ? 
                                <div className="dz-success-mark">
                                  <svg viewBox="0 0 55 55" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <title>{lang.labels.success}</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF"></path>
                                    </g>
                                  </svg>
                                </div>
                                :
                                <div className="dz-error-mark">
                                  <svg viewBox="0 0 55 55" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <title>{lang.labels.error}</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <g stroke="#7E8299" strokeOpacity="0.198794158" fill="#a92222" fillOpacity="0.816519475">
                                        <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              }
                              { !photos.valid.list[index].file &&
                                <div className="dz-error-message">
                                  <span className="text-justify">{photos.valid.list[index].message}</span>
                                </div>
                              }
                              <button className="dz-remove" onClick={() => executeRemoveFile(index)}>{lang.labels.remove}</button>
                            </div>
                          )}))}
                        </>
                        :
                        <div className="dz-message">
                          <span className="svg-icon svg-icon-primary svg-icon-3x">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z" fill="black"/>
                              <path d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z" fill="black"/>
                            </svg>
                          </span>
                          <div className="d-grid ms-5">
                            <span className="fs-6 fw-bold">{lang.labels.dropFilesHereOrClickUpload}</span>
                            <span className="fw-semibold text-muted">{lang.labels.uploadUpToFiles}</span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="form-text text-justify">{lang.labels.setServiceMediaGallery}</div>
                    <AppLegend component={null} attribute={{validity: photos.valid.gallery, name: "gallery", index: null, sub_index: null}} container={{valid: false, required: false, size: false, type: false, identical: false, exists: false, max: true}}></AppLegend>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Link to="/app/inventory/service/list" className="btn btn-lg btn-light fw-bolder text-uppercase me-2">{lang.labels.cancel}</Link>
              <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={() => submitService()}>
                <span className="indicator-label text-uppercase text-nowrap">{action === "create" ? lang.labels.createService : lang.labels.updateService}</span>
                <span className="indicator-progress">
                {lang.labels.pleaseWait}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      }
      <AppModalCategoryCreate companyForUser={companyForUser} type={categoryType} category={category} categories={categoriesForCompany} setType={setCategoryType} setCategory={setCategory} setCategories={setCategoriesForCompany}></AppModalCategoryCreate>
      <AppModalSettingCreateTax companyForUser={companyForUser} data={newTax} variant={null} requirements={null} setSettingsForCompany={setSettingsForCompany} setData={setNewTax} setTaxRate={setTaxRate} setVariant={null} setRequirements={null}></AppModalSettingCreateTax>
      <AppModalSettingCreateUnit companyForUser={companyForUser} data={newUnit} variant={null} requirements={null} setSettingsForCompany={setSettingsForCompany} setData={setNewUnit} setUnitMeasure={setUnit} setVariant={null} setRequirements={null}></AppModalSettingCreateUnit>
    </>
  )
};

export default AppServiceForm;
