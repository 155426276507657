import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { extractBase64 } from '../../scripts/extract.base.64.script';

export interface AppModalUploadFileVoucherProps {
  vouchers: {list: boolean, value: {name: string, view: string, file: any}[], valid: {name: boolean, view: boolean, file: boolean}[]},
  setVouchers: Dispatch<SetStateAction<{list: boolean, value: {name: string, view: string, file: any}[], valid: {name: boolean, view: boolean, file: boolean}[]}>>
};

const AppModalUploadFileVoucher: React.FunctionComponent<AppModalUploadFileVoucherProps> = ({vouchers, setVouchers}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [uploadVouchersValue, setUploadVouchersValue] = useState<{name: string, size: number, type: string, view: string, file: any}[]>([])
  const [uploadVouchersValid, setUploadVouchersValid] = useState<{file: boolean, message: string}[]>([])

  const handleChangeFile = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadIndicator('on')

    let archivesCapture = event.target.files
    let archivesValue: {name: string, size: number, type: string, view: string, file: any}[] = []
    let archivesValid: {file: boolean, message: string}[] = []
    let archiveType: string[] = ['application/pdf']
    let archiveSize: number = 1048576

    for (let i = 0; i < archivesCapture.length; i++) {
      let archiveValue: {name: string, size: number, type: string, view: string, file: any} = {name: archivesCapture[i].name, size: archivesCapture[i].size, type: archivesCapture[i].type, view: archivesCapture[i].name, file: archivesCapture[i]}
      let archiveValid: {file: boolean, message: string} = {file: true, message: lang.labels.valid}

      extractBase64(archivesCapture[i]).then( (pdf: any) => {
        archiveValue.view = pdf.base
      })

      if (!(archiveType === undefined || (archiveType && archiveType.includes(archivesCapture[i].type)))) {
        archiveValid = {file: false, message: lang.labels.fileInDifferentFormat}
      } else if (!((archiveSize !== 0 && archivesCapture[i].size <= archiveSize) || archiveSize === 0)) {
        archiveValid = {file: false, message: lang.labels.fileIsTooBig}
      } else {
        archiveValue.size = Number((archivesCapture[i].size / archiveSize).toFixed(2))
      }

      archivesValue.push(archiveValue)
      archivesValid.push(archiveValid)
    }

    setUploadVouchersValue(archivesValue)
    setUploadVouchersValid(archivesValid)
    setLoadIndicator('off')
  }

  const executeUploadFile = () => {
    let uploadVouchersIsValid: boolean = uploadVouchersValid.every((item) => (item.file === true))

    if (uploadVouchersIsValid) {
      let tempVouchersValue: {name: string, view: string, file: any}[] = []
      let tempVouchersValid: {name: boolean, view: boolean, file: boolean}[] = []

      for (let uploadVoucherValue of uploadVouchersValue) {
        let newContentValue: {name: string, view: string, file: any} = {
          name: uploadVoucherValue.name.replace('.pdf', ''),
          view: uploadVoucherValue.view,
          file: uploadVoucherValue.file
        }
        let newContentValid: {name: boolean, view: boolean, file: boolean} = {
          name: true,
          view: true,
          file: true
        }

        tempVouchersValue.push(newContentValue)
        tempVouchersValid.push(newContentValid)
      }

      setVouchers({...vouchers, value: vouchers.value.concat(tempVouchersValue), valid: vouchers.valid.concat(tempVouchersValid)})
      executeHideModaProviderUploadFile()
    } else {
      Swal.fire({
        title: lang.labels.incorrectFiles,
        text: lang.labels.someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics,
        icon: 'error',
        buttonsStyling: !1,
        confirmButtonText: lang.labels.okGotIt,
        customClass: {confirmButton: 'btn btn-primary'}
      } as SweetAlertOptions)
    }
  }

  const executeRemoveFile = (index: number) => {
    let tempUploadVouchersValue = uploadVouchersValue.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempUploadVouchersValid = uploadVouchersValid.filter((_temp_item, temp_index) => (temp_index !== index))

    setUploadVouchersValue(tempUploadVouchersValue)
    setUploadVouchersValid(tempUploadVouchersValid)
    restartModal()
  }

  const executeRemoveAllFiles = () => {
    setUploadVouchersValue([])
    setUploadVouchersValid([])
    restartModal()
  }

  const executeHideModaProviderUploadFile = () => {
    modalHide('modal-voucher-upload-file')
    uploadTooltip()

    setTimeout( () => {
      executeRemoveAllFiles()
    }, 200 )
  }

  function restartModal() {
    Array.from(document.querySelectorAll('input[name="voucher"]')).forEach( (node)  => {
      let element = node as HTMLInputElement
      element.value = ''
    })
  }

  return (
    <div id="modal-voucher-upload-file" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.uploadFiles}</h2>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="dropzone dropzone-queue cursor-none">
                <div className="dropzone-panel">
                  <label className="btn btn-sm btn-primary" data-kt-indicator={loadIndicator}>
                    <input className="btn-check w-100" type="file" name="voucher" accept=".pdf" multiple onChange={handleChangeFile} />
                    <span className="indicator-label">{lang.labels.attachFiles}</span>
                    <span className="indicator-progress">
                      {lang.labels.pleaseWait}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </label>
                  { uploadVouchersValue.length > 1 &&
                    <button className="btn btn-sm btn-light-primary ms-2" type="button" onClick={executeRemoveAllFiles}>{lang.labels.removeAll}</button>
                  }
                </div>
                <div className="separator my-2"></div>
                { uploadVouchersValue.length > 0 &&
                  <>
                    <div className="dropzone-items">
                      { uploadVouchersValue.map (( (item, index) => { return (
                        <div key={index} className="dropzone-item dz-image-preview my-1">
                          <div className="dropzone-file">
                            <div className="dropzone-filename text-dark">
                              <span className="me-1">{item.name}</span>
                              <strong>
                                (
                                <span>
                                  <strong className="me-1">{item.size}</strong>
                                  <strong>{lang.labels.mb}</strong>
                                </span>
                                )
                              </strong>
                            </div>
                            { !uploadVouchersValid[index].file &&
                              <div className="dropzone-error m-0">{uploadVouchersValid[index].message}</div>
                            }
                          </div>
                          <div className="dropzone-toolbar">
                            <span className="dropzone-delete" onClick={() => executeRemoveFile(index)}>
                              <i className="bi bi-x fs-1"></i>
                            </span>
                          </div>
                        </div>
                      )}))}
                    </div>
                    <div className="separator my-2"></div>
                  </>
                }
              </div>
              <span className="form-text text-justify">{lang.labels.fileFormatMaximum1MBPerFile}</span>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModaProviderUploadFile}>{lang.labels.discard}</button>
            <button className={`${uploadVouchersValue.length > 0 ? "btn btn-primary" : "d-none"} mx-2`} type="button" onClick={executeUploadFile}>{lang.labels.addFiles}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalUploadFileVoucher;
