import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Carousel, Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCategory } from './Category';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { CategoryDA } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Product } from '../../../../models/product.model';
import { Service } from '../../../../models/service.model';
import { modalShow } from '../../../../tools/modal.tool';
import { imageProduct } from '../../../../scripts/image.value.script';
import { languageDownloadValue } from '../../../../libraries/language.download.library';
import { categoryTypeValue } from '../../../../libraries/category.type.library';

import AppModalCategoryUpdate from '../../../../components/modal/ModalCategoryUpdate';
import AppModalCategoryUpdateListing from '../../../../components/modal/ModalCategoryUpdateListing';

import imageSearch from '../../../../assets/images/themes/image-search.png';
import imgProductDefault from '../../../../assets/images/product.png';
import imgServiceDefault from '../../../../assets/images/service.png';

export interface CategoryViewPageProps {};

let errorResponse: Error, messageResponse: Message, categoryResponse: Category;

const CategoryViewPage: React.FunctionComponent<CategoryViewPageProps> = props => {
  const {setRoute, companyForUser, productsForCompany, servicesForCompany, loadProductsForCompany, loadServicesForCompany} = UseOutletContextCategory()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [category, setCategory] = useState<Category | undefined | null>(null)
  const [categoryListing, setCategoryListing] = useState<string[] | undefined | null>([])

  const loadCategory = async (id_company: string, id_category: string) => {
    await CategoryDA.getCategory(id_company, id_category).then( (response) => {
      if (response.status === 200) {
        categoryResponse = response.data
        setCategory(categoryResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitCategoryDelete = async () => {
    if (companyForUser && category) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisCategory,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await CategoryDA.deleteCategories(companyForUser.id, [category.id]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.code),
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/inventory/category/list', {replace: true})
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const executeShowModalCategoryUpdate = () => {
    modalShow('modal-category-update')
  }

  const executeShowModalCategoryUpdateListing = () => {
    if (category) {
      let tempCategoryListing = category.list.map((item) => { return item.id })

      setCategoryListing(tempCategoryListing)
      modalShow('modal-category-update-listing')
    }
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadCategory(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.categories, branch: lang.labels.viewCategory}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { category
        ?
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-auto w-100 w-lg-350px mb-5 mb-lg-0">
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title align-items-start flex-column">
                  <h2 className="text-uppercase">{category.display_name}</h2>
                </div>
                <div className="card-toolbar">
                  <Dropdown>
                    <Dropdown.Toggle variant="btn btn-sm btn-primary btn-icon">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect x="10" y="10" width="4" height="4" rx="2" fill="black"></rect>
                          <rect x="17" y="10" width="4" height="4" rx="2" fill="black"></rect>
                          <rect x="3" y="10" width="4" height="4" rx="2" fill="black"></rect>
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary w-250px p-3">
                      <Dropdown.Item bsPrefix="menu-item" onClick={executeShowModalCategoryUpdate}>
                        <p className="menu-link fw-bold px-5 m-0">{lang.labels.editCategory}</p>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item" onClick={submitCategoryDelete}>
                        <p className="menu-link fw-bold px-5 m-0">{lang.labels.deleteCategory}</p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="fs-5 fw-bolder">{lang.labels.type}</div>
                <div className="separator separator-dashed mt-1 mb-2"></div>
                <div className="mb-5">
                  <div className="fw-bold text-gray-800 text-justify">
                    {lang.labels.categoryFor}
                    <span className="fw-bolder text-primary ms-1 text-lowercase">{categoryTypeValue(lang, category.type)}</span>
                  </div>
                </div>
                <div className="fs-5 fw-bolder">{lang.labels.description}</div>
                <div className="separator separator-dashed mt-1 mb-2"></div>
                <div className="mb-5">
                  <div className="fw-bold text-gray-800 text-justify">{category.description}</div>
                </div>
                <div className="fs-5 fw-bolder">{lang.labels.names}</div>
                <div className="separator separator-dashed mt-1 mb-2"></div>
                <div className="mt-3">
                  { Object.keys(category.names).map (( (item, index) => { return (
                    <div key={index} className={`${index < 3 && "pb-2"} ${category.names[item as keyof typeof category.names].length === 0 && "d-none"}`}>
                      <div className="fs-6 fw-bold">{category.names[item as keyof typeof category.names]}</div>
                      <div className="fs-7 text-gray-700">{languageDownloadValue(lang, item.toUpperCase())}</div>
                    </div>
                  )}))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid ms-lg-5">
            <div className="card card-flush">
              <div className="card-header pt-3">
                <div className="card-title flex-column">
                  <h4>{lang.labels.listCategoryItems}</h4>
                  <div className="text-muted fs-7 pt-1">
                    {lang.labels.total}
                    <span className="text-lowercase text-gray-600 fw-bolder mx-1">{category.list.length} {category.type === "product" ? lang.labels.products : lang.labels.services}</span>
                    {lang.labels.inThisCategory}
                  </div>
                </div>
                <div className="card-toolbar">
                  <button className="btn btn-light-primary" type="button" onClick={executeShowModalCategoryUpdateListing}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black"></path>
                          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                        </svg>
                      </span>
                      {lang.labels.editListing}
                    </div>
                  </button>
                </div>
              </div>
              <div className="card-body d-flex flex-column">
                { category.list.length > 0
                  ?
                  <div id="container-category-products" className="flex-column-fluid scroll-y">
                    { category.list.map (( (item, index) => { return (
                      <div key={index} className="d-flex align-items-center position-relative mb-3 me-1">
                        <div className="position-absolute top-0 start-0 rounded h-100 bg-secondary w-5px"></div>
                        <div className="d-flex justify-content-center ms-5">
                          { item.photos.length > 0
                            ?
                            <Carousel slide={false} controls={false} indicators={false} touch={false}>
                              { item.photos.map (( (item, index) => { return (
                                <Carousel.Item key={index}>
                                  <img src={imageProduct(item)} className="d-block w-50px h-50px" alt={lang.labels.photography + index} />
                                </Carousel.Item>
                              )}))}
                            </Carousel>
                            :
                            <div className="symbol symbol-50px">
                              <span className="symbol-label">
                                <img src={category.type === "product" ? imgProductDefault : imgServiceDefault} alt={lang.labels.photography} className="w-100 h-100" />
                              </span>
                            </div>
                          }
                        </div>
                        <div className="mx-5">
                          <div className="fw-bolder text-gray-800">{item.display_name}</div>
                          <div className="fs-8 text-muted">
                            { category.type === "product"
                              ?
                              <>
                                {lang.labels.variants}:
                                <span className="ms-1">{(item as Product).variants.length}</span>
                              </>
                              :
                              <>
                                {(item as Service).description}
                              </>
                            }
                          </div>
                        </div>
                        <Link to={`/app/inventory/${category.type}/update/` + item.id} className="btn btn-sm btn-light ms-auto">{lang.labels.edit}</Link>
                      </div>
                    )}))}
                  </div>
                  :
                  <div className="d-flex flex-column flex-center">
                    <img src={imageSearch} className="mw-175px" alt={lang.labels.none} />
                    <div className="fs-3 fw-bolder text-dark mt-1 mb-3">{lang.labels.noRecordsFound}</div>
                    <div className="fs-7">{lang.labels.startAddingNewElementsToThisCategory}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCategoryUpdate companyForUser={companyForUser} category={category} categories={null} setCategory={setCategory} setCategories={null}></AppModalCategoryUpdate>
      <AppModalCategoryUpdateListing companyForUser={companyForUser} productsForCompany={productsForCompany} servicesForCompany={servicesForCompany} category={category} categoryListing={categoryListing} setCategoryListing={setCategoryListing} setCategory={setCategory} loadProductsForCompany={loadProductsForCompany} loadServicesForCompany={loadServicesForCompany}></AppModalCategoryUpdateListing>
    </div>
  )
};

export default CategoryViewPage;
