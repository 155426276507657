import React from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Expense } from '../../../../models/expense.model';

export interface AppPurchaseViewSignaturesProps {
  record: any | ProviderOrder | ProviderInvoice | Expense
};

const AppPurchaseViewSignatures: React.FunctionComponent<AppPurchaseViewSignaturesProps> = ({record}) => {
  const {lang} = StateLanguage()

  return (
    <div className="card card-flush">
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.signatures}</h4>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="dataTables_wrapper">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed gy-2 mb-1">
              { record.signatures.length > 0
                ?
                <tbody>
                  { record.signatures.map (( (item: string, index: number) => { return (
                    <tr key={index} className="row-dashed fw-bold">
                      <td className="w-100px text-gray-600 text-nowrap">{lang.labels.signature} [{index + 1}]:</td>
                      <td className="min-w-500px text-gray-700 text-justify">{item}</td>
                    </tr>
                  )}))}
                </tbody>
                :
                <tbody>
                  <tr className="text-gray-700 fw-bold">
                    <td valign="top" colSpan={2} className="text-center">{lang.labels.noRecordsFound}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppPurchaseViewSignatures;
