import toast from "react-hot-toast"

function toastSuccess (text: string) {
  toast(`${text}!`, {style: {background: '#0BB7AF', color: '#FFFFFF'}})
}

function toastError (text: string) {
  toast(`${text}!`, {style: {background: '#EE2D41', color: '#FFFFFF'}})
}

function toastWarning (text: string) {
  toast(`${text}!`, {style: {background: '#EE9D01', color: '#FFFFFF'}})
}

export { toastSuccess, toastError, toastWarning }
