import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextExpense } from './Expense';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { ExpenseDA } from '../../../../services/expense.service';
import { Error } from '../../../../models/error.model';
import { CompanyExpenses } from '../../../../models/company.expenses.model';
import { Expense } from '../../../../models/expense.model';
import { ExpenseGroup } from '../../../../models/expense.group.model';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.script';
import { accumulatedAmountLite } from '../../../../scripts/amount.script';
import { paymentMethodValue } from '../../../../libraries/payment.library';
import { namePurchaseGroup } from '../../../../libraries/record.group.name.library';

import AppSort from '../../../../components/element/Sort';
import AppPurchaseRecordList from '../card/PurchaseRecordList';

export interface ExpenseListListPageProps {};

let errorResponse: Error, expensesResponse: CompanyExpenses;

const ExpenseListPage: React.FunctionComponent<ExpenseListListPageProps> = props => {
  const {setRoute, companyForUser, contactsForCompany} = UseOutletContextExpense()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState({filter: false, group: false})
  const [filter, setFilter] = useState({interval: '', start_date: '', end_date: '', contact: '', status: '', validity: '', payment: '', sort_field: '', sort_mode: '', group: ''})
  const [expenses, setExpenses] = useState<Expense[] | undefined | null>(null)
  const [expenseGroups, setExpenseGroups] = useState<ExpenseGroup[] | undefined | null>(null)

  const loadExpenses = async (id_company: string) => {
    let filtered: boolean = false
    let grouped: boolean = false

    await ExpenseDA.getExpenses(id_company, filter.start_date, filter.end_date, filter.validity, filter.payment, filter.sort_field, filter.sort_mode, filter.group).then( (response) => {
      if (response.status === 200) {
        expensesResponse = response.data

        filtered = (expensesResponse.filter.start_date === '' && expensesResponse.filter.end_date === '' && expensesResponse.filter.validity === '' && expensesResponse.filter.payment === '') ? false : true
        grouped = (expensesResponse.filter.group_by === '') ? false : true

        if (expensesResponse.filter.group_by === '') {
          setExpenseGroups([])
          setExpenses(expensesResponse.expenses)
        } else {
          setExpenses([])
          setExpenseGroups(expensesResponse.groups)
        }

        setButton({filter: filtered, group: grouped})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.sort_field) {
        switch (filter.sort_mode) {
          case 'asc':
            filter.sort_mode = 'desc'
            break;
          case 'desc':
            filter.sort_mode = 'asc'
            break;
        }
      } else {
        filter.sort_field = item
        filter.sort_mode = 'asc'
      }
      loadExpenses(companyForUser.id)
    }
  }

  useEffect( () => {
    if (companyForUser) {
      filter.interval = ''
      filter.start_date = ''
      filter.end_date = ''
      filter.validity = ''
      filter.payment = ''
      filter.sort_field = ''
      filter.sort_mode = ''
      filter.group = ''

      setButton({filter: false, group: false})
      setExpenses(null)
      setExpenseGroups(null)

      loadExpenses(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.expenses, branch: lang.labels.listExpenses}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && contactsForCompany && expenses && expenseGroups
        ?
        <div className="card card-flush">
          <AppPurchaseRecordList purchase={"expense"} button={button} filter={filter} records={expenses} recordGroups={expenseGroups} setFilter={setFilter} loadPurchaseRecord={loadExpenses}></AppPurchaseRecordList>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-1">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-125px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("expense number")}>
                          <span className="me-1">{lang.labels.expenseNumber}</span>
                          <AppSort attribute={"expense number"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("concerning")}>
                          <span className="me-1">{lang.labels.concerning}</span>
                          <AppSort attribute={"concerning"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("treasury")}>
                          <span className="me-1">{lang.labels.treasury}</span>
                          <AppSort attribute={"treasury"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("created date")}>
                          <span className="me-1">{lang.labels.createdDate}</span>
                          <AppSort attribute={"created date"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("payment method")}>
                          <span className="me-1">{lang.labels.paymentMethod}</span>
                          <AppSort attribute={"payment method"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="min-w-100px text-end">
                        <div className="d-flex align-items-center justify-content-end text-nowrap cursor-pointer" onClick={() => executeSortList("amount total")}>
                          <span className="me-1">{lang.labels.amountTotal}</span>
                          <AppSort attribute={"amount total"} field={filter.sort_field} mode={filter.sort_mode}></AppSort>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  { expenses.length > 0 || expenseGroups.length > 0
                    ?
                    <>
                      { button.group
                        ?
                        <>
                          { expenseGroups.map (( (item, index) => { return (
                            <tbody key={index} className="text-gray-700 fw-bold">
                              <tr>
                                <td colSpan={6} className="text-gray-700 fw-bolder fs-7 text-uppercase">
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-900 me-5">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M22 12C22 12.9 21.9 13.8 21.7 14.6L5 4.89999C6.8 3.09999 9.3 2 12 2C17.5 2 22 6.5 22 12Z" fill="black"/>
                                        <path opacity="0.3" d="M3.7 17.5C2.6 15.9 2 14 2 12C2 9.20003 3.1 6.70002 5 4.90002L9.3 7.40002V14.2L3.7 17.5ZM17.2 12L5 19.1C6.8 20.9 9.3 22 12 22C16.6 22 20.5 18.8 21.7 14.6L17.2 12Z" fill="black"/>
                                      </svg>
                                    </span>
                                    <span className="me-3">{lang.labels.group} {index + 1}:</span>
                                    {namePurchaseGroup(lang, filter.group, item.group_detail)}
                                  </div>
                                </td>
                              </tr>
                              { item.expenses.map (( (sub_item, sub_index) => { return (
                                <tr key={sub_index} className="row-dashed">
                                  <td>
                                    <Link to={"/app/purchase/expense/view/" + sub_item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{sub_item.expense_number}</Link>
                                  </td>
                                  <td>{sub_item.concerning}</td>
                                  <td>{sub_item.treasury_account.name}</td>
                                  <td className="text-nowrap">{dateFormatAbbreviated(sub_item.created_date)}</td>
                                  <td className="text-capitalize">
                                    <span className={`badge badge-light-info fs-7`}>{paymentMethodValue(lang, sub_item.payment_method)}</span>
                                  </td>
                                  <td className="text-end text-nowrap">{sub_item.currency.symbol} {moneyFormat(sub_item.amount.total)}</td>
                                </tr>
                              )}))}
                              <tr className={`text-gray-800 fw-bolder fs-7 ${index < expenseGroups.length - 1 && "row-dashed"}`}>
                                <td colSpan={5}></td>
                                <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountLite(item.expenses).total)}</td>
                              </tr>
                            </tbody>
                          )}))}
                        </>
                        :
                        <>
                          <tbody className="text-gray-700 fw-bold">
                            { expenses.map (( (item, index) => { return (
                              <tr key={index} className="row-dashed">
                                <td>
                                  <Link to={"/app/purchase/expense/view/" + item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{item.expense_number}</Link>
                                </td>
                                <td>{item.concerning}</td>
                                <td>{item.treasury_account.name}</td>
                                <td className="text-nowrap">{dateFormatAbbreviated(item.created_date)}</td>
                                <td className="text-capitalize">
                                  <span className={`badge badge-light-info fs-7`}>{paymentMethodValue(lang, item.payment_method)}</span>
                                </td>
                                <td className="text-end text-nowrap">{item.currency.symbol} {moneyFormat(item.amount.total)}</td>
                              </tr>
                            )}))}
                          </tbody>
                          <tfoot>
                            <tr className="text-gray-800 fw-bolder fs-7">
                              <td colSpan={5}></td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountLite(expenses).total)}</td>
                            </tr>
                          </tfoot>
                        </>
                      }
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={6} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default ExpenseListPage;
