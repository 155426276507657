export const contentFrench = {
  code: "FR",
  labels: {
    about: "À propos",
    accountSettings: "Paramètres du compte",
    actions: "Actions",
    additional: "Supplémentaire",
    address: "Addresse",
    administrativeArea: "Canton",
    amount: "Montant",
    amountTotal: "Montant total",
    apply: "Appliquer",
    approved: "Approuvé",
    ascending: "Ascendant",
    addContact: "Ajouter contact",
    addContent: "Ajouter contenu",
    addSignature: "Ajouter signature",
    avatar: "Avatar",
    back: "Retour",
    bban: "BBAN",
    bicSwift: "BIC/SWIFT",
    bimester: "Bimestre",
    calculatedValuesAreReferential: "Les valeurs calculées sont référentielles",
    cancel: "Annuler",
    canceled: "Annulé",
    city: "Localité/Ville",
    clickOn: "Cliquez sur",
    comment: "Commentaire",
    completed: "Terminé",
    companyContact: "Contact entreprise",
    content: "Contenu",
    registerNumber: "Number des registre",
    concerning: "Concernant",
    contactInformation: "Coordonnées",
    continue: "Continuer",
    country: "Pays",
    create: "Créer",
    createdAt: "Créé le",
    createdBy: "Créé par",
    createdDate: "Date de création",
    currency: "Devise",
    customer: "Client",
    date: "Date",
    day: "Jour",
    days: "Jours",
    default: "Par défaut",
    delete: "Supprimer",
    descending: "Descendant",
    description: "Description",
    detail: "Détail",
    details: "Détails",
    discard: "Annuler",
    doNotGroup: "Ne pas grouper",
    edit: "Modifier",
    email: "Email",
    endDate: "Date de fin",
    excel: "Excel",
    export: "Exporter",
    filter: "Filtres",
    filterOptions: "Options de filtrage",
    firstName: "Prénom",
    group: "Groupe",
    groups: "groupes",
    groupBy: "Groupé par",
    halfYear: "Six mois",
    iban: "IBAN",
    identity: "Identité",
    inProgress: "En cours",
    justNow: "Juste maintenant",
    lastDay: "Dernier jour",
    lastName: "Nom de famille",
    legalInformation: "Informations juridiques",
    list: "Liste",
    location: "Emplacement",
    menu: "Menu",
    message: "Message",
    noNumber: "No number",
    noMatchingRecordsFound: "Aucun enregistrement correspondant trouvé",
    month: "Mois",
    months: "Mois",
    number: "Numéro",
    or: "Ou",
    sortAlphabetically: "Trier alphabétiquement",
    pages: "Pages",
    pdf: "PDF",
    pending: "En attente",
    person: "Personne",
    phone: "Téléphone",
    phoneNumber: "Numéro de téléphone",
    priceGross: "Prix brut",
    priceNet: "Prix net",
    priceTotal: "Prix total",
    product: "Produit",
    products: "Produits",
    qrIban: "QR-IBAN",
    quantity: "Quantité",
    rejected: "Rejeté",
    remove: "Supprimer",
    removeContent: "Supprimer contenu",
    removeSignature: "Supprimer signature",
    removeRequirement: "Supprimer bloc",
    removeVoucher: "Supprimer voucher",
    requirement: "Bloc",
    requirements: "Blocs",
    reset: "Réinitialiser",
    saveChanges: "Enregistrer modifications",
    searchResults: "Résultats de la recherche",
    selectExportFormat: "Choisir le format d'exportation",
    send: "Envoyer",
    service: "Service",
    services: "Services",
    appService: "service d'application",
    appServices: "services d'application",
    signature: "Signature",
    signatures: "Signatures",
    startDate: "Date de début",
    status: "Statut",
    street: "Rue",
    subTotal: "Sous-total",
    support: "Support",
    symbol: "Symbole",
    tax: "Impôt",
    taxRate: "Taux de TVA",
    taxTotal: "Total de TVA",
    trimester: "Trimestre",
    total: "Total",
    totalAmount: "Montant total",
    top: "Top",
    type: "Type",
    timeInterval: "Intervalle de temps",
    unitMeasure: "Unité de mesure",
    unitPrice: "Prix unitaire",
    update: "Mettre à jour",
    validity: "Validité",
    validUntil: "Valable jusqu'à",
    value: "Valeur",
    vatNumber: "Numéro VAT",
    webSite: "Site web",
    week: "Semaine",
    view: "Afficher",
    yes: "Oui",
    zipCode: "Code postal",
    gross: "Brut",
    net: "Net",
    rebate: "Rabais",
    rebateTotal: "Total rabais",
    selectOptionAddAmountPercentageDesired: "Sélectionnez une option et ajoutez le montant ou le pourcentage souhaité",
    allowedFileTypes: "Types de fichiers autorisés : png, jpg, jpeg.",
    changeImage: "Modifier l'image",
    changeLogo: "Changer de logo",
    exportError: "Erreur d'exportation",
    failedToLoadTheScript: "Échec du chargement du script",
    identificationNumberForACompany: "Numéro d'identification pour une entreprise qui souhaite effectuer des opérations au niveau européen",
    internationalBankAccountNumberIban: "Numéro de compte bancaire international (IBAN)",
    listOfCompaniesToWhichIHaveAccess: "Liste des entreprises auxquelles j'ai accès",
    noRecordsFound: "Aucun enregistrement n'a été trouvé",
    noResultsFound: "Aucun résultat trouvé",
    removeImage: "Supprimer l'image",
    selectTheTypeOfContact: "Sélectionnez le type de contact que vous souhaitez créer",
    specifyTheUniqueUUID: "Spécifier le code UUID unique d'un utilisateur ou d'une entreprise",
    the: "Le-a",
    theDefaultBankAccountIsTheCompanys: "Le compte de trésorerie par défaut est celui de l'entreprise",
    theDefaultCurrencyIsTheCompanys: "La devise par défaut est celle de la société",
    theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients: "La forme ou la version par défaut d'une facture QR à télécharger est celle du client",
    theDefaultLanguageToDownloadIsTheClients: "La langue par défaut pour le téléchargement est celle du client",
    youMustAcceptTheTermsAndConditions: "Vous devez accepter les termes et conditions",
    acceptingInvitationToAccessCompany: "Accepter l'invitation à accéder à l'entreprise",
    verificationYourAccount: "Vérifiez votre compte",
    addCompany: "Ajouter entreprise",
    allInLicense: "Licence globale",
    buyNow: "Achetez maintenant",
    comingSoon: "Bientôt disponible",
    digitalAdmin: "Digital Admin",
    digitalAdminSarl: "Digital Admin SARL",
    licenseTerms: "Conditions de licence",
    pageNotFound: "Page introuvable",
    planYourBusinessApplications: "Planifiez vos applications métier en choisissant l'une des",
    viewInvitations: "Voir invitations",
    digitalAdminPro: "Digital Admin Pro",
    purchase: "Achetez",
    purchasePro: "Achetez Digital Admin Pro",
    reload: "Recharge",
    securedPaymentBy: "Paiement sécurisé par",
    systemError: "Erreur système",
    welcomeToDigitalAdminApp: "Bienvenue à l'appli Digital Admin",
    welcomeToDigitalAdmin: "Bienvenue dans Digital Admin",
    dashboard: "Dashboard",
    abbreviation: "Abréviation",
    amountPurchases: "Montant des achats",
    amountSales: "Montant des ventes",
    best: "Meilleur",
    companyInsight: "Perspicacité de l'entreprise",
    comparativeInterval: "Intervalle comparatif",
    comparisonIncomeAndExpenses: "Comparaison du chiffre d'affaires et des dépenses",
    customerPayments: "Paiements client",
    customRange: "Plage personnalisée",
    debtPercent: "Dette(%)",
    distributionOfQuotes: "Répartition des devis selon votre statut",
    distributionOfOrders: "Distribution des commandes selon leurs statuts",
    expirationDate: "Date d'expiration",
    invoicesNotYetPaid: "factures non encore payées",
    invoicesStillToBePaid: "Factures restant à payer",
    invoicesStillToBePaidByTheCustomer: "Factures restant à payer par le client",
    invoicesWithMoreDebt: "Factures avec plus de dettes",
    ordersByState: "Commandes par statuts",
    ordersToBilled: "Commandes à facturer",
    paidPercent: "Payé(%)",
    percentage: "Pourcentage",
    quotesByState: "Devis par statuts",
    quotesAccepted: "Devis acceptés",
    quotesToExpire: "Devis à expirer",
    quotesToExpireThisMonth: "devis expirent ce mois-ci",
    retailAnalysisSample: "Exemple d'analyse de vente au détail",
    representationOfSalesAndPurchases: "Représentation des ventes et des achats d'une entreprise par mois sur une année",
    salesVsPurchases: "Ventes vs Achats",
    thisBimester: "Ce bimestre",
    thisTrimester: "Ce trimestre",
    thisHalfYear: "Ce semestre",
    thisMonth: "Ce mois-ci",
    thisWeek: "Cette semaine",
    thisYear: "Cette année",
    today: "Aujourd'hui",
    turnout: "Chiffre d'affaires",
    viewAll: "Voir tout",
    worst: "Pire",
    worstCustomers: "plus mauvais clients",
    yesterday: "Hier",
    fixedAmount: "Montant fixé",
    discount: "Escompte",
    account: "Account",
    button: "button",
    changeEmail: "Change email",
    changePassword: "Change password",
    confirmNewPassword: "Confirm new password",
    currentPassword: "Current password",
    emailAddress: "Adresse e-mail",
    enterNewEmailAddress: "Enter new email address",
    fullName: "Full name",
    newPassword: "New password",
    pleaseCheckYourEmailInbox: "Please check your email inbox",
    profileCompleation: "Profile Compleation",
    profileDetails: "Profile details",
    signInMethod: "Sign-in method",
    updateEmail: "Update email",
    updatePassword: "Update Password",
    updateUser: "Update User",
    validateAccount: "Validate account",
    viewUser: "View user",
    yourAccountIsNotVerified: "Your account is not verified. Please validate your account through your email address, by clicking",
    user: "Utilisateur",
    users: "Utilisateurs",
    confirmPassword: "Confirmer le mot de passe",
    createAnAccount: "Créer un compte",
    enterTheNewPasswordForTheMail: "Saisissez le nouveau mot de passe pour l'email",
    enterYourEmailToResetYourPassword: "Entrez votre email pour réinitialiser votre mot de passe",
    goToLoginPage: "Aller à la page de connexion",
    goToPreviousPage: "Aller à la page précédente",
    iAgree: " J'accepte &",
    language: "Langue",
    languages: "Langues",
    myProfile: "Mon profil",
    notVerified: "Non vérifié",
    ourServicesOrRequesting: "Nos services ou en demander un nouveau.",
    password: "Mot de passe",
    resetPassword: "Réinitialiser le mot de passe",
    signIn: "Se connecter à l'administrateur numérique",
    signInHere: "Se connecter ici",
    signOut: "Déconnexion",
    spanish: "Español",
    submit: "Soumettre",
    termsAndConditions: "conditions d'utilisation",
    userProfile: "Profil de l'utilisateur",
    use8OrMoreCharacters: "Utilisez 8 caractères ou plus avec un mélange de lettres (minuscules et majuscules), de chiffres et de symboles.",
    verified: "Vérifié",
    welcome: "Bienvenue",
    company: "Entreprise",
    companyParameterSettings: "Paramètres de l'entreprise",
    companies: "Entreprises",
    current: "Actuel",
    addDeadlinePaymentParameter: "Ajouter paramètre de paiement à l'échéance",
    addImage: "Ajouter image",
    addTax: "Ajouter taxe",
    addDeadline: "Ajouter date limite",
    addTaxRateParameter: "Ajouter paramètre de taux d'imposition",
    addUnit: "Ajouter unité",
    addUnitMeasureParameter: "Ajouter paramètre de mesure d'unité",
    advanceSettings: "Paramètres avancés",
    anInvitationWillBeSent: "Une invitation sera envoyée à un utilisateur pour qu'il ait accès à l'application",
    aNewCompanyWillBeCreated: "Une nouvelle entreprise sera créée à partir des données saisies",
    browseUsers: "Parcourir les utilisateurs",
    companyAddress: "Adresse de la société",
    companyAdvanceSettings: "Paramètres avancés de l'entreprise",
    companyDownloadSettings: "Paramètres de téléchargement de l'entreprise",
    companyGeneralSettings: "Paramètres généraux de l'entreprise",
    companyIdentity: "Identité de l'entreprise",
    companyLogo: "Logo de l'entreprise",
    companyName: "Nom de l'entreprise",
    createCompany: "Créer entreprise",
    createAndSave: "Créer et enregistrer",
    createNewCompany: "Créer une nouvelle entreprise",
    designOfDocumentToDownloadQuotesAndInvoices: "Conception du document pour télécharger les devis et les factures.",
    downloadSettings: "Paramètres de téléchargement",
    dueDates: "Dates d'échéance pour le paiement d'une facture",
    editCompany: "Modifier entreprise",
    free: "Gratuit",
    generalSettings: "Paramètres généraux",
    helpPage: "Page d'aide",
    ifYouNeedMoreInfo: "Si vous avez besoin de plus d'informations, veuillez consulter",
    image: "Image",
    inviteUser: "Inviter un utilisateur",
    legal: "Légal",
    listOfUsers: "Liste des utilisateurs",
    logo: "Logo",
    name: "Nom",
    new: "Nouveau",
    noResultFound: "Aucun résultat trouvé",
    overview: "Aperçu",
    parameterSettings: "Parameter settings",
    pleaseTryAgainWithADifferentQuery: "Veuillez réessayer avec une requête différente",
    predefinedTaxesFees: "Taux de TVA prédéfinis",
    private: "Privé",
    removeLogo: "Supprimer le logo",
    selectModel: "Sélectionner le modèle",
    selectAndSaveDesiredLayoutToDownload: "Sélectionnez et enregistrez la mise en page souhaitée pour télécharger les devis et les factures",
    selectAndSaveOneToFourDesiredColors: "Sélectionnez et enregistrez une à quatre couleurs souhaitées pour la conception des devis, des commandes et des factures",
    sendInvitation: "Envoyer l'invitation",
    settings: "Paramètres",
    taxValue: "Valeur TVA",
    templateColors: "Template colors",
    templateModel: "Modèle",
    theEnteredInformationHasAlreadyValidated: "Les informations saisies ont déjà été validées, vous pouvez maintenant confirmer et créer une nouvelle entreprise",
    theNonRequiredFields: "Les champs non obligatoires qui ont été remplis avec des données erronées seront supprimés et ne seront pas sauvegardés.",
    toFinishCreationNewCompany: "Pour terminer la création de la nouvelle société, cliquez sur",
    unitsOfMeasure: "Unités de mesure pour une facture",
    uuidCode: "Code UUID",
    uuidCompany: "UUID entreprise",
    uuidName: "UUID/Nom",
    usersWhoAreSubscribed: "Utilisateurs abonnés et ayant accès à l'entreprise",
    viewCompany: "Afficher entreprise",
    viewMoreUsers: "Voir plus d'utilisateurs",
    thisContactWillOnlyBeRegisteredInCompany: "Ce contact sera uniquement enregistré dans l'entreprise",
    treasury: "Trésorerie",
    treasuryAccount: "Compte de trésorerie",
    treasuryAccounts: "Comptes de trésorerie",
    codeOfBelongingToABankingOrganization: "Code d'appartenance à un organisme bancaire",
    createANewTreasuryAccount: "Créer un nouveau compte de trésorerie",
    createNewTreasuryAccount: "Créer un nouveau compte de trésorerie",
    createTreasuryAccount: "Créer un compte de trésorerie",
    listTreasuryAccounts: "Liste des comptes de trésorerie",
    membership: "Abonnement",
    nameOfAccount: "Nom du compte",
    contactUs: "Contactez-nous",
    typeOfTreasuryAccount: "Type de compte de trésorerie",
    updateTreasuryAccountData: "Mise à jour des données du compte de trésorerie",
    viewTreasuryAccountsList: "Consulter la liste des comptes de trésorerie",
    notification: "Notification",
    notifications: "Notifications",
    accept: "Accepter",
    accessToCompany: "Accès à la société",
    activities: "Activités",
    answers: "Réponses",
    close: "Fermer",
    companyInvitations: "Invitations d'entreprise",
    hasReceivedAnInvitation: "A reçu une invitation",
    hasReceivedANotification: "A reçu une notification",
    hasReceivedAQuote: "A reçu un devis",
    hasReceivedAnOrder: "A reçu une commande",
    newNotification: "Nouvelle notification",
    noNotifications: "Aucune notification",
    other: "Autre",
    quoteEvaluation: "Évaluation du devis",
    quoteNotification: "Avis de devis",
    reject: "Rejeter",
    theInvitationToAccess: "L'invitation à accéder à l'entreprise par le",
    theQuoteThatYouSent: "Le devis que vous avez envoyé par",
    youHaveAlreadyEvaluatedTheOrderOfThe: "Vous avez déjà évalué la commande des",
    youHaveAlreadyEvaluatedQuote: "Vous avez déjà estimé le coût de la",
    youHaveAlreadyRespondedTo: "Vous avez déjà répondu à",
    youHaveReceivedAQuote: "Vous avez reçu un devis de",
    contact: "Contacter",
    contacts: "Contacts",
    basicBankAccountNumber: "Numéro de compte bancaire de base",
    bankIdentifierCode: "Bank Identifier Code / Société pour les télécommunications financières interbancaires mondiales",
    contactsList: "Liste des contacts ",
    contactUUID: "Contacter UUID",
    contactIdentity: "Identité du contact",
    contactIsNotAnAppUser: "Le contact n'est pas un utilisateur de l'application",
    contactType: "Type de contact",
    contactUUIDandType: "UUID et type de contact",
    countryContact: "Contact pays",
    createContact: "Créer contact",
    createNewContact: "Créer nouveau contact",
    createContactCompanyStructure: "Créer une structure de contact de type entreprise",
    createContactPersonStructure: "Créer une structure de contact de type personne",
    deleteContact: "Supprimer contact",
    deleteSelected: "Supprimer sélection",
    definingTheIdentityOfContact: "Définir l'identité du contact",
    definingTheInformationOfContact: "Définition des informations de contact",
    definingTheLegalInformationOfContact: "Définir les informations légales du contact",
    definingTheSettingsToDownloadQuotesAndInvoices: "Définition des paramètres de téléchargement des devis et des factures",
    dragOrClickOnTheBoxToAddTheCompanyLogo: "Faites glisser ou cliquez sur la case pour ajouter le logo de l'entreprise",
    editContact: "Modifier le contact",
    enterThePostalCodeOfTheCity: "Saisir le code postal de la ville",
    exportContacts: "Exporter contacts",
    extendChanges: "Étendre les changements",
    fileDownloadSettings: "Paramètres de téléchargement de fichiers",
    financialEntity: "Entité financière",
    identityOfContact: "Identité du contact",
    ifAddressDoesNotHaveANumber: "Si l'adresse ne comporte pas de numéro, indiquer 'nn' ou 'NN'",
    ifNecessaryEnterSomethingReferentialRegardingAddress: "Si nécessaire, entrez quelque chose de référentiel concernant l'adresse",
    ifYouDoNotWishToExtendContactModificationsToOtherCompanies: "Si vous ne souhaitez pas étendre les modifications de contact à d'autres sociétés, n'en sélectionnez aucune et sélectionnez",
    informationOfContact: "Information de contact",
    internationalBankAccountNumber: "Numéro de compte bancaire international",
    languageToDownload: "Langue de téléchargement du devis, de la commande ou de la facture",
    legalInformationOfContact: "Informations légales du contact",
    qrType: "Type de QR",
    shapesOrVersionsOfQR: "Formes ou versions d'une facture QR",
    selectCompanyAndSave: "Sélectionner une entreprise et enregistrer",
    selectCompaniesToCreateContact: "Sélectionnez les entreprises dans lesquelles un nouveau contact doit être ajouté",
    typeOfContact: "Type de contact",
    updateContactData: "Mettre à jour les données de contact",
    uuidAndType: "UUID et type",
    userUuid: "UUID de l'utilisateur",
    viewContact: "Afficher les contacts",
    viewContactsList: "Afficher la liste des contacts",
    quote: "Devis",
    quotes: "Devis",
    addQuote: "Ajouter devis",
    approveQuote: "Approuver devis",
    companyQuoteList: "Company quote list",
    createQuote: "Créer devis",
    newQuote: "Nouveau devis",
    dateOfQuote: "Date du devis, cette date peut être modifiée ultérieurement",
    editQuote: "Modifier devis",
    enterADateOrSelectADefaultPaymentDeadline: "Saisir une date ou sélectionner une date limite de paiement par défaut",
    expired: "Expiré",
    exportQuotes: "Exporter les devis",
    generateQuote: "Générer devis",
    inTime: "Dans le temps",
    listQuotes: "Liste des devis",
    listOfQuotes: "Liste des devis",
    quoteDate: "Date du devis",
    quoteGroups: "Groupes de devis",
    quoteNumber: "Numéro de devis",
    quoteReference: "Référence de devis",
    quoteStatus: "Statut du devis",
    quoteValidity: "Validité du devis",
    referenceQuote: "Devis de référence",
    rejectQuote: "Rejeter devis",
    sendQuote: "Envoyer devis",
    updateQuote: "Mise à jour du devis",
    viewQuote: "Afficher le devis",
    youWantToChangeQuoteStatusTo: "Vous voulez changer le statut du devis en",
    purchases: "Achats",
    sales: "Ventes",
    viewInvoices: "Voir les factures",
    viewOrders: "Voir les commandes",
    viewQuotes: "Voir les devis",
    viewProviderOrders: "Voir les commandes des fournisseurs",
    viewProviderInvoices: "Voir les factures des fournisseurs",
    viewExpenses: "Voir les dépenses",
    expenses: "Dépenses",
    addExpense: "Ajouter dépense",
    createExpense: "Créer une dépense",
    editExpense: "Modifier la dépense",
    expenseDate: "Expense date",
    expenseGroups: "Groupes de dépenses",
    expenseNumber: "Numéro de dépense",
    expenseValidity: "Validité de la dépense",
    invalidateExpense: "Invalider la dépense",
    listExpenses: "Liste des dépenses",
    listOfExpenses: "Liste des dépenses",
    removeExpense: "Supprimer la dépense",
    updateExpense: "actualiser la dépense",
    validateExpense: "Valider la dépense",
    viewExpense: "Voir la dépense",
    addProviderInvoice: "Ajouter facture fournisseur",
    createProviderInvoice: "Créer une facture fournisseur",
    editProviderInvoice: "Modifier la facture fournisseur",
    invalidateProviderInvoice: "Invalider la facture fournisseur",
    listProviderInvoices: "Liste des factures fournisseurs",
    listOfProviderInvoices: "Liste des factures fournisseurs",
    myBankAccount: "Mon compte de trésorerie",
    provider: "Fournisseur",
    providerBankAccount: "Compte de trésorerie du fournisseur",
    providerInvoiceDate: "Factures fournisseurs date",
    providerInvoiceGroups: "Groupes de la factures fournisseur",
    providerInvoiceStatus: "Statut de la facture fournisseur",
    providerInvoiceValidity: "Validité de la facture fournisseur",
    providerInvoices: "Factures des fournisseurs",
    providerOrderNumber: "Numéro de commande du fournisseur",
    updateProviderInvoice: "Mise à jour de la facture du fournisseur",
    validateProviderInvoice: "Valider la facture fournisseur",
    viewProviderInvoice: "Voir la facture fournisseur",
    addProviderOrder: "Ajouter commande fournisseur",
    approveProviderOrder: "Approuver la commande fournisseur",
    cancelProviderOrder: "Annuler la commande fournisseur",
    createProviderOrder: "Créer une commande fournisseur",
    editProviderOrder: "Modifier la commande fournisseur",
    generateProviderInvoice: "Générer la facture fournisseur",
    generateProviderOrder: "Générer la commande fournisseur",
    listProviderOrders: "Liste des commandes fournisseurs",
    listOfProviderOrders: "Liste des commandes fournisseurs",
    providerOrderDate: "commande fournisseur date",
    providerOrders: "Commandes fournisseurs",
    providerOrderGroups: "Groupes de commandes fournisseurs",
    providerOrderReference: "Référence de la commande fournisseur",
    providerInvoiceReference: "Référence de la factures fournisseur",
    expenseReference: "Référence de la dépenses",
    providerOrderStatus: "Statut du commande fournisseur",
    updateProviderOrder: "Mise à jour de la commande fournisseur",
    viewProviderOrder: "Voir la commande fournisseur",
    youWantToChangeProviderOrderStatusTo: "Vous souhaitez modifier le statut de la commande fournisseur en",
    addVouchers: "Ajouter une (des) pièces justificative(s)",
    download: "Télécharger",
    file: "Fichier",
    lastModified: "Dernière modification",
    size: "Taille",
    vouchers: "Pièces justificatives",
    addFiles: "Ajouter des fichiers",
    attachFiles: "Joindre des fichiers",
    removeAll: "Supprimer tout",
    uploadFiles: "Téléchargement de fichiers",
    orders: "Commandes",
    order: "Commande",
    orderEvaluation: "Évaluation de la commande",
    orderGroups: "Groupes de commande",
    orderNotification: "Notification de commande",
    orderReference: "Référence de la commande",
    orderStatus: "Statut de la commande",
    addOrder: "Ajouter commande",
    approveOrder: "Approbation de la commande",
    cancelOrder: "Annuler la commande",
    companyListOfOrders: "Liste des commandes de l'entreprise",
    createOrder: "Créer une commande",
    delivery: "Livraison",
    deliveyAddress: "Adresse de livraison",
    deliveryNote: "Bon de livraison",
    editOrder: "Editer la commande",
    exportOrder: "Exporter la commande",
    exportOrders: "Exporter les commandes",
    generateInvoice: "Générer facture",
    generateOrder: "Générer commande",
    isDeliveryAddressTheSameAsCustomersAddress: "L'adresse de livraison est-elle la même que celle du client?",
    listOrders: "Liste des commandes",
    listOfOrders: "Liste des commandes",
    orderDate: "Date de la commande",
    orderFile: "Dossier de commande",
    orderNumber: "Numéro de commande",
    selectExportType: "Sélectionner le type d'exportation",
    sendOrder: "Envoyer la commande",
    theOrderThatYouSentByThe: "La commande que vous avez envoyée par le",
    thisOptionIsUniqueAndNewInvoice: "Cette option est unique et une nouvelle facture ne peut pas être créée à partir de cette commande",
    updateOrder: "Mise à jour de la commander",
    viewOrder: "Voir la commande",
    youHaveReceivedAnOrderFromThe: "Vous avez reçu une commande du",
    youWantToChangeOrderStatusTo: "Vous souhaitez modifier le statut de la commande en",
    invoice: "Facture",
    invoices: "Factures",
    addInvoice: "Ajouter facture",
    amountPayed: "Montant payé",
    amountDebt: "Montant de la dette",
    amountPaid: "Montant payé",
    companyInvoiceList: "Company invoices list",
    createInvoice: "Créer une facture",
    editInvoice: "Modifier la facture",
    exportInvoices: "Exporter des factures",
    debt: "Dette",
    fullyPaid: "Entièrement payé",
    invalidInvoices: "Factures invalides",
    invalidateInvoice: "Invalider la facture",
    invoiceGroups: "Groupes de factures",
    invoiceDate: "Date de la facture",
    invoiceNumber: "Numéro de facture",
    invoiceStatus: "Statut de la facture",
    invoiceValidity: "Validité de la facture",
    listInvoices: "Liste des factures",
    listOfInvoices: "Liste des factures",
    notPayed: "Non payé",
    partiallyPaid: "Partiellement payé",
    payed: "Payé",
    thisInvoiceHasNoPayments: "Cette facture n'a pas de paiement",
    updateInvoice: "Mettre à jour la facture",
    validated: "Validé",
    validateInvoice: "Valider la facture",
    viewInvoice: "Afficher la facture",
    youWillBeAbleToUndoThisAction: "Vous pourrez annuler cette action",
    payment: "Paiement",
    payments: "Paiements",
    amountToPay: "Montant à payer",
    createNewPayment: "Créer un nouveau paiement",
    deleteSomePayment: "Supprimer un paiement",
    doNotDeletePayments: "Ne pas supprimer les paiements",
    makePayment: "Effectuer un paiement",
    method: "Méthode",
    paymentDate: "Date de paiement",
    paymentMethod: "Mode de paiement",
    save: "Enregistrer",
    sourceOfIncome: "Source of income",
    sourceTheAmount: "Source where the amount of money of payment made is entered",
    cash: "Espèces",
    card: "Carte",
    payPal: "PayPal",
    transfer: "Transfert",
    mobile: "Mobile",
    thisOptionIncludeTaxPrice: "Cette option inclut TVA dans prix indiqué",
    thisOptionNotIncludeTaxPrice: "Cette option ne inclut pas TVA dans prix indiqué",
    addOrRemoveContactCompanies: "Ajouter ou supprimer des contacts d'entreprises",
    theCurrentCompanyIs: "L'entreprise actuelle est",
    notifyMe: "Prévenez-moi",
    background: "Arrière-plan",
    inventory: "Inventaire",
    viewProducts: "Voir les produits",
    viewProduct: "Voir le produit",
    viewServices: "Voir les prestations",
    viewCategories: "Afficher les catégories",
    viewStorageCenter: "Voir le centre de stockage",
    toCreateA: "Pour créer un",
    theCompanyContainTreasutyAccount: "l'entreprise doit contenir au moins un compte de trésorerie",
    appLanguage: "Langue de l'application",
    theUuidCanBeUsedToCreateNnewContact: "L'UUID peut être utilisé pour créer un nouveau contact",
    settingInformation: "Informations sur les paramètres",
    addOrRemoveCompanies: "Ajouter/Supprimer entreprises",
    notGrouped: "Non groupé",
    orderValidity: "Validité de la commande",
    expiration: "Expiration",
    quoteExpiration: "Devis d'expiration",
    orderExpiration: "Commande d'expiration",
    invoiceExpiration: "Facture d'expiration ",
    discountTotal: "Total escompte",
    addService: "Ajouter service",
    addProduct: "Ajouter produit",
    noRecords: "Pas d'enregistrements",
    quantityMinimumForRebate: "Quantité minimum pour rabais",
    thisOptionRebateIsFixedAmount: "Cette option indique rabais est d'un montant fixe",
    thisOptionRebateIsPercentage: "Cette option indique rabais est un pourcentage",
    thisOptionRebateIsNone: "Cette option indique rabais est nulle",
    totalAfterRebates: "Total après rabais",
    calculator: "Calculatrice",
    selectTypeRebateAndEnterValueAndQuantity: "Sélectionnez le type de rabais et saisissez la valeur et la quantité",
    reference: "Référence",
    newOrder: "Nouvel commande",
    invoiceReference: "Référence de facture",
    newInvoice: "Nouvelle facture",
    providerOrderValidity: "Validité de la commande du fournisseur",
    expenseStatus: "Statut des dépenses",
    newProviderOrder: "Nouvelle commande de fournisseur",
    newProvederInvoice: "Nouvelle facture de fournisseur",
    newExpense: "Nouvelle dépense",
    viewVoucher: "Voir le bon",
    informationAdditional: "Informations supplémentaires",
    no: "Non",
    bank: "Banque",
    fullNameBankAccountHolder: "Nom complet du titulaire du compte bancaire",
    bankAccountHolder: "Titulaire du compte en banque",
    isNecessaryToHaveReferenceToActivateOption: "Il est nécessaire d'avoir une référence pour activer cette option",
    isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption: "Il est nécessaire de disposer d'au moins deux comptes de trésorerie pour activer cette option",
    none: "Aucun",
    undefined: "Indéfini",
    colorCombinationForDesignOfDocumentToDownload: "Combinaison de couleurs pour la conception du document à télécharger",
    all: "Tout",
    lettersTitlesSubtitles: "Lettres titres sous-titres",
    firstBar: "Première barre",
    secondBar: "Deuxième barre",
    barLetters: "Lettres à barres",
    qrIbanAndQrReference: "QR-IBAN avec référence QR",
    ibanAndStructuredCreditorReference: "IBAN avec référence créditeur (SCOR)",
    ibanWithoutReference: "IBAN sans référence",
    cashAccount: "Compte caisse",
    postalAccount: "Compte postal",
    bankAccount: "Compte bancaire",
    productsList: "Liste de products",
    category: "Catégorie",
    categories: "Catégories",
    listCategories: "Liste des catégories",
    tags: "Étiquettes",
    variants: "Variantes",
    photography: "Photographie",
    reminder: "Rappel",
    reminders: "Rappels",
    paymentStatus: "Statut de paiement",
    year: "Année",
    createReminder: "Créer rappel",
    updateReminder: "Aise à jour rappel",
    makeCopy: "Créer copie",
    reminderTotal: "Total rappel",
    inDispute: "En litige",
    createReminderFee: "Créer des frais de rappel",
    addCategory: "Ajouter catégorie",
    qtyAssets: "QTY actifs",
    viewCategory: "Voir la catégorie",
    editCategory: "Modifier la catégorie",
    deleteCategory: "Supprimer la catégorie",
    names: "Noms",
    listCategoryItems: "Liste des éléments de la catégorie",
    editListing: "Modifier liste",
    updateCategoryItemList: "Mettre à jour la liste des éléments de la catégorie",
    checkOrUncheckElementsToAddOrRemoveCategory: "Cochez ou décochez les éléments que vous souhaitez ajouter ou supprimer à la catégorie",
    updateCategory: "Catégorie de mise à jour",
    NameInOtherLanguages: "Nom dans d'autres langues",
    enterInLanguage: "Entrez la langue que vous souhaitez",
    enterOrModifyInLanguage: "Entrez ou modifiez dans la langue que vous souhaitez",
    general: "Général",
    categoryName: "Nom de la catégorie",
    categoryType: "Type de catégorie",
    createCategory: "Créer une catégorie",
    editProduct: "Modifier produit",
    deleteProduct: "Supprimer produit",
    productReferencePictures: "Photos de référence du produit",
    updatedAt: "Mis à jour à",
    code: "Code",
    sku: "SKU",
    codebar: "Code à barres",
    color: "Couleur",
    unit: "Unité",
    price: "Prix",
    fixed: "Fixée",
    productName: "Nom du produit",
    productDetails: "Détails du produit",
    advanced: "Avancé",
    addVariant: "Ajouter variante",
    uniqueProductCodeAssignedByCompany: "Code produit unique attribué par l'entreprise",
    selectDiscountTypeAppliedToProduct : "Sélectionnez un type de remise qui sera appliqué à ce produit",
    discountType: "Type de réduction",
    selectDiscountTypeThatWillAppliedProduct: "Sélectionnez un type de remise qui sera appliqué à ce produit",
    noDiscount: "Pas de réduction",
    setDiscountPercentage: "Définir le pourcentage de réduction",
    fixedDiscountedAmount: "Montant de réduction fixe",
    quantityMinimumDiscount: "Quantité minimum pour réduction",
    createProduct: "Créer produit",
    dropFilesHereOrClickUpload: "Déposez les fichiers ici ou cliquez pour les télécharger",
    uploadUpToFiles: "Téléchargez jusqu'à 10 fichiers",
    success: "Succès",
    addNewProductVariant: "Ajouter nouvelle variante de produit",
    editVariant: "Modifier la variante",
    editProductVariant: "Modifier la variante du produit",
    updateProduct: "Mettre à jour le produit",
    saveVariant: "Enregistrer variante",
    servicesList: "Liste des services",
    viewService: "Voir le service",
    editService: "Modifier le service",
    deleteService: "Supprimer le service",
    serviceReferencePictures: "Photos de référence de service",
    serviceName: "Nom du service",
    serviceDetails: "Détails du service",
    createService : "Créer un service",
    updateService : "Mettre à jour le service",
    uniqueServiceCodeAssignedByCompany: "Code de service unique attribué par l'entreprise",
    selectDiscountTypeAppliedToService: "Sélectionnez un type de remise qui sera appliqué à ce service",


    ifYouChangeYourMind: "Si vous changez d'avis, demandez qu'une nouvelle demande vous soit envoyée.",
    messageWillBeSentYourEmailToRecoverAccount: "Un message vous sera envoyé par email pour récupérer votre compte, veuillez vérifier votre e-mail. Vous serez redirigé vers la page de connexion.",
    nowYouCanLogIn: "Vous pouvez maintenant vous connecter avec le nouveau compte créé, vous serez redirigé vers la page de connexion.",
    newCompanyIsSelectedByDefault: "Nouvelle société est sélectionnée par défaut, redirigeant vers la page principale.",
    notificationWillBeDeleted: "La notification de l'invitation à accéder à la société sera supprimée.",
    theAppWillBeTranslated: "The app will be translated into indicated language, except for  information entered in documents.",
    toBeAddedInOrderToDownloadTheInvoice: "à ajouter pour pouvoir télécharger la facture.",
    userWillRespondInvitation: "L'utilisateur répondra s'il accepte ou non l'invitation à accéder à l'entreprise.",
    youWillNotBeAbleToUndo: "Vous ne pourrez pas annuler cette action.",
    tokenValidForAUserWhoHasNotLoggedIn: "Token valid for a user who has not logged in, redirecting to login page.",
    toValidateYourAccount: "To validate your account, you will be sent a validation email from where you can verify your account and enter again.",
    validatedAccountForLoggedInUser: "Validated account for logged in user, redirect to main page.",
    validatedAccountForADifferentUser: "Validated account for a different user than the one you are logged in, redirecting to login page.",
    validatedVerifiedForAUserWhoHasNotLoggedIn: "Validated verified for a user who has not logged in, redirecting to login page.",
    newServicesWithinTheSameApp: "Nouveaux services au sein d'une même application.",
    pageInProduction: "Page en production, vous pourrez très bientôt accéder à de nouvelles fonctionnalités au sein de l'application.",
    pleaseCorrectThePathOfThePageInOrderToAccess: "Veuillez corriger le chemin de la page afin d'accéder à l'application.",
    pleaseTryAgainLater: "Veuillez réessayer plus tard.",
    thereAreNoCompaniesAddedYet: "Aucune entreprise n'a encore été ajoutée.",
    tokenValidForAnUserThatIsDifferentFromLoggedIn: "Token valide pour un utilisateur différent de celui qui est connecté, redirigeant vers la page de connexion.",
    validTokenForTheUserThatLoggedIn: "Token valide pour l'utilisateur qui s'est connecté, redirigeant vers la page du tableau de bord.",
    redirectingToLogin: "Redirection vers la page de connexion.",
    willNotBeAbleToReturnUntilYouLogin: "Vous ne pourrez pas revenir tant que vous ne vous reconnecterez pas.",
    allTheFieldsAreValidated: "Tous les champs sont validés, en cas de saisie d'une donnée incorrecte dans un formulaire obligatoire vous ne pourrez pas passer à l'étape suivante, au contraire, lors de la saisie d'une donnée incorrecte dans un formulaire non requis, il ne sera pas enregistré.",
    pleaseClickContinue: "Veuillez cliquer sur continuer pour entrer les détails de la nouvelle société à créer.",
    searchUserForInvitate: "Recherchez l'utilisateur par son UUID ou par son nom et envoyez une notification pour accéder à l'entreprise.",
    theFileMustBeFormat: "Le fichier doit être au format jpeg, jpg ou png.",
    ifYouHaveAnyQuestionsContactUs: "Si vous avez des questions, vous pouvez nous contacter.",
    noNotificationsSeenYet: "Aucune notification n'a encore été vue.",
    thereAreNoNotifications: "Aucune notification n'a encore été reçue.",
    byDefaultValuesOfTheCompanyAreTaken: "Par défaut, les valeurs de l'entreprise sont prises en compte.",
    selectTheOptionsThatBestConsiderWhenDownloading: "Sélectionnez les options qui vous conviennent le mieux pour télécharger un devis ou une facture au format PDF.",
    notificationsAboutInvitationsToSubscribe: "Notifications concernant les invitations à s'abonner et à accéder à une entreprise.",
    whenCreatingAContactByUUID: "Lors de la création d'un contact par UUID vous ne pourrez pas modifier certains champs selon le type personne (nom, prénom et email) et société (nom).",
    youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact: "Vous pouvez saisir directement l'UUID d'un utilisateur ou d'une entreprise pour l'ajouter en tant que contact, sinon choisissez le type de contact à créer.",
    descriptionCreateQuote: "Veuillez entrer les données suivantes, elles sont nécessaires pour créer un nouveau devis.",
    descriptionCreateOrder: "Veuillez entrer les données suivantes, elles sont nécessaires pour créer un nouveau commande.",
    descriptionCreateInvoice: "Veuillez entrer les données suivantes, elles sont nécessaires pour créer un nouveau facture.",
    pleaseModifyAddOrDeleteFieldsFromQuote: "Veuillez modifier, ajouter et/ou supprimer les champs que vous souhaitez dans le devis.",
    sorryLooksLikeThereAreSomeErrorstryAgain: "Désolé, il semble qu'il y a des erreurs détectées, veuillez réessayer.",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Désolé, il semble qu'il y a des erreurs détectées, veuillez essayer de les résoudre.",
    sorryThereIsNothingToAdd: "Désolé, il n'y a rien à ajouter, veuillez réessayer.",
    thankYouVeryMuchForSubscribingRedirectingToPreviousPage: "Merci beaucoup pour votre abonnement et la redirection vers la page précédente.",
    actionCompletedReturningToPage: "Action terminée, retour à la page.",
    actionCompletedRedirectingToPage: "Acción completada, redirection a la página.",
    thisOptionIsUniqueAndNewOrderCannotBeCreatedFromThisQuote: "Cette option est unique et aucune nouvelle commande ne peut être créée à partir de ce devis.",
    pleaseEnterTheFollowingDataToCreateANewProviderInvoice: "Veuillez saisir les données suivantes, elles sont nécessaires à la création d'une nouvelle facture fournisseur.",
    pleaseModifyAddOrDeleteFieldsFromProviderInvoice: "Veuillez modifier, ajouter et/ou supprimer les champs que vous souhaitez voir figurer sur la facture fournisseur.",
    pleaseModifyAddOrDeleteFieldsFromExpense: "Veuillez modifier, ajouter et/ou supprimer les champs que vous souhaitez voir figurer sur la dépense.",
    pleaseEnterTheFollowingDataToCreateANewExpense: "Veuillez saisir les données suivantes, elles sont nécessaires à la création d'une nouvelle dépense.",
    pleaseEnterThFollowingDataToCreateANewProviderOrder: "Veuillez saisir les données suivantes, elles sont nécessaires pour créer une nouvelle commande fournisseur.",
    pleaseModifyAddAndOrDeleteFieldsFromProviderOrder: "Veuillez modifier, ajouter et/ou supprimer les champs que vous souhaitez dans la commande fournisseur.",
    fileInDifferentFormat: 'Fichier dans un format différent. Format du fichier : ".pdf".',
    fileIsTooBig: "Le fichier est trop volumineux. Taille maximale du fichier : 1MB.",
    fileFormatMaximum1MBPerFile: 'Le format de fichier est ".pdf" et la taille maximale est de 1 MB par fichier.',
    someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics: "Certains des fichiers téléchargés ne répondent pas aux caractéristiques requises.",
    PleaseModifyAddOrDeleteFieldsFromOrder: "Veuillez modifier, ajouter et/ou supprimer les champs que vous souhaitez dans la commande.",
    pleaseModifyAddOrDeleteFieldsFromInvoice: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la facture.",
    thisAmountCanNotBeGreater: "Ce montant ne peut être supérieur à la dette totale de la facture.",
    toCreateAnDocument: "Pour créer une documenter, l'entreprise doit disposer d'au moins un compte de trésorerie.",
    newContactWwillAddedToCompanyAndSelectDefault: "Le nouveau contact sera ajouté à l'entreprise et sélectionné par défaut, revenant à la page.",
    youHaveSuccessfullyResetYourPassword: "Vous avez réinitialisé votre mot de passe avec succès, vous pouvez maintenant vous connecter à nouveau. Vous serez redirigé vers la page de connexion.",
    kickstartYourBusinessByAddingAYourFirstCompany: "Démarrez votre activité en ajoutant une première entreprise.",
    ifNoAmountIsEnteredTheValueWillBeZero: "Si aucun montant n’est renseigné, la valeur sera 0.",
    ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary: "Si vous le souhaitez, vous pouvez attribuer le nom de la catégorie dans les langues que vous jugez nécessaires.",
    productNameRequiredAndRecommendedUnique: "Un nom de produit est requis et recommandé pour être unique.",
    addProductToCategory: "Ajouter un produit à une catégorie.",
    addTagsToProduct: "Ajouter des tags à un produit.",
    setProductMediaGallery: "Définir la galerie multimédia du produit.",
    serviceNameRequiredAndRecommendedUnique: "Un nom de service est requis et il est recommandé qu'il soit unique.",
    addServiceToCategory: "Ajoutez un service à une catégorie.",
    addTagsToService: "Ajoutez des balises à un service.",
    setServiceMediaGallery: "Définissez la galerie multimédia du service.",


    selectOneOption: "Sélectionnez l'une des options,",
    youAreWishingToEnterWithTheFollowingEmailAddress: "Vous souhaitez participer avec l'adresse électronique suivante:",
    categoryFor: "Catégorie pour un",


    ifYouWishToSaveTheChangesMade: "si vous souhaitez enregistrer les modifications apportées.",
    youWantToRun: "tu veux courir.",
    toContinue: "continuer.",
    waitAMomentToVerifyYourAccess: "attendez un moment pour vérifier votre accès.",
    companyPleaseEvaluateQuote: "entreprise, veuillez évaluer le devis.",
    hasSentARequestForAccessToTheFollowingCompany: "a envoyé une demande d'accès à l'entreprise suivante, merci d'y répondre.",
    ifYouWantToSaveTheNewQuote: "Si vous souhaitez enregistrer le nouveau devis.",
    ifYouWantToSaveTheNewQuoteWithTheChangesMade: "si vous souhaitez enregistrer le nouveau devis avec les modifications apportées.",
    ifYouWantToSaveTheNewInvoiceWithTheChangesMade: "si vous souhaitez enregistrer le nouveau facture avec les modifications apportées.",
    ifYouWantToSaveTheNewExpense: "si vous souhaitez sauvegarder la nouvelle dépense.",
    ifYouWantToSaveTheNewProviderInvoice: "si vous souhaitez enregistrer la nouvelle facture fournisseur.",
    ifYouWantToSaveTheNewProviderOrder: "si vous souhaitez enregistrer la nouvelle commande fournisseur.",
    ifYouWantToSaveTheNewProviderOrderWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle commande fournisseur avec les modifications apportées.",
    ifYouWantToSaveTheNewProviderInvoiceWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle facture fournisseur avec les modifications apportées.",
    ifYouWantToSaveTheNewOrder: "si vous souhaitez enregistrer la nouvelle commande.",
    ifYouWantToSaveTheNewOrderWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle commande avec les modifications apportées.",
    companyPleaseEvaluateTheOrder: "entreprise, veuillez évaluer la commande.",
    ifYouWantToSaveTheNewInvoice: "Si vous souhaitez enregistrer la nouvelle facture.",
    youWillNotBeAbleToRemoveItFromThisCompany: ", vous ne pourrez pas le supprimer de cette société, vous pouvez sélectionner les autres sociétés.",
    inThisCategory: "dans cette catégorie",


    yearl: "année",
    years: "années",
    hour: "heure",
    hours: "heures",
    minute: "minute",
    minutes: "minutes",
    information: "information",
    items: "articles",
    selected: "choisi",
    accountNeedsA: "le compte a besoin d'un",
    options: "options",
    with: "avec",
    bestCustomers: "meilleurs clients",
    byRecentUpdates: "Par mises à jour récentes",
    byTimeRange: "par plage horaire",
    template: "template",
    evaluated: "evaluated",
    noMailFound: "aucun courrier n'est trouvé",
    report: "rapport",
    reports: "rapports",
    requestOnAccess: "Demande d'accès à l'entreprise, votre réponse était",
    userHasBeen: "L'utilisateur a été",
    companyYourAnswerHasBeen: "entreprise, votre réponse a été",
    companyToUser: "de l'entreprise à l'utilisateur",
    hasBeen: "a été",
    byYear: "par année",


    youWantLogOut: "Vous voulez vous déconnecter?",
    doesItTakeALongTimeToLoadTheApp: "Le chargement de l'application prend-il beaucoup de temps?",
    alreadyHaveAnCccount: "Avez-vous déjà un compte?",
    forgotPassword: "Mot de passe oublié?",
    newHere: "Nouveau ici?",
    canNotFindTheUserYouWant: "Vous ne trouvez pas l'utilisateur que vous recherchez?",
    selectThisTreasuryAccountAsTheMainOne: "Sélectionner ce compte de trésorerie comme compte principal?",
    youWantToDeleteThisTreasuryAccount: "Vous voulez supprimer ce compte de trésorerie?",
    youWantToSelectThisTreasuryAccountAsPrimary: "Vous souhaitez sélectionner ce compte de trésorerie comme compte principal?",
    wantToExtendChangesToOtherCompanies: "Voulez-vous étendre les modifications à d'autres entreprises?",
    youWantToDeleteThisContact: "Vous voulez supprimer ce contact?",
    youWantToDeleteSelectedContacts: "Vous souhaitez supprimer les contacts sélectionnés?",
    youWantSendQuote: "Vous voulez envoyer le devis au client?",
    youWantToChangeQuoteStatusRejected: "Vous voulez changer le statut du devis en rejeté?",
    youWantToGenerateAnOrderFromThisQuote: "Vous souhaitez générer une commande à partir de ce devis?",
    youWantToChangeStatusTheExpense: "Vous voulez changer de statut de la dépense?",
    youWantToDeleteThisExpense: "Vous souhaitez supprimer cette dépense?",
    youWantToChangeStatusTheProviderInvoice: "Vous souhaitez modifier le statut de la facture du fournisseur?",
    youWantToGenerateAProviderInvoiceFromThisProviderOrder: "Vous souhaitez générer une facture fournisseur à partir de cette commande fournisseur?",
    youWantToChangeOrderStatusToCanceled: "Vous souhaitez modifier le statut de la commande à annulée?",
    youWantToGenerateAnInvoiceFromThisOrder: "Vous souhaitez générer une facture à partir de cette commande",
    youWantToSendTheOrderToCustomer: "Vous souhaitez envoyer la commande au client?",
    youWantToDeleteThisPayment: "Vous voulez supprimer ce paiement?",
    youWantToChangeStatusTheInvoice: "Vous souhaitez modifier le statut de la facture?",
    IsCompanyOwnerOfBankAccount: "L’entreprise est-elle propriétaire du compte bancaire?",
    useReferenceWithinQR: "Utiliser une référence dans le QR?",
    youWantDeleteProduct: "Vous souhaitez supprimer ce produit?",
    youWantDeleteSelectedProducts: "Vous souhaitez supprimer les produits sélectionnés?",
    youWantToDeleteThisReminder: "Vous voulez supprimer ce rappel?",
    youWantToDeleteThisCategory: "Vous souhaitez supprimer cette catégorie?",
    youWantToDeleteSelectedCategories: "Vous souhaitez supprimer les catégories sélectionnées?",
    youWantDeleteService: "Vous souhaitez supprimer ce produit?",
    youWantDeleteSelectedServices: "Vous souhaitez supprimer les services sélectionnés?",


    yourSessionHasBeenClosed: "Votre session a été clôturée, veuillez vous inscrire!",
    yourSessionHasExpired: "Votre session a expiré, veuillez vous réinscrire!",
    accountNotVerifiedYet: "Account not verified yet!",
    moneyBackGuarantee: "Garantie de remboursement à 100 %!",
    somethingWentWrong: "Quelque chose s'est mal passé!",
    thePageYouLookedNotFound: "La page que vous avez recherchée est introuvable!",
    yourSessionIsActiveCongratulations: "Votre session est active, félicitations!",
    weNeedYourAttention: "Nous avons besoin de votre attention!",
    yourAreDone: "Vous avez terminé!",
    startCreatingNewTreasuryAccountsForYourCompany: "Commencez à créer de nouveaux comptes de trésorerie pour votre entreprise, c'est très important pour le fonctionnement de l'application!",
    getNotifiedWhenWeLaunch: "Soyez averti lorsque nous lançons!",
    processOfCreatingANewInvoiceCompleted: "Le processus de création d'une nouvelle facture est terminé!",
    processOfUpdatingAInvoiceCompleted: "Le processus de mise à jour d'une facture est terminé",
    thereAreNoTreasuryAccounts: "Il n'y a pas de compte de trésorerie!",
    processOfCreatingANewOrderCompleted: "Processus de création d'une nouvelle commande terminé!",
    processOfUpdatingAnOrderCompleted: "Processus de mise à jour d'une commande terminé!",
    processOfCreatingQuoteCompleted: "Le processus de création d'un nouveau devis est terminé!",
    processOfCreatingAQuoteFromAnotherQuoteCompleted: "Le processus de création d'un devis à partir d'un autre devis est terminé!",
    processOfCreatingAOrderFromAnotherOrderCompleted: "Le processus de création d'un commande à partir d'un autre commande est terminé!",
    processOfCreatingAInvoiceFromAnotherInvoiceCompleted: "Le processus de création d'un facture à partir d'un autre facture est terminé!",
    processOfUpdatingAQuoteCompleted: "Le processus de mise à jour d'un devis terminé!",
    processOfCreatingANewExpenseCompleted: "Le processus de création d'une nouvelle dépense est terminé!",
    processOfUpdatingAnExpenseCompleted: "Le processus de mise à jour d'une dépense est terminé!",
    processOfCreatingANewProviderInvoiceCompleted: "Le processus de création de la nouvelle facture fournisseur est terminé!",
    processOfUpdatingAProviderInvoiceCompleted: "Le processus de mise à jour de la facture fournisseur est terminé!",
    processOfCreatedAProviderOrderCompleted: "Le processus de création d'une commande de fournisseur est terminé!",
    processOfCreatedAProviderInvoiceCompleted: "Le processus de création d'une facture de fournisseur est terminé!",
    processOfCreatingANewProviderOrderCompleted: "Le processus de création d'une nouvelle commande fournisseur est terminé!",
    processOfUpdatingAProviderOrderCompleted: "Le processus de mise à jour de la commande d'un fournisseur est terminé!",
    incorrectFiles: "Fichiers incorrects!",
    invalidFileType: "Type de fichier non valide! Types de fichiers autorisés : jpeg, jpg ou png",
    fileSizeNotAllowed: "La taille des fichiers n'est pas autorisée ! Taille maximale du fichier: 1 MB",
    startAddingNewElementsToThisCategory: "Commencez à ajouter de nouveaux éléments à cette catégorie!",


    english: "English",
    french: "Français",
    italian: "Italiano",
    german: "Allemand",


    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",


    first: "first",
    second: "second",
    third: "third",
    fourth: "fourth",
    fifth: "fifth",
    sixth: "sixth",


    n: "N°",
    hrs: "hrs",
    min: "min",
    sec: "sec",
    mb: "MB",


    pleaseWait: "Veuillez patienter...",
    selectOption: "Sélectionner option...",
    search: "Rechercher...",
    writeCommentToInvite: "Écrire un commentaire à l'invitation...",
    loading: "Chargement...",
    searchContact: "Rechercher contact et sélectionner...",
    searchCategory: "Rechercher catégorie et sélectionner...",
    searchProduct: "Rechercher product et sélectionner...",
    searchService: "Rechercher service et sélectionner...",


    okGotIt: "Ok, compris",
    yesLogOut: "Oui, déconnectez-vous",
    noCancel: "Non, annuler",
    yesDelete: "Oui, supprimer",
    yesResolveError: "Oui, résoudre l'erreur",
    yesSelect: "Oui, sélectionner",
    yesCanceled: "Oui, annulé",
    okClose: "D'accord, fermer",
    yesReject: "Oui, rejeter",
    yesGenerate: "Oui, générer",
    yesSend: "Oui, envoyer",
    yesChangeStatus: "Oui, changer de statut",
    yesCreateTreasuryAccount: "Oui, créer un compte de trésorerie",


    valid: "Valid",
    invalid: "Invalid",
    colorIsInvalid: "Couleur n'est pas valide",
    theValueIsNotAValid: "La valeur n'est pas valide",
    theValueIsNotAValidUUIDOrNameUser: "La valeur n'est pas un UUID ou un nom d'utilisateur valide",
    theValueIsAtLeast8Digits: "La valeur est d'au moins 8 chiffres",
    theValueDoesNotMatch: "La valeur ne correspond pas",
    theValueAlreadyExists: "La valeur existe déjà",
    limitFilesMaximum: "Limite de 10 fichiers maximum",
    required: "Requis",
    theValueIsRequired: "La valeur est obligatoire",
    colorIsRequired: "La couleur est requise",
    requirementsIsRequired: "Les blocs sont requis",
    contentsToRequirementIsRequired: "Le contenu du bloc est requis",


    successful: "Avec succès!",
    successfullyAcceptedCompany: "Entreprise acceptée avec succès!",
    successfullyCreatedTreasuryAccount: "Création réussie d'un compte de trésorerie!",
    successfullyCreatedCompany: "Entreprise créée avec succès!",
    successfullyCreatedCompanyParameters: "Création réussie des paramètres de l'entreprise!",
    successfullyCreatedContact: "Contact créé avec succès!",
    successfullyCreatedExpense: "Dépenses créées avec succès!",
    successfullyCreatedOrder: "Commande créée avec succès!",
    successfullyCreatedInvoice: "La facture a été créée avec succès!",
    successfullyCreatedNotification: "La notification a été créée avec succès!",
    successfullyCreatedPayment: "Le paiement a été créé avec succès!",
    successfullyCreatedProviderInvoice: "La facture fournisseur a été créée avec succès!",
    successfullyCreatedProviderOrder: "La commande fournisseur a été créée avec succès!",
    successfullyCreatedQuote: "Création réussie d'un devis!",
    successfullyCreatedUser: "L'utilisateur a été créé avec succès!",
    successfullyDeletedTreasuryAccount: "Compte de trésorerie supprimé avec succès!",
    successfullyDeletedContact: "Suppression réussie du contact!",
    successfullyDeletedExpense: "Suppression réussie de la dépense!",
    successfullyDeletedPayment: "Le paiement a bien été supprimé!",
    successfullyInvitationCancel: "L'annulation de l'invitation a été réussie!",
    successfullyInvitationSent: "L'invitation a été envoyée avec succès!",
    successfullyLoggedIn: "Vous vous êtes connecté avec succès!",
    successfullyOrderSent: "Commande envoyée avec succès!",
    successfullyUpdatedCompanySettings: "Mise à jour réussie des paramètres de l'entreprise!",
    successfullyUpdatedTreasuryAccount: "Mise à jour réussie du compte de trésorerie!",
    successfullyUpdatedDefaultTreasuryAccount: "Mise à jour réussie du compte de trésorerie par défaut!",
    successfullyUpdatedContact: "Le contact a été mis à jour avec succès!",
    successfullyUpdatedExpense: "Mise à jour réussie de la dépense!",
    successfullyUpdatedInvoice: "Mise à jour réussie de la facture!",
    successfullyUpdatedNotification: "La notification a été mise à jour avec succès!",
    successfullyUpdatedOrder: "Mise à jour réussie de la commande!",
    successfullyUpdatedPassword: "Mise à jour du mot de passe réussie!",
    successfullyUpdatedProviderInvoice: "Mise à jour réussie de la facture fournisseur!",
    successfullyUpdatedProviderOrder: "Mise à jour réussie de la commande fournisseur!",
    successfullyUpdatedQuote: "Mise à jour réussie du devis!",
    successfullyUpdatedStatus: "Le statut a été mis à jour avec succès!",
    successfullyQuoteSent: "Le devis a été envoyé avec succès!",
    successfullyRejectedCompany: "Entreprise rejetée avec succès!",
    successfullyRequest: "Demande acceptée!",
    successfullySubscribed: "Abonné avec succès !",
    successfullyAcceptedInvitation: "Successfully accepted invitation!",
    successfullyUpdatedUser: "Successfully updated user!",
    successfullyUpdatedAccount: "Successfully updated account!",
    successfullyMailSent: "Successfully mail sent!",
    verificationWasSuccessful: "La vérification a été effectuée avec succès!",
    youHaveSuccessfullySignedOut: "Vous vous êtes déconnecté avec succès!",
    successfullyDeletedReminder: "Le rappel a bien été supprimé!",
    successfullyCreatedReminder: "Rappel créées avec succès!",
    successfullyUpdatedReminder: "Mise à jour réussie de le rappel!",
    successfullyUpdatedCategory: "Catégorie mise à jour avec succès!",
    successfullyCreatedCategory: "Catégorie créée avec succès!",
    successfullyDeletedCategories: "Catégories supprimées avec succès !",

    error: "Error",
    error1000: "Jeton non trouvé!",
    error1001: "Jeton invalide!",
    error1002: "Une erreur s'est produite dans la base de données!",
    error1003: "Un ou plusieurs champs obligatoires sont vides!",
    error1004: "Uuid non trouvé!",
    error1005: "Les mots de passe ne correspondent pas!",
    error1006: "Format non valide pour l'e-mail!",
    error1007: "Mot de passe erroné!",
    error1008: "Utilisateur non trouvé!",
    error1009: "Clé API non valide!",
    error1010: "Email déjà pris!",
    error1011: "Doit comporter au moins 8 caractères!",
    error1012: "Cet utilisateur est déjà abonné à ce service!",
    error1014: "Nous n'avons pas d'email enregistré dans notre base de données!",
    error1015: "Erreur lors de l'envoi de l'émail!",
    error1016: "Cette entreprise est déjà enregistrée!",
    error1017: "L'utilisateur est déjà vérifié!",
    error1018: "Jeton de mot de passe erroné!",
    error1019: "Autre clé API non valide!",
    error1020: "Mauvais jeton de vérification!",
    error1021: "Vous n'avez pas accès à cette entreprise!",
    error1022: "Cette commande fait déjà l'objet d'une facture!",
    error1023: "Entreprise non trouvée!",
    error1024: "Impossible de créer une facture à partir d'une commande rejetée!",
    error1025: "Uuid invalide!",
    error1026: "Notification non trouvée!",
    error1027: "La citation ne se trouve pas dans cette entreprise!",
    error1028: "La date de fin doit être supérieure à la date de début!",
    error1029: "Mauvaise adresse mail!",
    error1030: "Devis non trouvée!",
    error1031: "Vous ne pouvez pas vous inviter vous-même!",
    error1032: "Facture non trouvée!",
    error1033: "Contact non trouvé!",
    error1034: "Monnaie incorrecte!",
    error1035: "Paiement non trouvé!",
    error1036: "Le paiement a déjà été supprimé!",
    error1037: "Le contact a déjà été supprimé!",
    error1038: "Facture non validée!",
    error1039: "Le montant ne doit pas être négatif!",
    error1040: "L'un des contacts a déjà été supprimé!",
    error1041: "Ce contact existe déjà!",
    error1042: "Le contact n'appartient pas à l'entreprise!",
    error1043: "Plus que ce que vous devriez payer!",
    error1044: "Le contact appartient déjà à l'entreprise!",
    error1047: "The new password you entered is the same as your previous password!",
    error1048: "Façon incorrecte de commander!",
    error1049: "The new email is the same as the email currently registered in your account!",
    error1050: "Mauvais mode de paiement!",
    error1051: "La devis n'existe pas!",
    error1052: "Mauvais type!",
    error1055: "Le prénom, le nom de famille et l'adresse électronique ne doivent pas être modifiés!",
    error1056: "Le prénom et le nom de famille ne doivent pas être modifiés!",
    error1057: "La devis avec ce statut ne peut pas être éditée!",
    error1058: "Il y a déjà une facture avec ce devis!",
    error1059: "Invitation non envoyée!",
    error1060: "L'invitation a déjà été acceptée!",
    error1061: "L'invitation a déjà été rejetée!",
    error1062: "Statut de l'invitation incorrect!",
    error1064: "Erreur lors de l'envoi de l'e-mail de vérification du compte!",
    error1065: "Format non valide pour le téléphone!",
    error1066: "Format non valide pour l'adresse supplémentaire!",
    error1067: "Format non valide pour le numéro d'adresse!",
    error1068: "Format non valide pour l'adresse de la rue!",
    error1069: "Code postal invalide!",
    error1070: "Invitation non trouvée!",
    error1071: "Format invalide pour le bic!",
    error1072: "Format non valide pour bban!",
    error1073: "Format invalide pour la TVA!",
    error1074: "Format non valide pour le registre!",
    error1075: "Format non valide pour le site web!",
    error1076: "Commande fournisseur non approuvée!",
    error1077: "Format non valide pour l'identifiant de l'entreprise!",
    error1078: "La notification n'appartient pas à l'utilisateur!",
    error1079: "Le commande fournisseur n'est pas trouvé dans cette entreprise!",
    error1080: "Le prénom et l'adresse électronique ne doivent pas être modifiés!",
    error1081: "Le prénom ne doit pas être modifié!",
    error1082: "Le nom de famille et l'adresse électronique ne doivent pas être modifiés!",
    error1083: "Le nom de famille ne doit pas être modifié!",
    error1084: "L'adresse électronique ne doit pas être modifiée!",
    error1085: "Le nom ne doit pas être modifié!",
    error1086: "Le nom ne doit pas être modifié!",
    error1087: "Les modèles n'existent pas!",
    error1088: "La devise doit être EUR ou CHF!",
    error1089: "Veuillez saisir un QR-IBAN valide!",
    error1090: "Veuillez saisir un IBAN valide!",
    error1091: "Format non valide pour la langue!",
    error1092: "Format non valide pour le pays!",
    error1093: "La commande fournisseur n'est pas trouvée dans cette entreprise!",
    error1094: "Facture fournisseur validée!",
    error1095: "Facture fournisseur non trouvée!",
    error1096: "Compte de trésorerie supprimé!",
    error1097: "Facture fournisseur non validée!",
    error1098: "Commande fournisseur non trouvée!",
    error1099: "Dépense non trouvée!",
    error1100: "La dépense a déjà été supprimée!",
    error1101: "La commande avec ce statut ne peut pas être édité!",
    error1102: "La commande n'existe pas!",
    error1103: "Commande non annulée!",
    error1104: "Commande non approuvée!",
    error1105: "Compte de trésorerie introuvable!",
    error1106: "La commande n'est pas trouvée dans cette entreprise!",
    error1107: "Ordre non trouvé!",
    error1108: "Il existe déjà une facture fournisseur pour cette commande fournisseur!",
    error1109: "Impossible de créer une facture fournisseur à partir d'une commande fournisseur rejetée!",
    error1110: "Si des paiements ont été effectués, ils ne peuvent pas être invalidés!"
  }
}