import styled, { css } from 'styled-components';

const Container = styled.div`
  display: block;

  ${props => props.property === "true" && css`
    display: block;
  `}

  ${props => props.property === "false" && css`
    display: none;
  `}
`;

export { Container }
