function accordionShow(menu: string, sub_menu: string) {
  let element1 = document.getElementById(menu)
  let element2 = document.getElementById(sub_menu)

  element1?.classList.add('hover')
  element1?.classList.add('show')
  element2?.classList.add('show')
}

function accordionHide(menu: string, sub_menu: string) {
  let element1 = document.getElementById(menu)
  let element2 = document.getElementById(sub_menu)

  element1?.classList.remove('hover')
  element1?.classList.remove('show')
  element2?.classList.remove('show')
}

function accordion(menu: string, sub_menu: string) {
  let element = document.getElementById(menu)

  if (element?.classList.contains('hover')) {
    accordionHide(menu, sub_menu)
  } else {
    accordionShow(menu, sub_menu)
  }
}

function accordions(list_menu: string[], list_submenu: string[], menu: string, sub_menu: string) {
  if (list_menu.length === list_submenu.length) {
    for (let i = 0; i < list_menu.length; i++) {
      if (menu === list_menu[i] && sub_menu === list_submenu[i]) {
        accordion(menu, sub_menu)
      } else {
        accordionHide(list_menu[i], list_submenu[i])
      }
    }
  }
}

export { accordionShow, accordionHide, accordion, accordions }
