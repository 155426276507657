function inputEnabled(identifier: string) {
  let element = document.getElementById(identifier) as HTMLInputElement

  if (element) {
    element.disabled = false
  }
}

function inputDisabled(identifier: string) {
  let element = document.getElementById(identifier) as HTMLInputElement
  
  if (element) {
    element.disabled = true
  }
}

function inputEnabledList(identifier: string) {
  Array.from(document.querySelectorAll(identifier)).forEach( (node) => {
    let element = node as HTMLInputElement
    element.disabled = false
  })
}

function inputDisabledList(identifier: string) {
  Array.from(document.querySelectorAll(identifier)).forEach( (node) => {
    let element = node as HTMLInputElement
    element.disabled = true
  })
}

export { inputEnabled, inputDisabled, inputEnabledList, inputDisabledList }
