import React from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Expense } from '../../../../models/expense.model';
import { AmountTax } from '../../../../models/amount.tax.model';
import { moneyFormat, taxRateFormat } from '../../../../scripts/format.value.script';

export interface AppPurchaseViewAmountProps {
  purchase: string,
  record: any | ProviderOrder | ProviderInvoice | Expense
};

const AppPurchaseViewAmount: React.FunctionComponent<AppPurchaseViewAmountProps> = ({purchase, record}) => {
  const {lang} = StateLanguage()

  return (
    <div className="card card-flush mb-5">
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.amount}</h4>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="d-flex justify-content-end">
          <div className="mw-500px">
            <div className="d-flex flex-stack px-5 py-2 bg-light-info border-top border-bottom">
              <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.subTotal}:</div>
              <div className="text-end fw-bolder ps-20 text-gray-800">{record.currency.symbol} {moneyFormat(record.amount.sub_total)}</div>
            </div>
            <div className="d-flex flex-stack px-5 py-2 bg-light-info border-bottom">
              <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.taxTotal}:</div>
              <div className="text-end fw-bolder ps-20 text-gray-800">{record.currency.symbol} {moneyFormat(record.amount.tax_total)}</div>
            </div>
            { record.amount.tax_list.map(( (item: AmountTax, index: number) => { return (
              <div  key={index} className="d-flex flex-stack ps-10 pe-5 py-1 bg-light-dark">
                <div className="fw-bold pe-20 text-gray-700 fs-8">{lang.labels.tax} {(taxRateFormat(item.tax_rate))}</div>
                <div className="text-end fw-bolder ps-20 text-gray-800 fs-7">{record.currency.symbol} {moneyFormat(item.tax_value)}</div>
              </div>
            )}))}
            <div className="d-flex flex-stack px-5 py-2 bg-light-warning border-top border-bottom">
              <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.total}:</div>
              <div className="text-end fw-bolder ps-20 text-gray-800">{record.currency.symbol} {moneyFormat(record.amount.total)}</div>
            </div>
            { purchase === "invoice" &&
              <>
                <div className="d-flex flex-stack px-5 py-2 bg-light-success border-top border-bottom">
                  <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.payed}:</div>
                  <div className="text-end fw-bolder ps-20 text-gray-800">{record.currency.symbol} {moneyFormat(record.amount.paid)}</div>
                </div>
                <div className="d-flex flex-stack px-5 py-2 bg-light-danger border-top border-bottom">
                  <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.debt}:</div>
                  <div className="text-end fw-bolder ps-20 text-gray-800">{record.currency.symbol} {moneyFormat(record.amount.debt)}</div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppPurchaseViewAmount;
