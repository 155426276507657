import { Language } from "../languages/labels/language.labels";

export function monthList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'january', description: lang.labels.january },
    { code: 'february', description: lang.labels.february },
    { code: 'march', description: lang.labels.march },
    { code: 'april', description: lang.labels.april },
    { code: 'may', description: lang.labels.may },
    { code: 'june', description: lang.labels.june },
    { code: 'july', description: lang.labels.july },
    { code: 'august', description: lang.labels.august },
    { code: 'september', description: lang.labels.september },
    { code: 'october', description: lang.labels.october },
    { code: 'november', description: lang.labels.november },
    { code: 'december', description: lang.labels.december }
  ]
}

export function monthValue(lang: Language, code: string): string {
  const month = monthList(lang).find(item => item.code === code)
  return month ? month.description : lang.labels.undefined
}
