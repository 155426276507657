
function drawerShow(identifier: string, overlay: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(overlay)

  element1?.classList.add('drawer')
  element1?.classList.add('drawer-start')
  element1?.classList.add('drawer-on')
  element2?.classList.add('drawer-overlay')
}

function drawerHide(identifier: string, overlay: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(overlay)

  element1?.classList.remove('drawer')
  element1?.classList.remove('drawer-start')
  element1?.classList.remove('drawer-on')
  element2?.classList.remove('drawer-overlay')
}

function drawerButtonShow(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.add('active')
  element2?.classList.add('drawer-on')
  element3?.classList.add('drawer-overlay')
}

function drawerButtonHide(button: string, container: string, overlay: string) {
  let element1 = document.getElementById(button)
  let element2 = document.getElementById(container)
  let element3 = document.getElementById(overlay)

  element1?.classList.remove('active')
  element2?.classList.remove('drawer-on')
  element3?.classList.remove('drawer-overlay')
}

export { drawerShow, drawerHide, drawerButtonShow, drawerButtonHide }
