export function moneyFormat(amount: number): string {
  if (amount) {
    let amountInteger: string = new Intl.NumberFormat("en-US").format(Math.floor(amount))
    let amountDecimal: string = amount.toFixed(2).toString().split(".")[1]
    return `${amountInteger}.${amountDecimal}`
  } else {
    return '0.00'
  }
}

export function taxRateFormat(tax_rate: string): string {
  return (Number(tax_rate) || Number(tax_rate) === 0) ? `${parseFloat(Number(tax_rate).toFixed(2))}%` : `${tax_rate.charAt(0).toUpperCase() + tax_rate.slice(1)}`
}

export function bicFormat(bic: string): string {
  return (bic.length > 0) ? `${bic.substring(0, 4)} ${bic.substring(4, 6)} ${bic.substring(6, 8)} ${bic.substring(8, bic.length)}` : ''
}

export function ibanFormat(iban: string): string {
  let value: string = ''

  if (iban.length > 0) {
    let array: string[] = iban.match(/.{1,4}/g) as string[]

    for (let i = 0; i < array.length; i++) {
      if (i < array.length - 1) {
        value += `${array[i]} `
      } else {
        value += `${array[i]}`
      }
    }
  }

  return value
}

/* export function ibanFormat(iban: string): string {
  let value: string = ''

  if (iban.length > 0) {
    let array: string[] = iban.match(/.{1,4}/g) as string[]

    for (let i = 0; i < array.length; i++) {
      if (i < 3) {
        value += `${array[i]} `
      } else if (i === 3) {
        let subArray: string[] = array[3].match(/.{1,2}/g) as string[]
        value += `${subArray[0]} ${subArray[1]}`
      } else {
        value += `${array[i]}`
      }
    }
  }

  return value
} */
