import { Language } from "../languages/labels/language.labels";

export function validationList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'valid', description: lang.labels.valid },
    { code: 'invalid', description: lang.labels.invalid }
  ]
}

export function validationValue(lang: Language, code: string): string {
  const validation = validationList(lang).find(item => item.code === code)
  return validation ? validation.description : lang.labels.undefined
}
