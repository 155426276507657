import React, { useEffect, useState } from 'react';

import { UseOutletContextCompany } from './Company';
import { StateLanguage } from '../../../languages/config/StateLanguage';

import AppCompanyUpdateGeneral from './card/CompanyUpdateGeneral';
import AppCompanyUpdateParameter from './card/CompanyUpdateParameter';
import AppCompanyUpdateDownload from './card/CompanyUpdateDownload';

export interface CompanyUpdatePageProps {};

const CompanyUpdatePage: React.FunctionComponent<CompanyUpdatePageProps> = props => {
  const {setRoute} = UseOutletContextCompany()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.companies, branch: lang.labels.editCompany}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="tab-content">
      <div id="container-settings-general" className="tab-pane fade show active" role="tabpanel">
        <AppCompanyUpdateGeneral></AppCompanyUpdateGeneral>
      </div>
      <div id="container-settings-parameter" className="tab-pane fade" role="tabpanel">
        <AppCompanyUpdateParameter></AppCompanyUpdateParameter>
      </div>
      <div id="container-settings-download" className="tab-pane fade" role="tabpanel">
        <AppCompanyUpdateDownload></AppCompanyUpdateDownload>
      </div>
    </div>
  )
};

export default CompanyUpdatePage;
