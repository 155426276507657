import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextInvoice } from './Invoice';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { InvoiceDA } from '../../../../services/invoice.service';
import { PaymentDA } from '../../../../services/payment.service';
import { Error } from '../../../../models/error.model';
import { Invoice } from '../../../../models/invoice.model';
import { Reminder } from '../../../../models/reminder.model';
import { modalShow } from '../../../../tools/modal.tool';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.script';
import { colorStatusInvoice } from '../../../../scripts/record.badge.color.script';
import { treasuryAccountSelected } from '../../../../scripts/selected.item.list.script';
import { statusRecordValue } from '../../../../libraries/status.record.library';
import { paymentMethodValue } from '../../../../libraries/payment.library';

import AppSaleViewDetail from '../card/SaleViewDetail';
import AppSaleViewRequirements from '../card/SaleViewRequirements';
import AppSaleViewAmount from '../card/SaleViewAmount';
import AppSaleViewSignatures from '../card/SaleViewSignatures';

import AppButtonInvoiceExport from '../../../../components/button/ButtonInvoiceExport';
import AppModalPaymentCreate from '../../../../components/modal/ModalPaymentCreate';
import AppModalInvoiceCreateReminder from '../../../../components/modal/ModalInvoiceCreateReminder';
import AppModalInvoiceUpdateReminder from '../../../../components/modal/ModalInvoiceUpdateReminder';

export interface InvoiceViewPageProps {};

let errorResponse: Error, invoiceResponse: Invoice, paymentResponse: Invoice;

const InvoiceViewPage: React.FunctionComponent<InvoiceViewPageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, treasuryAccountsForCompany} = UseOutletContextInvoice()
  const {lang} = StateLanguage()
  const param = useParams()

  const [mounted, setMounted] = useState(false)
  const [removePayment, setRemovePayment] = useState(false)
  const [reminder, setReminder] = useState<Reminder | undefined | null>(null)
  const [invoice, setInvoice] = useState<Invoice | undefined | null>(null)

  const loadInvoice = async (id_company: string, id_invoice: string) => {
    await InvoiceDA.getInvoice(id_company, id_invoice).then( (response) => {
      if (response.status === 200) {
        invoiceResponse = response.data
        setInvoice(invoiceResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitInvoiceValidated = async () => {
    if (companyForUser && invoice) {
      Swal.fire({
        title: lang.labels.youWantToChangeStatusTheInvoice,
        text: lang.labels.youWillBeAbleToUndoThisAction,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesChangeStatus,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await InvoiceDA.putInvoiceValidated(companyForUser.id, invoice.id).then( (response) => {
            if (response.status === 200) {
              invoiceResponse = response.data
              setInvoice(invoiceResponse)

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitPaymentDelete = async (item: string) => {
    if (companyForUser && invoice) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisPayment,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await PaymentDA.deletePayment(companyForUser.id, invoice.id, item).then( (response) => {
            if (response.status === 200) {
              paymentResponse = response.data
              setInvoice(paymentResponse)

              Swal.fire({
                title: lang.labels.successfullyDeletedPayment,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitReminderDelete = async (item: string) => {
    if (companyForUser && invoice) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisReminder,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await InvoiceDA.deleteInvoiceReminder(companyForUser.id, invoice.id, item).then( (response) => {
            if (response.status === 200) {
              paymentResponse = response.data
              setInvoice(paymentResponse)

              Swal.fire({
                title: lang.labels.successfullyDeletedReminder,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const excuteViewOrHideButtonsToPaymentDelete = () => {
    setRemovePayment(!removePayment)
  }

  const executeShowModalPaymentCreate = () => {
    modalShow('modal-payment-create')
  }

  const executeShowModalInvoiceCreateReminder = () => {
    modalShow('modal-invoice-create-reminder')
  }

  const executeShowModalInvoiceUpdateReminder = (item: Reminder) => {
    setReminder(item)
    modalShow('modal-invoice-update-reminder')
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadInvoice(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.invoices, branch: lang.labels.viewInvoice}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && settingsForCompany && treasuryAccountsForCompany && invoice
        ?
        <>
          <div className="card card-flush mb-5">
            <div className="card-header pt-3">
              <div className="card-title align-items-start flex-column">
                <h1>{invoice.invoice_number}</h1>
                <label className="fs-6 fw-bold mt-1">
                  {lang.labels.status}:
                  <span className={`badge badge-light-${colorStatusInvoice(invoice.status)} text-uppercase fs-7 ms-2`}>{statusRecordValue(lang, invoice.status)}</span>
                </label>
              </div>
              <div className="card-toolbar">
                <AppButtonInvoiceExport companyForUser={companyForUser} settingsForCompany ={settingsForCompany} invoice={invoice}></AppButtonInvoiceExport>
                { invoice.status !== "completed"
                  ?
                  <>
                    <button className={`btn ${invoice.validated ? "btn-light-primary" : "btn-primary"} mb-1 me-2`} type="button" onClick={submitInvoiceValidated}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                            <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                          </svg>
                        </span>
                        {invoice.validated ? lang.labels.invalidateInvoice : lang.labels.validateInvoice}
                      </div>
                    </button>
                    { invoice.validated
                      ?
                      <>
                        { invoice.reminders.length === 0 &&
                          <button className="btn btn-primary mb-1 me-2" type="button" onClick={executeShowModalInvoiceCreateReminder}>
                            <div className="d-flex align-items-center">
                              <span className="svg-icon svg-icon-2 ms-0">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M14 3V20H2V3C2 2.4 2.4 2 3 2H13C13.6 2 14 2.4 14 3ZM11 13V11C11 9.7 10.2 8.59995 9 8.19995V7C9 6.4 8.6 6 8 6C7.4 6 7 6.4 7 7V8.19995C5.8 8.59995 5 9.7 5 11V13C5 13.6 4.6 14 4 14V15C4 15.6 4.4 16 5 16H11C11.6 16 12 15.6 12 15V14C11.4 14 11 13.6 11 13Z" fill="black"/>
                                  <path d="M2 20H14V21C14 21.6 13.6 22 13 22H3C2.4 22 2 21.6 2 21V20ZM9 3V2H7V3C7 3.6 7.4 4 8 4C8.6 4 9 3.6 9 3ZM6.5 16C6.5 16.8 7.2 17.5 8 17.5C8.8 17.5 9.5 16.8 9.5 16H6.5ZM21.7 12C21.7 11.4 21.3 11 20.7 11H17.6C17 11 16.6 11.4 16.6 12C16.6 12.6 17 13 17.6 13H20.7C21.2 13 21.7 12.6 21.7 12ZM17 8C16.6 8 16.2 7.80002 16.1 7.40002C15.9 6.90002 16.1 6.29998 16.6 6.09998L19.1 5C19.6 4.8 20.2 5 20.4 5.5C20.6 6 20.4 6.60005 19.9 6.80005L17.4 7.90002C17.3 8.00002 17.1 8 17 8ZM19.5 19.1C19.4 19.1 19.2 19.1 19.1 19L16.6 17.9C16.1 17.7 15.9 17.1 16.1 16.6C16.3 16.1 16.9 15.9 17.4 16.1L19.9 17.2C20.4 17.4 20.6 18 20.4 18.5C20.2 18.9 19.9 19.1 19.5 19.1Z" fill="black"/>
                                </svg>
                              </span>
                              {lang.labels.createReminderFee}
                            </div>
                          </button>
                        }
                      </>
                      :
                      <Link to={"/app/sale/invoice/update/" + invoice.id} className="btn btn-primary mb-1 me-2">
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.editInvoice}
                        </div>
                      </Link>
                    }
                    <Link to={"/app/sale/invoice/create/reference/" + invoice.id} className="btn btn-icon btn-primary mb-1">
                      <span className="svg-icon svg-icon-2 icon-primary">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
                          <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#f6f5f4" stroke="none">
                            <path d="M150 440 c0 -19 7 -20 115 -20 l114 0 4 -122 c2 -80 6 -115 11 -100 5 12 12 22 17 22 17 0 9 -37 -11 -55 -11 -10 -20 -29 -20 -42 0 -20 5 -23 40 -23 l40 0 0 180 0 180 -155 0 c-148 0 -155 -1 -155 -20z"/>
                            <path d="M50 220 l0 -180 155 0 155 0 0 50 0 50 -41 0 c-36 0 -40 -2 -29 -15 23 -28 0 -29 -28 -2 l-27 27 27 27 c28 27 51 26 28 -2 -11 -13 -7 -15 29 -15 l41 0 0 120 0 120 -155 0 -155 0 0 -180z m250 110 c0 -6 -38 -10 -95 -10 -57 0 -95 4 -95 10 0 6 38 10 95 10 57 0 95 -4 95 -10z m0 -60 c0 -6 -38 -10 -95 -10 -57 0 -95 4 -95 10 0 6 38 10 95 10 57 0 95 -4 95 -10z m-70 -60 c0 -6 -27 -10 -60 -10 -33 0 -60 4 -60 10 0 6 27 10 60 10 33 0 60 -4 60 -10z"/>
                          </g>
                        </svg>
                      </span>
                    </Link>
                  </>
                  :
                  <Link to={"/app/sale/invoice/create/reference/" + invoice.id} className="btn btn-primary mb-1">
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="black"/>
                          <rect x="3" y="6" width="14" height="16" rx="3" fill="black"/>
                        </svg>
                      </span>
                      {lang.labels.makeCopy}
                    </div>
                  </Link>
                }
              </div>
            </div>
            <AppSaleViewDetail sale={"invoice"} record={invoice}></AppSaleViewDetail>
          </div>
          <AppSaleViewRequirements record={invoice}></AppSaleViewRequirements>
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h4>{lang.labels.payments}</h4>
              </div>
              { invoice.status !== "completed" && invoice.payment_status !== "fully paid" &&
                <div className="card-toolbar">
                  { invoice.validated &&
                    <button className="btn btn-sm btn-primary mb-1 me-2" type="button" onClick={executeShowModalPaymentCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="black"/>
                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="black"/>
                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.makePayment}
                      </div>
                    </button>
                  }
                  { invoice.validated && invoice.payments.length > 0 &&
                    <button className="btn btn-sm btn-primary mb-1" type="button" onClick={excuteViewOrHideButtonsToPaymentDelete}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M8.38 22H21C21.2652 22 21.5196 21.8947 21.7071 21.7072C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 20 21 20H10L8.38 22Z" fill="black"/>
                            <path d="M15.622 15.6219L9.855 21.3879C9.66117 21.582 9.43101 21.7359 9.17766 21.8409C8.92431 21.946 8.65275 22 8.37849 22C8.10424 22 7.83268 21.946 7.57933 21.8409C7.32598 21.7359 7.09582 21.582 6.90199 21.3879L2.612 17.098C2.41797 16.9042 2.26404 16.674 2.15903 16.4207C2.05401 16.1673 1.99997 15.8957 1.99997 15.6215C1.99997 15.3472 2.05401 15.0757 2.15903 14.8224C2.26404 14.569 2.41797 14.3388 2.612 14.145L8.37801 8.37805L15.622 15.6219Z" fill="black"/>
                            <path opacity="0.3" d="M8.37801 8.37805L14.145 2.61206C14.3388 2.41803 14.569 2.26408 14.8223 2.15906C15.0757 2.05404 15.3473 2 15.6215 2C15.8958 2 16.1673 2.05404 16.4207 2.15906C16.674 2.26408 16.9042 2.41803 17.098 2.61206L21.388 6.90198C21.582 7.0958 21.736 7.326 21.841 7.57935C21.946 7.83269 22 8.10429 22 8.37854C22 8.65279 21.946 8.92426 21.841 9.17761C21.736 9.43096 21.582 9.66116 21.388 9.85498L15.622 15.6219L8.37801 8.37805Z" fill="black"/>
                          </svg>
                        </span>
                        {removePayment ? lang.labels.doNotDeletePayments : lang.labels.deleteSomePayment}
                      </div>
                    </button>
                  }
                </div>
              }
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-2 mb-1">
                    <thead className="border-bottom">
                      <tr className="text-muted text-uppercase fw-bolder fs-7">
                        <th className="w-25px text-nowrap text-start">{lang.labels.n}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.createdDate}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.method}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.sourceOfIncome}</th>
                        <th className="min-w-100px text-nowrap text-end">{lang.labels.amount} ({invoice.currency.symbol})</th>
                        { invoice.validated && removePayment &&
                          <th className="w-75px text-nowrap text-end">{lang.labels.remove}</th>
                        }
                        </tr>
                    </thead>
                    { invoice.payments.length > 0
                      ?
                      <tbody className="text-gray-700 fw-bold">
                        { invoice.payments.map (( (item, index) => { return (
                          <tr key={index} className="row-dashed fw-bold">
                            <td>{index + 1}</td>
                            <td>{dateFormatAbbreviated(item.created_date)}</td>
                            <td>{paymentMethodValue(lang, item.method) }</td>
                            <td>{treasuryAccountSelected(lang, treasuryAccountsForCompany, item.source)}</td>
                            <td className="text-nowrap text-end">{moneyFormat(item.amount)}</td>
                            { invoice.validated && removePayment &&
                              <td valign="middle" className="text-end p-0">
                                <button className="btn btn-sm btn-icon btn-flex btn-bg-transparent btn-active-color-danger h-30px w-30px" type="button" onClick={() => submitPaymentDelete(item.id)}>
                                  <span className="svg-icon svg-icon-primary svg-icon-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                      <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                      <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                    </svg>
                                  </span>
                                </button>
                              </td>
                              }
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody>
                        <tr className="text-gray-700 fw-bold">
                          <td valign="top" colSpan={5} className="text-center">{lang.labels.thisInvoiceHasNoPayments}</td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
          { invoice.reminders.length > 0 &&
            <div className="card card-flush mb-5">
              <div className="card-header">
                <div className="card-title">
                  <h4>{lang.labels.reminders}</h4>
                </div>
                { invoice.status !== "completed" && invoice.payment_status !== "fully paid" &&
                  <div className="card-toolbar">
                    { invoice.validated &&
                      <button className="btn btn-sm btn-primary mb-1 me-2" type="button" onClick={executeShowModalInvoiceCreateReminder}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M14 3V20H2V3C2 2.4 2.4 2 3 2H13C13.6 2 14 2.4 14 3ZM11 13V11C11 9.7 10.2 8.59995 9 8.19995V7C9 6.4 8.6 6 8 6C7.4 6 7 6.4 7 7V8.19995C5.8 8.59995 5 9.7 5 11V13C5 13.6 4.6 14 4 14V15C4 15.6 4.4 16 5 16H11C11.6 16 12 15.6 12 15V14C11.4 14 11 13.6 11 13Z" fill="black"/>
                              <path d="M2 20H14V21C14 21.6 13.6 22 13 22H3C2.4 22 2 21.6 2 21V20ZM9 3V2H7V3C7 3.6 7.4 4 8 4C8.6 4 9 3.6 9 3ZM6.5 16C6.5 16.8 7.2 17.5 8 17.5C8.8 17.5 9.5 16.8 9.5 16H6.5ZM21.7 12C21.7 11.4 21.3 11 20.7 11H17.6C17 11 16.6 11.4 16.6 12C16.6 12.6 17 13 17.6 13H20.7C21.2 13 21.7 12.6 21.7 12ZM17 8C16.6 8 16.2 7.80002 16.1 7.40002C15.9 6.90002 16.1 6.29998 16.6 6.09998L19.1 5C19.6 4.8 20.2 5 20.4 5.5C20.6 6 20.4 6.60005 19.9 6.80005L17.4 7.90002C17.3 8.00002 17.1 8 17 8ZM19.5 19.1C19.4 19.1 19.2 19.1 19.1 19L16.6 17.9C16.1 17.7 15.9 17.1 16.1 16.6C16.3 16.1 16.9 15.9 17.4 16.1L19.9 17.2C20.4 17.4 20.6 18 20.4 18.5C20.2 18.9 19.9 19.1 19.5 19.1Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.createReminderFee}
                        </div>
                      </button>
                    }
                  </div>
                }
              </div>
              <div className="card-body pt-0">
                <div className="dataTables_wrapper">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed gy-2 mb-1">
                      <thead className="border-bottom">
                        <tr className="text-muted text-uppercase fw-bolder fs-7">
                          <th className="w-25px text-nowrap text-start">{lang.labels.n}</th>
                          <th className="min-w-100px text-nowrap text-start">{lang.labels.createdAt}</th>
                          <th className="min-w-300px text-nowrap text-start">{lang.labels.description}</th>
                          <th className="min-w-100px text-nowrap text-end">{lang.labels.amount} ({invoice.currency.symbol})</th>
                          { invoice.status !== "completed" && invoice.payment_status !== "fully paid" && invoice.validated &&
                            <th className="w-75px text-nowrap text-end">{lang.labels.actions}</th>
                          }
                          </tr>
                      </thead>
                      <tbody className="text-gray-700 fw-bold">
                        { invoice.reminders.map (( (item, index) => { return (
                          <tr key={index} className="row-dashed fw-bold">
                            <td>{index + 1}</td>
                            <td>{dateFormatAbbreviated(item.created_at)}</td>
                            <td className="text-justify">{item.description}</td>
                            <td className="text-nowrap text-end">{moneyFormat(item.amount)}</td>
                            { invoice.status !== "completed" && invoice.payment_status !== "fully paid" && invoice.validated &&
                              <td valign="middle" className="text-end p-0">
                                <button className="btn btn-sm btn-icon btn-flex btn-bg-transparent btn-active-color-info h-30px w-30px" type="button" onClick={() => executeShowModalInvoiceUpdateReminder(item)}>
                                  <span className="svg-icon svg-icon-primary svg-icon-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
                                      <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
                                    </svg>
                                  </span>
                                </button>
                                <button className="btn btn-sm btn-icon btn-flex btn-bg-transparent btn-active-color-danger h-30px w-30px" type="button" onClick={() => submitReminderDelete(item.created_at)}>
                                  <span className="svg-icon svg-icon-primary svg-icon-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                      <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                      <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                    </svg>
                                  </span>
                                </button>
                              </td>
                            }
                          </tr>
                        )}))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
          <AppSaleViewAmount sale={"invoice"} record={invoice}></AppSaleViewAmount>
          <AppSaleViewSignatures record={invoice}></AppSaleViewSignatures>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalInvoiceCreateReminder invoice={invoice} setInvoice={setInvoice}></AppModalInvoiceCreateReminder>
      <AppModalInvoiceUpdateReminder invoice={invoice} reminder={reminder} setInvoice={setInvoice} setReminder={setReminder}></AppModalInvoiceUpdateReminder>
      <AppModalPaymentCreate treasuryAccountsForCompany={treasuryAccountsForCompany} invoice={invoice} setInvoice={setInvoice}></AppModalPaymentCreate>
    </div>
  )
}

export default InvoiceViewPage;
