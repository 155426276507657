import { Language } from "../languages/labels/language.labels";

export function enumerationList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'first', description: lang.labels.first },
    { code: 'second', description: lang.labels.second },
    { code: 'third', description: lang.labels.third },
    { code: 'fourth', description: lang.labels.fourth },
    { code: 'fifth', description: lang.labels.fifth },
    { code: 'sixth', description: lang.labels.sixth }
  ]
}

export function enumerationValue(lang: Language, code: string): string {
  const enumeration = enumerationList(lang).find(item => item.code === code)
  return enumeration ? enumeration.description : lang.labels.undefined
}
