import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContextInventory } from '../Inventory';
import { AppService } from '../../../../models/app.service.model';
import { Company } from '../../../../models/company.model';
import { Product } from '../../../../models/product.model';
import { Service } from '../../../../models/service.model';

export interface CategoryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  productsForCompany: Product[] | undefined | null,
  servicesForCompany: Service[] | undefined | null,
  loadProductsForCompany: Function,
  loadServicesForCompany: Function
};

const CategoryPage: React.FunctionComponent<CategoryPageProps> = props => {
  const {setRoute, appServiceForUser, companyForUser, productsForCompany, servicesForCompany, loadProductsForCompany, loadServicesForCompany} = UseOutletContextInventory()

  return (
    <Outlet context={{setRoute, appServiceForUser, companyForUser, productsForCompany, servicesForCompany, loadProductsForCompany, loadServicesForCompany}}></Outlet>
  );
}

export function UseOutletContextCategory() {
  return useOutletContext<ContexType>()
};

export default CategoryPage;
