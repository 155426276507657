import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCompany } from '../Company';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { CompanyDL } from '../../../../services/company.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Currency } from '../../../../models/currency.model';
import { legendInvalidIcon, legendValid, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendInvalid } from '../../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { removeUploadedImage, uploadImage } from '../../../../scripts/upload.image.script';
import { evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequiredIcon } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { countryList, countryCode, countryName } from '../../../../libraries/countries.library';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { languageDownloadList, languageDownloadValue } from '../../../../libraries/language.download.library';
import { qrTemplateList, qrTemplateValue } from '../../../../libraries/qr.template.library';

import AppLegend from '../../../../components/element/Legend';

import imgDefault from '../../../../assets/images/default.png';

export interface AppCompanyUpdateGeneralProps {};

let errorResponse: Error, messageResponse: Message;

const AppCompanyUpdateGeneral: React.FunctionComponent<AppCompanyUpdateGeneralProps> = props => {
  const {appServiceForUser, companyForUser, reloadUserLoggedIn} = UseOutletContextCompany()
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: false})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: false})
  const [vatNumber, setVatNumber] = useState({value: '', valid: false})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [qrType, setQrTtype] = useState({value: '', valid: false})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitCompanyUpdate = async () => {
    setLoadIndicator('on')

    if (appServiceForUser && companyForUser && name.valid && logo.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && email.valid && phone.valid && website.valid && vatNumber.valid && registerNumber.valid && currency.valid && language.valid && qrType.valid) {
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value

      await CompanyDL.putCompany(appServiceForUser.api_key, companyForUser.id, name.value, logo.file, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, currency.object, language.value, qrType.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          reloadUserLoggedIn(null, null, null, () => {
            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              restartForm()
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'container-validate-name-required')
        }
        if (street.value.length === 0) {
          legendInvalidIcon('input-street', 'container-validate-street-required')
        }
        if (streetNumber.value.length === 0) {
          legendInvalidIcon('input-street-number', 'container-validate-street-number-required')
        }
        if (administrativeArea.value.length === 0) {
          legendInvalidIcon('input-administrative-area', 'container-validate-administrative-area-required')
        }
        if (city.value.length === 0) {
          legendInvalidIcon('input-city', 'container-validate-city-required')
        }
        if (zipCode.value.length === 0) {
          legendInvalidIcon('input-zip-code', 'container-validate-zip-code-required')
        }
        if (country.value.length === 0) {
          legendInvalid('container-validate-country-required')
        }
        if (email.value.length === 0) {
          legendInvalidIcon('input-email', 'container-validate-email-required')
        }
        if (phone.value.length === 0) {
          legendInvalidIcon('input-phone', 'container-validate-phone-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('container-validate-currency-required')
        }
        if (language.value.length === 0) {
          legendInvalid('container-validate-language-required')
        }
        if (qrType.value.length === 0) {
          legendInvalid('container-validate-qr-type-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], logo, setLogo, 'container-validate-logo-type', 'container-validate-logo-size')
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid('container-validate-country-required')
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeVatNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVatNumber({...vatNumber, value: event.target.value})
  }

  const handleChangeRegisterNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setRegisterNumber({...registerNumber, value: event.target.value})
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('container-validate-currency-required')
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
    legendValid('container-validate-language-required')
  }

  const handleChangeQrType = (item: string) => {
    setQrTtype({...qrType, value: item, valid: true})
    legendValid('container-validate-qr-type-required')
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({text: text, list: list})
  }

  const handleRemoveLogo = () => {
    removeUploadedImage(logo, setLogo, 'container-validate-logo-type', 'container-validate-logo-size')
    removeTooltip()
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name', 'container-validate-name-valid', 'container-validate-name-required')
  }

  const validateStreet = () => {
    evaluateLegendValidateRequiredIcon(expressions.street, street, setStreet, 'input-street', 'container-validate-street-valid', 'container-validate-street-required')
  }

  const validateNumber = () => {
    if (streetNumber.value === 'NN' || streetNumber.value === 'nn') {
      setStreetNumber({...streetNumber, valid: true})
      legendValidRequiredIcon('input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    } else {
      evaluateLegendValidateRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    }
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, 'input-additional', 'container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, 'input-administrative-area', 'container-validate-administrative-area-valid', 'container-validate-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, city, setCity, 'input-city', 'container-validate-city-valid', 'container-validate-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidateRequiredIcon(expressions.zipcode, zipCode, setZipCode, 'input-zip-code', 'container-validate-zip-code-valid', 'container-validate-zip-code-required')
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
  }

  const validatePhone = () => {
    evaluateLegendValidateRequiredIcon(expressions.phone, phone, setPhone, 'input-phone', 'container-validate-phone-valid', 'container-validate-phone-required')
  }

  const validateWebsite = () => {
    evaluateLegendValidateEmptyIcon(expressions.website, website, setWebsite, 'input-website', 'container-validate-website-valid')
  }

  const validateVatNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.vat, vatNumber, setVatNumber, 'input-vat-number', 'container-validate-vat-number-valid')
  }

  const validateRegisterNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.registernumber, registerNumber, setRegisterNumber, 'input-register-number', 'container-validate-register-number-valid')
  }

  const executeFormRestart = () => {
    uploadData()
    restartForm()
  }

  function restartForm() {
    legendValidInvalidIconRestart('input-name', 'container-validate-name-valid')
    legendValidInvalidIconRestart('input-name', 'container-validate-name-required')
    legendValidInvalidRestart('container-validate-logo-type')
    legendValidInvalidRestart('container-validate-logo-size')
    legendValidInvalidIconRestart('input-street', 'container-validate-street-valid')
    legendValidInvalidIconRestart('input-street', 'container-validate-street-required')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-valid')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-required')
    legendValidInvalidIconRestart('input-additional', 'container-validate-additional-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-required')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-valid')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-required')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-valid')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-required')
    legendValidInvalidRestart('container-validate-country-required')
    legendValidInvalidIconRestart('input-email', 'container-validate-email-valid')
    legendValidInvalidIconRestart('input-email', 'container-validate-email-required')
    legendValidInvalidIconRestart('input-phone', 'container-validate-phone-valid')
    legendValidInvalidIconRestart('input-phone', 'container-validate-phone-required')
    legendValidInvalidIconRestart('input-website', 'container-validate-website-valid')
    legendValidInvalidIconRestart('input-vat-number', 'container-validate-vat-number-valid')
    legendValidInvalidIconRestart('input-register-number', 'container-validate-register-number-valid')
    legendValidInvalidRestart('container-validate-currency-required')
    legendValidInvalidRestart('container-validate-language-required')
    legendValidInvalidRestart('container-validate-qr-type-required')
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  function uploadData() {
    if (companyForUser) {
      setName({value: companyForUser.name, valid: true})
      setLogo({value: companyForUser.logo, file: companyForUser.logo, valid: true})
      setStreet({value: companyForUser.address.street, valid: true})
      setStreetNumber({value: companyForUser.address.number, valid: true})
      setAdditional({value: companyForUser.address.additional, valid: true})
      setAdministrativeArea({value: companyForUser.address.administrative_area, valid: true})
      setCity({value: companyForUser.address.city, valid: true})
      setZipCode({value: companyForUser.address.zip_code, valid: true})
      setCountry({value: companyForUser.address.country, valid: true})
      setEmail({value: companyForUser.email, valid: true})
      setPhone({value: companyForUser.phone, valid: true})
      setWebsite({value: companyForUser.website, valid: true})
      setVatNumber({value: companyForUser.vat_number, valid: true})
      setRegisterNumber({value: companyForUser.register_number, valid: true})
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})
      setLanguage({value: companyForUser.language, valid: true})
      setQrTtype({value: companyForUser.qr_type, valid: true})
    }
  }

  useEffect( () => {
    uploadData()
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title fs-4 fw-bolder">{lang.labels.companyGeneralSettings}</div>
      </div>
      { companyForUser
        ?
        <div className="form">
          <div className="card-body">
            <div className="row mb-3 mb-xl-5">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.name}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                <AppLegend component={null} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3 mb-xl-5">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.logo}</div>
              </div>
              <div className="col-lg-8">
                <div className="image-input image-input-outline" data-kt-image-input="true">
                  <img src={logo.valid && logo.value.length > 0 ? logo.value : imgDefault} alt={lang.labels.logo} className="image-input-wrapper w-auto h-125px bgi-position-center" />
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.changeImage} onClick={removeTooltip}>
                    <i className="bi bi-pencil-fill fs-8"></i>
                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                  </label>
                  <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow ${(!logo.valid || logo.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
                <div className="form-text text-justify">{lang.labels.allowedFileTypes}</div>
                <AppLegend component={null} attribute={{validity: logo.valid, name: "logo", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.street}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input  id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                <AppLegend component={null} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.number}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-street-number" className="form-control form-control-solid" type="text" name="street-number" value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
                <AppLegend component={null} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    {lang.labels.additional}
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-additional" className="form-control form-control-solid" type="text" name="additional" value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                <AppLegend component={null} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.administrativeArea}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                <AppLegend component={null} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.zipCode}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                <AppLegend component={null} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.city}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                <AppLegend component={null} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.country}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {country.valid ? countryName(country.value, lang.code) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                      </span>
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { searchCountry.list.length > 0
                            ?
                            <>
                              { searchCountry.list.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                                </li>
                              )}))}
                            </>
                            :
                            <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                          }
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.email}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                <AppLegend component={null} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.phoneNumber}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                <AppLegend component={null} attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.webSite}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                <AppLegend component={null} attribute={{validity: website.valid, name: "website", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    {lang.labels.vatNumber}
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.identificationNumberForACompany}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-vat-number" className="form-control form-control-solid" type="text" name="vat-number" value={vatNumber.value} onChange={handleChangeVatNumber} onKeyUp={validateVatNumber} onBlur={validateVatNumber} />
                <AppLegend component={null} attribute={{validity: vatNumber.valid, name: "vat-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3 d-none">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.registerNumber}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-register-number" className="form-control form-control-solid" type="text" name="register-number" value={registerNumber.value} onChange={handleChangeRegisterNumber} onKeyUp={validateRegisterNumber} onBlur={validateRegisterNumber} />
                <AppLegend component={null} attribute={{validity: registerNumber.valid, name: "register-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.currency}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? currencyValue(currency.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList().map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.language}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {language.valid ? languageDownloadValue(lang, language.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { languageDownloadList(lang).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.description}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.qrType}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {qrType.valid ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { qrTemplateList(lang).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.description}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: qrType.valid, name: "qr-type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-light btn-active-light-primary" type="reset" onClick={executeFormRestart}>{lang.labels.discard}</button>
            <button className="btn btn-primary ms-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCompanyUpdate}>
              <span className="indicator-label">
                {lang.labels.saveChanges}
              </span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <div className="d-flex justify-content-center align-items-center w-100 h-200px">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{lang.labels.loading}</span>
          </div>
        </div>
      }
    </div>
  )
};

export default AppCompanyUpdateGeneral;
