import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from '../../models/company.model';

export interface AppChartBarInvoicesProps {
  id: string,
  companyForUser: Company,
  info: {invoice: string, amount_total: number, amount_paid: number}[]
};

const AppChartBarInvoices: React.FunctionComponent<AppChartBarInvoicesProps> = ({id, companyForUser, info}) => {
  const {lang} = StateLanguage()

  useEffect( () => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "none",
      layout: root.verticalLayout
    }));

    chart.set("scrollbarY", am5.Scrollbar.new(root, {
      orientation: "vertical",
      marginLeft: 30
    }));

    let data = info.map((item) => { return {
      "invoice": item.invoice,
      "paid": item.amount_paid,
      "total": item.amount_total
    }})

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 0
    });

    yRenderer.grid.template.set("visible", false);
    yRenderer.grid.template.set("location", 1);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "invoice",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yAxis.data.setAll(data);

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("strokeOpacity", 0.1);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: xRenderer
      })
    );

    let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: lang.labels.amountTotal,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "total",
      categoryYField: "invoice",
      clustered: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `[bold]{name}[/]\n{categoryY}: ${companyForUser.currency.symbol}{valueX}`
      })
    }));

    series1.columns.template.setAll({
      height: am5.percent(80),
      tooltipX: 0,
      strokeOpacity: 0
    });

    let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: lang.labels.amountPaid,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "paid",
      categoryYField: "invoice",
      clustered: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `[bold]{name}[/]\n{categoryY}: ${companyForUser.currency.symbol}{valueX}`
      })
    }));

    series2.columns.template.setAll({
      height: am5.percent(70),
      tooltipX: 0,
      strokeOpacity: 0
    });

    let legend = chart.children.push(am5.Legend.new(root, {
      marginTop: 10,
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));

    cursor.lineX.set("visible", false);

    series1.data.setAll(data);
    series2.data.setAll(data);

    series1.appear();
    series2.appear();

    chart.appear(100, 10);

    return () => { root.dispose() }
  }, [id, companyForUser, info, lang])

  return (
    <div id={id} className="w-100 h-700px"></div> 
  )
};

export default AppChartBarInvoices;
