import { Language } from "../languages/labels/language.labels"

export function colorTemplateDescription(lang: Language, index: number): string {
  switch (index) {
    case 0:
      return lang.labels.lettersTitlesSubtitles
    case 1:
      return lang.labels.firstBar
    case 2:
      return lang.labels.secondBar
    case 3:
      return lang.labels.barLetters
    default:
      return lang.labels.other
  }
}
