import { Language } from "../languages/labels/language.labels";

export function categoryTypeList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'product', description: lang.labels.product },
    { code: 'service', description: lang.labels.service }
  ]
}

export function categoryTypeValue(lang: Language, code: string): string {
  const categoryType = categoryTypeList(lang).find(item => item.code === code)
  return categoryType ? categoryType.description : lang.labels.undefined
}
