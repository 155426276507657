import { Dispatch, SetStateAction } from "react"
import { legendInvalidRequired, legendValidInvalidRequiredRestart, legendValidRequired } from "../tools/legend.data.entry.tool"
import { extractBase64 } from "./extract.base.64.script"

function uploadImage(archive: any, subject: any, setSubject: Dispatch<SetStateAction<any>>, container_type: string, container_size: string) {
  let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
  let archiveSize: number = 1048576

  if (!(archiveType === undefined || (archiveType && archiveType.includes(archive.type)))) {
    setSubject({...subject, value: '', file: null, valid: false})
    legendInvalidRequired(container_type, container_size)
  } else if (!((archiveSize !== 0 && archive.size <= archiveSize) || archiveSize === 0)) {
    setSubject({...subject, value: '', file: null, valid: false})
    legendInvalidRequired(container_size, container_type)
  } else {
    extractBase64(archive).then( (image: any) => {
      setSubject({...subject, value: image.base, file: archive, valid: true})
      legendValidRequired(container_type, container_size)
    })
  }
}

function removeUploadedImage(subject: any, setSubject: Dispatch<SetStateAction<any>>, container_type: string, container_size: string) {
  setSubject({...subject, value: '', file: null, valid: true})
  legendValidInvalidRequiredRestart(container_type, container_size)
}

export { uploadImage, removeUploadedImage }
