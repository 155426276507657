import { Modal } from 'bootstrap'

function modalShow(identifier: string) {
  let element = document.getElementById(identifier)
  let modal = Modal.getOrCreateInstance(element!!)

  modal.show()
}

function modalHide(identifier: string) {
  let element = document.getElementById(identifier)
  let modal = Modal.getOrCreateInstance(element!!)

  modal.hide()
}

export { modalShow, modalHide }
