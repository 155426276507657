import { Language } from "../languages/labels/language.labels";

export function expirationList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'expired', description: lang.labels.expired },
    { code: 'last day', description: lang.labels.lastDay },
    { code: 'in time', description: lang.labels.inTime }
  ]
}

export function expirationValue(lang: Language, code: string): string {
  const expiration = expirationList(lang).find(item => item.code === code)
  return expiration ? expiration.description : lang.labels.undefined
}
