function firstForm(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('between')
  element?.classList.add('first')
}

function lastForm(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('between')
  element?.classList.add('last')
}

function removeFirstForm(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('first')
  element?.classList.add('between')
}

function removeLastForm(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('last')
  element?.classList.add('between')
}

function nextForm(title_1: string, title_2: string, container_1: string, container_2: string) {
  let element1 = document.getElementById(title_1)
  let element2 = document.getElementById(title_2)
  let element3 = document.getElementById(container_1)
  let element4 = document.getElementById(container_2)

  element1?.classList.remove('current')
  element2?.classList.remove('pending')
  element3?.classList.remove('current')
  element4?.classList.remove('pending')

  element1?.classList.add('completed')
  element2?.classList.add('current')
  element3?.classList.add('completed')
  element4?.classList.add('current')
}

function previousForm(title_1: string, title_2: string, container_1: string, container_2: string) {
  let element1 = document.getElementById(title_1)
  let element2 = document.getElementById(title_2)
  let element3 = document.getElementById(container_1)
  let element4 = document.getElementById(container_2)

  element1?.classList.remove('completed')
  element2?.classList.remove('current')
  element3?.classList.remove('completed')
  element4?.classList.remove('current')

  element1?.classList.add('current')
  element2?.classList.add('pending')
  element3?.classList.add('current')
  element4?.classList.add('pending')
}

function restartForm(identifier: string, list_title: string[], list_container: string[]) {
  let element = document.getElementById(identifier)

  element?.classList.remove('first')
  element?.classList.remove('last')
  element?.classList.remove('between')

  if (list_title.length === list_container.length) {
    for(let i = 0; i < list_title.length; i++) {
      let element = document.getElementById(list_title[i])

      if (i === 0) {
        element?.classList.remove('completed')
        element?.classList.add('current')
      } else {
        element?.classList.remove('completed')
        element?.classList.remove('current')
      }
    }

    for(let i = 0; i < list_container.length; i++) {
      let element = document.getElementById(list_container[i])

      if (i === 0) {
        element?.classList.remove('pending')
        element?.classList.add('current')
      } else {
        element?.classList.remove('pending')
        element?.classList.remove('current')
      }
    }
  }
}

export { firstForm, lastForm, removeFirstForm, removeLastForm, nextForm, previousForm, restartForm }
