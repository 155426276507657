import styled, { css } from 'styled-components';

const Legend = styled.div`
  visibility: hidden;

  ${props => props.property === "true" && css`
    visibility: hidden;
  `}

  ${props => props.property === "false" && css`
    visibility: visible;
  `}
`;

export { Legend }
