type Country = { code: string; en: string; es: string; fr: string };

export const countries: Country[] = [
  { code: "AD", en: "Andorra", es: "Andorra", fr: "Andorra" },
  { code: "AE", en: "United Arab Emirates", es: "Emiratos Árabes Unidos", fr: "United Arab Emirates" },
  { code: "AF", en: "Afghanistan ", es: "Afganistán", fr: "Afghanistan " },
  { code: "AG", en: "Antigua and Barbuda", es: "Antigua y Barbuda", fr: "Antigua and Barbuda" },
  { code: "AI", en: "Anguilla", es: "Anguila", fr: "Anguilla" },
  { code: "AL", en: "Albania", es: "Albania", fr: "Albania" },
  { code: "AM", en: "Armenia", es: "Armenia", fr: "Armenia" },
  { code: "AO", en: "Angola", es: "Angola", fr: "Angola" },
  { code: "AQ", en: "Antarctica", es: "Antártida", fr: "Antarctica" },
  { code: "AR", en: "Argentina", es: "Argentina", fr: "Argentina" },
  { code: "AS", en: "American Samoa", es: "Samoa Americana", fr: "American Samoa" },
  { code: "AT", en: "Austria", es: "Austria", fr: "Austria" },
  { code: "AU", en: "Australia", es: "Australia", fr: "Australia" },
  { code: "AW", en: "Aruba", es: "Aruba", fr: "Aruba" },
  { code: "AX", en: "Åland Islands", es: "Islas Aland", fr: "Åland Islands" },
  { code: "AZ", en: "Azerbaijan", es: "Azerbaiyán", fr: "Azerbaijan" },
  { code: "BA", en: "Bosnia and Herzegovina", es: "Bosnia y Herzegovina", fr: "Bosnia and Herzegovina" },
  { code: "BB", en: "Barbados", es: "Barbados", fr: "Barbados" },
  { code: "BD", en: "Bangladesh", es: "Bangladesh", fr: "Bangladesh" },
  { code: "BE", en: "Belgium", es: "Bélgica", fr: "Belgium" },
  { code: "BF", en: "Burkina Faso", es: "Burkina Faso", fr: "Burkina Faso" },
  { code: "BG", en: "Bulgaria", es: "Bulgaria", fr: "Bulgaria" },
  { code: "BH", en: "Bahrain", es: "Bahrein", fr: "Bahrain" },
  { code: "BI", en: "Burundi", es: "Burundi", fr: "Burundi" },
  { code: "BJ", en: "Benin", es: "Benín", fr: "Benin" },
  { code: "BL", en: "Saint Barthélemy", es: "San Bartolomé", fr: "Saint Barthélemy" },
  { code: "BM", en: "Bermuda", es: "Islas Bermudas", fr: "Bermuda" },
  { code: "BN", en: "Brunei Darussalam", es: "Brunei Darussalam", fr: "Brunei Darussalam" },
  { code: "BO", en: "Bolivia, Plurinational State of", es: "Bolivia, Estado Plurinacional de", fr: "Bolivia, Plurinational State of" },
  { code: "BQ", en: "Bonaire, Sint Eustatius and Saba", es: "Bonaire, San Eustaquio y Saba", fr: "Bonaire, Sint Eustatius and Saba" },
  { code: "BR", en: "Brazil", es: "Brasil", fr: "Brazil" },
  { code: "BS", en: "Bahamas", es: "Bahamas", fr: "Bahamas" },
  { code: "BT", en: "Bhutan", es: "Bután", fr: "Bhutan" },
  { code: "BV", en: "Bouvet Island", es: "Isla Bouvet", fr: "Bouvet Island" },
  { code: "BW", en: "Botswana", es: "Botsuana", fr: "Botswana" },
  { code: "BY", en: "Belarus", es: "Bielorrusia", fr: "Belarus" },
  { code: "BZ", en: "Belize", es: "Belice", fr: "Belize" },
  { code: "CA", en: "Canada", es: "Canadá", fr: "Canada" },
  { code: "CC", en: "Cocos (Keeling) Islands", es: "Islas Cocos (Keeling)", fr: "Cocos (Keeling) Islands" },
  { code: "CD", en: "Congo, Democratic Republic of the", es: "Congo, República Democrática del", fr: "Congo, Democratic Republic of the" },
  { code: "CF", en: "Central African Republic", es: "República Centroafricana", fr: "Central African Republic" },
  { code: "CG", en: "Congo", es: "Congo", fr: "Congo" },
  { code: "CH", en: "Switzerland", es: "Suiza", fr: "Switzerland" },
  { code: "CI", en: "Côte d'Ivoire", es: "Costa de Marfil", fr: "Côte d'Ivoire" },
  { code: "CK", en: "Cook Islands", es: "Islas Cook", fr: "Cook Islands" },
  { code: "CL", en: "Chile", es: "Chile", fr: "Chile" },
  { code: "CM", en: "Cameroon", es: "Camerún", fr: "Cameroon" },
  { code: "CN", en: "China", es: "Porcelana", fr: "China" },
  { code: "CO", en: "Colombia", es: "Colombia", fr: "Colombia" },
  { code: "CR", en: "Costa Rica", es: "Costa Rica", fr: "Costa Rica" },
  { code: "CU", en: "Cuba", es: "Cuba", fr: "Cuba" },
  { code: "CV", en: "Cabo Verde", es: "Cabo Verde", fr: "Cabo Verde" },
  { code: "CW", en: "Curaçao", es: "Curazao", fr: "Curaçao" },
  { code: "CX", en: "Christmas Island", es: "Isla de Navidad", fr: "Christmas Island" },
  { code: "CY", en: "Cyprus", es: "Chipre", fr: "Cyprus" },
  { code: "CZ", en: "Czechia", es: "Chequia", fr: "Czechia" },
  { code: "DE", en: "Germany", es: "Alemania", fr: "Germany" },
  { code: "DJ", en: "Djibouti", es: "Yibuti", fr: "Djibouti" },
  { code: "DK", en: "Denmark", es: "Dinamarca", fr: "Denmark" },
  { code: "DM", en: "Dominica", es: "Dominica", fr: "Dominica" },
  { code: "DO", en: "Dominican Republic", es: "República Dominicana", fr: "Dominican Republic" },
  { code: "DZ", en: "Algeria", es: "Argelia", fr: "Algeria" },
  { code: "EC", en: "Ecuador", es: "Ecuador", fr: "Ecuador" },
  { code: "EE", en: "Estonia", es: "Estonia", fr: "Estonia" },
  { code: "EG", en: "Egypt", es: "Egipto", fr: "Egypt" },
  { code: "EH", en: "Western Sahara", es: "Sahara Occidental", fr: "Western Sahara" },
  { code: "ER", en: "Eritrea", es: "Eritrea", fr: "Eritrea" },
  { code: "ES", en: "Spain", es: "España", fr: "Spain" },
  { code: "ET", en: "Ethiopia", es: "Etiopía", fr: "Ethiopia" },
  { code: "FI", en: "Finland", es: "Finlandia", fr: "Finland" },
  { code: "FJ", en: "Fiji", es: "Fiyi", fr: "Fiji" },
  { code: "FK", en: "Falkland Islands (Malvinas)", es: "Islas Malvinas (Falkland)", fr: "Falkland Islands (Malvinas)" },
  { code: "FM", en: "Micronesia, Federated States of", es: "Micronesia, Estados Federados de", fr: "Micronesia, Federated States of" },
  { code: "FO", en: "Faroe Islands", es: "Islas Faroe", fr: "Faroe Islands" },
  { code: "FR", en: "France", es: "Francia", fr: "France" },
  { code: "GA", en: "Gabon", es: "Gabón", fr: "Gabon" },
  { code: "GB", en: "United Kingdom of Great Britain and Northern Ireland", es: "Reino Unido de Gran Bretaña e Irlanda del Norte", fr: "United Kingdom of Great Britain and Northern Ireland" },
  { code: "GD", en: "Grenada", es: "Granada", fr: "Grenada" },
  { code: "GE", en: "Georgia", es: "Georgia", fr: "Georgia" },
  { code: "GF", en: "French Guiana", es: "Guayana Francesa", fr: "French Guiana" },
  { code: "GG", en: "Guernsey", es: "Guernesey", fr: "Guernsey" },
  { code: "GH", en: "Ghana", es: "Ghana", fr: "Ghana" },
  { code: "GI", en: "Gibraltar", es: "Gibraltar", fr: "Gibraltar" },
  { code: "GL", en: "Greenland", es: "Groenlandia", fr: "Greenland" },
  { code: "GM", en: "Gambia", es: "Gambia", fr: "Gambia" },
  { code: "GN", en: "Guinea", es: "Guinea", fr: "Guinea" },
  { code: "GP", en: "Guadeloupe", es: "Guadalupe", fr: "Guadeloupe" },
  { code: "GQ", en: "Equatorial Guinea", es: "Guinea Ecuatorial", fr: "Equatorial Guinea" },
  { code: "GR", en: "Greece", es: "Grecia", fr: "Greece" },
  { code: "GS", en: "South Georgia and the South Sandwich Islands", es: "Georgia del sur y las islas Sandwich del sur", fr: "South Georgia and the South Sandwich Islands" },
  { code: "GT", en: "Guatemala", es: "Guatemala", fr: "Guatemala" },
  { code: "GU", en: "Guam", es: "Guam", fr: "Guam" },
  { code: "GW", en: "Guinea-Bissau", es: "Guinea-Bisáu", fr: "Guinea-Bissau" },
  { code: "GY", en: "Guyana", es: "Guayana", fr: "Guyana" },
  { code: "HK", en: "Hong Kong", es: "Hong Kong", fr: "Hong Kong" },
  { code: "HM", en: "Heard Island and McDonald Islands", es: "Islas Heard y McDonald", fr: "Heard Island and McDonald Islands" },
  { code: "HN", en: "Honduras", es: "Honduras", fr: "Honduras" },
  { code: "HR", en: "Croatia", es: "Croacia", fr: "Croatia" },
  { code: "HT", en: "Haiti", es: "Haití", fr: "Haiti" },
  { code: "HU", en: "Hungary", es: "Hungría", fr: "Hungary" },
  { code: "ID", en: "Indonesia", es: "Indonesia", fr: "Indonesia" },
  { code: "IE", en: "Ireland", es: "Irlanda", fr: "Ireland" },
  { code: "IL", en: "Israel", es: "Israel", fr: "Israel" },
  { code: "IM", en: "Isle of Man", es: "Isla del hombre", fr: "Isle of Man" },
  { code: "IN", en: "India", es: "India", fr: "India" },
  { code: "IO", en: "British Indian Ocean Territory", es: "Territorio Británico del Océano Índico", fr: "British Indian Ocean Territory" },
  { code: "IQ", en: "Iraq", es: "Irak", fr: "Iraq" },
  { code: "IR", en: "Iran, Islamic Republic of", es: "Irán, República Islámica de", fr: "Iran, Islamic Republic of" },
  { code: "IS", en: "Iceland", es: "Islandia", fr: "Iceland" },
  { code: "IT", en: "Italy", es: "Italia", fr: "Italy" },
  { code: "JE", en: "Jersey", es: "Jersey", fr: "Jersey" },
  { code: "JM", en: "Jamaica", es: "Jamaica", fr: "Jamaica" },
  { code: "JO", en: "Jordan", es: "Jordán", fr: "Jordan" },
  { code: "JP", en: "Japan", es: "Japón", fr: "Japan" },
  { code: "KE", en: "Kenya", es: "Kenia", fr: "Kenya" },
  { code: "KG", en: "Kyrgyzstan", es: "Kirguistán", fr: "Kyrgyzstan" },
  { code: "KH", en: "Cambodia", es: "Camboya", fr: "Cambodia" },
  { code: "KI", en: "Kiribati", es: "Kiribatí", fr: "Kiribati" },
  { code: "KM", en: "Comoros", es: "Comoras", fr: "Comoros" },
  { code: "KN", en: "Saint Kitts and Nevis", es: "San Cristóbal y Nieves", fr: "Saint Kitts and Nevis" },
  { code: "KP", en: "Korea, Democratic People's Republic of", es: "República de Corea, Popular Democrática de", fr: "Korea, Democratic People's Republic of" },
  { code: "KR", en: "Korea, Republic of", es: "Corea, república de", fr: "Korea, Republic of" },
  { code: "KW", en: "Kuwait", es: "Kuwait", fr: "Kuwait" },
  { code: "KY", en: "Cayman Islands", es: "Islas Caimán", fr: "Cayman Islands" },
  { code: "KZ", en: "Kazakhstan", es: "Kazajstán", fr: "Kazakhstan" },
  { code: "LA", en: "Lao People's Democratic Republic", es: "República Democrática Popular Lao", fr: "Lao People's Democratic Republic" },
  { code: "LB", en: "Lebanon", es: "Líbano", fr: "Lebanon" },
  { code: "LC", en: "Saint Lucia", es: "Santa Lucía", fr: "Saint Lucia" },
  { code: "LI", en: "Liechtenstein", es: "Liechtenstein", fr: "Liechtenstein" },
  { code: "LK", en: "Sri Lanka", es: "Sri Lanka", fr: "Sri Lanka" },
  { code: "LR", en: "Liberia", es: "Liberia", fr: "Liberia" },
  { code: "LS", en: "Lesotho", es: "Lesoto", fr: "Lesotho" },
  { code: "LT", en: "Lithuania", es: "Lituania", fr: "Lithuania" },
  { code: "LU", en: "Luxembourg", es: "Luxemburgo", fr: "Luxembourg" },
  { code: "LV", en: "Latvia", es: "Letonia", fr: "Latvia" },
  { code: "LY", en: "Libya", es: "Libia", fr: "Libya" },
  { code: "MA", en: "Morocco", es: "Marruecos", fr: "Morocco" },
  { code: "MC", en: "Monaco", es: "Mónaco", fr: "Monaco" },
  { code: "MD", en: "Moldova, Republic of", es: "Moldavia, República de", fr: "Moldova, Republic of" },
  { code: "ME", en: "Montenegro", es: "Montenegro", fr: "Montenegro" },
  { code: "MF", en: "Saint Martin, (French part)", es: "San Martín, (parte francesa)", fr: "Saint Martin, (French part)" },
  { code: "MG", en: "Madagascar", es: "Madagascar", fr: "Madagascar" },
  { code: "MH", en: "Marshall Islands", es: "Islas Marshall", fr: "Marshall Islands" },
  { code: "MK", en: "North Macedonia", es: "Macedonia del Norte", fr: "North Macedonia" },
  { code: "ML", en: "Mali", es: "Malí", fr: "Mali" },
  { code: "MM", en: "Myanmar", es: "Birmania", fr: "Myanmar" },
  { code: "MN", en: "Mongolia", es: "Mongolia", fr: "Mongolia" },
  { code: "MO", en: "Macao", es: "Macao", fr: "Macao" },
  { code: "MP", en: "Northern Mariana Islands", es: "Islas Marianas del Norte", fr: "Northern Mariana Islands" },
  { code: "MQ", en: "Martinique", es: "Martinica", fr: "Martinique" },
  { code: "MR", en: "Mauritania", es: "Mauritania", fr: "Mauritania" },
  { code: "MS", en: "Montserrat", es: "Monserrat", fr: "Montserrat" },
  { code: "MT", en: "Malta", es: "Malta", fr: "Malta" },
  { code: "MU", en: "Mauritius", es: "Mauricio", fr: "Mauritius" },
  { code: "MV", en: "Maldives", es: "Maldivas", fr: "Maldives" },
  { code: "MW", en: "Malawi", es: "Malaui", fr: "Malawi" },
  { code: "MX", en: "Mexico", es: "México", fr: "Mexico" },
  { code: "MY", en: "Malaysia", es: "Malasia", fr: "Malaysia" },
  { code: "MZ", en: "Mozambique", es: "Mozambique", fr: "Mozambique" },
  { code: "NA", en: "Namibia", es: "Namibia", fr: "Namibia" },
  { code: "NC", en: "New Caledonia", es: "Nueva Caledonia", fr: "New Caledonia" },
  { code: "NE", en: "Niger", es: "Níger", fr: "Niger" },
  { code: "NF", en: "Norfolk Island", es: "Isla Norfolk", fr: "Norfolk Island" },
  { code: "NG", en: "Nigeria", es: "Nigeria", fr: "Nigeria" },
  { code: "NI", en: "Nicaragua", es: "Nicaragua", fr: "Nicaragua" },
  { code: "NL", en: "Netherlands", es: "Países Bajos", fr: "Netherlands" },
  { code: "NO", en: "Norway", es: "Noruega", fr: "Norway" },
  { code: "NP", en: "Nepal", es: "Nepal", fr: "Nepal" },
  { code: "NR", en: "Nauru", es: "Nauru", fr: "Nauru" },
  { code: "NU", en: "Niue", es: "Niue", fr: "Niue" },
  { code: "NZ", en: "New Zealand", es: "Nueva Zelanda", fr: "New Zealand" },
  { code: "OM", en: "Oman", es: "Omán", fr: "Oman" },
  { code: "PA", en: "Panama", es: "Panamá", fr: "Panama" },
  { code: "PE", en: "Peru", es: "Perú", fr: "Peru" },
  { code: "PF", en: "French Polynesia", es: "Polinesia francés", fr: "French Polynesia" },
  { code: "PG", en: "Papua New Guinea", es: "Papúa Nueva Guinea", fr: "Papua New Guinea" },
  { code: "PH", en: "Philippines", es: "Filipinas", fr: "Philippines" },
  { code: "PK", en: "Pakistan", es: "Pakistán", fr: "Pakistan" },
  { code: "PL", en: "Poland", es: "Polonia", fr: "Poland" },
  { code: "PM", en: "Saint Pierre and Miquelon", es: "San Pedro y Miquelón", fr: "Saint Pierre and Miquelon" },
  { code: "PN", en: "Pitcairn", es: "Pitcairn", fr: "Pitcairn" },
  { code: "PR", en: "Puerto Rico", es: "Puerto Rico", fr: "Puerto Rico" },
  { code: "PS", en: "Palestine, State of", es: "Palestina, Estado de", fr: "Palestine, State of" },
  { code: "PT", en: "Portugal", es: "Portugal", fr: "Portugal" },
  { code: "PW", en: "Palau", es: "Palaus", fr: "Palau" },
  { code: "PY", en: "Paraguay", es: "Paraguay", fr: "Paraguay" },
  { code: "QA", en: "Qatar", es: "Katar", fr: "Qatar" },
  { code: "RE", en: "Réunion", es: "Reunión", fr: "Réunion" },
  { code: "RO", en: "Romania", es: "Rumania", fr: "Romania" },
  { code: "RS", en: "Serbia", es: "Serbia", fr: "Serbia" },
  { code: "RU", en: "Russian Federation", es: "Federación Rusa", fr: "Russian Federation" },
  { code: "RW", en: "Rwanda", es: "Ruanda", fr: "Rwanda" },
  { code: "SA", en: "Saudi Arabia", es: "Arabia Saudita", fr: "Saudi Arabia" },
  { code: "SB", en: "Solomon Islands", es: "Islas Salomón", fr: "Solomon Islands" },
  { code: "SC", en: "Seychelles", es: "Seychelles", fr: "Seychelles" },
  { code: "SD", en: "Sudan", es: "Sudán", fr: "Sudan" },
  { code: "SE", en: "Sweden", es: "Suecia", fr: "Sweden" },
  { code: "SG", en: "Singapore", es: "Singapur", fr: "Singapore" },
  { code: "SH", en: "Saint Helena, Ascension and Tristan da Cunha", es: "Santa Elena, Ascensión y Tristan da Cunha", fr: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "SI", en: "Slovenia", es: "Eslovenia", fr: "Slovenia" },
  { code: "SJ", en: "Svalbard and Jan Mayen", es: "Svalbard y Jan Mayen", fr: "Svalbard and Jan Mayen" },
  { code: "SK", en: "Slovakia", es: "Eslovaquia", fr: "Slovakia" },
  { code: "SL", en: "Sierra Leone", es: "Sierra Leona", fr: "Sierra Leone" },
  { code: "SM", en: "San Marino", es: "San Marino", fr: "San Marino" },
  { code: "SN", en: "Senegal", es: "Senegal", fr: "Senegal" },
  { code: "SO", en: "Somalia", es: "Somalia", fr: "Somalia" },
  { code: "SR", en: "Suriname", es: "Surinam", fr: "Suriname" },
  { code: "SS", en: "South Sudan", es: "Sudán del Sur", fr: "South Sudan" },
  { code: "ST", en: "Sao Tome and Principe", es: "Santo Tomé y Príncipe", fr: "Sao Tome and Principe" },
  { code: "SV", en: "El Salvador", es: "El Salvador", fr: "El Salvador" },
  { code: "SX", en: "Sint Maarten, (Dutch part)", es: "Sint Maarten, (parte holandesa)", fr: "Sint Maarten, (Dutch part)" },
  { code: "SY", en: "Syrian Arab Republic", es: "República Árabe Siria", fr: "Syrian Arab Republic" },
  { code: "SZ", en: "Eswatini", es: "Esuatini", fr: "Eswatini" },
  { code: "TC", en: "Turks and Caicos Islands", es: "Islas Turcas y Caicos", fr: "Turks and Caicos Islands" },
  { code: "TD", en: "Chad", es: "Chad", fr: "Chad" },
  { code: "TF", en: "French Southern Territories", es: "Territorios Franceses del Sur", fr: "French Southern Territories" },
  { code: "TG", en: "Togo", es: "Ir", fr: "Togo" },
  { code: "TH", en: "Thailand", es: "Tailandia", fr: "Thailand" },
  { code: "TJ", en: "Tajikistan", es: "Tayikistán", fr: "Tajikistan" },
  { code: "TK", en: "Tokelau", es: "Tokelau", fr: "Tokelau" },
  { code: "TL", en: "Timor-Leste", es: "Timor Oriental", fr: "Timor-Leste" },
  { code: "TM", en: "Turkmenistan", es: "Turkmenistán", fr: "Turkmenistan" },
  { code: "TN", en: "Tunisia", es: "Túnez", fr: "Tunisia" },
  { code: "TO", en: "Tonga", es: "Tonga", fr: "Tonga" },
  { code: "TR", en: "Türkiye", es: "Turquía", fr: "Türkiye" },
  { code: "TT", en: "Trinidad and Tobago", es: "Trinidad y Tobago", fr: "Trinidad and Tobago" },
  { code: "TV", en: "Tuvalu", es: "Tuvalu", fr: "Tuvalu" },
  { code: "TW", en: "Taiwan, Province of China", es: "Taiwan, provincia de China", fr: "Taiwan, Province of China" },
  { code: "TZ", en: "Tanzania, United Republic of", es: "Tanzania, República Unida de", fr: "Tanzania, United Republic of" },
  { code: "UA", en: "Ukraine", es: "Ucrania", fr: "Ukraine" },
  { code: "UG", en: "Uganda", es: "Uganda", fr: "Uganda" },
  { code: "UM", en: "United States Minor Outlying Islands", es: "Islas menores alejadas de los Estados Unidos", fr: "United States Minor Outlying Islands" },
  { code: "US", en: "United States of America", es: "Estados Unidos de América", fr: "United States of America" },
  { code: "UY", en: "Uruguay", es: "Uruguay", fr: "Uruguay" },
  { code: "UZ", en: "Uzbekistan", es: "Uzbekistán", fr: "Uzbekistan" },
  { code: "VA", en: "Holy See", es: "Santa Sede", fr: "Holy See" },
  { code: "VC", en: "Saint Vincent and the Grenadines", es: "San Vicente y las Granadinas", fr: "Saint Vincent and the Grenadines" },
  { code: "VE", en: "Venezuela, Bolivarian Republic of", es: "Venezuela, República Bolivariana de", fr: "Venezuela, Bolivarian Republic of" },
  { code: "VG", en: "Virgin Islands, British", es: "Islas Vírgenes Británicas", fr: "Virgin Islands, British" },
  { code: "VI", en: "Virgin Islands, U.S.", es: "Islas Vírgenes, EE. UU.", fr: "Virgin Islands, U.S." },
  { code: "VN", en: "Viet Nam", es: "Vietnam", fr: "Viet Nam" },
  { code: "VU", en: "Vanuatu", es: "Vanuatu", fr: "Vanuatu" },
  { code: "WF", en: "Wallis and Futuna", es: "Wallis y Futuna", fr: "Wallis and Futuna" },
  { code: "WS", en: "Samoa", es: "Samoa", fr: "Samoa" },
  { code: "YE", en: "Yemen", es: "Yemen", fr: "Yemen" },
  { code: "YT", en: "Mayotte", es: "Mayotte", fr: "Mayotte" },
  { code: "ZA", en: "South Africa", es: "Sudáfrica", fr: "South Africa" },
  { code: "ZM", en: "Zambia", es: "Zambia", fr: "Zambia" },
  { code: "ZW", en: "Zimbabwe", es: "Zimbabue", fr: "Zimbabwe" }
]
