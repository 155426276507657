function legendIcon(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('is-invalid')
  element?.classList.add('is-valid')
}

function legendValid(container: string) {
  let element = document.getElementById(container)

  element?.classList.remove('d-block')
  element?.classList.add('d-none')
}

function legendInvalid(container: string) {
  let element = document.getElementById(container)

  element?.classList.remove('d-none')
  element?.classList.add('d-block')
}

function legendValidInvalidRestart(container: string) {
  let element = document.getElementById(container)

  element?.classList.remove('d-block')
  element?.classList.add('d-none')
}

function legendValidIcon(identifier: string, container: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container)

  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')

  element1?.classList.add('is-valid')
  element2?.classList.add('d-none')
}

function legendInvalidIcon(identifier: string, container: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container)

  element1?.classList.remove('is-valid')
  element2?.classList.remove('d-none')

  element1?.classList.add('is-invalid')
  element2?.classList.add('d-block')
}

function legendValidInvalidIconRestart(identifier: string, container: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container)

  element1?.classList.remove('is-valid')
  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')

  element2?.classList.add('d-none')
}

function legendValidRequired(container_1: string, container_2: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)

  element1?.classList.remove('d-block')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-none')
  element2?.classList.add('d-none')
}

function legendInvalidRequired(container_1: string, container_2: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)

  element1?.classList.remove('d-none')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-block')
  element2?.classList.add('d-none')
}

function legendValidInvalidRequiredRestart(container_1: string, container_2: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)

  element1?.classList.remove('d-block')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-none')
  element2?.classList.add('d-none')
}

function legendInvalidEmpty(attribute: String, container_1: string, container_2: string) {
  let element1 = document.getElementById(container_1)
  let element2 = document.getElementById(container_2)

  if (attribute.length > 0) {
    element1?.classList.remove('d-none')
    element2?.classList.remove('d-block')

    element1?.classList.add('d-block')
    element2?.classList.add('d-none')
  } else {
    element1?.classList.remove('d-block')
    element2?.classList.remove('d-none')

    element1?.classList.add('d-none')
    element2?.classList.add('d-block')
  }
}

function legendInvalidEmptyIcon(attribute: string, identifier: string, container_1: string, container_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)

  if (element1?.classList.contains('is-valid')) {
    element1?.classList.remove('is-valid')
    element1?.classList.add('is-invalid')
  } else {
    element1?.classList.add('is-invalid')
  }

  if (attribute.length > 0) {
    element2?.classList.remove('d-none')
    element3?.classList.remove('d-block')

    element2?.classList.add('d-block')
    element3?.classList.add('d-none')
  } else {
    element2?.classList.remove('d-block')
    element3?.classList.remove('d-none')

    element2?.classList.add('d-none')
    element3?.classList.add('d-block')
  }
}

function legendValidRequiredIcon(identifier: string, container_1: string, container_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)

  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')
  element3?.classList.remove('d-block')

  element1?.classList.add('is-valid')
  element2?.classList.add('d-none')
  element3?.classList.add('d-none')
}

function legendInvalidRequiredIcon(identifier: string, container_1: string, container_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)

  if (element1?.classList.contains('is-valid')) {
    element1?.classList.remove('is-valid')
    element1?.classList.add('is-invalid')
  } else {
    element1?.classList.add('is-invalid')
  }

  element2?.classList.remove('d-none')
  element3?.classList.remove('d-block')

  element2?.classList.add('d-block')
  element3?.classList.add('d-none')
}

function legendValidRequiredConfirmationIcon(identifier: string, container_1: string, container_2: string, container_3: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)
  let element4 = document.getElementById(container_3)

  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')
  element3?.classList.remove('d-block')
  element4?.classList.remove('d-block')

  element1?.classList.add('is-valid')
  element2?.classList.add('d-none')
  element3?.classList.add('d-none')
  element4?.classList.add('d-none')
}

function legendInvalidRequiredConfirmationIcon(identifier: string, container_1: string, container_2: string, container_3: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)
  let element4 = document.getElementById(container_3)

  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')
  element3?.classList.remove('d-block')
  element4?.classList.remove('d-block')

  element2?.classList.add('d-none')
  element3?.classList.add('d-none')
  element4?.classList.add('d-none')
}

function legendInvalidEmptyConfirmationIcon(expression: RegExp, attribute: string, attribute_confirmation: string, identifier: string, container_1: string, container_2: string, container_3: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(container_1)
  let element3 = document.getElementById(container_2)
  let element4 = document.getElementById(container_3)

  if (element1?.classList.contains('is-valid')) {
    element1?.classList.remove('is-valid')
    element1?.classList.add('is-invalid')
  } else {
    element1?.classList.add('is-invalid')
  }

  if (attribute_confirmation.length > 0) {
    element4?.classList.remove('d-block')
    element4?.classList.add('d-none')

    if (expression.test(attribute_confirmation)) {
      if (attribute_confirmation !== attribute) {
        element2?.classList.remove('d-none')
        element3?.classList.remove('d-block')

        element2?.classList.add('d-block')
        element3?.classList.add('d-none')
      }
    } else {
      element2?.classList.remove('d-block')
      element3?.classList.remove('d-none')

      element2?.classList.add('d-none')
      element3?.classList.add('d-block')
    }
  } else {
    element2?.classList.remove('d-block')
    element3?.classList.remove('d-block')
    element4?.classList.remove('d-none')

    element2?.classList.add('d-none')
    element3?.classList.add('d-none')
    element4?.classList.add('d-block')
  }
}

export { legendIcon, legendValid, legendInvalid, legendValidInvalidRestart, legendValidIcon, legendInvalidIcon, legendValidInvalidIconRestart, legendValidRequired, legendInvalidRequired, legendValidInvalidRequiredRestart, legendInvalidEmpty, legendInvalidEmptyIcon, legendValidRequiredIcon, legendInvalidRequiredIcon, legendValidRequiredConfirmationIcon, legendInvalidRequiredConfirmationIcon, legendInvalidEmptyConfirmationIcon }
