import { Language } from "../languages/labels/language.labels";

export function deadlinetypeList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'year', description: lang.labels.year },
    { code: 'month', description: lang.labels.month },
    { code: 'week', description: lang.labels.week },
    { code: 'day', description: lang.labels.day }
  ]
}

export function deadlinetypeValue(lang: Language, code: string): string {
  const deadlinetype = deadlinetypeList(lang).find(item => item.code === code)
  return deadlinetype ? deadlinetype.description : lang.labels.undefined
}
