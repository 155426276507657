import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/translations/response';
import { CategoryDA } from '../../../services/category.service';
import { AppService } from '../../../models/app.service.model';
import { CompanyCategories } from '../../../models/company.categories.model';
import { Error } from '../../../models/error.model';
import { Company }  from '../../../models/company.model';
import { Settings } from '../../../models/settings.model';
import { Category } from '../../../models/category.model';
import { Product } from '../../../models/product.model';
import { Service } from '../../../models/service.model';

export interface InventoryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  productsForCompany: Product[] | undefined | null,
  servicesForCompany: Service[] | undefined | null,
  categoriesForCompany: Category[] | undefined | null,
  setSettingsForCompany: Dispatch<SetStateAction<Settings | undefined | null>>,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  loadProductsForCompany: Function,
  loadServicesForCompany: Function
};

let errorResponse: Error, categoriesResponse: CompanyCategories;

const InventoryPage: React.FunctionComponent<InventoryPageProps> = props => {
  const {setRoute, appServiceForUser, companyForUser, settingsForCompany, productsForCompany, servicesForCompany, setSettingsForCompany, loadProductsForCompany, loadServicesForCompany} = UseOutletContext()
  const {lang} = StateLanguage()

  const [categoriesForCompany, setCategoriesForCompany] = useState<Category[] | undefined | null>(null)

  const loadCategories = async (id_company: string) => {
    await CategoryDA.getCategories(id_company, '', '', '', '').then( (response) => {
      if (response.status === 200) {
        categoriesResponse = response.data
        setCategoriesForCompany(categoriesResponse.categories)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (companyForUser) {
      setCategoriesForCompany(null)
      loadCategories(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <Outlet context={{setRoute, appServiceForUser, companyForUser, settingsForCompany, productsForCompany, servicesForCompany, categoriesForCompany, setSettingsForCompany, setCategoriesForCompany, loadProductsForCompany, loadServicesForCompany}}></Outlet>
  );
}

export function UseOutletContextInventory() {
  return useOutletContext<ContexType>()
};

export default InventoryPage;
