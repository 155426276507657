import { Category } from "../models/category.model";
import { Unit } from "../models/unit.model";

export function productUnits(measure_units: Unit[]): Unit[] {
  return measure_units.filter((item: Unit) => (item.type === 'product'))
}

export function serviceUnits(measure_units: Unit[]): Unit[] {
  return measure_units.filter((item: Unit) => (item.type === 'service'))
}

export function productCategories(categories: Category[]): Category[] {
  return categories.filter((item: Category) => (item.type === 'product'))
}

export function serviceCategories(categories: Category[]): Category[] {
  return categories.filter((item: Category) => (item.type === 'service'))
}

