import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Workbook  } from 'exceljs';
import FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { legendInvalid, legendValidInvalidRestart, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { countryName } from '../../libraries/countries.library';

import AppLegend from '../element/Legend';

import logotipo from '../../assets/images/svg/logo-official-dark.png';

export interface AppModalContactExportProps {
  companyForUser: Company | undefined | null,
  contacts: Contact[] | undefined | null
};

const AppModalContactExport: React.FunctionComponent<AppModalContactExportProps> = ({companyForUser, contacts}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [formatToExport, setFormatToExport] = useState({value: '', valid: false})

  const fileExcel = async (company: Company, tableTitle: string[][], tableData: any[][]) => {
    let workbook = new Workbook()
    let worksheet = workbook.addWorksheet('Contacts Data')

    worksheet.addRow([])
    worksheet.mergeCells(`A1:F1`)
    worksheet.getRow(1).height = 35

    let imageBuffer = await axios.get(logotipo, {responseType: 'arraybuffer'})

    let imageLogotipo = workbook.addImage({
      buffer: imageBuffer.data,
      extension: 'png'
    })

    worksheet.addImage(imageLogotipo, { tl: {col: 0.1, row: 0.2}, ext: {width: 100, height: 30}})

    let titleRow = worksheet.addRow([`${company.name} - ${lang.labels.contactsList}`])
    titleRow.font = {size: 18, bold: true}
    titleRow.getCell(1).alignment = {vertical: 'distributed', horizontal: 'center'}

    worksheet.mergeCells(`A${titleRow.number}:F${titleRow.number}`)
    worksheet.getRow(2).height = 30

    worksheet.addRow([])
    worksheet.mergeCells(`A3:F3`)
    worksheet.getRow(3).height = 10

    let headerRow = worksheet.addRow(tableTitle[0])
    headerRow.font = {bold: true, color: {argb: 'FFFFFF'}}
    headerRow.eachCell( (cell) => {
      cell.alignment = {vertical: 'distributed', horizontal: 'left'}
      cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '2980BA'}, bgColor: {argb: '2980BA'}}
    })

    worksheet.getRow(4).height = 20

    tableData.forEach((item) => { worksheet.addRow(item) })

    worksheet.getColumn(1).width = 35;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 40;

    workbook.xlsx.writeBuffer().then( data => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      FileSaver.saveAs(blob, `Contacts_of_${company.name.replaceAll(' ', '_')}.xlsx`);
    })
  }

  const filePdf = async (company: Company, tableTitle: string[][], tableData: any[][]) => {
    let document = new jsPDF('landscape', 'mm', [340, 220])

    document.addImage(logotipo, 'PNG', 290, 5, 30, 9)
    
    document.setFontSize(18)
    document.setFont('helvetica', 'bold')
    document.text(`${company.name} - ${lang.labels.contactsList}`, 25, 20)

    autoTable(document, {head: tableTitle, body: tableData, startY: 25})

    document.save(`Contacts_of_${company.name.replaceAll(' ', '_')}.pdf`)
  }

  const handleChangeFormat = (item: string) => {
    setFormatToExport({ ...formatToExport, value: item, valid: true })
    legendValid('modal-contact-export-container-validate-format-required')
  }

  const executeContactExport = () => {
    setLoadIndicator('on')

    if (companyForUser && contacts && formatToExport.valid) {
      let tableTitle: string[][] = [[lang.labels.user.toUpperCase(), lang.labels.type.toUpperCase(), lang.labels.location.toUpperCase(), lang.labels.webSite.toUpperCase(), lang.labels.phone.toUpperCase(), lang.labels.email.toUpperCase()]]
      let tableData: any[][]  = [[]]

      tableData.pop()

      for (let contact of contacts) {
        tableData.push([nameContact(contact), contact.type.replace(contact.type.charAt(0), contact.type.charAt(0).toUpperCase()), `${contact.data.address.city}, ${countryName(contact.data.address.country, lang.code)}`, contact.data.website, contact.data.phone, contact.data.email])
      }

      setTimeout( () => {
        switch (formatToExport.value) {
          case 'excel':
            fileExcel(companyForUser, tableTitle, tableData)
            break;
          case  'pdf':
            filePdf(companyForUser, tableTitle, tableData)
            break;
        }

        setLoadIndicator('off')
        executeHideModalContactExport()
      }, 1000)
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
        legendInvalid('modal-contact-export-container-validate-format-required')
      })
    }
  }

  const executeHideModalContactExport = () => {
    modalHide('modal-contact-export')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function nameContact(contact: Contact): string {
    return (contact.type === 'person') ? `${contact.data.first_name} ${contact.data.last_name}` : contact.data.name
  }

  function restartModal() {
    legendValidInvalidRestart('modal-contact-export-container-validate-format-required')
    setFormatToExport({value: '', valid: false})
  }

  return (
    <div id="modal-contact-export" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.exportContacts}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="fv-row">
                <label className="form-label required">{lang.labels.selectExportFormat}:</label>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            { formatToExport.valid
                              ?
                              <>
                                {formatToExport.value === "excel" && "Excel (.xlsx)"}
                                {formatToExport.value === "pdf" && "PDF (.pdf)"}
                              </>
                              :
                              <>
                                {lang.labels.selectOption}
                              </>
                            }
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "excel" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "excel"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("excel")}>{lang.labels.excel} (.xlsx)</Dropdown.Item>
                          </li>
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "pdf" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "pdf"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("pdf")}>{lang.labels.pdf} (.pdf)</Dropdown.Item>
                          </li>
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={"modal-contact-export"} attribute={{validity: formatToExport.valid, name: "format", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalContactExport}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={executeContactExport}>
              <span className="indicator-label">{lang.labels.export}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalContactExport;
