import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import background from '../../assets/images/themes/image-error.png';

export interface NotFoundAccessPageProps {};

const NotFoundAccessPage: React.FunctionComponent<NotFoundAccessPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate('/auth/login', {replace: true})
  }

  return (
    <>
      <div className="pt-5 mb-10">
        <h1 className="fs-4x text-gray-900 text-capitalize mb-5">{lang.labels.pageNotFound}</h1>
        <div className="fw-bold fs-3 text-gray-400 mb-10">
          {lang.labels.thePageYouLookedNotFound}
          <br />
          {lang.labels.pleaseCorrectThePathOfThePageInOrderToAccess}
        </div>
        <div className="text-center">
          <button className="btn btn-lg btn-primary fw-bolder" onClick={handleOnClick}>{lang.labels.goToLoginPage}</button>
        </div>
      </div>
      <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-150px" style={{backgroundImage: `url(${background})`}}></div>
    </>
  )
};

export default NotFoundAccessPage;
