import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextProviderInvoice } from './ProviderInvoice';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { ProviderInvoiceDA } from '../../../../services/provider.invoice.service';
import { PaymentDA } from '../../../../services/payment.service';
import { Error } from '../../../../models/error.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { modalShow } from '../../../../tools/modal.tool';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.script';
import { colorStatusPaymentInvoice } from '../../../../scripts/record.badge.color.script';
import { treasuryAccountSelected } from '../../../../scripts/selected.item.list.script';
import { paymentMethodValue, paymentStatusValue } from '../../../../libraries/payment.library';

import AppModalPaymentProviderCreate from '../../../../components/modal/ModalPaymentProviderCreate';
import AppPurchaseViewDetail from '../card/PurchaseViewDetail';
import AppPurchaseViewRequirements from '../card/PurchaseViewRequirements';
import AppPurchaseViewAmount from '../card/PurchaseViewAmount';
import AppPurchaseViewSignatures from '../card/PurchaseViewSignatures';
import AppPurchaseViewVouchers from '../card/PurchaseViewVouchers';

export interface ProviderInvoiceViewPageProps {};

let errorResponse: Error, providerInvoiceResponse: ProviderInvoice, paymentResponse: ProviderInvoice;

const ProviderInvoiceViewPage: React.FunctionComponent<ProviderInvoiceViewPageProps> = props => {
  const {setRoute, companyForUser, treasuryAccountsForCompany} = UseOutletContextProviderInvoice()
  const {lang} = StateLanguage()
  const param = useParams()

  const [mounted, setMounted] = useState(false)
  const [removePayment, setRemovePayment] = useState(false)
  const [providerInvoice, setProviderInvoice] = useState<ProviderInvoice | undefined | null>(null)

  const loadProviderInvoice = async (id_company: string, id_provider_invoice: string) => {
    await ProviderInvoiceDA.getProviderInvoice(id_company, id_provider_invoice).then( (response) => {
      if (response.status === 200) {
        providerInvoiceResponse = response.data
        setProviderInvoice(providerInvoiceResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    }) 
  }

  const submitProviderInvoiceValidated = async () => {
    if (companyForUser && providerInvoice) {
      Swal.fire({
        title: lang.labels.youWantToChangeStatusTheProviderInvoice,
        text: lang.labels.youWillBeAbleToUndoThisAction,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesChangeStatus,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProviderInvoiceDA.putProviderInvoiceValidated(companyForUser.id, providerInvoice.id).then( (response) => {
            if (response.status === 200) {
              providerInvoiceResponse = response.data
              setProviderInvoice(providerInvoiceResponse)

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitPaymentDelete = async (item: string) => {
    if (companyForUser && providerInvoice) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisPayment,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await PaymentDA.deletePaymentProvider(companyForUser.id, providerInvoice.id, item).then( (response) => {
            if (response.status === 200) {
              paymentResponse = response.data

              Swal.fire({
                title: lang.labels.successfullyDeletedPayment,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setProviderInvoice(paymentResponse)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const excuteViewOrHideButtonsToPaymentDelete = () => {
    setRemovePayment(!removePayment)
  }

  const executeShowModalPaymentCreate = () => {
    modalShow('modal-payment-provider-create')
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadProviderInvoice(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.providerInvoices, branch: lang.labels.viewProviderInvoice}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && treasuryAccountsForCompany && providerInvoice
        ?
        <>
          <div className="card card-flush mb-5">
            <div className="card-header pt-3">
              <div className="card-title align-items-start flex-column">
                <h1>{providerInvoice.provider_invoice_number}</h1>
                <label className="fs-6 fw-bold mt-1">
                  {lang.labels.status}:
                  <span className={`badge badge-light-${colorStatusPaymentInvoice(providerInvoice.payment_status)} text-uppercase fs-7 ms-2`}>{paymentStatusValue(lang, providerInvoice.payment_status)}</span>
                </label>
              </div>
              <div className="card-toolbar">
                { providerInvoice.payment_status !== "fully paid" &&
                  <>
                    <button className="btn btn-light-primary mb-1 me-2" type="button" onClick={submitProviderInvoiceValidated}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                            <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                          </svg>
                        </span>
                        {providerInvoice.validated ? lang.labels.invalidateProviderInvoice : lang.labels.validateProviderInvoice}
                      </div>
                    </button>
                    { !providerInvoice.validated &&
                      <Link to={"/app/purchase/invoice/update/" + providerInvoice.id} className="btn btn-primary mb-1 me-2">
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.editProviderInvoice}
                        </div>
                      </Link>
                    }
                  </>
                }
                <Link to={"/app/purchase/invoice/create/reference/" + providerInvoice.id} className="btn btn-primary mb-1">
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="black"/>
                        <rect x="3" y="6" width="14" height="16" rx="3" fill="black"/>
                      </svg>
                    </span>
                    {lang.labels.makeCopy}
                  </div>
                </Link>
              </div>
            </div>
            <AppPurchaseViewDetail purchase={"invoice"} record={providerInvoice}></AppPurchaseViewDetail>
          </div>
          <AppPurchaseViewRequirements record={providerInvoice}></AppPurchaseViewRequirements>
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h4>{lang.labels.payments}</h4>
              </div>
              { providerInvoice.payment_status !== "fully paid" &&
                <div className="card-toolbar">
                  { providerInvoice.validated &&
                    <button className="btn btn-sm btn-primary mb-1 me-2" type="button" onClick={executeShowModalPaymentCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="black"/>
                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="black"/>
                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.makePayment}
                      </div>
                    </button>
                  }
                  { providerInvoice.validated && providerInvoice.payments.length > 0 &&
                    <button className="btn btn-sm btn-primary mb-1" type="button" onClick={excuteViewOrHideButtonsToPaymentDelete}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M8.38 22H21C21.2652 22 21.5196 21.8947 21.7071 21.7072C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 20 21 20H10L8.38 22Z" fill="black"/>
                            <path d="M15.622 15.6219L9.855 21.3879C9.66117 21.582 9.43101 21.7359 9.17766 21.8409C8.92431 21.946 8.65275 22 8.37849 22C8.10424 22 7.83268 21.946 7.57933 21.8409C7.32598 21.7359 7.09582 21.582 6.90199 21.3879L2.612 17.098C2.41797 16.9042 2.26404 16.674 2.15903 16.4207C2.05401 16.1673 1.99997 15.8957 1.99997 15.6215C1.99997 15.3472 2.05401 15.0757 2.15903 14.8224C2.26404 14.569 2.41797 14.3388 2.612 14.145L8.37801 8.37805L15.622 15.6219Z" fill="black"/>
                            <path opacity="0.3" d="M8.37801 8.37805L14.145 2.61206C14.3388 2.41803 14.569 2.26408 14.8223 2.15906C15.0757 2.05404 15.3473 2 15.6215 2C15.8958 2 16.1673 2.05404 16.4207 2.15906C16.674 2.26408 16.9042 2.41803 17.098 2.61206L21.388 6.90198C21.582 7.0958 21.736 7.326 21.841 7.57935C21.946 7.83269 22 8.10429 22 8.37854C22 8.65279 21.946 8.92426 21.841 9.17761C21.736 9.43096 21.582 9.66116 21.388 9.85498L15.622 15.6219L8.37801 8.37805Z" fill="black"/>
                          </svg>
                        </span>
                        {removePayment ? `${lang.labels.doNotDeletePayments}` : `${lang.labels.deleteSomePayment}`}
                      </div>
                    </button>
                  }
                </div>
              }
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-2 mb-1">
                    <thead className="border-bottom">
                      <tr className="text-muted text-uppercase fw-bolder fs-7">
                        <th className="w-25px text-nowrap text-start">{lang.labels.n}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.createdDate}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.method}</th>
                        <th className="min-w-100px text-nowrap text-start">{lang.labels.sourceOfIncome}</th>
                        <th className="min-w-100px text-nowrap text-end">{lang.labels.amount} ({providerInvoice.currency.symbol})</th>
                        { providerInvoice.validated && removePayment &&
                          <th className="w-75px text-nowrap text-end">{lang.labels.remove}</th>
                        }
                        </tr>
                    </thead>
                    { providerInvoice.payments.length > 0
                      ?
                      <tbody className="text-gray-700 fw-bold">
                        { providerInvoice.payments.map (( (item, index) => { return (
                          <tr key={index} className="row-dashed fw-bold">
                            <td>{index + 1}</td>
                              <td>{dateFormatAbbreviated(item.created_date)}</td>
                              <td>{paymentMethodValue(lang, item.method) }</td>
                              <td>{treasuryAccountSelected(lang, treasuryAccountsForCompany, item.source)}</td>
                              <td className="text-nowrap text-end">{moneyFormat(item.amount)}</td>
                              { providerInvoice.validated && removePayment &&
                                <td valign="middle" className="text-end p-0">
                                  <button className="btn btn-sm btn-icon btn-flex btn-bg-transparent btn-active-color-danger h-30px w-30px" type="button" onClick={() => submitPaymentDelete(item.id)}>
                                    <span className="svg-icon svg-icon-primary svg-icon-3">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                      </svg>
                                    </span>
                                  </button>
                                </td>
                              }
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody>
                        <tr className="text-gray-700 fw-bold">
                          <td valign="top" colSpan={5} className="text-center">{lang.labels.thisInvoiceHasNoPayments}</td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
          <AppPurchaseViewAmount purchase={"invoice"} record={providerInvoice}></AppPurchaseViewAmount>
          <AppPurchaseViewVouchers purchase={"invoice"} record={providerInvoice}></AppPurchaseViewVouchers>
          <AppPurchaseViewSignatures record={providerInvoice}></AppPurchaseViewSignatures>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalPaymentProviderCreate treasuryAccountsForCompany={treasuryAccountsForCompany} providerInvoice={providerInvoice} setProviderInvoice={setProviderInvoice}></AppModalPaymentProviderCreate>
    </div>
  )
}

export default ProviderInvoiceViewPage;
