import { Language } from "../languages/labels/language.labels";
import { Category } from "../models/category.model"
import { Contact } from "../models/contact.model";
import { Deadline } from "../models/deadline.model";
import { TreasuryAccount } from "../models/treasury.account.model";
import { Unit } from "../models/unit.model";


export function contactSelected(lang: Language, list: Contact[], id: string): string {
  if (list.length > 0 && list.find((item: Contact) => (item.data.id === id))) {
    let contact: Contact = list.find((item: Contact) => (item.data.id === id)) as Contact
    switch (contact.type) {
      case 'person':
        return `${contact.data.first_name} ${contact.data.last_name}`
      case 'company':
        return contact.data.name
      default:
        return contact.type
    }
  } else {
    return lang.labels.undefined
  }
}

export function categorySelected(lang: Language, list: Category[], id: string): string {
  if (list.length > 0 && list.find((item: Category) => (item.id === id))) {
    let category: Category = list.find((item: Category) => (item.id === id)) as Category
    return category.display_name
  } else if (id.length === 0) {
    return lang.labels.all
  } else {
    return lang.labels.undefined
  }
}

export function paymentDeadlineSelected(lang: Language, list: Deadline[], id: string): string {
  if (list.length > 0 && list.find((item: Deadline) => (item.id === id))) {
    let tempPaymentDeadline: Deadline = list.find((item: Deadline) => (item.id === id)) as Deadline
    return `${tempPaymentDeadline.quantity} ${tempPaymentDeadline.type}${tempPaymentDeadline.quantity > 1 ? 's' : ''}`
  } else {
    return lang.labels.undefined
  }
}

export function measureUnitSelected(lang: Language, list: Unit[], type: string, id: string): string {
  if (list.length > 0) {
    let services: Unit[] = list.filter((item: Unit) => (item.type === 'service'))
    let products: Unit[] = list.filter((item: Unit) => (item.type === 'product'))

    switch (type){
      case 'service':
        if (services.find((item: Unit) => (item.id === id))) {
          let unit: Unit = services.find((item) => item.id === id) as Unit
          return unit.name
        } else {
          return id
        }
      case 'product':
        if (products.find((item: Unit) => (item.id === id))) {
          let unit: Unit = products.find((item) => item.id === id) as Unit
          return unit.name
        } else {
          return id
        }
      default:
        return type
    }
  } else {
    return lang.labels.undefined
  }
}

export function treasuryAccountSelected(lang: Language, list: TreasuryAccount[], id: string): string {
  if (list.length > 0 && list.find((item: TreasuryAccount) => (item.id === id))) {
    let treasuryAccount: TreasuryAccount = list.find((item) => item.id === id) as TreasuryAccount
    return treasuryAccount.name
  } else {
    return lang.labels.undefined
  }
}
