import { getCode, getCodeList, getName, getNameList } from 'country-list';
import { countries } from '../languages/translations/countries';

const codeEnMap: {[key: string]: string} = {}
const codeEsMap: {[key: string]: string} = {}
const codeFrMap: {[key: string]: string} = {}
const nameEnMap: {[key: string]: string} = {}
const nameEsMap: {[key: string]: string} = {}
const nameFrMap: {[key: string]: string} = {}

function mapCodeAndName(country: any): void {
  nameEnMap[country.en.toLowerCase()] = country.code
  nameEsMap[country.es.toLowerCase()] = country.code
  nameFrMap[country.fr.toLowerCase()] = country.code
  codeEnMap[country.code.toLowerCase()] = country.en
  codeEsMap[country.code.toLowerCase()] = country.es
  codeFrMap[country.code.toLowerCase()] = country.fr
}

countries.forEach(mapCodeAndName)

export function countryList(code_language: string): string[] {
  getCodeList()
  getNameList()

  return countries.map((country: any) => {
    switch (code_language) {
      case 'ES':
        return country.es;
      case 'FR':
        return country.fr;
      default:
        return country.en;
    }
  })
}

export function countryCode(name: string, code_language: string): string {
  getCode(name)

  switch (code_language) {
    case 'ES':
      return nameEsMap[name.toLowerCase()];
    case 'FR':
      return nameFrMap[name.toLowerCase()];
    default:
      return nameEnMap[name.toLowerCase()];
  }
}

export function countryName(code: string, code_language: string): string {
  getName(code)

  switch (code_language) {
    case 'ES':
      return codeEsMap[code.toLowerCase()];
    case 'FR':
      return codeFrMap[code.toLowerCase()];
    default:
      return codeEnMap[code.toLowerCase()];
  }
}
