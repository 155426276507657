import React, { Dispatch, SetStateAction} from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { StateLanguage } from '../languages/config/StateLanguage';
import { AppService } from '../models/app.service.model';
import { Company } from '../models/company.model';
import { TreasuryAccount } from '../models/treasury.account.model';
import { modalShow } from '../tools/modal.tool';
import { drawerShow } from '../tools/drawer.tool';

import logo from '../assets/images/svg/logo-official-dark.svg';

export interface AppHeaderProps {
  activeDrawer: {value: string, aside: string, button: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string, button: string}>>,
  route: {path: {root: string, branch: string} | null, company: boolean} | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  loadNonUsersForCompany: Function
};

const AppHeader: React.FunctionComponent<AppHeaderProps> = ({activeDrawer, setActiveDrawer, route, appServiceForUser, companyForUser, treasuryAccountsForCompany, loadNonUsersForCompany}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const executeNavigationOption = (item: string) => {
    if (treasuryAccountsForCompany && treasuryAccountsForCompany.length > 0) {
      switch (item) {
        case 'quote':
          navigate('/app/sale/quote/create')
          break;
        case 'order':
          navigate('/app/sale/order/create')
          break;
        case 'invoice':
          navigate('/app/sale/invoice/create')
          break;
        case 'provider order':
          navigate('/app/purchase/order/create')
          break;
        case 'provider invoice':
          navigate('/app/purchase/invoice/create')
          break;
        case 'expense':
          navigate('/app/purchase/expense/create')
          break;
      }
    } else {
      Swal.fire({
        title: lang.labels.thereAreNoTreasuryAccounts,
        text: `${lang.labels.toCreateA} ${item}, ${lang.labels.theCompanyContainTreasutyAccount}.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.okGotIt,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then( (result) => {
        if (result.isConfirmed) {
          navigate('/app/treasury/create')
        }
      })
    }
  }

  const executeNavbarAside = () => {
    setActiveDrawer({...activeDrawer, value: 'on', aside: 'on'})
    drawerShow('navbar-aside', 'overlay-drawer')
  }

  const executeShowModalCompanyInvitation = () => {
    if (appServiceForUser && companyForUser) {
      loadNonUsersForCompany(appServiceForUser.api_key, companyForUser.id)
      modalShow('modal-company-invitation')
    }
  }

  return (
    <>
      { route &&
        <div id="wrapper-header" className="header">
          <div className="container-xxl d-flex align-items-center justify-content-between">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap">
              <h2 className="text-dark fw-bold mb-1">{(route.company && companyForUser) ? companyForUser.name : lang.labels.digitalAdmin}</h2>
              <ul className="breadcrumb breadcrumb-line text-muted fw-bold fs-base">
                { route.path
                  ?
                  <>
                    <li className="breadcrumb-item text-muted">{lang.labels.digitalAdmin} - {route.path.root}</li>
                    <li className="breadcrumb-item text-dark">{route.path.branch}</li>
                  </>
                  :
                  <>
                    <li className="breadcrumb-item text-muted">{lang.labels.digitalAdmin}</li>
                    <li className="breadcrumb-item text-dark">{route && route.company ? lang.labels.dashboard : lang.labels.welcome}</li>
                  </>
                }
              </ul>
            </div>
            <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
              <div className="btn btn-icon btn-active-icon-primary" onClick={executeNavbarAside}>
                <span className="svg-icon svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                  </svg>
                </span>
              </div>
              <div className="d-flex align-items-center cursor-pointer ms-1">
                <img src={logo} alt={lang.labels.digitalAdmin} className="h-20px" />
              </div>
            </div>
            { !route.path && companyForUser &&
              <div className="d-flex flex-shrink-0">
                <div className="d-flex ms-3">
                  <button className="btn btn-active-info bg-body btn-color-gray-600" type="button" onClick={executeShowModalCompanyInvitation}>{lang.labels.sendInvitation}</button>
                </div>
                <div className="d-flex ms-3">
                  <Dropdown>
                    <Dropdown.Toggle variant="btn btn-icon btn-info">
                      <span className="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-info w-275px py-3 fs-6 fw-bold" align="end">
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('quote')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addQuote}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('order')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addOrder}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('invoice')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addInvoice}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('provider order')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addProviderOrder}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('provider invoice')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addProviderInvoice}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-3" onClick={() => executeNavigationOption('expense')}>
                        <div className="menu-link fw-bold m-0">{lang.labels.addExpense}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default AppHeader;
