function iconEye(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('bi-eye-slash')
  element?.classList.add('bi-eye')
}

function iconEyeSlash(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('bi-eye')
  element?.classList.add('bi-eye-slash')
}

export { iconEye, iconEyeSlash }
