import React  from 'react';

import { StateLanguage } from '../languages/config/StateLanguage';

export interface AppFooterProps {};

const AppFooter: React.FunctionComponent<AppFooterProps> = () => {
  const {lang} = StateLanguage()

  return (
    <div className="footer d-flex flex-lg-column">
      <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
        <div className="text-dark order-2 order-md-1">
          <span className="text-gray-400 fw-bold me-1">{lang.labels.createdBy}</span>
          <a href="https://www.linkedin.com/company/digital-admin-sarl" className="text-muted text-hover-primary fw-bold fs-6">{lang.labels.digitalAdminSarl}</a>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a href="https://digitaladmin.ch" className="menu-link px-2">{lang.labels.about}</a>
          </li>
          <li className="menu-item">
            <a href="mailto:contact@digitaladmin.ch" className="menu-link px-2">{lang.labels.contact}</a>
          </li>
          <li className="menu-item">
            <a href="mailto:support@digitaladmin.ch" className="menu-link px-2">{lang.labels.support}</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AppFooter;
