import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { CompanyDL } from '../../services/company.service';
import { NotificationDL } from '../../services/notification.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { TokenData } from '../../models/token.data.model';
import { AppService } from '../../models/app.service.model';
import { Notification } from '../../models/notification.model';
import { modalHide } from '../../tools/modal.tool';
import { toastError, toastSuccess } from '../../tools/toast.tool';
import { imageCompany } from '../../scripts/image.value.script';
import { countryName } from '../../libraries/countries.library';

export interface AppModalNotificationCompanyProps {
  appServiceForUser: AppService | undefined | null,
  notification: Notification | undefined | null,
  reloadUserLoggedIn: Function
};

let errorResponse: Error, messageResponse: Message, tokenDataResponse: TokenData;

const AppModalNotificationCompany: React.FunctionComponent<AppModalNotificationCompanyProps> = ({appServiceForUser, notification, reloadUserLoggedIn}) => {
  const {lang} = StateLanguage()
  const param = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState({accept: 'off', reject: 'off', view: 'off'})

  const submitInvitationReply = async (item: string) => {
    if (appServiceForUser && notification) {
      switch (item) {
        case 'accept':
          setLoadIndicator({accept: 'on', reject: 'off', view: 'off'})
          break;
        case 'reject':
          setLoadIndicator({accept: 'off', reject: 'on', view: 'off'})
          break;
      }

      await CompanyDL.putCompanyInvitationReply(appServiceForUser.api_key, notification.id, notification.detail.company.id, notification.user.id, item).then( (response) => {
        if (response.status === 200) {
          tokenDataResponse = response.data

          switch (item) {
            case 'accept':
              reloadUserLoggedIn(tokenDataResponse.authorization, appServiceForUser.api_key, notification.detail.company.id, () => {
                Swal.fire({
                  title: getMessage(tokenDataResponse.data.toString(), lang.code),
                  text: lang.labels.newCompanyIsSelectedByDefault,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions).then( () => {
                  setLoadIndicator({accept: 'off', reject: 'off', view: 'off'})
                  executeHideModalNotificationCompany()

                  if (location.pathname !== '/app/dashboard' || (param.language && `/${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}` !== '/app/dashboard')) {
                    navigate('/app/dashboard')
                  }
                })
              })
              break;
            case 'reject':
              Swal.fire({
                title: getMessage(tokenDataResponse.data.toString(), lang.code),
                text: lang.labels.ifYouChangeYourMind,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator({accept: 'off', reject: 'off', view: 'off'})
                executeHideModalNotificationCompany()
              })
              break;
          }
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator({...setLoadIndicator, accept: 'off', reject: 'off', view: 'off'})
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const submitNotificationView = async () => {
    if (appServiceForUser && notification) {
      setLoadIndicator({accept: 'off', reject: 'off', view: 'on'})

      if (notification.view) {
        setLoadIndicator({accept: 'off', reject: 'off', view: 'off'})
        executeHideModalNotificationCompany()
      } else {
        await NotificationDL.putNotification(appServiceForUser.api_key, notification.id).then( (response) => {
          if (response.status === 200) {
            messageResponse = response.data
            toastSuccess(getMessage(messageResponse.message, lang.code))
          } else {
            errorResponse = response.data
            toastError(getError(errorResponse.code, lang.code))
          }

          setLoadIndicator({accept: 'off', reject: 'off', view: 'off'})
          executeHideModalNotificationCompany()
        }).catch( (error) => {
          console.error(error)
          window.location.href = '/error'
        })
      }
    }
  }

  const executeHideModalNotificationCompany = () => {
    modalHide('modal-notification-company')
  }

  return (
    <div id="modal-notification-company" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
          <div className="modal-content">
            { notification && notification.description === "company access"
              ?
              <>
                <div className="modal-header pb-0 border-0 justify-content-end">
                  { notification.type === 'activity' && !notification.view &&
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalNotificationCompany}>
                      <span className="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                        </svg>
                      </span>
                    </div>
                  }
                </div>
                <div className={`modal-body ${notification.type === 'activity' && !notification.view ? "pt-0" : "pt-1"}`}>
                  <div className="text-center mb-5">
                    <h2 className="text-capitalize mb-3">{lang.labels.accessToCompany}</h2>
                    { notification.type === 'activity' && !notification.view &&
                      <div className="text-gray-800 text-justify">
                        {lang.labels.user}
                        <strong className="mx-1">{notification.user.first_name} {notification.user.last_name}</strong>
                        {lang.labels.hasSentARequestForAccessToTheFollowingCompany}
                      </div>
                    }
                    { notification.type === 'answer' &&
                      <div className="text-gray-800 text-justify">
                        {lang.labels.theInvitationToAccess}
                        <strong className="mx-1">{notification.user.first_name} {notification.user.last_name}</strong>
                        {lang.labels.userHasBeen}
                        <strong className="ms-1 text-uppercase">{notification.detail.response}</strong>
                        .
                      </div>
                    }
                    { notification.detail.comment && notification.detail.comment.length > 0 &&
                      <div className="fs-7 text-gray-900 text-justify mt-3">
                        <strong className="text-uppercase me-1">{lang.labels.comment}:</strong>
                        {notification.detail.comment}
                      </div>
                    }
                  </div>
                  <div className="bg-light">
                    <div className="d-flex flex-stack p-3">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-45px">
                          <img src={imageCompany(notification.detail.company.logo)} alt={lang.labels.logo} />
                        </div>
                        <div className="ms-5">
                          <div className="fs-6 fw-bold text-dark">{notification.detail.company.name}</div>
                          <div className="fw-semibold text-muted">{notification.detail.company.address.city}, {countryName(notification.detail.company.address.country, lang.code)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  { notification.type === 'activity' && notification.view &&
                    <div className="text-justify text-gray-900 mt-5">
                      {lang.labels.youHaveAlreadyRespondedTo}
                      <strong className="mx-1">{notification.user.first_name} {notification.user.last_name}</strong>
                      {lang.labels.requestOnAccess}:
                      <strong className="ms-1 text-uppercase">{notification.detail.response}</strong>
                      .
                    </div>
                  }
                </div>
                <div className="modal-footer pt-0 border-0 justify-content-center align-items-end">
                  { notification.type === 'activity' && !notification.view &&
                    <>
                      <div className="mx-3">
                        <button className="btn btn-danger" type="button" data-kt-indicator={loadIndicator.reject} onClick={() => submitInvitationReply("reject")}>
                          <span className="indicator-label">{lang.labels.reject}</span>
                          <span className="indicator-progress">
                            {lang.labels.pleaseWait}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                      <div className="mx-3">
                        <button className="btn btn-success" type="button" data-kt-indicator={loadIndicator.accept} onClick={() => submitInvitationReply('accept')}>
                          <span className="indicator-label">{lang.labels.accept}</span>
                          <span className="indicator-progress">
                            {lang.labels.pleaseWait}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                    </>
                  }
                  { (notification.type === "answer" || notification.view) &&
                    <div className="mx-3">
                      <button className="btn btn-secondary" type="button" data-kt-indicator={loadIndicator.view} onClick={submitNotificationView}>
                        <span className="indicator-label">{lang.labels.okClose}</span>
                        <span className="indicator-progress">
                          {lang.labels.pleaseWait}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  }
                </div>
              </>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
      </div>
    </div>
  )
}

export default AppModalNotificationCompany;
