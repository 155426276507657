import { Texts } from '../labels/language.texts';

export type Action = {
  type: string
}

export const InitialState = {
  code: Texts.default.code,
  labels: Texts.default.labels
}

export const ReducerLanguage = (state = InitialState, action : Action) => {
  switch(action.type) {
    case 'spanish':
      return { 
        code: Texts.lang.spanish.code,
        labels: Texts.lang.spanish.labels
      }
    case 'english':
      return { 
        code: Texts.lang.english.code,
        labels: Texts.lang.english.labels
      }
    case 'french':
      return { 
        code: Texts.lang.french.code,
        labels: Texts.lang.french.labels
      }
    default:
      return state
  }
}
