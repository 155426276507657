import React, { Dispatch, SetStateAction, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { legendInvalidEmpty, legendValidRequired } from '../../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { evaluateLegendValidateRequiredList } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../components/element/Legend';

export interface AppSaleFormSignaturesProps {
  signatures: {list: boolean, value: string[], valid: boolean[]},
  setSignatures: Dispatch<SetStateAction<{list: boolean, value: string[], valid: boolean[]}>>
};

const AppSaleFormSignatures: React.FunctionComponent<AppSaleFormSignaturesProps> = ({signatures, setSignatures}) => {
  const {lang} = StateLanguage()

  const handleChangeSignature = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    let tempSignaturesValue = signatures.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return event.target.value
      } else {
        return temp_item
      }
    })

    setSignatures({...signatures, value: tempSignaturesValue})
  }

  const validateSignature = (index: number) => {
    evaluateLegendValidateRequiredList(expressions.text, index, signatures, setSignatures, 'container-validate-signature-valid-' + index, 'container-validate-signature-required-' + index)
  }

  const executeAddSignature = () => {
    let allow: boolean = true

    for (let i = 0; i < signatures.valid.length; i++) {
      if (!signatures.valid[i]) {
        legendInvalidEmpty(signatures.value[i], 'container-validate-signature-valid-' + i, 'container-validate-signature-required-' + i)
        allow = false
      }
    }

    if (allow) {
      setSignatures({list: true, value: [...signatures.value, ''], valid: [...signatures.valid, false]})
      uploadTooltip()
    }
  }

  const executeRemoveSignature = (index: number) => {
    let tempSignaturesValue = signatures.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempSignaturesValid = signatures.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setSignatures({list: true, value: tempSignaturesValue, valid: tempSignaturesValid})

    for (let i = 0; i < tempSignaturesValue.length; i++) {
      if (expressions.text.test(tempSignaturesValue[i])) {
        legendValidRequired('container-validate-signature-valid-' + i, 'container-validate-signature-required-' + i)
      } else {
        legendInvalidEmpty(tempSignaturesValue[i], 'container-validate-signature-valid-' + i, 'container-validate-signature-required-' + i)
      }
    }
  }

  useEffect( () => {
    uploadTooltip()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="card card-flush mb-5">
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.signatures}</h4>
        </div>
        <div className="card-toolbar">
          <button className="btn btn-sm btn-light-primary" type="button" onClick={executeAddSignature}>
            <div className="d-flex align-items-center">
              <span className="svg-icon svg-icon-3 ms-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                  <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                  <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                </svg>
              </span>
              {lang.labels.addSignature}
            </div>
          </button>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="dataTables_wrapper">
            <div className="table-responsive">
              <table className="table align-middle g-1 mb-1">
                <thead className="border-bottom">
                  <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                    <th className="min-w-300px text-nowrap">{lang.labels.signature}</th>
                    <th className="w-50px"></th>
                  </tr>
                </thead>
                { signatures.value.length > 0 && signatures.valid.length > 0
                  ?
                  <tbody>
                    { signatures.value.map (( (item, index) => { return (
                      <tr key={index}>
                        <td valign="top">
                          <TextareaAutosize className="form-control form-control-solid" minRows={1} maxRows={2} name="signature" autoComplete="off" value={item} onChange={(event) => handleChangeSignature(index, event)} onKeyUp={() => validateSignature(index)} onBlur={() => validateSignature(index)} />
                          <AppLegend component={null} attribute={{validity: signatures.valid[index], name: "signature", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top" className="text-end">
                          <button className="btn btn-icon btn-primary btn-light-primary" type="button" onClick={() => executeRemoveSignature(index)}>
                            <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeSignature} onClick={removeTooltip}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                    )}))}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td valign="top" colSpan={2}>
                        <div className="form-control form-control-sm form-control-solid text-center text-uppercase">{lang.labels.noRecords}</div>
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
        </div>
      </div>
    </div>
  )
};

export default AppSaleFormSignatures;
