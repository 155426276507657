import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Collapse } from 'react-bootstrap';

import { UseOutletContextAccount } from './Account';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/translations/response';
import { UserDL } from '../../../services/user.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { Container } from '../../../styles/container.style';
import { legendInvalidIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../tools/tooltip.tool';
import { removeUploadedImage, uploadImage } from '../../../scripts/upload.image.script';
import { evaluateLegendValidateRequiredIcon } from '../../../scripts/validate.legend.script';
import { validateInputPassword, validateInputPasswordConfirmation } from '../../../scripts/validate.input.password.script';
import { expressions } from '../../../libraries/regular.expressions.library';

import AppLegend from '../../../components/element/Legend';

import imgDefault from '../../../assets/images/avatar.png';

export interface AccountUpdatePageProps {};

let errorResponse: Error, messageResponse: Message;

const AccountUpdatePage: React.FunctionComponent<AccountUpdatePageProps> = props => {
  const {setRoute, userLoggedIn, appServiceForUser, reloadUserLoggedIn} = UseOutletContextAccount()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState({profile_details: 'off', account_email: 'off', account_password: 'off'})
  const [collapse, setCollapse] = useState({profile_details: true, signInMethod: true})
  const [container, setContainer] = useState({email: false, password: false})
  const [avatar, setAvatar] = useState({value: '', file: null as any, valid: false})
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [emailPassword, setEmailPassword] = useState({value: '', valid: false})
  const [passwordCurrent, setPasswordCurrent] = useState({value: '', valid: false})
  const [passwordNew, setPasswordNew] = useState({value: '', valid: false})
  const [passwordConfirmation, setPasswordConfirmation] = useState({value: '', valid: false})

  const submitUpdateProfileDetails = async () => {
    setLoadIndicator({...loadIndicator, profile_details: 'on'})

    if (appServiceForUser && avatar.valid && firstName.valid && lastName.valid && phone.valid) {
      await UserDL.putAccountUser(appServiceForUser.api_key, avatar.file, firstName.value, lastName.value, phone.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          reloadUserLoggedIn(null, null, null, () => {
            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator({...loadIndicator, profile_details: 'off'})
              restartFormProfileDetails()
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator({...loadIndicator, profile_details: 'off'})
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (firstName.value.length === 0) {
          legendInvalidIcon('input-first-name', 'container-validate-first-name-required')
        }
        if (lastName.value.length === 0) {
          legendInvalidIcon('input-last-name', 'container-validate-last-name-required')
        }
        if (phone.value.length === 0) {
          legendInvalidIcon('input-phone', 'container-validate-phone-required')
        }
        setLoadIndicator({...loadIndicator, profile_details: 'off'})
      })
    }
  }

  const submitUpdateAccountEmail = async () => {
    setLoadIndicator({...loadIndicator, account_email: 'on'})

    if (appServiceForUser && email.valid && emailPassword.valid) {
      await UserDL.putAccountEmail(appServiceForUser.api_key, email.value, emailPassword.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          reloadUserLoggedIn(null, null, null, () => {
            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator({...loadIndicator, account_email: 'off'})
              setContainer({...container, email: false})
              setEmailPassword({value: '', valid: false})
              restartFormAccountEmail()
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator({...loadIndicator, account_email: 'off'})
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (email.value.length === 0) {
          legendInvalidIcon('input-email', 'container-validate-email-required')
        }
        if (emailPassword.value.length === 0) {
          legendInvalidIcon('input-email-password', 'container-validate-email-password-required')
        }
        setLoadIndicator({...loadIndicator, account_email: 'off'})
      })
    }
  }

  const submitUpdateAccountPassword = async () => {
    setLoadIndicator({...loadIndicator, account_password: 'on'})

    if (appServiceForUser && passwordCurrent.valid && passwordNew.valid && passwordConfirmation.valid) {
      await UserDL.putAccountPassword(appServiceForUser.api_key, passwordCurrent.value, passwordNew.value, passwordConfirmation.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          reloadUserLoggedIn(null, null, null, () => {
            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator({...loadIndicator, account_password: 'off'})
              setContainer({...container, password: false})
              setPasswordCurrent({value: '', valid: false})
              setPasswordNew({value: '', valid: false})
              setPasswordConfirmation({value: '', valid: false})
              restartFormAccountPassword()
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator({...loadIndicator, account_password: 'off'})
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (passwordCurrent.value.length === 0) {
          legendInvalidIcon('input-password-current', 'container-validate-password-current-required')
        }
        if (passwordNew.value.length === 0) {
          legendInvalidIcon('input-password-new', 'container-validate-password-new-required')
        }
        if (passwordConfirmation.value.length === 0) {
          legendInvalidIcon('input-password-confirmation', 'container-validate-confirmation-password-required')
        }
        setLoadIndicator({...loadIndicator, account_password: 'off'})
      })
    }
  }

  const handleChangeAvatar = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], avatar, setAvatar, 'container-validate-avatar-type', 'container-validate-avatar-size')
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeEmailPassword = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmailPassword({ ...emailPassword, value: event.target.value })
  }

  const handleChangePasswordCurrent = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPasswordCurrent({ ...passwordCurrent, value: event.target.value })
  }

  const handleChangePasswordNew = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPasswordNew({ ...passwordNew, value: event.target.value })
  }

  const handleChangePasswordConfirmation = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPasswordConfirmation({...passwordConfirmation , value: event.target.value})
  }

  const handleRemoveAvatar = () => {
    removeUploadedImage(avatar, setAvatar, 'container-validate-avatar-type', 'container-validate-avatar-size')
    removeTooltip()
  }

  const validateFirstName = () => {
    evaluateLegendValidateRequiredIcon(expressions.user, firstName, setFirstName, 'input-first-name', 'container-validate-first-name-valid', 'container-validate-first-name-required')
  }

  const validateLastName = () => {
    evaluateLegendValidateRequiredIcon(expressions.user, lastName, setLastName, 'input-last-name', 'container-validate-last-name-valid', 'container-validate-last-name-required')
  }

  const validatePhone = () => {
    evaluateLegendValidateRequiredIcon(expressions.phone, phone, setPhone, 'input-phone', 'container-validate-phone-valid', 'container-validate-phone-required')
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
  }

  const validateEmailPassword = () => {
    evaluateLegendValidateRequiredIcon(expressions.limit, emailPassword, setEmailPassword, 'input-email-password', 'container-validate-email-password-valid', 'container-validate-email-password-required')
  }

  const validatePasswordCurrent = () => {
    evaluateLegendValidateRequiredIcon(expressions.limit, passwordCurrent, setPasswordCurrent, 'input-password-current', 'container-validate-password-current-valid', 'container-validate-password-current-required')
  }

  const validatePasswordNew = () => {
    validateInputPassword(passwordNew, setPasswordNew, 'input-password-new', 'container-validate-password-new-valid', 'container-validate-password-new-required')
    validateInputPasswordConfirmation(true, passwordNew, passwordConfirmation, setPasswordConfirmation,'input-password-confirmation', 'container-validate-confirmation-password-identical', 'container-validate-confirmation-password-valid', 'container-validate-confirmation-password-required')
  }

  const validatePasswordConfirmation = () => {
    validateInputPasswordConfirmation(false, passwordNew, passwordConfirmation, setPasswordConfirmation,'input-password-confirmation', 'container-validate-confirmation-password-identical', 'container-validate-confirmation-password-valid', 'container-validate-confirmation-password-required')
  }

  const executeCollapse = (item: string) => {
    switch (item) {
      case 'profile-details':
        setCollapse({...collapse, profile_details: !collapse.profile_details})
        break;
      case 'sign-in-method':
        setCollapse({...collapse, signInMethod: !collapse.signInMethod})
        break;
    }
  }

  const executeChangeContainer = (item: string) => {
    switch (item) {
      case 'email':
        setContainer({...container, email: !container.email})
        break;
      case 'password':
        setContainer({...container, password: !container.password})
        break;
    }
  }

  const executeRestartFormProfileDetails = () => {
    uploadData()
    restartFormProfileDetails()
  }

  function restartFormProfileDetails() {
    legendValidInvalidRestart('container-validate-logo-type')
    legendValidInvalidRestart('container-validate-logo-size')
    legendValidInvalidIconRestart('input-first-name', 'container-validate-first-name-valid')
    legendValidInvalidIconRestart('input-first-name', 'container-validate-first-name-required')
    legendValidInvalidIconRestart('input-last-name', 'container-validate-last-name-valid')
    legendValidInvalidIconRestart('input-last-name', 'container-validate-last-name-required')
    legendValidInvalidIconRestart('input-phone', 'container-validate-phone-valid')
    legendValidInvalidIconRestart('input-phone', 'container-validate-phone-required')
  }

  function restartFormAccountEmail() {
    legendValidInvalidIconRestart('input-email', 'container-validate-email-valid')
    legendValidInvalidIconRestart('input-email', 'container-validate-email-required')
    legendValidInvalidIconRestart('input-email-password', 'container-validate-email-password-valid')
    legendValidInvalidIconRestart('input-email-password', 'container-validate-email-password-required')
  }

  function restartFormAccountPassword() {
    legendValidInvalidIconRestart('input-password-current', 'container-validate-password-current-valid')
    legendValidInvalidIconRestart('input-password-current', 'container-validate-password-current-required')
    legendValidInvalidIconRestart('input-password-new', 'container-validate-password-new-valid')
    legendValidInvalidIconRestart('input-password-new', 'container-validate-password-new-required')
    legendValidInvalidIconRestart('input-password-confirmation', 'container-validate-confirmation-password-identical')
    legendValidInvalidIconRestart('input-password-confirmation', 'container-validate-confirmation-password-valid')
    legendValidInvalidIconRestart('input-password-confirmation', 'container-validate-confirmation-password-required')
  }

  function uploadData() {
    if (userLoggedIn) {
      setAvatar({value: userLoggedIn.avatar, file: userLoggedIn.avatar, valid: true})
      setFirstName({value: userLoggedIn.first_name, valid: true})
      setLastName({value: userLoggedIn.last_name, valid: true})
      setPhone({value: userLoggedIn.phone, valid: true})
      setEmail({value: userLoggedIn.email, valid: true})
    }
  }

  useEffect( () => {
    uploadData()
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.account, branch: lang.labels.updateUser}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      <div className="card mb-5">
        <div className="card-header cursor-pointer" role="button" onClick={() => executeCollapse("profile-details")}>
          <div className="card-title">
            <h4>{lang.labels.profileDetails}</h4>
          </div>
        </div>
        <Collapse in={collapse.profile_details}>
          <div className="form">
            <div className="card-body">
              <div className="row mb-5">
                <label className="col-lg-3 col-form-label">{lang.labels.avatar}</label>
                <div className="col-lg-9">
                  <div className="image-input image-input-outline" data-kt-image-input="true">
                    <img src={avatar.valid && avatar.value.length > 0 ? avatar.value : imgDefault} alt={lang.labels.avatar} className="image-input-wrapper w-auto h-125px bgi-position-center" />
                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.changeImage} onClick={removeTooltip}>
                      <i className="bi bi-pencil-fill fs-8"></i>
                      <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleChangeAvatar} />
                    </label>
                    <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow ${(!avatar.valid || avatar.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveAvatar}>
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div>
                  <div className="form-text text-justify">{lang.labels.allowedFileTypes}</div>
                  <AppLegend component={null} attribute={{validity: avatar.valid, name: "avatar", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="row mb-5">
                <label className="col-lg-3 col-form-label required">{lang.labels.fullName}</label>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input id="input-first-name" className="form-control form-control-solid mb-3 mb-lg-0" type="text" name="first-name" placeholder={lang.labels.firstName} value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                      <AppLegend component={null} attribute={{validity: firstName.valid, name: "first-name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                    <div className="col-lg-6 fv-row">
                      <input id="input-last-name" className="form-control form-control-solid" type="text" name="last-name" placeholder={lang.labels.lastName} value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                      <AppLegend component={null} attribute={{validity: lastName.valid, name: "last-name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-3 col-form-label required">{lang.labels.phoneNumber}</label>
                <div className="col-lg-9 fv-row">
                  <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                  <AppLegend component={null} attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <button className="btn btn-white btn-active-light-primary" type="reset" onClick={executeRestartFormProfileDetails}>{lang.labels.discard}</button>
              <button className="btn btn-primary ms-2" type="button" data-kt-indicator={loadIndicator.profile_details} onClick={submitUpdateProfileDetails}>
                <span className="indicator-label">{lang.labels.saveChanges}</span>
                <span className="indicator-progress">
                  {lang.labels.pleaseWait}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="card">
        <div className="card-header cursor-pointer" role="button" onClick={() => executeCollapse("sign-in-method")}>
          <div className="card-title">
            <h4>{lang.labels.signInMethod}</h4>
          </div>
        </div>
        { userLoggedIn
          ?
          <Collapse in={collapse.signInMethod}>
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-center">
                <Container property={(!container.email).toString()}>
                  <div className="fs-6 fw-bolder mb-1">{lang.labels.emailAddress}</div>
                  <div className="fw-bold text-gray-600">{userLoggedIn.email}</div>
                </Container>
                <Container property={(container.email).toString()} className="flex-row-fluid">
                  <div className="form">
                    <div className="row mb-5">
                      <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="fv-row">
                          <label className="form-label">{lang.labels.enterNewEmailAddress}</label>
                          <input id="input-email" className="form-control form-control-solid" type="text" name="email" autoComplete="off" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                          <AppLegend component={null} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="fv-row">
                          <label className="form-label">{lang.labels.confirmPassword}</label>
                          <input id="input-email-password" className="form-control form-control-solid" type="password" name="emailPassword" autoComplete="off" value={emailPassword.value} onChange={handleChangeEmailPassword} onKeyUp={validateEmailPassword} onBlur={validateEmailPassword} />
                          <AppLegend component={null} attribute={{validity: emailPassword.valid, name: "email-password", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button className="btn btn-primary me-2" type="button" data-kt-indicator={loadIndicator.account_email} onClick={submitUpdateAccountEmail}>
                        <span className="indicator-label">{lang.labels.updateEmail}</span>
                        <span className="indicator-progress">
                          {lang.labels.pleaseWait}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <button className="btn btn-color-gray-400 btn-active-light-primary" type="button" onClick={() => executeChangeContainer("email")}>{lang.labels.cancel}</button>
                    </div>
                  </div>
                </Container>
                <Container property={(!container.email).toString()} className="ms-auto">
                  <button className="btn btn-light btn-active-light-primary" type="button" onClick={() => executeChangeContainer("email")}>{lang.labels.changeEmail}</button>
                </Container>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="d-flex flex-wrap align-items-center">
                <Container property={(!container.password).toString()}>
                  <div className="fs-6 fw-bolder mb-1">{lang.labels.password}</div>
                  <div className="fw-bold text-gray-600">**********************</div>
                </Container>
                <Container property={(container.password).toString()} className="flex-row-fluid">
                  <div className="form">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="fv-row">
                          <label className="form-label">{lang.labels.currentPassword}</label>
                          <input id="input-password-current" className="form-control form-control-solid" type="password" name="password-current" autoComplete="off" value={passwordCurrent.value} onChange={handleChangePasswordCurrent} onKeyUp={validatePasswordCurrent} onBlur={validatePasswordCurrent} />
                          <AppLegend component={null} attribute={{validity: passwordCurrent.valid, name: "password-current", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="fv-row">
                          <label className="form-label">{lang.labels.newPassword}</label>
                          <input id="input-password-new" className="form-control form-control-solid" type="password" name="password-new" autoComplete="off" value={passwordNew.value} onChange={handleChangePasswordNew} onKeyUp={validatePasswordNew} onBlur={validatePasswordNew} />
                          <AppLegend component={null} attribute={{validity: passwordNew.valid, name: "password-new", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="fv-row">
                          <label className="form-label">{lang.labels.confirmNewPassword}</label>
                          <input id="input-password-confirmation" className="form-control form-control-solid" type="password" name="password-confirmation" autoComplete="off" value={passwordConfirmation.value} onChange={handleChangePasswordConfirmation} onKeyUp={validatePasswordConfirmation} onBlur={validatePasswordConfirmation} />
                          <AppLegend component={null} attribute={{validity: passwordConfirmation.valid, name: "confirmation-password", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: true, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                    </div>
                    <div className="form-text text-justify mb-5">{lang.labels.use8OrMoreCharacters}</div>
                    <div className="d-flex">
                      <button className="btn btn-primary me-2" type="button" data-kt-indicator={loadIndicator.account_password} onClick={submitUpdateAccountPassword}>
                        <span className="indicator-label">{lang.labels.updatePassword}</span>
                        <span className="indicator-progress">
                          {lang.labels.pleaseWait}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <button className="btn btn-color-gray-400 btn-active-light-primary" type="button" onClick={() => executeChangeContainer("password")}>{lang.labels.cancel}</button>
                    </div>
                  </div>
                </Container>
                <Container property={(!container.password).toString()} className="ms-auto">
                  <button className="btn btn-light btn-active-light-primary" type="button" onClick={() => executeChangeContainer("password")}>{lang.labels.changePassword}</button>
                </Container>
              </div>
            </div>
          </Collapse>
          :
          <div className="d-flex justify-content-center align-items-center w-100 h-200px">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{lang.labels.loading}</span>
            </div>
          </div>
        }
      </div>
    </>
    
  )
};

export default AccountUpdatePage;
