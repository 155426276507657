import moment, { Moment } from 'moment';

export function colorStatusQuote(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('pending'):
      return 'warning'
    case ('rejected'):
      return 'danger'
    case ('approved'):
      return 'success'
    case ('completed'):
      return 'primary'
    default:
      return 'dark'
  }
}

export function colorStatusOrder(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('pending'):
      return 'warning'
    case ('canceled'):
      return 'danger'
    case ('approved'):
      return 'success'
    case ('completed'):
      return 'primary'
    default:
      return 'dark'
  }
}

export function colorStatusInvoice(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('reminder'):
      return 'warning'
    case ('in dispute'):
      return 'primary'
    case ('completed'):
      return 'success'
    default:
      return 'dark'
  }
}

export function colorStatusPaymentInvoice(status: string): string {
  switch (status) {
    case ('fully paid'):
      return 'success'
    case ('partially paid'):
      return 'warning'
    case ('not payed'):
      return 'danger'
    default:
      return 'dark'
  }
}

export function colorValidUntilQuote(status: string, valid_until: string): string {
  if (status === 'completed' || status === 'approved' || status === 'rejected') {
    return 'dark'
  } else {
    let dateValidUntilString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValidUntil: Date = new Date(dateValidUntilString)
    let dateCurrent: Date = new Date()
    let momentValidUntil: Moment = moment(dateValidUntil)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentValidUntil.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  }
}

export function colorValidUntilOrder(status: string, valid_until: string): string {
  if (status === 'completed' || status === 'approved' || status === 'canceled') {
    return 'dark'
  } else {
    let dateValidUntilString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValidUntil: Date = new Date(dateValidUntilString)
    let dateCurrent: Date = new Date()
    let momentValidUntil: Moment = moment(dateValidUntil)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentValidUntil.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  }
}

export function colorValidUntilInvoice(status: string, valid_until: string): string {
  if (status === 'completed') {
    return 'dark'
  } else {
    let dateValidUntilString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValidUntil: Date = new Date(dateValidUntilString)
    let dateCurrent: Date = new Date()
    let momentValidUntil: Moment = moment(dateValidUntil)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentValidUntil.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  }
}

export function colorValidatedExpense(validated: boolean): string {
  if (validated) {
    return 'success'
  } else {
    return 'danger'
  }
}

export function colorPercentageDebtInvoice(total: number, amount_debt: number): string {
  let percentage: number = (amount_debt * 100) / total

  switch (true) {
    case (percentage <= 10):
      return 'success'
    case (10 < percentage && percentage < 90):
      return 'warning'
    default:
      return 'danger'
  }
}

