import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { format } from 'date-fns';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { PaymentDA } from '../../services/payment.service';
import { Error } from '../../models/error.model';
import { ProviderInvoice } from '../../models/provider.invoice.model';
import { TreasuryAccount } from '../../models/treasury.account.model';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { moneyFormat } from '../../scripts/format.value.script';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { datetimeFormat12h } from '../../scripts/datetime.script';
import { treasuryAccountSelected } from '../../scripts/selected.item.list.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { paymentMethodList, paymentMethodValue } from '../../libraries/payment.library';

import AppLegend from '../element/Legend';

export interface AppModalPaymentProviderCreateProps {
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  providerInvoice: ProviderInvoice | undefined | null,
  setProviderInvoice: Dispatch<SetStateAction<ProviderInvoice | undefined | null>>
};

let errorResponse: Error, paymentResponse: ProviderInvoice;

const AppModalPaymentProviderCreate: React.FunctionComponent<AppModalPaymentProviderCreateProps> = ({treasuryAccountsForCompany, providerInvoice, setProviderInvoice}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [datetimeCurrent, setDateTimeCurrent] = useState('')
  const [amount, setAmount] = useState({value: '', valid: false, starting: true})
  const [source, setSource] = useState({value: '', valid: false})
  const [method, setMethod] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false, starting: true})

  const submitPaymentCreate = async () => {
    setLoadIndicator('on')

    if (providerInvoice && amount.valid && source.valid && method.valid && date.valid) {
      await PaymentDA.postPaymentProvider(providerInvoice.company.id, providerInvoice.id, amount.value, source.value, method.value, date.value).then( (response) => {
        if (response.status === 201) {
          paymentResponse = response.data
          setProviderInvoice(paymentResponse)

          Swal.fire({
            title: lang.labels.successfullyCreatedPayment,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalPaymentCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (amount.value.length === 0) {
          legendInvalidIcon('modal-payment-provider-create-input-amount', 'modal-payment-provider-create-container-validate-amount-required')
        }
        if (source.value.length === 0) {
          legendInvalid('modal-payment-provider-create-container-validate-source-required')
        }
        if (method.value.length === 0) {
          legendInvalid('modal-payment-provider-create-container-validate-method-required')
        }
        if (date.value.length === 0) {
          legendInvalid('modal-payment-provider-create-container-validate-date-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAmount = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAmount({...amount, value: event.target.value})
  }

  const handleChangeSource = (item: string) => {
    setSource({...source, value: item, valid: true})
    legendValid('modal-payment-provider-create-validate-source-required')
  }

  const handleChangeMethod = (item: string) => {
    setMethod({...method, value: item, valid: true})
    legendValid('modal-payment-provider-create-container-validate-method-required')
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('modal-payment-provider-create-container-validate-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('modal-payment-provider-create-container-validate-date-required')
    }
  }

  const validateAmount = () => {
    evaluateLegendValidateRequiredIcon(expressions.currency, amount, setAmount, 'modal-payment-provider-create-input-amount', 'modal-payment-provider-create-container-validate-amount-valid', 'modal-payment-provider-create-container-validate-amount-required')
  }

  const executeHideModalPaymentCreate = () => {
    modalHide('modal-payment-provider-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function amountPayment(): boolean {
    switch (true) {
      case (providerInvoice && amount.starting && !amount.valid && amount.value === ''):
        setAmount({value: providerInvoice!!.amount.debt.toString(), valid: true, starting: false})
        return true
      case (amount.valid && amount.value.length > 0):
        return true
      default:
        return false
    }
  }

  function datePayment(): boolean {
    switch (true) {
      case (providerInvoice && date.starting && !date.valid && date.value === ''):
        setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true, starting: false})
        return true
      case (date.valid && date.value.length > 0):
        return true
      default:
        return false
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-payment-provider-create-input-amount', 'modal-payment-provider-create-container-validate-amount-valid')
    legendValidInvalidIconRestart('modal-payment-provider-create-input-amount', 'modal-payment-provider-create-container-validate-amount-required')
    legendValidInvalidRestart('modal-payment-provider-create-validate-source-required')
    legendValidInvalidRestart('modal-payment-provider-create-container-validate-method-required')
    legendValidInvalidRestart('modal-payment-provider-create-container-validate-date-required')

    setAmount({value: '', valid: false, starting: true})
    setSource({value: '', valid: false})
    setMethod({value: '', valid: false})
    setDate({value: '', valid: false, starting: true})
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      setDateTimeCurrent(datetimeFormat12h(format((new Date()), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)))
    }, 1000)

    return () => {
      clearInterval(timerID)
    }
  }, [])

  return (
    <div id="modal-payment-provider-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-700px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createNewPayment}</h2>
          </div>
          <div className="modal-body">
            { providerInvoice && treasuryAccountsForCompany
              ?
              <div className="form" onLoad={uploadTooltip}>
                <div className="text-end text-primary fw-bolder fs-8">{datetimeCurrent}</div>
                <table className="table align-middle gy-0">
                  <tbody className="fw-bold">
                    <tr>
                      <td className="w-150px text-gray-500">{lang.labels.currency}:</td>
                      <td className="min-w-200px text-gray-800">{providerInvoice.currency.name} ({providerInvoice.currency.code})</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-gray-500">{lang.labels.amountTotal}:</td>
                      <td className="min-w-200px text-gray-800">{providerInvoice.currency.symbol} {moneyFormat(providerInvoice.amount.total)}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-gray-500">{lang.labels.amountPaid}:</td>
                      <td className="min-w-200px text-gray-800">{providerInvoice.currency.symbol} {moneyFormat(providerInvoice.amount.paid)}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-gray-500 align-text-top">{lang.labels.amountDebt}:</td>
                      <td className="min-w-200px text-gray-800">{providerInvoice.currency.symbol} {moneyFormat(providerInvoice.amount.debt)}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="separator mb-5"></div>
                <div className="fv-row mb-3">
                  <label className="form-label required">{lang.labels.amountToPay}</label>
                  <div className="position-relative">
                    <div className="position-absolute translate-middle-y top-50 start-0 fw-bolder ms-5">{providerInvoice.currency.symbol}</div>  
                    <input id="modal-payment-provider-create-input-amount" className="form-control form-control-solid ps-20" type="text" name="amount" placeholder="0.00" value={amount.value} onChange={handleChangeAmount} onKeyUp={validateAmount} onBlur={validateAmount} />
                  </div>
                  <div className="form-text text-justify">{lang.labels.thisAmountCanNotBeGreater}</div>
                  <AppLegend component={"modal-payment-provider-create"} attribute={{validity: amountPayment(), name: "amount", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row mb-3">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      <span className="required">{lang.labels.sourceOfIncome}</span>
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.sourceTheAmount}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {source.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, source.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                              <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                            </li>
                            { treasuryAccountsForCompany.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === source.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === source.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeSource(item.id)}>{item.name}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={"modal-payment-provider-create"} attribute={{validity: source.valid, name: "source", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <label className="form-label required">{lang.labels.paymentMethod}</label>
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0 mb-3 mb-lg-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {method.valid ? paymentMethodValue(lang, method.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { paymentMethodList(lang).map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === method.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === method.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeMethod(item.code)}>{item.description}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={"modal-payment-provider-create"} attribute={{validity: method.valid, name: "method", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="col-lg-6 fv-row">
                    <label className="form-label required">{lang.labels.paymentDate}</label>
                    <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                    <AppLegend component={"modal-payment-provider-create"} attribute={{validity: datePayment(), name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </div >
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalPaymentCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitPaymentCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalPaymentProviderCreate;
