import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { CompanyDL } from '../../services/company.service';
import { Error } from '../../models/error.model';
import { AppService } from '../../models/app.service.model';
import { Company } from '../../models/company.model';
import { User } from '../../models/user.model';
import { legendInvalidIcon, legendValidIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidateEmpty } from '../../scripts/validate.legend.script';
import { imageUser } from '../../scripts/image.value.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../element/Legend';

export interface AppModalCompanyInvitationProps {
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  nonUsersForCompany: User[] | undefined | null
};

let errorResponse: Error, userResponse: User;

const AppModalCompanyInvitation: React.FunctionComponent<AppModalCompanyInvitationProps> = ({appServiceForUser, companyForUser, nonUsersForCompany}) => {
  const {lang} = StateLanguage()

  const [comment, setComment] = useState({value: '', valid: true})
  const [searchNonUsers, setSearchNonUsers] = useState({text: {value: '', valid: false}, list: [] as User[]})

  const submitInvitationSend = async (index: number, item: string) => {
    let element: HTMLElement = document.getElementById('modal-company-invitation-button-send-' + index) as HTMLElement

    if (appServiceForUser && companyForUser && nonUsersForCompany && comment.valid) {
      element.setAttribute('data-kt-indicator', 'on')

      await CompanyDL.putCompanyInvitation(appServiceForUser.api_key, companyForUser.id, item, comment.value).then( (response) => {
        if (response.status === 200) {
          userResponse = response.data
          nonUsersForCompany.find((temp_item: User) => (temp_item.id === item))!!.invitation = userResponse.invitation

          Swal.fire({
            title: lang.labels.successfullyInvitationSent,
            text: lang.labels.userWillRespondInvitation,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setTimeout( () => {
              element.setAttribute('data-kt-indicator', 'off')
            }, 500 )
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            element.setAttribute('data-kt-indicator', 'off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions)
    }
  }

  const submitInvitationCancel = async (index: number, item: string) => {
    let element: HTMLElement = document.getElementById('modal-company-invitation-button-cancel-' + index) as HTMLElement

    if (appServiceForUser && companyForUser && nonUsersForCompany) {
      element.setAttribute('data-kt-indicator', 'on')

      await CompanyDL.putCompanyInvitation(appServiceForUser.api_key, companyForUser.id, item, '').then( (response) => {
        if (response.status === 200) {
          userResponse = response.data
          nonUsersForCompany.find((temp_item: User) => (temp_item.id === item))!!.invitation = userResponse.invitation

          Swal.fire({
            title: lang.labels.successfullyInvitationCancel,
            text: lang.labels.notificationWillBeDeleted,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setTimeout( () => {
              element.setAttribute('data-kt-indicator', 'off')
            }, 500 )
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            element.setAttribute('data-kt-indicator', 'off')
          })
        }
      }).catch( () => {
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions)
    }
  }

  const handleChangeComment = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setComment({...comment, value: event.target.value})
  }

  const handleChangeSearchNonUsers = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: User[] = []

    if (expressions && nonUsersForCompany && text.length > 0) {
      switch (true) {
        case expressions.uuid.test(text):
          validity = true
          list = nonUsersForCompany.filter((user) => user.uuid === text)
          legendValidIcon('modal-company-invitation-input-search', 'modal-company-invitation-container-validate-search-valid')
          break;
        case expressions.text.test(text):
          for (let user of nonUsersForCompany) {
            let name: string = (`${user.first_name} ${user.last_name}`).toLowerCase()
            if (name.indexOf(text.toLowerCase()) !== -1) {
              list.push(user)
            }
          }
          validity = true
          legendValidIcon('modal-company-invitation-input-search', 'modal-company-invitation-container-validate-search-valid')
          break;
        default :
          validity = false
          list = nonUsersForCompany
          legendInvalidIcon('modal-company-invitation-input-search', 'modal-company-invitation-container-validate-search-valid')
          break;
      }
    } else if (nonUsersForCompany && text.length === 0) {
      validity = false
      list = nonUsersForCompany
      legendValidInvalidIconRestart('modal-company-invitation-input-search', 'modal-company-invitation-container-validate-search-valid')
    }

    setSearchNonUsers({text: {valid: validity, value: text}, list: list})
  }

  const validateComment = () => {
    evaluateLegendValidateEmpty(expressions.text, comment, setComment, 'modal-company-invitation-container-validate-comment-valid')
  }

  const executeHideModalCompanyInvitation = () => {
    modalHide('modal-company-invitation')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-company-invitation-container-validate-comment-valid')
    legendValidInvalidIconRestart('modal-company-invitation-input-search', 'modal-company-invitation-container-validate-search-valid')

    setComment({value: '', valid: true})
    setSearchNonUsers({text: {valid: false, value: ''}, list: []})
  }

  useEffect( () => {
    if (nonUsersForCompany) {
      setSearchNonUsers({text: {value: '', valid: false}, list: nonUsersForCompany})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonUsersForCompany])

  return (
    <div id="modal-company-invitation" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-700px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalCompanyInvitation}>
              <span className="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body pt-0">
            <div className="text-center mb-5">
              <h2 className="text-capitalize mb-3">{lang.labels.inviteUser}</h2>
              <div className="text-gray-800">
                {lang.labels.anInvitationWillBeSent}
                <strong className="mx-1">{companyForUser && companyForUser.name}</strong>
                <span className="text-lowercase">{lang.labels.company}</span>
              </div>
            </div>
            <TextareaAutosize className="form-control form-control-solid" name="comment" minRows={2} maxRows={5} placeholder={lang.labels.writeCommentToInvite} value={comment.value} onChange={handleChangeComment} onKeyUp={validateComment} onBlur={validateComment} />
            <AppLegend component={"modal-company-invitation"} attribute={{validity: comment.valid, name: "comment", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            <div className="my-5">
              <div className="fs-6 fw-bold mb-2">{lang.labels.listOfUsers}:</div>
              <div className="h-250px scroll-y px-3 border-top border-bottom">
                { nonUsersForCompany
                  ?
                    <>
                      { searchNonUsers.list.length > 0
                        ?
                        <>
                          { searchNonUsers.list.map (( (item, index) => { return (
                            <div key={index}>
                              { item.invitation !== 'accepted' &&
                                <div className={`d-flex flex-stack py-3 ${index < searchNonUsers.list.length - 1 && "border-bottom border-gray-300 border-bottom-dashed"}`}>
                                  <div className="d-flex align-items-center me-3">
                                    <div className="symbol symbol-35px symbol-circle">
                                      { item.avatar.length === 0
                                        ?
                                        <span className="symbol-label bg-primary text-inverse-primary fw-bolder">{item.last_name.charAt(0)}</span>
                                        :
                                        <img src={imageUser(item.avatar)} alt={lang.labels.avatar} />
                                      }
                                    </div>
                                    <div className="ms-5">
                                      <div className="fw-bolder text-gray-900">{item.first_name} {item.last_name}</div>
                                      <div className="fw-bold text-muted">{item.email}</div>
                                    </div>
                                  </div>
                                  <div className="w-125px">
                                    { item.invitation === 'not sent' &&
                                      <button id={"modal-company-invitation-button-send-" + index} className="btn btn-success btn-sm w-100" type="button" data-kt-indicator="off" onClick={() => submitInvitationSend(index, item.id)}>
                                        <span className="indicator-label">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-5">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="black"/>
                                                <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="black"/>
                                              </svg>
                                            </span>
                                            {lang.labels.send}
                                          </div>
                                        </span>
                                        <span className="indicator-progress">
                                          <span className="spinner-border spinner-border-sm align-middle"></span>
                                        </span>
                                      </button>
                                    }
                                    { item.invitation === 'sent' &&
                                      <button id={"modal-company-invitation-button-cancel-" + index} className="btn btn-danger btn-sm w-100" type="button" data-kt-indicator="off" onClick={() => submitInvitationCancel(index, item.id)}>
                                        <span className="indicator-label">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-5">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black"/>
                                              </svg>
                                            </span>
                                            {lang.labels.cancel}
                                          </div>
                                        </span>
                                        <span className="indicator-progress">
                                          <span className="spinner-border spinner-border-sm align-middle"></span>
                                        </span>
                                      </button>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          )}))}
                        </>
                        :
                        <div className="py-5 text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </div>
                      }
                    </>
                  :
                  <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">{lang.labels.loading}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="d-flex flex-stack">
              <div className="fw-bold w-100">
                <div className="fs-6 fw-bolder">{lang.labels.canNotFindTheUserYouWant}</div>
                <div className="fs-7 text-gray-700 text-justify mb-3">{lang.labels.searchUserForInvitate}</div>
                <input id="modal-company-invitation-input-search" className="form-control form-control-solid" type="text" name="search" autoComplete="off" placeholder={lang.labels.uuidName} value={searchNonUsers.text.value} onChange={handleChangeSearchNonUsers} />
                <AppLegend component={"modal-company-invitation"} attribute={{validity: searchNonUsers.text.valid, name: "search", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCompanyInvitation;
