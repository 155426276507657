import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextTreasury } from './Treasury';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/translations/response';
import { TreasuryAccountDA } from '../../../services/treasury.account.service';
import { Error } from '../../../models/error.model';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { uploadTooltip } from '../../../tools/tooltip.tool';
import { modalShow } from '../../../tools/modal.tool';
import { bicFormat, ibanFormat } from '../../../scripts/format.value.script';
import { countryName } from '../../../libraries/countries.library';
import { treasuryAccountTypeValue } from '../../../libraries/treasury.account.type.library';

import AppModalTreasuryUpdate from '../../../components/modal/ModalTreasuryAccountUpdate';

import background from '../../../assets/images/themes/image-list.png';

export interface TreasuryListPageProps {};

let errorResponse: Error, treasuryAccountsResponse: TreasuryAccount[];

const TreasuryListPage: React.FunctionComponent<TreasuryListPageProps> = props => {
  const {setRoute, companyForUser, treasuryAccountsForCompany, setTreasuryAccountsForCompany} = UseOutletContextTreasury()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [treasuryAccount, setTreasuryAccount] = useState<TreasuryAccount | undefined | null>(null)

  const submitTreasuryAccountUpdateDefault = async (item: TreasuryAccount) => {
    if (companyForUser && !item.default) {
      Swal.fire({
        title: lang.labels.youWantToSelectThisTreasuryAccountAsPrimary,
        text: lang.labels.youWillBeAbleToUndoThisAction,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesSelect,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await TreasuryAccountDA.putTreasuryAccountDefault(companyForUser.id, item.id).then( (response) => {
            if (response.status === 200) {
              treasuryAccountsResponse = response.data
              setTreasuryAccountsForCompany(treasuryAccountsResponse)

              Swal.fire({
                title: lang.labels.successfullyUpdatedDefaultTreasuryAccount,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitTreasuryAccountDelete = async (item: TreasuryAccount) => {
    if (companyForUser) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisTreasuryAccount,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await TreasuryAccountDA.deleteTreasuryAccount(companyForUser.id, item.id).then( (response) => {
            if (response.status === 200) {
              treasuryAccountsResponse = response.data
              setTreasuryAccountsForCompany(treasuryAccountsResponse)

              Swal.fire({
                title: lang.labels.successfullyDeletedTreasuryAccount,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const executeShowModalTreasuryAccountUpdate = (item: TreasuryAccount) => {
    setTreasuryAccount(item)
    modalShow('modal-treasury-account-update')
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treasuryAccountsForCompany])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.treasury, branch: lang.labels.listTreasuryAccounts}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { treasuryAccountsForCompany
        ?
        <>
        { treasuryAccountsForCompany.length > 0
          ?
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-2">
            { treasuryAccountsForCompany.map (( (item, index) => { return (
              <div key={index} className="col">
                <div className="card card-flush h-md-100">
                  <div className="card-header flex-nowrap">
                    <div className="card-title">
                      <h4>{item.name}</h4>
                    </div>
                    <div className="card-toolbar">
                      <button className="btn btn-sm btn-icon btn-flex btn-bg-light btn-hover-color-primary h-30px w-30px" type="button" onClick={() => submitTreasuryAccountUpdateDefault(item)}>
                        <span className={`svg-icon svg-icon-3 ${item.default && "svg-icon-primary"}`}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z" fill="black"/>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body py-0">
                    <div className="d-flex flex-column">
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8">{lang.labels.typeOfTreasuryAccount}</label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{treasuryAccountTypeValue(lang, item.type)}</label>
                      </div>
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.iban}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumber}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{item.iban.length > 0 ? ibanFormat(item.iban) : lang.labels.none}</label>
                      </div>
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.bban}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.basicBankAccountNumber}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{item.bban.length > 0 ? item.bban : lang.labels.none}</label>
                      </div>
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.bicSwift}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.bankIdentifierCode}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{item.bic.length > 0 ? bicFormat(item.bic) : lang.labels.none}</label>
                      </div>
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.membership}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.codeOfBelongingToABankingOrganization}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{item.membership.length > 0 ? item.membership : lang.labels.none}</label>
                      </div>
                      <div className="row mb-2">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.qrIban}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumberIban}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase">{item.qr_iban.length > 0 ? ibanFormat(item.qr_iban) : lang.labels.none}</label>
                      </div>
                      <div className="row mb-5">
                        <label className="text-gray-600 fw-bold fs-8 d-flex align-items-center">
                          {lang.labels.reference}
                          <i className="ms-1 fs-10" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.informationAdditional}><FaExclamationCircle /></i>
                        </label>
                        <label className="text-gray-800 fw-bolder text-uppercase d-flex align-items-center">
                          {item.reference.length > 0 ? item.reference : lang.labels.none}
                          { item.reference.length > 0 &&
                            <span className="badge badge-secondary fs-10 ms-2">{item.is_reference ? lang.labels.yes : lang.labels.no}</span>
                          }
                        </label>
                      </div>
                      <div className="border border-dashed border-gray-300 rounded-1 bg-lighten text-end w-100 px-2 py-1">
                        <p className="text-gray-800 fw-bold p-0 m-0">{item.account_holder.full_name}</p>
                        <p className="text-gray-700 fs-9 p-0 m-0">{item.account_holder.address.street} {item.account_holder.address.number}</p>
                        <p className="text-gray-700 fs-9 p-0 m-0">{item.account_holder.address.additional.length > 0 && `${item.account_holder.address.additional}, `}{item.account_holder.address.administrative_area}</p>
                        <p className="text-gray-700 fs-9 p-0 m-0">{item.account_holder.address.city} - {item.account_holder.address.zip_code}</p>
                        <p className="text-gray-700 fs-9 p-0 m-0">{countryName(item.account_holder.address.country, lang.code)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center py-5">
                    <button className="btn btn-sm btn-light-primary mx-1" type="button" onClick={() => executeShowModalTreasuryAccountUpdate(item)}>{lang.labels.edit}</button>
                    <button className="btn btn-sm btn-light-primary mx-1" type="button" onClick={() => submitTreasuryAccountDelete(item)}>{lang.labels.remove}</button>
                  </div>
                </div>
              </div>
            )}))}
          </div>
          :
          <div className="d-flex flex-column flex-center py-10">
            <h2 className="text-dark mb-1">{lang.labels.noRecordsFound}</h2>
            <label className="mb-5">{lang.labels.startCreatingNewTreasuryAccountsForYourCompany}</label>
            <img src={background} alt={lang.labels.background} className="mw-200px" />
          </div>
        }
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalTreasuryUpdate companyForUser={companyForUser} treasuryAccount={treasuryAccount} setTreasuryAccountsForCompany={setTreasuryAccountsForCompany} setTreasuryAccount={setTreasuryAccount}></AppModalTreasuryUpdate>
    </div>
  )
};

export default TreasuryListPage;
