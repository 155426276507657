import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextExpense } from './Expense';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ExpenseDA } from '../../../../services/expense.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Expense } from '../../../../models/expense.model';
import { colorValidatedExpense } from '../../../../scripts/record.badge.color.script';
import { validationValue } from '../../../../libraries/validation.library';

import AppPurchaseViewDetail from '../card/PurchaseViewDetail';
import AppPurchaseViewRequirements from '../card/PurchaseViewRequirements';
import AppPurchaseViewAmount from '../card/PurchaseViewAmount';
import AppPurchaseViewVouchers from '../card/PurchaseViewVouchers';

export interface ExpenseViewPageProps {};

let errorResponse: Error, messageResponse: Message, expenseResponse: Expense;

const ExpenseViewPage: React.FunctionComponent<ExpenseViewPageProps> = props => {
  const {setRoute, companyForUser} = UseOutletContextExpense()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [expense, setExpense] = useState<Expense | undefined | null>(null)

  const loadExpense = async (id_company: string, id_expense: string) => {
    await ExpenseDA.getExpense(id_company, id_expense).then( (response) => {
      if (response.status === 200) {
        expenseResponse = response.data
        setExpense(expenseResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    }) 
  }

  const submitExpenseValidated = async () => {
    if (companyForUser && expense) {
      Swal.fire({
        title: lang.labels.youWantToChangeStatusTheExpense,
        text: lang.labels.youWillBeAbleToUndoThisAction,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesChangeStatus,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ExpenseDA.putExpenseValidated(companyForUser.id, expense.id).then( (response) => {
            if (response.status === 200) {
              expenseResponse = response.data
              setExpense(expenseResponse)

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitExpenseDelete = async () => {
    if (companyForUser && expense) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisExpense,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ExpenseDA.deleteExpense(companyForUser.id, expense.id).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.code),
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/purchase/expense/list', {replace: true})
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  useEffect( () => {
    if(companyForUser && param.id){
      loadExpense(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.expenses, branch: lang.labels.viewExpense}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && expense
        ?
        <>
          <div className="card card-flush mb-5">
            <div className="card-header pt-3">
              <div className="card-title align-items-start flex-column">
                <h1>{expense.expense_number}</h1>
                <label className="fs-6 fw-bold mt-1">
                  {lang.labels.status}:
                  <span className={`badge badge-light-${colorValidatedExpense(expense.validated)} text-uppercase fs-7 ms-2`}>{validationValue(lang, expense.validated ? "valid" : "invalid")}</span>
                </label>
              </div>
              <div className="card-toolbar">
                <button className="btn btn-light-primary mb-1 me-2" type="button" onClick={submitExpenseValidated}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                      </svg>
                    </span>
                    {expense.validated ? lang.labels.invalidateExpense : lang.labels.validateExpense}
                  </div>
                </button>
                { !expense.validated &&
                  <>
                    <Link to={"/app/purchase/expense/update/" + expense.id} className="btn btn-primary mb-1 me-2">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.editExpense}
                      </div>
                    </Link>
                    <button className="btn btn-primary mb-1" type="button" onClick={submitExpenseDelete}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                          </svg>
                        </span>
                        {lang.labels.removeExpense}
                      </div>
                    </button>
                  </>
                }
              </div>
            </div>
            <AppPurchaseViewDetail purchase={"expense"} record={expense}></AppPurchaseViewDetail>
          </div>
          <AppPurchaseViewRequirements record={expense}></AppPurchaseViewRequirements>
          <AppPurchaseViewAmount purchase={"expense"} record={expense}></AppPurchaseViewAmount>
          <AppPurchaseViewVouchers purchase={"expense"} record={expense}></AppPurchaseViewVouchers>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default ExpenseViewPage;
