import React, { Dispatch, SetStateAction } from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { drawerButtonHide, drawerButtonShow } from '../../tools/drawer.tool';

import cardAmericanExpressBlur from '../../assets/images/icons/card-american-express-blur-icon.svg';
import cardMastercardBlur from '../../assets/images/icons/card-mastercard-blur-icon.svg';
import cardPaypalBlur from '../../assets/images/icons/card-paypal-blur-icon.svg';
import cardVisaBlur from '../../assets/images/icons/card-visa-blur-icon.svg';

export interface AppDrawerDigitalAdminProProps {
  activeDrawer: {value: string, aside: string, button: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string, button: string}>>,
  route: {path: {root: string, branch: string} | null, company: boolean} | null
};

const AppDrawerDigitalAdminPro: React.FunctionComponent<AppDrawerDigitalAdminProProps> = ({activeDrawer, setActiveDrawer, route}) => {  
  const {lang} = StateLanguage()

  const executeSideBar = () => {
    setActiveDrawer({...activeDrawer, value: 'on', button: 'on'})
    drawerButtonShow('button-digital-admin-pro', 'drawer-digital-admin-pro', 'overlay-drawer')
  }

  const executeOverlayDrawer = () => {
    setActiveDrawer({...activeDrawer, value: 'off', button: 'off'})
    drawerButtonHide('button-digital-admin-pro', 'drawer-digital-admin-pro', 'overlay-drawer')
  }

  return (
    <>
      { route && !route.path &&
        <button id="button-digital-admin-pro" className="explore-toggle btn btn-sm bg-body btn-color-gray-700 btn-active-primary shadow-sm position-fixed px-8 fw-bolder zindex-2 top-50 mt-10 end-0 transform-90 fs-5 rounded-top-0 d-none" type="button" onClick={executeSideBar}> 
          <span>{lang.labels.digitalAdminPro}</span>
        </button>
      }
      <div id="drawer-digital-admin-pro" className="explore bg-white drawer drawer-end w-25" data-kt-drawer="true">
        <div className="card shadow-none rounded-0 w-100">
          <div className="card-header">
            <h5 className="card-title fw-bold text-gray-600">{lang.labels.purchasePro}</h5>
            <div className="card-toolbar">
              <button className="btn btn-sm btn-icon explore-btn-dismiss me-n5" type="button" onClick={executeOverlayDrawer}>
                <span className="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="scroll-y me-n5 pe-5">
              <div className="rounded border border-dashed border-gray-300 py-6 px-8 mb-5">
                <div className="d-flex flex-stack mb-5">
                  <h3 className="fs-3 fw-bolder mb-0">{lang.labels.allInLicense}</h3>
                </div>
                <div className="fs-5 fw-bold mb-7">
                  <span className="text-gray-500">{lang.labels.newServicesWithinTheSameApp}</span>
                  <a className="explore-link ms-2" href="https://digitaladmin.ch">{lang.labels.licenseTerms}</a>
                </div>
                <div className="mb-7">
                  <a href="mailto:contact@digitaladmin.ch" className="btn explore-btn-primary w-100">{lang.labels.buyNow}</a>
                </div>
                <div className="d-flex flex-column flex-center mb-3">
                  <div className="mb-3 text-gray-500 fw-bold fs-6">
                    {lang.labels.securedPaymentBy}
                    <a href="mailto:support@digitaladmin.ch" target="_black" className="fw-bolder text-dark explore-link-hover mx-1">{lang.labels.options}</a>
                    {lang.labels.with}:
                  </div>
                  <div className="mb-2">
                    <img src={cardPaypalBlur} alt={lang.labels.card} className="h-30px me-3 rounded-1" />
                    <img src={cardVisaBlur} alt={lang.labels.card} className="h-30px me-3 rounded-1" />
                    <img src={cardMastercardBlur} alt={lang.labels.card} className="h-30px me-3 rounded-1" />
                    <img src={cardAmericanExpressBlur} alt={lang.labels.card} className="h-30px rounded-1" />
                  </div>
                  <div className="text-gray-400 fs-7">{lang.labels.moneyBackGuarantee}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppDrawerDigitalAdminPro;
