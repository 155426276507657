function navBarShow(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('desactive')
  element?.classList.add('active')
}

function navBarHide(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('active')
  element?.classList.add('desactive')
}

export { navBarShow, navBarHide }
