function headerShow(identifier: string) {
  let element = document.getElementById(identifier)
  element?.classList.add('animation-slide-in-down')
}

function headerHide(identifier: string) {
  let element = document.getElementById(identifier)
  element?.classList.remove('animation-slide-in-down')
}

export { headerShow, headerHide }
