function validateAttributes(item: any) {
  for (let key in item) {
    if (typeof item[key] === 'object') {
      if (Array.isArray(item[key])) {
        for (let i = 0; i < item[key].length; i++) {
          if (!validateAttributes(item[key][i])) {
            return false
          }
        }
      } else {
        if (!validateAttributes(item[key])) {
          return false
        }
      }
    } else {
      if (!item[key]) {
        return false
      }
    }
  }
  return true
}

function isEqualObjects(item_1: any, item_2: any) {
  if (typeof item_1 !== 'object' || typeof item_2 !== 'object') {
    return false
  }

  const keys1 = Object.keys(item_1)
  const keys2 = Object.keys(item_2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!item_2.hasOwnProperty(key)) {
      return false;
    }
    if (item_1[key] !== item_2[key]) {
      return false;
    }
  }

  return true;
}

export { validateAttributes, isEqualObjects }
