import React from 'react';
import { Outlet } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import logo from '../../assets/images/svg/logo-official.svg';

export interface AccessPageProps {};

const AccessPage: React.FunctionComponent<AccessPageProps> = props => {
  const {lang} = StateLanguage()

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid">
        <div className="d-flex flex-column flex-column-fluid text-center p-10">
          <div className="cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-75px mb-5" />
          </div>
          <Outlet></Outlet>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.contact}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AccessPage;
