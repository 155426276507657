import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';
import { format } from 'date-fns';
import TextareaAutosize from 'react-textarea-autosize';

import { UseOutletContextPurchase } from '../Purchase';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Currency } from '../../../../models/currency.model';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Expense } from '../../../../models/expense.model';
import { legendInvalid, legendValid } from '../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidateRequired } from '../../../../scripts/validate.legend.script';
import { datetimeFormat12h } from '../../../../scripts/datetime.script';
import { treasuryAccountSelected } from '../../../../scripts/selected.item.list.script';
import { contactTypeValue } from '../../../../libraries/contatc.type.library';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { paymentMethodList, paymentMethodValue } from '../../../../libraries/payment.library';

import AppLegend from '../../../../components/element/Legend';

export interface AppPurchaseRecordUpdateProps {
  purchase: string,
  record: any | ProviderOrder | ProviderInvoice | Expense,
  concerning: {value: string, valid: boolean},
  date: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  treasuryAccount: {value: string, valid: boolean},
  paymentMethod: {value: string, valid: boolean} | null,
  setConcerning: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setTreasuryAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPaymentMethod: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null
};

const AppPurchaseRecordUpdate: React.FunctionComponent<AppPurchaseRecordUpdateProps> = ({purchase, record, concerning, date, currency, treasuryAccount, paymentMethod, setConcerning, setDate, setCurrency, setPaymentMethod, setTreasuryAccount,}) => {
  const {companyForUser, settingsForCompany, treasuryAccountsForCompany} = UseOutletContextPurchase()
  const {lang} = StateLanguage()

  const labels: {[key: string]: {desc: string; reference: string, date: string}} = {
    order: {desc: lang.labels.pleaseModifyAddAndOrDeleteFieldsFromProviderOrder, reference: lang.labels.providerOrderReference, date: lang.labels.providerOrderDate},
    invoice: {desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromProviderInvoice, reference: lang.labels.providerInvoiceReference, date: lang.labels.providerInvoiceDate},
    expense: {desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromExpense, reference: lang.labels.expenseReference, date: lang.labels.expenseDate}
  }

  const handleChangeConcerning = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setConcerning({...concerning, value: event.target.value})
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('container-validate-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('container-validate-date-required')
    }
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('container-validate-currency-required')
  }

  const handleChangeTreasuryAccount = (item: string) => {
    setTreasuryAccount({...treasuryAccount, value: item, valid: true})
    legendValid('container-validate-treasury-account-required')
  }

  const handleChangePaymentMethod = (item: string) => {
    if (paymentMethod && setPaymentMethod) {
      setPaymentMethod({...paymentMethod, value: item, valid: true})
      legendValid('container-validate-payment-method-required')
    }
  }

  const validateConcerning = () => {
    evaluateLegendValidateRequired(expressions.text, concerning, setConcerning, 'container-validate-concerning-valid', 'container-validate-concerning-required')
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  return (
    <>
      { companyForUser && settingsForCompany && treasuryAccountsForCompany &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h2>
                {purchase === "order" && record.provider_order_number}
                {purchase === "invoice" && record.provider_invoice_number}
                {purchase === "expense" && record.expense_number}
              </h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex flex-wrap mb-5">
              <div className="flex-equal me-5">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    <tr>
                      <td className="w-200px text-gray-500">{lang.labels.company}:</td>
                      <td className="min-w-200px text-gray-800">{record.company.name}</td>
                    </tr>
                    { (purchase === "order" || purchase === "invoice") &&
                      <tr>
                        <td  valign="top" className="w-200px text-gray-500">{lang.labels.provider}:</td>
                        <td className="min-w-200px text-gray-800">
                          <div className="d-flex align-items-center">
                            {record.provider.type === "person" ? `${record.provider.data.first_name} ${record.provider.data.last_name}` : `${record.provider.data.name}`}
                            <span className="badge badge-sm badge-light text-uppercase fs-9 ms-3">{contactTypeValue(lang, record.provider.type)}</span>
                          </div>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td className="w-200px text-gray-500">{lang.labels.createdAt}:</td>
                      <td className="min-w-200px text-gray-800">{datetimeFormat12h(record.created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex-equal">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    { (purchase === "order" || purchase === "invoice") &&
                      <tr>
                        <td className="w-200px text-gray-500">{labels[purchase].reference}:</td>
                        <td className="min-w-200px text-gray-800">{record.reference.length > 0 ? record.reference : lang.labels.none}</td>
                      </tr>
                    }
                    { purchase === "invoice" &&
                      <tr>
                        <td className="w-200px text-gray-500">{lang.labels.providerOrderNumber}:</td>
                        <td className="min-w-200px text-gray-800">{record.provider_order.id.length > 0 ? record.provider_order.provider_order_number : lang.labels.none}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="fw-bold fs-6 mb-5">{labels[purchase].desc}</div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.concerning}</label>
              <div className="fv-row">
                <TextareaAutosize className="form-control form-control-solid" name="concerning" minRows={2} autoComplete="off" value={concerning.value} onChange={handleChangeConcerning} onKeyUp={validateConcerning} onBlur={validateConcerning} />
                <AppLegend component={null} attribute={{validity: concerning.valid, name: "concerning", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{labels[purchase].date}</label>
              <div className="fv-row">
                <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                <AppLegend component={null} attribute={{validity: date.valid, name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.currency}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? currencyValue(currency.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList().map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className={`row ${purchase === "expense" && paymentMethod && setPaymentMethod && "mb-3"}`}>
              <label className="form-label required">{lang.labels.treasuryAccount}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {treasuryAccount.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasuryAccount.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { treasuryAccountsForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasuryAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasuryAccount.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasuryAccount(item.id)}>{item.name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: treasuryAccount.valid, name: "treasury-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            { purchase === "expense" && paymentMethod && setPaymentMethod &&
              <div className="row">
                <label className="form-label required">{lang.labels.paymentMethod}</label>
                <div className="fv-row">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {paymentMethod.valid ? paymentMethodValue(lang, paymentMethod.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { paymentMethodList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === paymentMethod.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === paymentMethod.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePaymentMethod(item.code)}>{item.description}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={null} attribute={{validity: paymentMethod.valid, name: "payment-method", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
};

export default AppPurchaseRecordUpdate;
