import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { SettingsDA } from '../../services/settings.services';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Settings } from '../../models/settings.model';
import { legendValidInvalidIconRestart, legendInvalidIcon, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide, modalShow } from '../../tools/modal.tool';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../element/Legend';

export interface AppModalSettingTaxCreateProps {
  companyForUser: Company | undefined | null,
  data: {main_modal: string | null, index: number | null, sub_index: number | null},
  variant: {value: any, valid: any} | null,
  requirements: {list: boolean, value: any[], valid: any[]} | null,
  setSettingsForCompany: Dispatch<SetStateAction<Settings | undefined | null>>,
  setData: Dispatch<SetStateAction<{main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setTaxRate: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setVariant: Dispatch<SetStateAction<{value: any, valid: any}>> | null,
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: any[], valid: any[]}>> | null
};

let errorResponse: Error, settingsResponse: Settings;

const AppModalSettingCreateTax: React.FunctionComponent<AppModalSettingTaxCreateProps> = ({companyForUser, data, variant, requirements, setSettingsForCompany, setData, setTaxRate, setVariant, setRequirements}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [tax, setTax] = useState({value: '', valid: false})

  const submitSettingsParameterCreate = async () => {
    setLoadIndicator('on')

      if (companyForUser && data && tax.valid) {
        await SettingsDA.putSettingsParameters(companyForUser.id, tax.value, null, null).then( (response) => {
          if (response.status === 200) {
            settingsResponse = response.data
            setSettingsForCompany(settingsResponse)

            if (setTaxRate) {
              setTaxRate({value: settingsResponse.tax_fees.find((item: string) => (item === tax.value)) as string, valid: true})
              legendValid('container-validate-tax-required')
            }

            if (variant && setVariant) {
              setVariant({value: {...variant.value, tax_rate: settingsResponse.tax_fees.find((item: string) => (item === tax.value)) as string}, valid: {...variant.valid, tax_rate: true}})

              if ("main_modal" in data) {
                legendValid(data.main_modal + '-container-validate-variant-tax-required')
              } else {
                legendValid('page-container-validate-variant-tax-required')
              }
            }

            if ("index" in data && "sub_index" in data && requirements && setRequirements) {
              let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
                if (data.index === temp_index) {
                  temp_item.contents[data.sub_index!].tax_rate = settingsResponse.tax_fees.find((item: string) => (item === tax.value)) as string
                }
                return temp_item
              })
              let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
                if (data.index === temp_index) {
                  temp_item.contents[data.sub_index!].tax_rate = true
                }
                return temp_item
              })

              setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
              legendValid('container-validate-requirement-content-tax-required-' + data.index + data.sub_index)
            }

            Swal.fire({
              title: lang.labels.successfullyCreatedCompanyParameters,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalSettingsUnitCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          window.location.href = '/error'
        })
      } else {
        Swal.fire({
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
          icon: 'error',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          if (tax.value.length === 0) {
            legendInvalidIcon('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-required')
          }
          setLoadIndicator('off')
        })
      }
  }

  const handleChangeTax = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTax({...tax, value: event.target.value})
  }

  const validateTax = () => {
    evaluateLegendValidateRequiredIcon(expressions.tax, tax, setTax, 'modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-valid', 'modal-settings-create-tax-container-validate-tax-required')
  }

  const executeHideModalSettingsUnitCreate = () => {
    modalHide('modal-settings-create-tax')

    if (data.main_modal) {
      modalShow(data.main_modal)
    }

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-valid')
    legendValidInvalidIconRestart('modal-settings-create-tax-input-tax', 'modal-settings-create-tax-container-validate-tax-required')

    setData({...data, index: null, sub_index: null})
    setTax({value: '', valid: false})
  }

  return (
    <div id="modal-settings-create-tax" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addTaxRateParameter}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="row">
                <label className="col-lg-3 col-form-label required">{lang.labels.taxValue}</label>
                <div className="col-lg-9">
                  <input id="modal-settings-create-tax-input-tax" className="form-control form-control-solid pe-13" type="text" name="tax" value={tax.value} onChange={handleChangeTax} onKeyUp={validateTax} onBlur={validateTax} />
                  <AppLegend component={"modal-settings-create-tax"} attribute={{validity: tax.valid, name: "tax", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalSettingsUnitCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitSettingsParameterCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSettingCreateTax;
