import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { ApiGeolocation } from '../../services/api.geolocation';
import { ContactDA } from '../../services/contact.service';
import { Error } from '../../models/error.model';
import { User } from '../../models/user.model';
import { Company } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { legendValidInvalidRestart, legendValidInvalidRequiredRestart, legendValidInvalidIconRestart, legendValid } from '../../tools/legend.data.entry.tool';
import { restartForm } from '../../tools/stepper.tool';
import { modalHide } from '../../tools/modal.tool';
import { inputEnabledList } from '../../tools/input.enabled.disabled.tool';

import AppContactCreateStepper from '../../interfaces/main/contact/card/ContactCreateStepper';

export interface AppModalContactCreateProps {
  userLoggedIn: User | undefined | null,
  companyForUser: Company | undefined | null,
  contactsForCompany: Contact[] | undefined | null,
  languageFile: {value: string, valid: boolean} | undefined | null,
  qrTtypeFile: {value: string, valid: boolean} | undefined | null,
  setContactsForCompany: Dispatch<SetStateAction<Contact[] | undefined | null>>,
  setContact: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setLanguageFile: Dispatch<SetStateAction<{value: string, valid: boolean}>> | undefined | null,
  setQrTtypeFile: Dispatch<SetStateAction<{value: string, valid: boolean}>> | undefined | null
};

let errorResponse: Error, contactResponse: Contact;

const AppModalContactCreate: React.FunctionComponent<AppModalContactCreateProps> = ({userLoggedIn, companyForUser, contactsForCompany, languageFile, qrTtypeFile, setContactsForCompany, setContact, setLanguageFile, setQrTtypeFile}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [uuid, setUuid] = useState({value: '', valid: true})
  const [type, setType] = useState({value: '', valid: false})
  const [name, setName] = useState({value: '', valid: false})
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: true})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: true})
  const [vatNumber, setVatNumber] = useState({value: '', valid: true})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [financialEntity, setFinancialEntity] = useState({value: '', valid: true})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bban, setBban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [qrType, setQrTtype] = useState({value: '', valid: false})

  const submitContactCreate = async () => {
    setLoadIndicator('on')

    if (companyForUser && contactsForCompany) {
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = bban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')

      await ContactDA.postContact(companyForUser.id, type.value, uuid.value, name.value, firstName.value, lastName.value, logo.file, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, currency.object, financialEntity.value, valueIban, valueBban, valueBic, language.value, qrType.value, []).then( (response) => {
        if (response.status === 201) {
          contactResponse = response.data

          setContactsForCompany([contactResponse, ...contactsForCompany])
          setContact({value: contactResponse.data.id, object: contactResponse, valid: true})
          legendValid('container-validate-customer-required')

          if (languageFile && setLanguageFile) {
            setLanguageFile({value: contactResponse.data.language, valid: true})
            legendValid('container-validate-language-required')
          }
          if (qrTtypeFile && setQrTtypeFile) {
            setQrTtypeFile({value: contactResponse.data.qr_type, valid: true})
            legendValid('container-validate-qr-type-required')
          }

          Swal.fire({
            title: lang.labels.successfullyCreatedContact,
            text: lang.labels.newContactWwillAddedToCompanyAndSelectDefault,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalContactCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalContactCreate = () => {
    modalHide('modal-contact-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModalForm() {
    legendValidInvalidRestart('modal-container-validate-type-required')
    legendValidInvalidIconRestart('modal-input-name', 'modal-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-input-name', 'modal-container-validate-name-required')
    legendValidInvalidIconRestart('modal-input-first-name', 'modal-container-validate-first-name-valid')
    legendValidInvalidIconRestart('modal-input-first-name', 'modal-container-validate-first-name-required')
    legendValidInvalidIconRestart('modal-input-last-name', 'modal-container-validate-last-name-valid')
    legendValidInvalidIconRestart('modal-input-last-name', 'modal-container-validate-last-name-required')
    legendValidInvalidRequiredRestart('modal-container-validate-logo-type', 'modal-container-validate-logo-size')
    legendValidInvalidIconRestart('modal-input-email', 'modal-container-validate-email-valid')
    legendValidInvalidIconRestart('modal-input-email', 'modal-container-validate-email-required')
    legendValidInvalidIconRestart('modal-input-phone', 'modal-container-validate-phone-valid')
    legendValidInvalidIconRestart('modal-input-phone', 'modal-container-validate-phone-required')
    legendValidInvalidIconRestart('modal-input-website', 'modal-container-validate-website-valid')
    legendValidInvalidIconRestart('modal-input-vat-number', 'modal-container-validate-vat-number-valid')
    legendValidInvalidIconRestart('modal-input-register-number', 'modal-container-validate-register-number-valid')
    legendValidInvalidIconRestart('modal-input-street', 'modal-container-validate-street-valid')
    legendValidInvalidIconRestart('modal-input-street', 'modal-container-validate-street-required')
    legendValidInvalidIconRestart('modal-input-street-number', 'modal-container-validate-street-number-valid')
    legendValidInvalidIconRestart('modal-input-street-number', 'modal-container-validate-street-number-required')
    legendValidInvalidIconRestart('modal-input-additional', 'modal-container-validate-additional-valid')
    legendValidInvalidIconRestart('modal-input-administrative-area', 'modal-container-validate-administrative-area-valid')
    legendValidInvalidIconRestart('modal-input-administrative-area', 'modal-container-validate-administrative-area-required')
    legendValidInvalidIconRestart('modal-input-city', 'modal-container-validate-city-valid')
    legendValidInvalidIconRestart('modal-input-city', 'modal-container-validate-city-required')
    legendValidInvalidIconRestart('modal-input-zip-code', 'modal-container-validate-zip-code-valid')
    legendValidInvalidIconRestart('modal-input-zip-code', 'modal-container-validate-zip-code-required')
    legendValidInvalidRestart('modal-container-validate-country-required')
    legendValidInvalidRestart('modal-container-validate-currency-required')
    legendValidInvalidRestart('modal-container-validate-financial-entity-valid')
    legendValidInvalidRestart('modal-container-validate-iban-valid')
    legendValidInvalidRestart('modal-container-validate-bban-valid')
    legendValidInvalidRestart('modal-container-validate-bic-valid')
    legendValidInvalidRestart('modal-container-validate-language-required')
    legendValidInvalidRestart('modal-container-validate-qr-type-required')
    inputEnabledList('input[type="text"]')

    if (companyForUser) {
      setType({value: '', valid: false})
      setName({value: '', valid: false})
      setFirstName({value: '', valid: false})
      setLastName({value: '', valid: false})
      setLogo({value: '', file: null, valid: true})
      setEmail({value: '', valid: false})
      setPhone({value: '', valid: false})
      setWebsite({value: '', valid: true})
      setVatNumber({value: '', valid: true})
      setRegisterNumber({value: '', valid: true})
      setStreet({value: '', valid: false})
      setStreetNumber({value: '', valid: false})
      setAdditional({value: '', valid: true})
      setAdministrativeArea({value: '', valid: false})
      setCity({value: '', valid: false})
      setZipCode({value: '', valid: false})
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})
      setFinancialEntity({value: '', valid: true})
      setIban({value: '', valid: true})
      setBban({value: '', valid: true})
      setBic({value: '', valid: true})
      setLanguage({value: companyForUser.language, valid: true})
      setQrTtype({value: companyForUser.qr_type, valid: true})
    }

    setUuid({value: '', valid: true})

    loadLocationBrowser()
  }

  function restartModal() {
    restartForm('container-stepper-form', ['form-title-1', 'form-title-2', 'form-title-3', 'form-title-4', 'form-title-5'], ['form-container-1', 'form-container-2', 'form-container-3', 'form-container-4', 'form-container-5'])
    legendValidInvalidIconRestart('modal-input-uuid', 'modal-container-validate-uuid-valid')

    restartModalForm()
  }

  async function loadLocationBrowser() {
    ApiGeolocation.getLocateIPAddress().then( (response) => {
      if (response) {
        setCountry({value: response.country_code, valid: true})
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  return (
    <div id="modal-contact-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createNewContact}</h2>
              <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalContactCreate}>
              <span className="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body">
            { userLoggedIn && companyForUser
              ?
              <AppContactCreateStepper component={"modal"} loadIndicator={loadIndicator} uuid={uuid} type={type} name={name} firstName={firstName} lastName={lastName} logo={logo} email={email} phone={phone} website={website} vatNumber={vatNumber} registerNumber={registerNumber} street={street} streetNumber={streetNumber} additional={additional} administrativeArea={administrativeArea} city={city} zipCode={zipCode} country={country} currency={currency} financialEntity={financialEntity} iban={iban} bban={bban} bic={bic} language={language} qrType={qrType} companies={null} setUuid={setUuid} setType={setType} setName={setName} setFirstName={setFirstName} setLastName={setLastName} setLogo={setLogo} setEmail={setEmail} setPhone={setPhone} setWebsite={setWebsite} setVatNumber={setVatNumber} setRegisterNumber={setRegisterNumber} setStreet={setStreet} setStreetNumber={setStreetNumber} setAdditional={setAdditional} setAdministrativeArea={setAdministrativeArea} setCity={setCity} setZipCode={setZipCode} setCountry={setCountry} setCurrency={setCurrency} setFinancialEntity={setFinancialEntity} setIban={setIban} setBban={setBban} setBic={setBic} setLanguage={setLanguage} setQrTtype={setQrTtype} setCompanies={null} submitContactCreate={submitContactCreate}></AppContactCreateStepper>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalContactCreate;
