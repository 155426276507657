function pageCurrent (index: number, list_page: string[]) {
  for (let i = 0; i < list_page.length; i++) {
    let element = document.getElementById(list_page[i])

    if (i + 1 === index) {
      element?.classList.add('active')
    } else {
      element?.classList.remove('active')
    }
  }
}

function pageLimitEnabled(identifier: string) {
  let element = document.getElementById(identifier)
  element?.classList.remove('disabled')
}

function pageLimitDisabled(identifier: string) {
  let element = document.getElementById(identifier)
  element?.classList.add('disabled')
}

export { pageCurrent, pageLimitEnabled, pageLimitDisabled }
