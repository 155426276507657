import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { ResourceUUID } from '../models/resource.uuid.model';
import { appToken } from '../scripts/app.token.script';

export class UuidDL {
  static apiResponse: ApiResponse

  public static async getUuid(api_key: string, uuid: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let resourceUser: ResourceUUID | null = null
      let resourceCompany: ResourceUUID | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/uuid.user.data.json').then(response => { resourceUser = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/uuid.company.data.json').then(response => { resourceCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (resourceUser!.data.uuid === uuid || resourceCompany!.data.uuid === uuid) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (resourceUser!.data.uuid === uuid) ? resourceUser : resourceCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/uuid/${uuid}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
