import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { TokenAccess } from '../models/token.access.model';
import { User } from '../models/user.model';

export class AccessDL {
  static apiResponse: ApiResponse

  public static async invitation(api_key: string, email: string, invitation_token: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let tokenAccess: TokenAccess | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/token.access.data.json').then(response => { tokenAccess = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (tokenAccess!.email === email && tokenAccess!.token === invitation_token) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: tokenAccess
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/companies/access`

      let data: any = {
        email: email,
        invitation_token: invitation_token 
      }

      await axios.post(url, data).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async verification(api_key: string, email: string, verification_token: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let user: User | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/user.data.json').then(response => { user = response.data.user }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (user!.email === email) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/user/verification`

      let data: any = {
        email: email,
        verification_token: verification_token 
      }

      await axios.post(url, data).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
