import React from 'react';

import { StateLanguage } from '../languages/config/StateLanguage';
import { Company } from '../models/company.model';

export interface AppTitleProps {
  route: {path: {root: string, branch: string} | null, company: boolean} | null,
  companyForUser: Company | undefined | null
};

const AppTitle: React.FunctionComponent<AppTitleProps> = ({route, companyForUser}) => {
  const {lang} = StateLanguage()

  return (
    <>
      { route &&
        <div className="page-title d-flex d-lg-none flex-column align-items-start justify-content-center flex-wrap mb-5">
          <h2 className="text-dark fw-bold mb-1">{(route.company && companyForUser) ? companyForUser.name : lang.labels.digitalAdmin}</h2>
          <ul className="breadcrumb breadcrumb-line text-muted fw-bold fs-base">
            { route.path
              ?
              <>
                <li className="breadcrumb-item text-muted">{lang.labels.digitalAdmin} - {route.path.root}</li>
                <li className="breadcrumb-item text-dark">{route.path.branch}</li>
              </>
              :
              <>
                <li className="breadcrumb-item text-muted">{lang.labels.digitalAdmin}</li>
                <li className="breadcrumb-item text-dark">{route && route.company ? lang.labels.dashboard : lang.labels.welcome}</li>
              </>
            }
          </ul>
        </div>
      }
    </>
  )
}

export default AppTitle;
